import React, { useEffect, useState } from "react";
import HeadingWithSearchBar from "../../common/HeadingWithSearchBar";
import ProductCard from "../../common/ProductCard";
import ViewAllBtnCard from "../../common/ViewAllBtnCard";
import { useSelector } from "react-redux";
import NoResultFound from "../../common/NoResultFound";
import { BASE_URL } from "../../env";

const SearchProductCategory = ({
  homeCategory,
  searchProduct,
  stateSearchProduct,
}) => {
  const categoryProduct = homeCategory?.filter(
    (category) => category?.menu_position === 1
  );
  // const categoryData = useSelector((state) => state?.AllCategoryState);
  // useEffect(() => {
  //   if (allCategory?.length > 0) {
  //     const response = fetch(
  //       `${BASE_URL}catalogue/get-products-by-main-category/${
  //         allCategory?.filter((category) => category.home_screen === true)[0]
  //           ?.id
  //       }/`
  //     )
  //       .then((res) => res.json())
  //       .then((result) => setData(result.data));
  //   }
  // }, [categoryData]);

  console.log("searchProduct", searchProduct);
  return (
    <>
      <div className="container py-4  ">
        <div className="d-flex flex-column  gap-4">
          <HeadingWithSearchBar
            heading={
              searchProduct?.length > 1 ? (
                <span>
                  {searchProduct?.length} items found for{" "}
                  <span style={{ color: "rgb(0, 128, 0)" }}>
                    "{stateSearchProduct}"
                  </span>
                </span>
              ) : (
                <span>
                  {searchProduct?.length} item found for{" "}
                  <span style={{ color: "rgb(0, 128, 0)" }}>
                    "{stateSearchProduct}"
                  </span>
                </span>
              )
            }
          />
          {searchProduct?.length > 0 ? (
            <div className="row ">
              {searchProduct?.map((product, index) => (
                <div
                  key={product?.id}
                  className="col-6 col-sm-4 col-md-3 col-lg-2 mb-3 "
                >
                  <ProductCard
                    categoryId={product?.category?.id}
                    home={true}
                    categoryName={product?.category?.item_name}
                    product={product}
                    id={product?.id}
                  />
                </div>
              ))}
            </div>
          ) : (
            <NoResultFound />
          )}
        </div>
      </div>
    </>
  );
};

export default SearchProductCategory;
