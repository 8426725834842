import React from "react";
import hatImage from "../assets/image/product/89f830b59f96befd90acffcea62ffaf0.png";
import bagImage from "../assets/image/product/40ae99a9d9526b369372d47035867706.png";
import { BsStar } from "react-icons/bs";
import { FaCartPlus } from "react-icons/fa";
import productDummyImage from "../assets/image/product.jpeg";
import { useDispatch } from "react-redux";
import { addToCart } from "../store/uiSlices/AddToCartSlice";
import NoResultFound from "./NoResultFound";
import { useNavigate } from "react-router-dom";
import { setPriceRangeFirstLoading } from "../store/uiSlices/PriceRangeFirstLoading";

const AddToCartBtn = ({ products }) => {
  const dispatch = useDispatch();
  const handleAddtoCart = () => {
    dispatch(
      addToCart({
        ...products,
        count: 1,
        productColor: "",
        productSize: "",
        itemTotalPrice: products.offer
          ? products?.offer_price
          : products.selling_price,
      })
    );
  };
  return (
    <div
      onClick={handleAddtoCart}
      className="d-flex align-items-center  justify-content-center  gap-3 add-to-cart-btn"
    >
      <FaCartPlus /> <p>Add to cart</p>
    </div>
  );
};

const RreviewAndDeatail = ({ products, categoryName, categoryId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const difference = products?.selling_price - products?.offer_price;
  const formattedDifference = parseFloat(difference).toFixed(2);
  return (
    <div className=" d-flex flex-column align-items-center  justify-content-center gap-3 text-center ">
      <p
        style={{ lineHeight: "22px", cursor: "pointer" }}
        onClick={() => {
          dispatch(setPriceRangeFirstLoading(false));
          navigate(
            `${`/${categoryName}/${products?.item_name.split("/").join(" ")}`}`,
            {
              state: {
                path: categoryName,
                id: categoryId,
                product: products,
              },
            }
          );
        }}
      >
        {products.item_name.length > 50
          ? `${products.item_name.slice(0, 50)}...`
          : products.item_name}
      </p>
      <div className="text-center d-flex flex-column  align-items-center">
        <div className="d-flex gap-1 text-center">
          <BsStar style={{ fontSize: "15px", color: "#f5ae0f" }} />
          <BsStar style={{ fontSize: "15px", color: "#f5ae0f" }} />
          <BsStar style={{ fontSize: "15px", color: "#f5ae0f" }} />
          <BsStar style={{ fontSize: "15px", color: "#f5ae0f" }} />
          <BsStar style={{ fontSize: "15px", color: "#f5ae0f" }} />
        </div>
        <div className="d-flex flex-column  align-items-center justify-content-center">
          <del className="mt-1">$ {products?.selling_price}</del>
          <div>
            <p>$ {products?.offer_price}</p>
            <p className="" style={{ color: "#a50034" }}>
              Save $ {formattedDifference}
            </p>
          </div>
        </div>
      </div>
      <AddToCartBtn products={products} />
    </div>
  );
};

const GroupCardVertical = ({ products, categoryName, categoryId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className="d-flex flex-column align-items-center h-100 ">
      <div
        className="d-flex align-items-center  justify-content-center "
        style={{ maxWidth: "250px", width: "170px", height: "160px" }}
      >
        {products?.product_media?.length > 0 ? (
          <img
            src={products?.product_media[0]?.image}
            style={{ maxHeight: "160px", maxWidth: "100%", cursor: "pointer" }}
            className=""
            loading="lazy"
            onClick={() => {
              dispatch(setPriceRangeFirstLoading(false));
              navigate(
                `${`/${categoryName}/${products?.item_name
                  .split("/")
                  .join(" ")}`}`,
                {
                  state: {
                    path: categoryName,
                    id: categoryId,
                    product: products,
                  },
                }
              );
            }}
          />
        ) : (
          <img
            src={productDummyImage}
            style={{ maxHeight: "160px", maxWidth: "180px" }}
          />
        )}
      </div>
      <div className="text-center">
        <RreviewAndDeatail
          products={products}
          categoryName={categoryName}
          categoryId={categoryId}
        />
      </div>
    </div>
  );
};

const ItemsGroupcard = ({ products, categoryName, categoryId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div
      className="d-flex flex-column h-100 bg-white w-100"
      style={{ border: "1px solid var(--border-color-gray)" }}
    >
      <div
        className="d-flex flex-column  flex-lg-row align-items-center justify-content-center card-hover  group-top-card group-card-horezental"
        style={{
          borderBottom: "1px solid var(--border-color-gray)",
          padding: "30px 20px 32px 0",
        }}
      >
        <div className="w-100  d-flex  align-items-center justify-content-center hate-cont">
          {products?.length > 0 &&
          products[1]?.product_media?.length > 0 &&
          products[1]?.product_media[1]?.image ? (
            <img
              src={products[1]?.product_media[1]?.image}
              style={{ height: "160px", cursor: "pointer" }}
              className=""
              onClick={() => {
                dispatch(setPriceRangeFirstLoading(false));
                navigate(
                  `${`/${categoryName}/${products[1]?.item_name
                    .split("/")
                    .join(" ")}`}`,
                  {
                    state: {
                      path: categoryName,
                      id: categoryId,
                      product: products[1],
                    },
                  }
                );
              }}
            />
          ) : (
            <img
              src={productDummyImage}
              style={{ maxHeight: "160px", maxWidth: "180px" }}
            />
          )}
        </div>
        <div className="w-100  hate-cont ">
          <RreviewAndDeatail
            products={products?.length > 0 && products[1]}
            categoryName={categoryName}
            categoryId={categoryId}
          />
        </div>
      </div>
      <div className="d-flex flex-column flex-sm-row  h-100 justify-content-between ">
        <div
          className="w-100 group-vertical-card card-hover"
          style={{
            padding: "30px 20px 32px 20px",
          }}
        >
          {products?.length > 0 && products[4] ? (
            <GroupCardVertical
              products={products?.length > 0 && products[4]}
              categoryName={categoryName}
              categoryId={categoryId}
            />
          ) : (
            <NoResultFound />
          )}
        </div>
        <div
          className="w-100 group-vertical-card  sec-verticale-card card-hover"
          style={{
            borderLeft: "1px solid var(--border-color-gray)",
            padding: "30px 20px 32px 20px",
          }}
        >
          {products?.length > 0 && products[3] ? (
            <GroupCardVertical
              products={products?.length > 0 && products[3]}
              categoryName={categoryName}
              categoryId={categoryId}
            />
          ) : (
            <NoResultFound />
          )}
          {/* <GroupCardVertical products={products?.length > 0 && products[2]} /> */}
        </div>
      </div>
    </div>
  );
};

export default ItemsGroupcard;
