import React from "react";
import BottomFullModal from "../../../common/BottomFullModal";
import ProfileCard from "../../ProfileComponent/ProfileCard";
import ProfileDetail from "../../ProfileComponent/ProfileDetail";

function ProfilePopUp({ title, show, setShow }) {
  return (
    <BottomFullModal
      profile={true}
      show={show}
      setShow={setShow}
      title={`${title}`}
    >
      <div style={{ background: "#f8f8f8" }} className="container">
        <div className="  my-4 py-4">
          <h2
            className="fw-semibold d-flex  mb-5 justify-content-sm-start  justify-content-center w-100"
            style={{ fontSize: "24px" }}
          >
            Profile
          </h2>
          <div className="d-flex  flex-column align-items-center gap-3 justify-content-center">
            {/* =========profile card =========== */}
            <div className="w-100 d-flex justify-content-center ">
              <ProfileCard />
            </div>
            {/* ===========profile detail ========= */}
            <ProfileDetail />
          </div>
        </div>
      </div>
    </BottomFullModal>
  );
}

export default ProfilePopUp;
