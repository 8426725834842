import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { selectLoginVal } from "../store/uiSlices/loginStateSlice";

const ProtectedRoute = ({ token, children }) => {
  const loginVal = useSelector(selectLoginVal);
  const [userData, setUserData] = useState("");
  const location = useLocation();

  useEffect(() => {
    const uservalue = localStorage.getItem("userLogin");
    const tokenVALUE = JSON.parse(uservalue);
    setUserData(tokenVALUE?.data?.token?.access);
  }, [loginVal]);
  if (userData === undefined) {
    return <Navigate to="/" token={{ from: location }} replace />;
  }
  return children;
};

export default ProtectedRoute;
