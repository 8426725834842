import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "./Modal";
import StartingModal from "./StartingModal";
import OpeningHourModal from "./OpeningHourModal";
import { BsStar } from "react-icons/bs";
import { ApiCall, initializeApiWithHeaders } from "./PostApiRequest";
import { toast } from "react-toastify";
import { LoaderAPICalls } from "./LoaderAPICalls";
import { BalanceAndProfit } from "../store/BoostDataSlice/GetBalanceandProfitSlice";
import { AllSetsRecord } from "../store/BoostDataSlice/GetAllSetSlice";
import { BoostDataUser } from "../store/BoostDataSlice/GetBoostDataUserSlice";
import { ProductSets } from "../store/BoostDataSlice/GetProductSetsSlice";
import { setIncorrectType } from "../store/uiSlices/IncorrectTypeSlice";
import { setAddTask } from "../store/uiSlices/AddTaskSlice";
import { setPreviousSubmission } from "../store/uiSlices/PreviousSubmissionSlice";
import { selectLoginVal, setLoginVal } from "../store/uiSlices/loginStateSlice";
import CurrentlyLoggedInComponent from "./CurrentlyLoggedIn";
import CongratulationModal from "./CongratulationModal";
import InsufficiantBalance from "../components/categories/boostDataComponent/InsufficientBalance";
const BoostDataCard = ({ data, isLoading, setPausedSlide }) => {
  const dispatch = useDispatch();
  const boostDataUser = useSelector(
    (state) => state?.GetBoostDataUserSlice?.data?.data
  );
  const user_level = boostDataUser?.user_level;
  const balanceAndProfit = useSelector(
    (state) => state?.GetBalanceRecords?.data?.data
  );
  const loginVal = useSelector(selectLoginVal);
  const [showCurrentlyLoggedIn, setShowCurrentlyLoggedIn] = useState(false);
  const [showStartingModal, setShowStartingModal] = useState(false);
  const [openingHourModal, setOpeningHourModal] = useState(false);
  const [userData, setUserData] = useState(loginVal);
  const [insufficiantBalance, setInsufficiantBalance] = useState(false);
  const [showCongratulations, setShowCongratulations] = useState(false);
  const [recordId, setRecordId] = useState("");
  useEffect(() => {
    dispatch(BoostDataUser());
    dispatch(BalanceAndProfit());
  }, []);
  const currentTime = new Date();
  const openingTime = new Date(currentTime);
  const closingTime = new Date(currentTime);
  // Parse opening time
  const openingTimeParts = data.opening_time.split(":");
  openingTime.setHours(parseInt(openingTimeParts[0], 10));
  openingTime.setMinutes(parseInt(openingTimeParts[1], 10));
  openingTime.setSeconds(parseInt(openingTimeParts[2], 10));
  // Parse closing time
  const closingTimeParts = data.closing_time.split(":");
  closingTime.setHours(parseInt(closingTimeParts[0], 10));
  closingTime.setMinutes(parseInt(closingTimeParts[1], 10));
  closingTime.setSeconds(parseInt(closingTimeParts[2], 10));
  let isSetComplete = data?.submitted_task === data?.assigned_tasks;

  const startingNowClick = async () => {
    if (currentTime >= openingTime && currentTime <= closingTime) {
      setShowStartingModal(true);
      await boostDataAdd();
    } else {
      setOpeningHourModal(true);
    }
    const minimumBalance = parseFloat(data?.minimum_balance_required);
    const balance = parseFloat(balanceAndProfit?.total_balance);
    if (balance < minimumBalance) {
      setInsufficiantBalance(true);
    }
  };

  const currentlyLoggedInClick = () => {
    setShowCurrentlyLoggedIn(true);
  };

  const boostDataAdd = async () => {
    dispatch(setAddTask(false));
    dispatch(setIncorrectType(false));
    dispatch(setPreviousSubmission(false));
    initializeApiWithHeaders();
    let productID;

    try {
      if (data?.set_amount !== "") {
        const result = await dispatch(ProductSets(data?.set_amount));
        productID = result?.payload?.data?.id;

        if (
          result?.payload?.meta?.message ===
          "No products available for boost data !"
        ) {
          dispatch(setAddTask(false));
          dispatch(setIncorrectType(true));
          dispatch(setPreviousSubmission(false));
        }
      }
      if (productID !== undefined) {
        const formData = new FormData();
        formData.append("boosted_product", productID);
        formData.append("boosted_by", boostDataUser?.id);
        formData.append("set", data?.id);

        const response = await ApiCall(
          "boost-data/add-task/",
          "POST",
          formData
        );

        dispatch(setAddTask(true));
        dispatch(setIncorrectType(false));
        dispatch(setPreviousSubmission(false));

        if (response?.meta?.status_code === 200) {
          setRecordId(response?.data?.id);
          dispatch(AllSetsRecord(user_level)).then((result) => {
            if (
              result?.error?.message === "Request failed with status code 401"
            ) {
              dispatch(setLoginVal(false));
              localStorage.removeItem("userLogin");
            }
          });
          dispatch(BalanceAndProfit());
          dispatch(BoostDataUser());
        }
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response) {
      if (error.response.status === 400) {
        if (
          error.response?.data?.meta?.message ===
          "You have completed your today task, you can submit your task next day !"
        ) {
          toast.error(error.response?.data?.meta?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        if (
          error.response?.data?.meta?.message ===
          "Kindly complete the previous set tasks before you proceed next set"
        ) {
          toast.error(error.response?.data?.meta?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        if (
          error.response?.data?.meta?.message ===
          "Product already exist, try another one !"
        ) {
          toast.error(error.response?.data?.meta?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        if (
          error.response?.data?.meta?.message ===
            "You cannot submit your new task until you have completed your previous pending task. !"
        ) {
          dispatch(setAddTask(false));
          dispatch(setIncorrectType(false));
          dispatch(setPreviousSubmission(true));
        }
      }
    }
  };
  useMemo(() => {
    const uservalue = localStorage.getItem("userLogin");
    const tokenVALUE = JSON.parse(uservalue);
    setUserData(tokenVALUE?.data?.token?.access);
  }, [!loginVal]);

  const handleButtonClick = () => {
    startingNowClick();
  };
  return (
    <>
      {isLoading && (
        <div className="loader-container">
          <LoaderAPICalls />
        </div>
      )}

      <div
        className="boost-data-card mt-2 "
        style={{
          width: "100%",
        }}
      >
        <div className="bg-white mb-2">
          <div
            className="boost-data-sub-card d-flex boostData-card-top align-items-center justify-content-center card-hover"
            style={{
              paddingTop: "30px ",
              paddingBottom: "20px",
              border: "1px solid var(--border-color-gray)",
            }}
          >
            <div>
              <img src={data?.image} />
            </div>
            <div className="text-center">
              <div>
                {/* ========== title ==========*/}
                {data?.name === "FIRST SET" && (
                  <b style={{ color: `` }}>{data?.name}</b>
                )}
                {data?.name === "SECOND SET" && (
                  <b style={{ color: `orange` }}>{data?.name}</b>
                )}
                {data?.name === "THIRD SET" && (
                  <b style={{ color: `red` }}>{data?.name}</b>
                )}
                {data?.name === "FOURTH SET" && (
                  <b style={{ color: `darkblue` }}>{data?.name}</b>
                )}
                {data?.name === "FIFTH SET" && (
                  <b style={{ color: `darkgreen` }}>{data?.name}</b>
                )}
                {data?.name === "SIXTH SET" && (
                  <b style={{ color: `purple` }}>{data?.name}</b>
                )}
                {/* ========== task =========== */}
                {data?.name === "FIRST SET" && (
                  <p style={{ color: `` }}>{data?.assigned_tasks} tasks</p>
                )}
                {data?.name === "SECOND SET" && (
                  <p style={{ color: `orange` }}>
                    {data?.assigned_tasks} tasks
                  </p>
                )}
                {data?.name === "THIRD SET" && (
                  <p style={{ color: `red` }}>{data?.assigned_tasks} tasks</p>
                )}
                {data?.name === "FOURTH SET" && (
                  <p style={{ color: `darkblue` }}>
                    {data?.assigned_tasks} tasks
                  </p>
                )}
                {data?.name === "FIFTH SET" && (
                  <p style={{ color: `darkgreen` }}>
                    {data?.assigned_tasks} tasks
                  </p>
                )}
                {data?.name === "SIXTH SET" && (
                  <p style={{ color: `purple` }}>
                    {data?.assigned_tasks} tasks
                  </p>
                )}

                <div className="d-flex justify-content-center gap-1">
                  {data?.star?.map((star) => (
                    <div key={star}>
                      {/* ========== stars =========== */}
                      {data?.name === "FIRST SET" && (
                        <BsStar className="fs-5" style={{ color: `` }} />
                      )}
                      {data?.name === "SECOND SET" && (
                        <BsStar className="fs-5" style={{ color: `orange` }} />
                      )}
                      {data?.name === "THIRD SET" && (
                        <BsStar className="fs-5" style={{ color: `red` }} />
                      )}
                      {data?.name === "FOURTH SET" && (
                        <BsStar
                          className="fs-5"
                          style={{ color: `darkblue` }}
                        />
                      )}
                      {data?.name === "FIFTH SET" && (
                        <BsStar
                          className="fs-5"
                          style={{ color: `darkgreen` }}
                        />
                      )}
                      {data?.name === "SIXTH SET" && (
                        <BsStar className="fs-5" style={{ color: `purple` }} />
                      )}
                    </div>
                  ))}
                </div>
                <p className="fw-bold text-end pe-3  d-sm-none ">
                  {parseInt(data?.set_amount, 10)} USDT
                </p>
              </div>
            </div>
          </div>
          <p
            className="fw-bold text-end pe-3 d-none d-sm-block "
            style={{ marginTop: "-25px" }}
          >
            {parseInt(data?.set_amount, 10)} USDT
          </p>
        </div>
        <div
          className="mb-2 bg-white card-hover boost-data-sub-card boost-data-sub-text-card d-flex align-items-center justify-content-between  boost-data-card-list-cont  flex-column gap-1"
          style={{
            border: "1px solid var(--border-color-gray)",
          }}
        >
          <ul
            style={{
              listStyle: "disc",
              fontWeight: "500",
              fontSize: " 0.8rem",
            }}
          >
            {data?.list.map((li, index) => (
              <li key={index} className="boost-data-card-list">
                {li.li}
              </li>
            ))}
          </ul>
        </div>
        <div
          className="bg-white card-hover boost-data-sub-card d-flex align-items-center justify-content-between flex-column gap-1"
          style={{
            border: "1px solid var(--border-color-gray)",
            padding: "30px 0px 32px",
            height: "130px",
          }}
        >
          {userData ? (
            <>
              <button
                key={data.name}
                onClick={handleButtonClick}
                className={`rounded-pill ${
                  isSetComplete || data?.status
                    ? "complete-class"
                    : "starting-btn"
                }`}
                style={{
                  maxWidth: "200px",
                  padding: "0.525rem 2rem",
                  cursor:
                    data?.status || isSetComplete ? "not-allowed" : "pointer",
                }}
                disabled={isSetComplete || data?.status}
              >
                {`Starting ${data?.submitted_task}/${data?.assigned_tasks}`}
              </button>
              {showStartingModal && (
                <StartingModal
                  setShowStartingModal={setShowStartingModal}
                  showStartingModal={showStartingModal}
                  setShowCongratulations={setShowCongratulations}
                  first_set={data?.name}
                  amount={data?.set_amount}
                  data={data}
                  recordId={recordId}
                />
              )}
              {openingHourModal && (
                <OpeningHourModal
                  openingHourModal={openingHourModal}
                  setOpeningHourModal={setOpeningHourModal}
                  data={data}
                />
              )}
              {insufficiantBalance && (
                <InsufficiantBalance
                  insufficiantBalance={insufficiantBalance}
                  setInsufficiantBalance={setInsufficiantBalance}
                />
              )}
            </>
          ) : (
            <>
              <button
                onClick={() => {
                  currentlyLoggedInClick();
                }}
                className="rounded-pill starting-btn "
                style={{
                  maxWidth: "200px",
                  padding: "0.525rem 2rem",
                }}
              >
                {`Starting ${data?.submitted_task}/${data?.assigned_tasks}`}
              </button>
              {showCurrentlyLoggedIn && (
                <CurrentlyLoggedInComponent
                  setShowCurrentlyLoggedIn={setShowCurrentlyLoggedIn}
                  showCurrentlyLoggedIn={showCurrentlyLoggedIn}
                />
              )}
            </>
          )}
        </div>
      </div>
      {showCongratulations && (
        <CongratulationModal
          data={data}
          total_profit={balanceAndProfit?.total_profit}
          showCongratulations={showCongratulations}
          setShowCongratulations={setShowCongratulations}
        />
      )}
    </>
  );
};

export default BoostDataCard;
