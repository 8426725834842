import Home from "./screens/Home/Home";
import ForgetPassowrd from "./screens/ForgetPassowrd";
import ResetPassword from "./screens/ResetPassword";
import DetailPage from "./screens/DetailPage";
import { ToastContainer } from "react-toastify";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import ContactUs from "./screens/ContactUs";
import Notification from "./screens/Notification";
import BoostDataPage from "./screens/BoostDataPage";
import CheckoutScreen from "./components/CheckoutScreen";
import Category from "./screens/Category";
import NoResultFound from "./common/NoResultFound";
import ProtectedRoute from "./common/ProtectedRoute";
import WithoutTokenProtectedRoute from "./common/WithoutTokenProtectedRoute";
import AboutUs from "./screens/AboutUs";
import DeliveryInfo from "./screens/DeliveryInfo";
import TermsAndConditions from "./screens/TermsAndConditions";
import React from "react";
import { Store } from "../src/store/Store";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import DepositMainScreen from "./screens/DepositMainScreen";

//   return (
//     <>
//        <Provider store={Store}>
//       <ToastContainer />
//       <Router>
//         <Routes>
//           <Route path="/" element={<Home />} />

//           <Route path="/:category">
//             <Route path="/:category" element={<Category />} />
//             <Route path=":prodname" element={<DetailPage />} />
//           </Route>

//           <Route
//             path="/forget-password"
//             element={
//               <WithoutTokenProtectedRoute isLogin={tokenVALUE}>
//                 <ForgetPassowrd />
//               </WithoutTokenProtectedRoute>
//             }
//           />
//           <Route
//             path="/reset-password-confirm"
//             element={
//               <WithoutTokenProtectedRoute isLogin={tokenVALUE}>
//                 <ResetPassword params={params} />
//               </WithoutTokenProtectedRoute>
//             }
//           />
//           <Route path="/privacy-policy" element={<PrivacyPolicy />} />
//           <Route path="/drive-data" element={<BoostDataPage />} />
//           <Route path="/contact-us" element={<ContactUs />} />
//           <Route path="/notification" element={<Notification />} />
//           <Route
//             exact
//             path="/checkout"
//             element={
//               <ProtectedRoute token={tokenVALUE}>
//                 <CheckoutScreen />
//               </ProtectedRoute>
//             }
//           />

//           <Route path="*" element={<NoResultFound />} />
//         </Routes>
//       </Router>
//     </Provider>
//     </>
//   );
// }

// export default App;

function App() {
  const params = useParams();
  const uservalue = localStorage.getItem("userLogin");
  const tokenVALUE = JSON.parse(uservalue);
  return (
    <Provider store={Store}>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/forgot-password"
            element={
              <WithoutTokenProtectedRoute isLogin={tokenVALUE}>
                <ForgetPassowrd />
              </WithoutTokenProtectedRoute>
            }
          />
          <Route path="/:category">
            <Route path="/:category" element={<Category />} />
            <Route path=":prodname" element={<DetailPage />} />
          </Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/drive-data" element={<BoostDataPage />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route
            path="/reset-password-confirm"
            element={
              <WithoutTokenProtectedRoute isLogin={tokenVALUE}>
                <ResetPassword params={params} />
              </WithoutTokenProtectedRoute>
            }
          />
          <Route path="/deposit" element={<DepositMainScreen />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/drive-data" element={<BoostDataPage />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/delivery-info" element={<DeliveryInfo />} />
          <Route path="/terms-conditions" element={<TermsAndConditions />} />

          <Route path="*" element={<NoResultFound />} />
          <Route
            exact
            path="/checkout"
            element={
              <ProtectedRoute token={tokenVALUE}>
                <CheckoutScreen />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </Provider>
  );
}
export default App;
