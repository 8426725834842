import React from "react";

const Description = ({ product }) => {
  return (
    <div className="m-4">
      <h5 className="fw-bolder mb-2">About the Item:</h5>
      <p>{product?.item_description}</p>
    </div>
  );
};

export default Description;
