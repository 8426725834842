import React, { useEffect, useRef } from "react";
import illustration from "../assets/image/link_page/abc.jpeg";
import BreadCrumbs from "../common/BreadCrumbs";
import IlustrationPagedummyComp from "../common/IlustrationPagedummyComp";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { showNavabr } from "../store/uiSlices/NavSlice";
const AboutUsComp = () => {
  const dispatch = useDispatch();
  const aboutRef = useRef(null);

  useEffect(() => {
    const option = {
      root: null,
      threshold: 0,
    };

    const heroObsrver = (enteries) => {
      const entery = enteries[0];
      if (entery.isIntersecting) {
        dispatch(showNavabr(true));
      } else {
        dispatch(showNavabr(false));
      }
    };

    const observer = new IntersectionObserver(heroObsrver, option);
    if (aboutRef.current) {
      observer.observe(aboutRef.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [aboutRef]);
  return (
    <div>
      <div ref={aboutRef}>
        {/* <BreadCrumbs category={"About Us"} link={"/about-us"} /> */}
      </div>

      <div className="container">
        <IlustrationPagedummyComp
          illustration={illustration}
          pageTitle={"About Us"}
          descHeading={"Welcome to Get-It-On!"}
          descSubHeading={""}
          subDesc1p1={""}
          subDesc1p2={""}
          subDesc1p3={""}
          description={
            "We are delighted to welcome you to our platform dedicated to dropshipping, e-commerce and efficient data management. Our mission is to simplify your journey through the exciting world of online commerce by providing you with innovative solutions and cutting-edge tools."
          }
          illustration2={illustration}
          descHeading2={"What we offer At Get-It-On,"}
          subDesc2p1={
            "Easy Dropshipping: We provide you with a carefully selected range of high-quality products that you can sell on your online shop, without having to worry about stock management or shipping. Save time and focus on growing your business."
          }
          subDesc2p2={
            "Customised e-commerce: We understand that every business is unique. That's why we offer customised e-commerce solutions to meet your specific needs. From website design to online payment management, we're there for you."
          }
          subDesc2p3={
            "Expert Data Management: Collecting and analysing data is essential to making informed decisions. We provide you with powerful tools to manage and interpret your data in a way that optimises your business and maximises your profits."
          }
          descSubHeading2={
            "We strive to meet the changing needs of online entrepreneurs. Our range of services includes:"
          }
          description2={""}
          illustration3={illustration}
          descHeading3={"Our commitment to you At Get-It-On,"}
          subDesc3p1={""}
          subDesc3p2={""}
          subDesc3p3={""}
          descSubHeading3={""}
          description3={
            "We are committed to providing you with an exceptional experience. We believe in transparency, innovation and quality. Our dedicated team is here to support you every step of the way, whether you're new to online business or an expert."
          }
        />
        <div className="d-flex justify-content-center  justify-content-md-start w-100 mb-2">
          <Link
            to="/contact-us"
            type="submit"
            className="btn  float-right rounded-pill text-white "
            style={{ padding: "10px 25px", background: "#008000" }}
          >
            Contact Us
          </Link>
        </div>
        <h1 className="text-center">Why Choose Us</h1>
      </div>
    </div>
  );
};

export default AboutUsComp;
