import React, { useEffect, useState } from "react";
import ColorVariations from "./ColorVariations";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import IncDecQuantity from "./IncDecQuantity";
import SizeSelector from "./SizeSelector";
import { FaStar, FaRegStar } from "react-icons/fa";
import { BiSolidChevronDown } from "react-icons/bi";
import RatingModal from "./RatingModal";
import { Link } from "react-router-dom";
import "./Detail.css";
import { addToCart } from "../../store/uiSlices/AddToCartSlice";
import { useDispatch, useSelector } from "react-redux";
import { ApiCall, initializeApiWithHeaders } from "../PostApiRequest";
import { GetWishlist } from "../../store/Wishlist/GetWishlist";
function DetailPagesfirstSection({ product, img }) {
  const [wish, setWish] = useState(false);
  const [productColor, setProductColor] = useState("");
  const [productSize, setProductSize] = useState("");
  const wishlist = useSelector(
    (state) => state?.GetWishlist?.data?.data?.items
  );

  const [selectedImage, setSelectedImage] = useState(null); // Initialize with the main image
  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
  };
  useEffect(() => {
    setSelectedImage(null);
    if (wishlist?.some((wish) => wish?.product?.id === product.id)) {
      setWish(true);
    } else {
      setWish(false);
    }
  }, [product]);

  const count = 5;
  const stars = Array.from({ length: count }, (_, index) => (
    <FaRegStar key={index} style={{ fontSize: "15px", color: "#f5ae0f" }} />
  ));
  const Filledstars = Array.from({ length: count }, (_, index) => (
    <FaStar key={index} style={{ fontSize: "15px", color: "#f5ae0f" }} />
  ));
  const [showModal, setShowModal] = useState(false);
  const handleMouseEnter = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };
  useEffect(() => {
    dispatch(GetWishlist());
  }, []);

  const dispatch = useDispatch();
  const prodname = "Arduino Uno R3 (China) - Board ATmega328P 5V 16M";
  const handleAddToCart = () => {
    dispatch(
      addToCart({
        ...product,
        count: 1,
        productColor,
        productSize,
        itemTotalPrice: product.offer
          ? product?.offer_price
          : product.selling_price,
      })
    );
  };

  const handleWishClick = async (item) => {
    const formData = new FormData();
    formData.append("product", `${item?.id}`);
    formData.append("title", `${item?.item_name}`);

    initializeApiWithHeaders();
    if (!wish) {
      const response = await ApiCall(
        `wishlist/add-item-in-wishlist/`,
        "POST",
        formData
      );
      if (response?.meta?.status_code == 201) {
        dispatch(GetWishlist());
        setWish(true);
      }
    } else {
      const wishItems = wishlist?.filter(
        (wish) => wish?.product?.id === item.id
      );
      const response = await ApiCall(
        `wishlist/remove-wishlist-item/${wishItems[0].id}/`,
        "DELETE"
      );
      if (response?.meta?.status_code == 200) {
        dispatch(GetWishlist());
        setWish(false);
      }
    }
  };
  const difference = product?.offer
    ? ((product?.selling_price - product?.offer_price) / 100) *
      product?.selling_price
    : 0;
  const formattedDifference = parseFloat(difference).toFixed(2);

  return (
    <div>
      <div className="row my-2">
        <div className="col-3 col-md-1 flex">
          {product?.product_media?.map((image, index) => (
            <img
              style={{ cursor: "pointer" }}
              key={index}
              src={image.image}
              alt={"image"}
              loading="lazy"
              width={40}
              className={`mb-3 img-fluid border w-100 ${
                selectedImage === image
                  ? "selected border-success shadow-lg w-100"
                  : ""
              }`}
              onClick={() => handleImageClick(image.image)}
            />
          ))}
        </div>
        <div className="col-8 col-md-4 d-flex flex-column justify-content-center justify-content-md-start">
          <div className="  d-flex justify-content-center align-items-center">
            {product?.product_media?.length > 0 &&
              product?.product_media[0]?.image && (
                <img
                  src={
                    selectedImage
                      ? selectedImage
                      : product?.product_media?.length > 0 &&
                        product?.product_media[0]?.image
                  }
                  alt="main image"
                  loading="lazy"
                  style={{ maxHeight: "600px" }}
                  className="zoomedImgDetailPage img-fluid"
                />
              )}
          </div>
          <p className="my-1 text-center text-bg-secondary ">
            Hover to Zoom In
          </p>
        </div>
        <div className="col-md-5">
          <h2 className="mb-1 product-detail-heading">
            {product?.item_name ? product?.item_name : "-"}
          </h2>
          <ul className="d-flex flex-wrap align-items-center font-weight-500 list-unstyled m-0">
            <li className="position-relative ">
              Brand:
              <Link
                className="meta-value text-primary"
                to="https://www.bata.com.pk/"
                target="_"
              >
                {product?.brand?.brand_name}
              </Link>
            </li>
            <li className="text-warning position-relative px-2 px-md-3 position-relative">
              <div className="d-flex gap-1 text-center">
                <div onMouseEnter={handleMouseEnter} onMouseLeave={handleClose}>
                  <span>{stars}</span>
                  <BiSolidChevronDown className="text-secondary" />
                </div>
                {showModal && (
                  <RatingModal showModal={showModal} f={Filledstars} />
                )}
                <p className="count text-secondary ">(1 Review)</p>
                <p className="text-dark">
                  &nbsp; Item Code:{" "}
                  <span className="meta-value">{product?.item_code}</span>
                </p>
              </div>
            </li>
          </ul>
          <div className="pl-md-4">
            <div className="fs-4 text-danger  d-inline-block">
              <span className="amount var_amount">
                $ {product?.offer && product?.offer_price}{" "}
              </span>{" "}
              <span className="fs-6 text-black ">
                <del>$ {product?.selling_price}</del>
              </span>
              <span className="fs-5 font-weight-500 ml-1">
                (<span className="save_perct">{formattedDifference}</span>
                %)
              </span>
            </div>
            <div className="border-bottom pb-1  ">
              Status: &nbsp;
              <span style={{ color: "#8bc500" }} className="fw-bold">
                {0 ? "Out of Stock" : "In Stock"}
              </span>{" "}
            </div>
          </div>
          {/* ==================================color variations===================================== */}
          <div className="">
            <ColorVariations setProductColor={setProductColor} />
          </div>
          {/* ==================================product size===================================== */}
          <div className="my-3">
            <hr />

            <SizeSelector
              productSize={productSize}
              setProductSize={setProductSize}
            />
            <hr />
          </div>
          {/* ================================== Add to cart ===================================== */}{" "}
          <div className="d-flex justify-content-between align-items-center mb-3">
            <IncDecQuantity product={product} />
            <button
              style={{ backgroundColor: "#FFA41C" }}
              className="border-0 rounded-pill   detail-add-to-cart-btn"
              onClick={handleAddToCart}
            >
              Add to Cart
            </button>
            <button
              title="wishlist"
              className="border-0"
              style={{ background: "transparent " }}
              onClick={() => {
                handleWishClick(product);
              }}
            >
              {!wish ? (
                <FaRegHeart
                  className="wish-icon"
                  style={{ color: "var(--primary-color-green)" }}
                />
              ) : (
                <FaHeart
                  className="wish-icon"
                  style={{ color: "var(--primary-color-green)" }}
                />
              )}
            </button>
            {/* <button
              title="Compare"
              className="border-0 compare-btn"
              style={{ background: "transparent " }}
            >
              <img className="w-100 h-100" src={VscGitCompare} alt="compare" />
            </button> */}
            <hr />
          </div>
          {/* ========== */}
          <div>
            <p> Category: {product?.category?.category_name}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailPagesfirstSection;
