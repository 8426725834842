import React from "react";
import { Link } from "react-router-dom";
const RatingModal = ({ showModal, f }) => {
  const starsData = [
    { rating: "5 star", width: "85%" },
    { rating: "4 star", width: "65%" },
    { rating: "3 star", width: "50%" },
    { rating: "2 star", width: "25%" },
    { rating: "1 star", width: "10%" },
  ];
  return (
    <>
      <div
        className="bg-white border rounded px-2 position-absolute "
        style={{ top: "20px", left: "-20%", zIndex: "1000", width: "110%" }}
      >
        <h4 className="mt-4">
          {f}
          <span className="text-left text-black fw-bolder "> 4.8 out of 5</span>
        </h4>
        <p style={{ color: "#7C7C80", marginBottom: "50px" }}>
          132,000 global ratings
        </p>
        <div className="mb-4">
          {starsData.map((star, index) => (
            <div
              key={index}
              className="d-flex align-items-center justify-content-center gap-2"
            >
              <Link>
                <p style={{ fontSize: "12px" }} className="rating-hover ">
                  {star.rating}
                </p>
              </Link>
              <div
                style={{
                  height: "25px",
                  backgroundColor: "#efeff2",
                  position: "relative",
                }}
                className="w-75 rounded border"
              >
                <div
                  style={{
                    border: "1px solid #ff6f1c",
                    height: "100%",
                    width: `${showModal ? star.width : 0}`, // You can adjust this value based on your requirements
                    backgroundColor: "#FFA41C",
                    position: "absolute",
                    transition: "width 0.5s ease",
                  }}
                  className="rounded"
                ></div>
              </div>{" "}
              <Link>
                <p style={{ fontSize: "12px" }} className="rating-hover my-3">
                  {star.width}
                </p>
              </Link>
            </div>
          ))}
        </div>

        {/* <button
            className="btn btn-secondary float-right"
            onClick={handleClose}
          >
            Close
          </button> */}
      </div>
    </>
  );
};

export default RatingModal;
