import React from "react";
import productImage from "../assets/image/product/c71ce8320c4b049762d83ba4b7dcca27.png";
import { cardImages, cardImages2 } from "../constants/categoriesCard";
import { Container, Row, Col } from "react-bootstrap";
import productDummyImage from "../assets/image/product.jpeg";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setPriceRangeFirstLoading } from "../store/uiSlices/PriceRangeFirstLoading";
const GroupCard = ({ products, categoryName, categoryId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className="d-flex  flex-column justify-content-center gap-3 flex-md-row w-100 h-100">
      <div
        className="last-section-large-card  card-hover py-5 px-4   bg-white text-center w-100"
        style={{
          border: "1px solid var(--border-color-gray)",
        }}
      >
        <div className="mb-4">
          <h5
            className=" fw-bold mb-0"
            style={{ marToptom: "20px", cursor: "pointer" }}
            onClick={() => {
              dispatch(setPriceRangeFirstLoading(false));
              navigate(
                `${`/${categoryName}/${products[5]?.item_name
                  .split("/")
                  .join(" ")}`}`,
                {
                  state: {
                    path: categoryName,
                    id: categoryId,
                    product: products[5],
                  },
                }
              );
            }}
          >
            {products[5]?.item_name?.length > 30
              ? `${products[5]?.item_name.slice(0, 30)}...`
              : products[5]?.item_name}
          </h5>
        </div>
        <div>
          {products[5]?.product_media?.length > 0 &&
          products[5]?.product_media[0]?.image ? (
            <img
              src={
                products[5]?.product_media?.length > 0 &&
                products[5]?.product_media[0]?.image
              }
              height={222}
              className=""
              style={{ cursor: "pointer" }}
              onClick={() => {
                dispatch(setPriceRangeFirstLoading(false));
                navigate(
                  `${`/${categoryName}/${products[5]?.item_name
                    .split("/")
                    .join(" ")}`}`,
                  {
                    state: {
                      path: categoryName,
                      id: categoryId,
                      product: products[5],
                    },
                  }
                );
              }}
            />
          ) : (
            <img scr={productDummyImage} height={222} />
          )}
          <p>
            {" "}
            {products[5]?.item_description?.length > 120
              ? `${products[5]?.item_description?.slice(0, 120)}...`
              : products[5]?.item_description}
          </p>
        </div>
      </div>
      <div className="pt-3 fourItem-group-card border border-1 d-flex flex-column justify-content-center gap-lg-4 gap-xl-0  justify-content-xl-evenly justify-content-xxl-evenly  py-1 card-hover w-100 px-3  px-xl-2 ">
        <h5 className="fw-bold ">Designs by made in Italy</h5>
        <div>
          <div className="row">
            <div className="col-6 last-section-small-card-product">
              <div className=" fourItem-group-card-img-cont">
                {products[6]?.product_media?.length > 0 &&
                products[6]?.product_media[0]?.image ? (
                  <img
                    src={
                      products[6]?.product_media?.length > 0 &&
                      products[6]?.product_media[0]?.image
                    }
                    className="  mt-2 w-100 h-100"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      dispatch(setPriceRangeFirstLoading(false));
                      navigate(
                        `${`/${categoryName}/${products[6]?.item_name
                          .split("/")
                          .join(" ")}`}`,
                        {
                          state: {
                            path: categoryName,
                            id: categoryId,
                            product: products[6],
                          },
                        }
                      );
                    }}
                  />
                ) : (
                  <img src={productDummyImage} className="  mt-2 w-100 h-100" />
                )}
              </div>
              <h6
                className="fw-bold "
                style={{ marginTop: "30px", cursor: "pointer" }}
                onClick={() => {
                  dispatch(setPriceRangeFirstLoading(false));
                  navigate(
                    `${`/${categoryName}/${products[6]?.item_name
                      .split("/")
                      .join(" ")}`}`,
                    {
                      state: {
                        path: categoryName,
                        id: categoryId,
                        product: products[6],
                      },
                    }
                  );
                }}
              >
                {" "}
                {products[6]?.item_name?.length > 20
                  ? `${products[6]?.item_name?.slice(0, 20)}...`
                  : products[6]?.item_name}
              </h6>
            </div>
            <div className="col-6 last-section-small-card-product">
              <div className="fourItem-group-card-img-cont ">
                {products[7]?.product_media?.length > 0 &&
                products[7]?.product_media[0]?.image ? (
                  <img
                    src={
                      products[7]?.product_media?.length > 0 &&
                      products[7]?.product_media[0]?.image
                    }
                    className="  mt-2 w-100 h-100"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      dispatch(setPriceRangeFirstLoading(false));
                      navigate(
                        `${`/${categoryName}/${products[7]?.item_name
                          .split("/")
                          .join(" ")}`}`,
                        {
                          state: {
                            path: categoryName,
                            id: categoryId,
                            product: products[7],
                          },
                        }
                      );
                    }}
                  />
                ) : (
                  <img src={productDummyImage} className="  mt-2 w-100 h-100" />
                )}
              </div>
              <h6
                className="fw-bold "
                style={{ marginTop: "30px", cursor: "pointer" }}
                onClick={() => {
                  dispatch(setPriceRangeFirstLoading(false));
                  navigate(
                    `${`/${categoryName}/${products[7]?.item_name
                      .split("/")
                      .join(" ")}`}`,
                    {
                      state: {
                        path: categoryName,
                        id: categoryId,
                        product: products[7],
                      },
                    }
                  );
                }}
              >
                {products[7]?.item_name.length > 20
                  ? `${products[7]?.item_name.slice(0, 20)}...`
                  : products[7]?.item_name}
              </h6>
            </div>
          </div>
          <div className="row">
            <div className="col-6 last-section-small-card-product">
              <div className="fourItem-group-card-img-cont ">
                {products[8]?.product_media?.length > 0 &&
                products[8]?.product_media[0]?.image ? (
                  <img
                    src={
                      products[8]?.product_media?.length > 0 &&
                      products[8]?.product_media[0]?.image
                    }
                    className="  mt-2 w-100 h-100"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      dispatch(setPriceRangeFirstLoading(false));
                      navigate(
                        `${`/${categoryName}/${products[8]?.item_name
                          .split("/")
                          .join(" ")}`}`,
                        {
                          state: {
                            path: categoryName,
                            id: categoryId,
                            product: products[8],
                          },
                        }
                      );
                    }}
                  />
                ) : (
                  <img src={productDummyImage} className="  mt-2 w-100 h-100" />
                )}
              </div>
              <h6
                className="fw-bold "
                style={{ marginTop: "30px", cursor: "pointer" }}
                onClick={() => {
                  dispatch(setPriceRangeFirstLoading(false));
                  navigate(
                    `${`/${categoryName}/${products[8]?.item_name
                      .split("/")
                      .join(" ")}`}`,
                    {
                      state: {
                        path: categoryName,
                        id: categoryId,
                        product: products[8],
                      },
                    }
                  );
                }}
              >
                {products[8]?.item_name?.length > 20
                  ? `${products[8]?.item_name.slice(0, 20)}...`
                  : products[8]?.item_name}
              </h6>
            </div>
            <div className="col-6 last-section-small-card-product">
              <div className="fourItem-group-card-img-cont ">
                {products[9]?.product_media?.length > 0 &&
                products[9]?.product_media[0]?.image ? (
                  <img
                    src={
                      products[9]?.product_media?.length > 0 &&
                      products[9]?.product_media[0]?.image
                    }
                    className="  mt-2 w-100 h-100"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      dispatch(setPriceRangeFirstLoading(false));
                      navigate(
                        `${`/${categoryName}/${products[9]?.item_name
                          .split("/")
                          .join(" ")}`}`,
                        {
                          state: {
                            path: categoryName,
                            id: categoryId,
                            product: products[9],
                          },
                        }
                      );
                    }}
                  />
                ) : (
                  <img src={productDummyImage} className="  mt-2 w-100 h-100" />
                )}
              </div>
              <h6
                className="fw-bold"
                style={{ marginTop: "30px", cursor: "pointer" }}
                onClick={() => {
                  dispatch(setPriceRangeFirstLoading(false));
                  navigate(
                    `${`/${categoryName}/${products[9]?.item_name
                      .split("/")
                      .join(" ")}`}`,
                    {
                      state: {
                        path: categoryName,
                        id: categoryId,
                        product: products[9],
                      },
                    }
                  );
                }}
              >
                {products[9]?.item_name?.length > 20
                  ? `${products[9]?.item_name?.slice(0, 20)}...`
                  : products[9]?.item_name}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupCard;
