import ustdCard from "../assets/image/pay_with/USDT.png";
import tlcCard from "../assets/image/pay_with/LTC.png";
import xmrCard from "../assets/image/pay_with/XMR.png";

// ======================================= ~End Footer data~ ===========================================
export const paymentOptions = [
  { src: ustdCard },
  { src: tlcCard },
  { src: xmrCard },
];

// ============================ ~Getitton Footer data "our Comunities column"~ ===========================================
export const links = [
  { name: "About Us", route: "/about-us" },
  { name: "Contact Us", route: "/contact-us" },
  { name: "Delivery Information", route: "/delivery-info" },
  { name: "Privacy Information", route: "/privacy-policy" },
  { name: "Term & Condition", route: "/terms-conditions" },
  // { name: "Track My Order", route: "/" },
];

// ======================================= ~Social Medias icon~ ===========================================
import { BsFacebook, BsInstagram, BsLinkedin, BsYoutube } from "react-icons/bs";
import { AiOutlineReddit } from "react-icons/ai";
import { LiaTelegramPlane } from "react-icons/lia";
export const socialMediaLinks = [
  {
    backgroundColor: "#3f729b",
    icon: (
      <BsFacebook
        className="mt-1"
        style={{ color: "#fff", fontSize: "1.25rem" }}
      />
    ),
    url: "https://www.facebook.com/",
    label: "Facebook",
  },
  {
    backgroundColor: "#e0389a",
    icon: (
      <BsInstagram
        className="mt-1"
        style={{ mcolor: "#fff", fontSize: "1.25rem" }}
      />
    ),
    url: "https://www.instagram.com/",
    label: "Instagram",
  },
  {
    backgroundColor: "#FF4500",
    icon: (
      <AiOutlineReddit
        className="mt-1"
        style={{ color: "#fff", fontSize: "1.25rem" }}
      />
    ),
    url: "",
    label: "Reddit",
  },
  {
    backgroundColor: "#31A8E0",
    icon: (
      <LiaTelegramPlane
        className="mt-1"
        style={{ color: "#fff", fontSize: "1.25rem" }}
      />
    ),
    url: "",
    label: "Telegram",
  },
];
