import { createSlice } from "@reduxjs/toolkit";
const uservalue = localStorage.getItem("userLogin");
const tokenVALUE = JSON.parse(uservalue);
const userId = tokenVALUE?.data?.id;
const cartKey = `cartItems_${userId}`;
console.log("cartKey", cartKey);
const cartProduct = JSON.parse(localStorage.getItem(cartKey)) || [];
console.log("cartProduct", cartProduct);
const cartSlice = createSlice({
  name: "cart",
  initialState: {
    items: cartProduct?.length > 0 ? cartProduct : [],
  },
  reducers: {
    addToCart: (state, action) => {
      const newItem = action.payload;
      const updatedItem = JSON.parse(JSON.stringify(state.items));
      if (updatedItem.length >= 2) {
        const newItems = updatedItem.filter((item) => item?.id !== newItem?.id);

        state.items = [...newItems, newItem];
      } else if (updatedItem.length === 1) {
        const newItems = updatedItem.filter((item) => item?.id !== newItem?.id);

        if (newItems.length !== 0) {
          state.items.push(newItem);
        }
      } else if (updatedItem.length < 1) {
        state.items.push(newItem);
      }

      localStorage.setItem(cartKey, JSON.stringify(state.items));
    },
    itemCounter: (state, action) => {
      const updatedItem = JSON.parse(JSON.stringify(state.items));
      const calculatePrice = function (price, count) {
        let totalPrice = 0;
        for (let i = 1; i <= count; i++) {
          totalPrice += Number(price);
        }

        return totalPrice;
      };
      if (action.payload.change === "increment") {
        const counterPriceUpdate = updatedItem.map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              count: item.count + 1,
              itemTotalPrice: item?.offer
                ? calculatePrice(item?.offer_price, item.count + 1)
                : calculatePrice(item.selling_price, item.count + 1),
            };
          } else {
            return item;
          }
        });
        state.items = counterPriceUpdate;
      } else {
        if (updatedItem.length > 0) {
          const counterPriceUpdate = updatedItem.map((item) => {
            if (item.id === action.payload.id) {
              return {
                ...item,
                count: item.count > 0 ? item.count - 1 : 0,
                itemTotalPrice: item?.offer
                  ? calculatePrice(item?.offer_price, item.count - 1)
                  : calculatePrice(item.selling_price, item.count - 1),
              };
            } else {
              return item;
            }
          });
          state.items = counterPriceUpdate;
        }
      }
    },
    removeFromCart: (state, action) => {
      const itemIdToRemove = action.payload;
      const updatedItem = JSON.parse(JSON.stringify(state.items));
      state.items = updatedItem.filter((item) => item.id !== itemIdToRemove);
      localStorage.setItem(cartKey, JSON.stringify(state.items));
    },
    removeAllFromCart: (state, action) => {
      state.items = [];
    },
  },
});

export const { addToCart, itemCounter, removeFromCart, removeAllFromCart } =
  cartSlice.actions;
export default cartSlice.reducer;
