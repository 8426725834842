import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  show: false,
};

const showAllCategoryNavSlice = createSlice({
  name: "showAllCategoryNav",
  initialState,
  reducers: {
    setshowAllCategoryNav: (state, action) => {
      state.show = state.show ? false : true;
    },

    setHideAllCategoryNav: (state, action) => {
      state.show = false;
    },
  },
});

export const { setshowAllCategoryNav, setHideAllCategoryNav } =
  showAllCategoryNavSlice.actions;
export default showAllCategoryNavSlice.reducer;
