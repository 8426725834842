import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../env";

export const fetchUser = createAsyncThunk("fetchUser", async (values) => {
  var formdata = new FormData();
  formdata.append("email", values.email.toLowerCase());
  formdata.append("password", values.password);

  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };

  return fetch(`${BASE_URL}auth/login/`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      localStorage.setItem("userLogin", JSON.stringify(result));

      return result;
    })
    .catch((error) => {
      return error;
    });
});
const loginSlice = createSlice({
  name: "loginAuth",
  initialState: {
    isloading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUser.pending, (state, action) => {
      state.isloading = true;
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.isloading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchUser.rejected, (state, action) => {
      state.isError = true;
    });
  },
});

export default loginSlice.reducer;
