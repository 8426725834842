import React from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import SideCategoryNavHeader from "../../common/SideCategoryNavHeader";
import { useDispatch } from "react-redux";
import { setPriceRangeFirstLoading } from "../../store/uiSlices/PriceRangeFirstLoading";

function PriceRange({ setPriceRange, priceRange, minValue, maxValue }) {
  const dispatch = useDispatch();
  const handleSliderChange = (newValue) => {
    dispatch(setPriceRangeFirstLoading(true));
    if (newValue[0] <= newValue[1]) {
      setPriceRange(newValue);
    }
  };
  const leftSpanPosition = `${
    ((priceRange && priceRange[0] - minValue) / maxValue) * 100
  }%`;

  const rightSpanPosition = `${
    100 - ((priceRange && priceRange[1]) / maxValue) * 100
  }%`;

  return (
    <div className=" border rounded pt-4 mb-4">
      <SideCategoryNavHeader category={"Price"} />

      <div className="position-relative mb-4 mt-5 mx-4">
        <div className="text-white d-flex selected-values">
          <span
            style={{
              backgroundColor: "#008000",
              fontSize: "12px",
              transform: `translateX(-${leftSpanPosition}px)`,
              top: "-20px",
              left: leftSpanPosition,
            }}
            className="rounded py-1 position-absolute px-1"
          >
            ${priceRange && priceRange[0]}
          </span>
          <span
            style={{
              backgroundColor: "#008000",
              fontSize: "12px",
              transform: `translateX(-${rightSpanPosition}px)`,
              right: rightSpanPosition,
              top: "-20px",
            }}
            className="rounded py-1 position-absolute px-1 "
          >
            ${priceRange && priceRange[1]}
          </span>
        </div>

        <Slider
          min={minValue}
          max={maxValue}
          value={priceRange && priceRange}
          onChange={handleSliderChange}
          range
          step={1}
          tipFormatter={(priceRange) => `$${priceRange && priceRange}`}
        />
      </div>
    </div>
  );
}

export default PriceRange;
