import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginVal } from "../store/uiSlices/loginStateSlice";
import WithDrawalPop from "./WithDrawalPop";
import InternalTransfer from "../components/categories/boostDataComponent/InternalTransfer";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import ProfilePopUp from "../components/categories/boostDataComponent/ProfilePopUp";
import HistoricPopUp from "../components/categories/boostDataComponent/HistoricPopUp";
import RecordsPopUp from "../components/categories/boostDataComponent/RecordsPopUp";
import { depositeModalHide } from "../store/uiSlices/LoginModalSlice";
import { BalanceAndProfit } from "../store/BoostDataSlice/GetBalanceandProfitSlice";
import { BoostDataUser } from "../store/BoostDataSlice/GetBoostDataUserSlice";
import { GetDepositeAddress } from "../store/BoostDataSlice/GetdepositeAddress";
import { Historic } from "../store/BoostDataSlice/HistoricSlice";
import { GetAllRecords } from "../store/BoostDataSlice/GetAllRecordsSlice";
import { UnlockWithDrawalPop } from "./UnlockWithDrawalPop";
import Modal from "./Modal";
import AddWithdrawalAddress from "./AddWithdrawalAddress";
const data = [
  { id: 1, title: "BALANCE", amount: "00.00" },
  { id: 2, title: "PROFITS", amount: "00.00" },
  { id: 3, title: "WORKING DAYS", amount: "00.00" },

  {
    id: 4,
    title: "FINANCE",
    dropdown: true,
    SubCategory: [
      { id: 1, title: "DEPOSIT", modal: true },
      { id: 2, title: "WIDTHDRAW", modal: true },
      { id: 3, title: "INTERNAL TRANSFER", modal: true },
    ],
  },
  {
    id: 5,
    title: "TRANSACTION",
    dropdown: true,
    SubCategory: [
      { id: 1, title: "HISTORIC", bottomModal: true },
      { id: 2, title: "RECORDS", bottomModal: true },
    ],
  },
  { id: 6, title: "PROFILE", bottomModal: true },
];
const BoostDataNavLayout = () => {
  const dispatch = useDispatch();
  const isLight = useSelector((state) => state.darkLight);
  const loginVal = useSelector(selectLoginVal);
  const [showDropdown, setshowDropdown] = useState(false);
  const [showHictoricPop, setShowHictoricPop] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showRecords, setshowRecords] = useState(false);
  const [withdraw, setWithdraw] = useState(false);
  const [withdrawAddress, setWithdrawAddress] = useState(true);
  const [withdrawMOny, setWithdrawMOny] = useState(true);
  const [internalTransfer, setInternalTransfer] = useState(false);
  const [showFinanceDropdown, setshowFinanceDropdown] = useState(false);
  const [currentNavId, setCurrentNavId] = useState(0);
  const [prevNavId, setPrevNavId] = useState(0);
  const [moreData, setMoreData] = useState([...data]);
  const [userData, setUserData] = useState(loginVal);
  const [currentHistory, setCurrentHistory] = useState("deposit");
  const moreMenuRef = useRef();
  const financeMenuRef = useRef();
  const userBalance = useSelector(
    (state) => state?.GetBalanceRecords?.data?.data
  );
  const withdrawalVariable = useSelector(
    (state) => state?.GetBoostDataUserSlice?.data?.data
  );

  const profileData = useSelector(
    (state) => state?.ProfileRecordSlice?.data?.data
  );

  console.log(profileData?.user_profile?.withdraw_address_link);
  useEffect(() => {
    dispatch(BalanceAndProfit());
    dispatch(BoostDataUser());
  }, []);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (e.target.closest(".dropdown-modal") || e.target.closest(".modal")) {
      } else {
        setCurrentNavId(0);
      }
    };

    document.addEventListener("click", handleOutsideClick, true);
    return () => {
      document.removeEventListener("click", handleOutsideClick, true);
    };
  }, [financeMenuRef]);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (moreMenuRef.current && !moreMenuRef.current.contains(e.target)) {
        setshowDropdown(false);
      }
    };

    document.addEventListener("click", handleOutsideClick, true);
    return () => {
      document.removeEventListener("click", handleOutsideClick, true);
    };
  }, [moreMenuRef]);

  useEffect(() => {
    const uservalue = localStorage.getItem("userLogin");
    const tokenVALUE = JSON.parse(uservalue);
    setUserData(tokenVALUE?.data?.token?.access);
  }, [loginVal]);

  const handleWidtdraw = () => {
    setWithdraw(true);
    setWithdrawAddress(true);
    setWithdrawMOny(true);
  };

  return (
    <div className="">
      {userData && (
        <div className="d-flex justify-content-center gap-3 flex-column flex-lg-row">
          <div className="d-flex align-items-center  justify-content-center gap-1  gap-sm-3 ps-0">
            {moreData.map(
              (menu) =>
                menu.amount && (
                  <div className="d-flex ">
                    <div>
                      {" "}
                      {menu.title === "BALANCE" && (
                        <p
                          key={menu.id}
                          className={`bost-data-newbies-menu  green-border-btn ${
                            isLight.lightDark ? "text-dark" : "text-dark"
                          } ${
                            userBalance?.total_balance < 0
                              ? "red-border-btn"
                              : "green-border-btn"
                          }`}
                        >
                          {menu.title}{" "}
                          <span className="fw-bold">
                            {userBalance?.total_balance
                              ? userBalance?.total_balance
                              : "0.00"}
                            $
                          </span>
                        </p>
                      )}
                      {menu.title === "PROFITS" && (
                        <p
                          key={menu.id}
                          className={`bost-data-newbies-menu green-border-btn ${
                            isLight.lightDark ? "text-dark" : "text-dark"
                          }`}
                        >
                          {menu.title}{" "}
                          <span className="fw-bold">
                            {userBalance?.total_profit
                              ? userBalance?.total_profit
                              : "0.00"}
                            $
                          </span>
                        </p>
                      )}
                    </div>
                    <div>
                      {menu.title === "WORKING DAYS" && (
                        <p
                          key={menu.id}
                          className={`bost-data-newbies-menu green-border-btn ${
                            isLight.lightDark ? "text-dark" : "text-dark"
                          }`}
                        >
                          {menu.title}{" "}
                          <span className="fw-bold">
                            {userBalance?.consecutive_working_days
                              ? userBalance?.consecutive_working_days
                              : "0"}
                          </span>
                        </p>
                      )}
                    </div>
                  </div>
                )
            )}
          </div>

          <div className="d-flex align-items-center  justify-content-center gap-1  gap-sm-3 ps-0">
            {moreData.map(
              (menu) =>
                !menu.amount && (
                  <div>
                    {menu?.bottomModal ? (
                      <>
                        <div
                          className="green-border-btn bost-data-newbies-menu d-flex align-items-center bg-transparent gap-2"
                          onClick={() => {
                            setShowProfile(true);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          PROFILE
                        </div>

                        {showProfile && (
                          <ProfilePopUp
                            show={showProfile}
                            setShow={setShowProfile}
                            title={menu.title}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {menu.dropdown && (
                          <div
                            ref={financeMenuRef}
                            className="position-relative dropdown-modal green-border-btn  "
                          >
                            <div
                              className="bost-data-newbies-menu d-flex align-items-center bg-transparent gap-2"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                if (currentNavId === menu.id) {
                                  setCurrentNavId(0);
                                  setshowFinanceDropdown(false);
                                } else {
                                  setCurrentNavId(menu.id);
                                  setPrevNavId(menu.id);
                                  setshowFinanceDropdown(true);
                                }
                              }}
                            >
                              <p>{menu.title}</p>
                              {menu.id === currentNavId ? (
                                <BsChevronUp />
                              ) : (
                                <BsChevronDown />
                              )}
                            </div>
                            {menu.id === currentNavId &&
                              showFinanceDropdown && (
                                <div
                                  className="position-absolute  d-flex flex-column text-left w-100 "
                                  style={{
                                    background: "#f0f0f0",
                                    top: "45px",
                                    left: 0,
                                    padding: "5px",
                                    borderRadius: "5px",
                                    minWidth: "200px",
                                    zIndex: 1000,
                                  }}
                                >
                                  {menu.SubCategory.map((categ) => (
                                    <>
                                      {categ.modal ? (
                                        <div
                                          key={categ.id}
                                          className={`py-1 ps-2 bg-white my-1   ${
                                            isLight.lightDark
                                              ? "text-dark"
                                              : "text-dark"
                                          }`}
                                          style={{
                                            fontSize: "14px",
                                            width: "100%",
                                          }}
                                        >
                                          {categ.title === "DEPOSIT" && (
                                            <p
                                              onClick={() => {
                                                dispatch(
                                                  depositeModalHide(true)
                                                );
                                              }}
                                              className="w-100 text-center "
                                              style={{ cursor: "pointer" }}
                                            >
                                              DEPOSIT
                                            </p>
                                          )}

                                          {categ.title === "WIDTHDRAW" && (
                                            <>
                                              <button
                                                onClick={handleWidtdraw}
                                                className="bg-white border-0 text-center w-100"
                                              >
                                                WIDTHDRAW
                                              </button>
                                              {/* ============== CHECK FOR IS USER CAN WITHDRAW OR NOT============== */}
                                              {withdraw && (
                                                // &&
                                                // withdrawalVariable?.withdraw_available ===
                                                //   true
                                                // ============CHECK FOR IS USER HAS WITHDRAWAL LINK OR NOT ===============//
                                                // if(profileData?.user_profile?.withdraw_address_link){

                                                // }
                                                <>
                                                  {profileData?.user_profile
                                                    ?.withdraw_address_link ? (
                                                    <WithDrawalPop
                                                      show={withdrawMOny}
                                                      setShow={setWithdrawMOny}
                                                      setWithdraw={setWithdraw}
                                                      heading={categ.title}
                                                      title={"WIDTHDRAW"}
                                                    />
                                                  ) : (
                                                    <AddWithdrawalAddress
                                                      show={withdrawAddress}
                                                      setShow={
                                                        setWithdrawAddress
                                                      }
                                                      setWithdraw={setWithdraw}
                                                      setShowMoney={
                                                        setWithdrawMOny
                                                      }
                                                    />
                                                  )}
                                                </>
                                                // ) : (
                                                //   withdraw && (
                                                //     <UnlockWithDrawalPop
                                                //       show={withdraw}
                                                //       setShow={setWithdraw}
                                                //       heading={categ.title}
                                                //       title={"WIDTHDRAW"}
                                                //     />
                                                //   )
                                              )}
                                            </>
                                          )}

                                          {categ.title ===
                                            "INTERNAL TRANSFER" && (
                                            <>
                                              <button
                                                className="bg-white border-0 text-center w-100"
                                                onClick={() => {
                                                  setInternalTransfer(
                                                    (prev) => !prev
                                                  );
                                                }}
                                              >
                                                INTERNAL TRANSFER
                                              </button>{" "}
                                              {internalTransfer && (
                                                <InternalTransfer
                                                  internalTransfer={
                                                    internalTransfer
                                                  }
                                                  setInternalTransfer={
                                                    setInternalTransfer
                                                  }
                                                  heading={categ.title}
                                                />
                                              )}
                                            </>
                                          )}
                                        </div>
                                      ) : (
                                        <>
                                          {categ.title === "HISTORIC" && (
                                            <>
                                              <div
                                                onClick={() => {
                                                  setShowHictoricPop(true);
                                                  dispatch(
                                                    Historic(currentHistory)
                                                  );
                                                }}
                                                key={categ.id}
                                                className={`py-1 ps-2 bg-white my-1  text-center ${
                                                  isLight.lightDark
                                                    ? "text-dark"
                                                    : "text-dark"
                                                }`}
                                                style={{
                                                  fontSize: "14px",
                                                  width: "100%",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                HISTORIC
                                              </div>
                                              {showHictoricPop && (
                                                <HistoricPopUp
                                                  currentHistory={
                                                    currentHistory
                                                  }
                                                  setCurrentHistory={
                                                    setCurrentHistory
                                                  }
                                                  show={showHictoricPop}
                                                  setShow={setShowHictoricPop}
                                                />
                                              )}
                                            </>
                                          )}
                                          {categ.title === "RECORDS" && (
                                            <>
                                              <div
                                                onClick={() => {
                                                  setshowRecords(true);
                                                  dispatch(GetAllRecords());
                                                }}
                                                key={categ.id}
                                                className={`py-1 ps-2 bg-white my-1  text-center ${
                                                  isLight.lightDark
                                                    ? "text-dark"
                                                    : "text-dark"
                                                }`}
                                                style={{
                                                  fontSize: "14px",
                                                  width: "100%",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                RECORDS
                                              </div>
                                              {showRecords && (
                                                <RecordsPopUp
                                                  show={showRecords}
                                                  setShow={setshowRecords}
                                                />
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </>
                                  ))}
                                </div>
                              )}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default BoostDataNavLayout;
