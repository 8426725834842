import { createSlice } from "@reduxjs/toolkit";

const previousSubmissionSlice = createSlice({
  name: "previousSubmission",
  initialState: false,
  reducers: {
    setPreviousSubmission: (state, action) => {
      return action.payload;
    },
  },
});

export const { setPreviousSubmission } = previousSubmissionSlice.actions;
export const selectPreviousSubmission = (state) => state.previousSubmission;

export default previousSubmissionSlice.reducer;
