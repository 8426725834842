import React, { useState } from "react";
import "./commonCompStyle/Acordion.css";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
const FaqAcordion = ({ data }) => {
  const [openId, setOpenId] = useState(1);
  return (
    <div className="my-3 w-100">
      {data.map((faq) => (
        <div key={faq.id} className="accordion-container rounded w-100 mb-3">
          <div
            className={`${
              openId === faq.id ? "open" : ""
            } acc-question  rounded-top d-flex align-items-start w-100  justify-content-between `}
          >
            <p className="faq-question">{faq.question}</p>
            <div
              onClick={() => {
                if (openId === faq.id) {
                  setOpenId(0);
                } else {
                  setOpenId(faq.id);
                }
              }}
              className="bg-white rounded-circle d-flex align-items-center justify-content-center"
              style={{
                height: "25px",
                width: "25px",
                padding: "2px",
                cursor: "pointer",
                border: ` ${
                  openId === faq.id
                    ? "open"
                    : ""
                    ? ""
                    : "1px solid var(--primary-color-green)"
                }`,
              }}
            >
              {openId === faq.id ? (
                <BsChevronUp
                  style={{
                    color: "var(--primary-color-green)",
                  }}
                  className="fs-4"
                />
              ) : (
                <BsChevronDown
                  style={{
                    color: "var(--primary-color-green)",
                  }}
                  className="fs-4"
                />
              )}
            </div>
          </div>
          {
            <div
              className={`accordion bg-white ${
                openId === faq.id ? "open" : "" ? "open" : ""
              } px-2 `}
            >
              <p className="faq-question">{faq.answer}</p>
            </div>
          }
        </div>
      ))}
    </div>
  );
};

export default FaqAcordion;
