import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showMainCategories: true,
};

const ShowMainCategoriesSlice = createSlice({
  name: "show-main-categories",
  initialState,
  reducers: {
    isShowMainCategories: (state, action) => {
      state.showMainCategories = action.payload;
    },
  },
});

export const { isShowMainCategories } = ShowMainCategoriesSlice.actions;
export default ShowMainCategoriesSlice.reducer;
