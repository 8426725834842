import React, { useEffect, useState } from "react";
import BottomFullModal from "../../../common/BottomFullModal";
import { Historic } from "../../../store/BoostDataSlice/HistoricSlice";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { HistroicRecord } from "./HistroicRecord";
import NoResultFound from "../../../common/NoResultFound";
const recordsDepositData = [
  {
    id: 1,
    title: "The friend at level 1 is allowed to order profit",
    dataString: "2023-08-30 21:00:35",
    recData: "0.06",
  },
  {
    id: 2,
    title: "The friend at level 2 is allowed",
    dataString: "2023-08-30 21:00:35",
    recData: "0.06",
  },
  {
    id: 3,
    title: "The friend at level 3 is allowed",
    dataString: "2023-08-30 21:00:35",
    recData: "0.06",
  },
  {
    id: 4,
    title: "The friend at level 4 is allowed",
    dataString: "2023-08-30 21:00:35",
    recData: "0.06",
  },
  {
    id: 5,
    title: "The friend at level 5 is allowed",
    dataString: "2023-08-30 21:00:35",
    recData: "0.06",
  },
];

const recordsWithdrawalData = [
  {
    id: 1,
    title: "The friend at level 1 is allowed to order profit",
    dataString: "2023-08-30 21:00:35",
    recData: "0.06",
  },
  {
    id: 2,
    title: "The friend at level 2 is allowed",
    dataString: "2023-08-30 21:00:35",
    recData: "0.06",
  },
  {
    id: 3,
    title: "The friend at level 3 is allowed",
    dataString: "2023-08-30 21:00:35",
    recData: "0.06",
  },
  {
    id: 4,
    title: "The friend at level 4 is allowed",
    dataString: "2023-08-30 21:00:35",
    recData: "0.06",
  },
  {
    id: 5,
    title: "The friend at level 5 is allowed",
    dataString: "2023-08-30 21:00:35",
    recData: "0.06",
  },
];
const HistoricPopUp = ({
  title,
  currentHistory,
  setCurrentHistory,
  show,
  setShow,
}) => {
  const dispatch = useDispatch();
  const historicData = useSelector((state) => state?.historic?.data?.data);
  const historyNav = [
    {
      id: "deposit",
      title: "Deposit",
      subNav: [
        {
          id: 1,
          title: "All",
        },
        {
          id: 2,
          title: "Pending",
        },
        {
          id: 3,
          title: "In-Progress",
        },
        {
          id: 4,
          title: "Completed",
        },
      ],
    },
    {
      id: "withdrawal",
      title: "Withdrawal",
      subNav: [
        {
          id: 1,
          title: "All",
        },
        {
          id: 2,
          title: "Pending",
        },
        {
          id: 3,
          title: "In-Progress",
        },
        {
          id: 4,
          title: "Completed",
        },
      ],
    },
  ];
  const [currentSubNav, setCurrentSubNav] = useState(1);
  const [currentTab, setCurrentTab] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [transactionData, setTransactionData] = useState([]);

  useEffect(() => {
    dispatch(Historic(currentHistory))
      .then((response) => {
        if (response && response.payload) {
          const recordsFromApi = response.payload.data;

          setPageCount(Math.ceil(recordsFromApi.length / 5));
        }

        if (
          response?.error?.message === "Request failed with status code 401"
        ) {
          dispatch(setLoginVal(false));
          localStorage.removeItem("userLogin");
        }
      })
      .catch((error) => {
        console.error("Error fetching records:", error);
      });
  }, [currentHistory]);
  useEffect(() => {
    if (historicData !== null && historicData !== undefined)
      setTransactionData(historicData);
  }, [historicData]);

  const handleTabChange = (tabId) => {
    setCurrentHistory(tabId);
    setCurrentSubNav(1);
    setCurrentTab(1);
    setCurrentPage(0);
    dispatch(Historic(tabId));
  };
  const handleTabClick = (subTabId) => {
    setCurrentSubNav(subTabId);
    setCurrentPage(0);
  };

  const filteredData = transactionData
    ?.filter((rec) => {
      if (currentHistory === "deposit") {
        if (currentSubNav === 1) {
          return true;
        } else if (currentSubNav === 2) {
          return rec.status === "Pending";
        } else if (currentSubNav === 3) {
          return rec.status === "In Progress";
        } else if (currentSubNav === 4) {
          return rec.status === "Completed";
        }
      } else if (currentHistory === "withdrawal") {
        if (currentSubNav === 1) {
          return true;
        } else if (currentSubNav === 2) {
          return rec.status === "Pending";
        } else if (currentSubNav === 3) {
          return rec.status === "In Progress";
        } else if (currentSubNav === 4) {
          return rec.status === "Completed";
        }
      }
      return false;
    })
    .slice(currentPage * 5, (currentPage + 1) * 5); // Paginate the data

  return (
    <BottomFullModal title={title} show={show} setShow={setShow}>
      <div style={{ background: "#f8f8f8" }} className="container">
        <div className=" my-4 py-4 his-rec-pop-inner-cont">
          <h2 className="d-flex fw-semibold his-rec-pop-heading">
            Transaction History
          </h2>
          <div className="row my-3 justify-content-between">
            <div className="row my-3 justify-content-between">
              {historyNav.map((nav) => (
                <div
                  key={nav.id}
                  className={`col-6 bg-white py-3 rounded-top fw-semibold history-nav-title ${
                    nav.id === currentHistory ? "text-success" : "text-dark"
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleTabChange(nav.id)}
                >
                  {nav.id === "deposit" ? "Deposit" : "Withdrawal"}
                </div>
              ))}
            </div>
          </div>

          <div>
            {/* Render the tabs */}
            <div className="d-flex w-100 justify-content-between"></div>
            {historyNav.map(
              (nav) =>
                nav.id === currentHistory && (
                  <div className="  py-3 d-flex justify-content-between ">
                    {nav.subNav.map((subNav) => (
                      <div
                        key={subNav.id}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleTabClick(subNav.id)}
                      >
                        <p
                          className="history-rec-subnav-title"
                          style={{
                            color: `${
                              subNav.id === currentSubNav
                                ? "var(--primary-color-green)"
                                : "#000"
                            }`,
                            borderBottom: `${
                              subNav.id === currentSubNav
                                ? "2px solid var(--primary-color-green)"
                                : "#000"
                            }`,
                          }}
                        >
                          {subNav.title}
                        </p>
                      </div>
                    ))}
                  </div>
                )
            )}
          </div>

          <div>
            {filteredData?.length > 0 ? (
              filteredData?.map((data) => <HistroicRecord data={data} />)
            ) : (
              <div className="my-5">
                <NoResultFound />
              </div>
            )}
          </div>
          {filteredData?.length >= 5 && (
            <div>
              <ReactPaginate
                breakLabel="..."
                nextLabel={<span style={{ color: "green" }}>Next</span>}
                onPageChange={(selectedPage) =>
                  setCurrentPage(selectedPage.selected)
                }
                pageRangeDisplayed={3}
                pageCount={pageCount}
                previousLabel={<span style={{ color: "green" }}>Previous</span>}
                containerClassName="d-flex gap-1 gap-sm-3 font-class p-3 align-items-center justify-content-center justify-content-sm-end text-secondary"
                activeClassName="border px-2 btn-class text-light"
                previousClassName="text-color"
                nextClassName="text-color"
              />
            </div>
          )}
        </div>
      </div>
    </BottomFullModal>
  );
};

export default HistoricPopUp;
