import React, { useEffect, useState } from "react";
import HeadingWithSearchBar from "../../common/HeadingWithSearchBar";
import LargeOneItemCard from "../../common/LargeOneItemCard";
import ItemsGroupcard from "../../common/ItemsGroupcard";
import { useSelector } from "react-redux";
import NoResultFound from "../../common/NoResultFound";
import { BASE_URL } from "../../env";

const CategorySeven = ({ homeCategory }) => {
  const [data, setData] = useState([]);
  const categoryProduct = homeCategory?.filter(
    (category) => category?.menu_position === 7
  );

  return (
    <>
      {categoryProduct.length > 0 && (
        <div style={{ backgroundColor: "#f4f4f4" }}>
          <div className="container  py-4">
            <div className="d-flex flex-column ">
              <div>
                <HeadingWithSearchBar
                  heading={`${categoryProduct[0]?.category_name}`}
                />
              </div>
              {categoryProduct[0]?.products?.length > 1 ? (
                <div className="mt-5 d-flex flex-column gap-3 flex-lg-row  justify-content-center w-100">
                  <div
                    className=" w-100 bg-dark "
                    style={{ background: "blue" }}
                  >
                    <LargeOneItemCard
                      products={categoryProduct[0]?.products[0]}
                      categoryName={categoryProduct[0]?.category_name}
                      categoryId={categoryProduct[0]?.id}
                    />
                  </div>
                  <div className="w-100 " style={{ background: "red" }}>
                    <ItemsGroupcard
                      products={categoryProduct[0]?.products}
                      categoryName={categoryProduct[0]?.category_name}
                      categoryId={categoryProduct[0]?.id}
                    />
                  </div>
                </div>
              ) : (
                <NoResultFound />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CategorySeven;
