import React, { useEffect, useState } from "react";
import Layout from "../common/layout/Layout";
import ScrollToTop from "../components/ScrollToTop";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetProductWithMainCateg } from "../store/Product/GetProductMainCateg";
import { LoaderAPICalls } from "../common/LoaderAPICalls";
import CategoryComponent from "../components/categories/CategoryComp";
const Category = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const priceRangeLoading = useSelector(
    (state) => state?.priceRangeLoading?.firstLoadig
  );
  const productByCategory = useSelector(
    (state) => state?.ProductWithMainCateg?.data?.data
  );
  const subCategoryId = useSelector(
    (state) => state.SubCategoryId.subCategoryId
  );
  const [isLoading, setIsLoading] = useState(false);
  const [checkedBarnd, setCheckedBarnd] = useState([]);
  const [priceRange, setPriceRange] = useState(null);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(100);
  const [allProductdata, setAllProductdata] = useState([]);

  useEffect(() => {
    setCheckedBarnd([]);
  }, [location?.state?.id]);
  useEffect(() => {
    setIsLoading(true);
    if (location?.state?.id) {
      const response = dispatch(
        GetProductWithMainCateg({
          id: location?.state?.id,
          subCategId: subCategoryId,
          priceRange: priceRangeLoading ? priceRange : null,
          brandData: checkedBarnd,
        })
      );
      response.then((res) => {
        if (res.payload.meta.status_code === 200) {
          setIsLoading(false);
        }
      });
    }
  }, [
    subCategoryId,
    priceRangeLoading && priceRange,
    checkedBarnd,
    location?.state?.id,
  ]);

  useEffect(() => {
    if (productByCategory?.filter((item) => item.price_range).length !== 0) {
      const maxPrice = productByCategory?.filter((item) => item.price_range)[0];

      setMaxValue(maxPrice?.price_range?.max_price);
      setMinValue(maxPrice?.price_range?.min_price);
      setPriceRange([
        maxPrice?.price_range?.min_price,
        maxPrice?.price_range?.max_price,
      ]);
      setAllProductdata(productByCategory);
    } else {
      setMinValue(0);
      setMaxValue(100);
      setPriceRange([0, 100]);
      setAllProductdata([]);
    }
  }, [!priceRangeLoading && productByCategory]);

  return (
    <Layout>
      {isLoading && (
        <div className="loader-container">
          <LoaderAPICalls />
        </div>
      )}
      <CategoryComponent
        isLoading={isLoading}
        checked={checkedBarnd}
        setChecked={setCheckedBarnd}
        allProductdata={allProductdata}
        categoryId={location?.state?.id}
        categoryName={location?.state?.categoryName}
        setPriceRange={setPriceRange}
        priceRange={priceRange}
        minValue={minValue}
        maxValue={maxValue}
      />
      <ScrollToTop />
    </Layout>
  );
};

export default Category;
