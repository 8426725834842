import React, { useEffect, useState } from "react";
import Headers from "./Headers/Headers";
import Footer from "./Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import ScrollToTop from "../../components/ScrollToTop";
import WhatsAppAndLiveChat from "../../components/WhatsAppAndLiveChat";
import { right } from "@popperjs/core";
import { RefeshToken } from "../../store/RefreshToken/RefreshTokenSlice";
import { AllCategory } from "../../store/Category/GetAllCategory";
import { allcategoryState } from "../../store/uiSlices/AllcategoryStateTrue";
function Layout({ children }) {
  const dispatch = useDispatch();
  const isLight = useSelector((state) => state.darkLight);
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    const response = dispatch(AllCategory());
    response.then((res) => {
      if (res.payload.meta.status_code === 200) {
        dispatch(allcategoryState(true));
      }
    });
  }, []);
  setInterval(async () => {
    const res = await dispatch(RefeshToken());
  }, 1800000);

  return (
    <div
      className="position-relative"
      // style={{ background: isLight.lightDark ? "white" : "#343541" }}
    >
      <Headers setSearchTerm={setSearchTerm} searchTerm={searchTerm} />
      {children}
      <Footer />
      <ScrollToTop />
      <div
        className="position-fixed "
        style={{ zIndex: "1000", bottom: "20px", right: "0" }}
      >
        <WhatsAppAndLiveChat />
      </div>
    </div>
  );
}
export default Layout;
