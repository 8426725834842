import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../env";

export const GetNetworkType = createAsyncThunk("GetNetworkType", async () => {
  const uservalue = localStorage.getItem("userLogin");
  const tokenVALUE = JSON.parse(uservalue);
  const config = {
    headers: {
      Authorization: `Bearer ${tokenVALUE?.data?.token?.access}`,
    },
    timeout: 30000,
    cancelToken: new axios.CancelToken((cancel) => {
      // Create a cancel token and function
      setTimeout(() => {
        cancel("Request timed out");
      }, 30000);
    }),
  };
  try {
    const response = await axios.get(
      `${BASE_URL}lookups/get-network-type/`,
      config
    );
    return response.data;
  } catch (error) {
    throw error;
  }
});
const GetNetworkTypeSlice = createSlice({
  name: "GetNetworkType",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(GetNetworkType.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetNetworkType.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(GetNetworkType.rejected, (state, action) => {
      console.log(action.error.message);
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export default GetNetworkTypeSlice.reducer;
