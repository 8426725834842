import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../env";

export const GetSearchProduct = createAsyncThunk(
  "GetSearchProduct",
  async (searchTerm) => {
    const uservalue = localStorage.getItem("userLogin");
    const tokenVALUE = JSON.parse(uservalue);
    const config = {
      timeout: 30000,
      cancelToken: new axios.CancelToken((cancel) => {
        // Create a cancel token and function
        setTimeout(() => {
          cancel("Request timed out");
        }, 30000);
      }),
    };

    if (tokenVALUE?.data?.token?.access) {
      config.headers = {
        Authorization: `Bearer ${tokenVALUE?.data?.token?.access}`,
      };
    }
    try {
      const response = await axios.get(
        `${BASE_URL}catalogue/search-products/?item_name__icontains=${searchTerm}`,
        config
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
const GetSearchProductSlice = createSlice({
  name: "GetSearchProduct",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    searchProduct: null,
  },
  reducers: {
    setSearchProduct: (state, action) => {
      state.searchProduct = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetSearchProduct.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetSearchProduct.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(GetSearchProduct.rejected, (state, action) => {
      // console.log(action.error.message);
      state.isLoading = false;
      state.isError = true;
    });
  },
});
export const { setSearchProduct } = GetSearchProductSlice.actions;
export default GetSearchProductSlice.reducer;
