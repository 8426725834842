import { FaMinus, FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { itemCounter, removeFromCart } from "../store/uiSlices/AddToCartSlice";
import productDummyImage from "../assets/image/product.jpeg";
import NoResultFound from "../common/NoResultFound";

const AddToCart = () => {
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state?.AddToCartSlice?.items);
  const inrementFunc = (id) => {
    dispatch(itemCounter({ id: id, change: "increment" }));
  };

  const decrementFunc = (item) => {
    if (item.count > 1) {
      dispatch(itemCounter({ id: item.id, change: "decrement" }));
    } else {
      dispatch(removeFromCart(item.id));
    }
  };

  const calculatePrice = function (price, count) {
    let totalPrice = 0;
    for (let i = 1; i <= count; i++) {
      totalPrice += Number(price);
    }

    return totalPrice;
  };

  return (
    <>
      {cartItems?.length > 0 ? (
        cartItems?.map((item) => (
          <div
            key={item.id}
            className="my-4 d-flex align-items-center  justify-content-between "
          >
            <div>
              <div>
                <h5 className="cart-card-heading">
                  {item?.item_name.length > 20
                    ? `${item?.item_name.slice(0, 20)}...`
                    : item?.item_name}
                </h5>
                <p
                  style={{
                    color: "darkgray",
                    fontSize: "15px",
                    maxWidth: "200px",
                  }}
                  className="cart-card-desc"
                >
                  {item?.item_description?.length > 50
                    ? `${item?.item_description?.slice(0, 50)}...`
                    : item?.item_description}
                </p>
              </div>
              <div className="d-flex align-items-center gap-2 mt-2 ">
                <p>
                  <span className="fw-bold  cart-card-price">
                    $ {item?.itemTotalPrice}
                  </span>
                  <span
                    style={{ color: "darkgray", fontSize: "15px" }}
                    className="ms-1"
                  >
                    for
                  </span>
                  <span className="fw-bold  ms-2">{item.count}</span>
                </p>
                <div className="d-flex gap-2 ">
                  <div
                    className="rounded d-flex align-items-center  justify-content-center "
                    style={{
                      height: "25px",
                      width: "25px",
                      background: "#00800024",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      decrementFunc(item);
                    }}
                  >
                    <FaMinus style={{ color: "var(--primary-color-green)" }} />
                  </div>
                  <div
                    className="rounded d-flex align-items-center  justify-content-center "
                    style={{
                      height: "25px",
                      width: "25px",
                      background: "#00800024",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      inrementFunc(item?.id);
                    }}
                  >
                    <FaPlus
                      style={{ color: "var(--primary-color-green)" }}
                      className=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="rounded overflow-hidden"
              style={{ background: "#eee" }}
            >
              {item?.product_media?.length > 0 &&
              item?.product_media[0]?.image ? (
                <img
                  src={item?.product_media[0]?.image}
                  className="cartImage"
                />
              ) : (
                <img src={productDummyImage} className="cartImage" />
              )}
            </div>
          </div>
        ))
      ) : (
        <div>
          <NoResultFound />
        </div>
      )}
    </>
  );
};
export default AddToCart;
