import React, { useEffect, useState } from "react";
import { BsChevronRight } from "react-icons/bs";
import { RiLuggageDepositLine } from "react-icons/ri";
import { BiMoneyWithdraw } from "react-icons/bi";
import { TbBellRinging, TbUserDollar } from "react-icons/tb";
import { LiaWalletSolid } from "react-icons/lia";
import { RiLockPasswordFill } from "react-icons/ri";
import { BiPhoneCall } from "react-icons/bi";
import WithDrawalPop from "../../common/WithDrawalPop";
import EditProfilePopUp from "./EditProfilePopUp";
import LinkWalletPopup from "../../common/LinkWalletPopup";
import { depositeModalHide } from "../../store/uiSlices/LoginModalSlice";
import { useDispatch, useSelector } from "react-redux";
import { GetDepositeAddress } from "../../store/BoostDataSlice/GetdepositeAddress";
import { UnlockWithDrawalPop } from "../../common/UnlockWithDrawalPop";
import PasswordProfile from "./PasswordProfile";
import { GetWithdrawalAddress } from "../../store/BoostDataSlice/GetWithdrawalAddress";
import { BoostDataUser } from "../../store/BoostDataSlice/GetBoostDataUserSlice";
import AddWithdrawalAddress from "../../common/AddWithdrawalAddress";

const profileData = [
  {
    id: 1,
    title: "Financial",
    data: [
      { id: 1, title: "Deposit", icon: RiLuggageDepositLine, modal: true },
      { id: 2, title: "Withdraw", icon: BiMoneyWithdraw, modal: true },
    ],
  },
  {
    id: 2,
    title: "Profile",
    data: [
      { id: 1, title: "Edit Profile", icon: TbUserDollar, modal: true },
      {
        id: 2,
        title: "Change Password",
        icon: RiLockPasswordFill,
        modal: true,
      },
      { id: 3, title: "Link Wallet", icon: LiaWalletSolid, modal: true },
    ],
  },
  // {
  //   id: 3,
  //   title: "About us",
  //   data: [
  //     { id: 1, title: "Contact Us", icon: BiPhoneCall },
  //     {
  //       id: 2,
  //       title: "Notification",
  //       icon: TbBellRinging,
  //       link: "notification",
  //     },
  //   ],
  // },
];

const ProfileDetail = () => {
  const boostDataUser = useSelector(
    (state) => state?.GetBoostDataUserSlice?.data?.data
  );
  const dispatch = useDispatch();
  const [showWithdraw, setShowWithdraw] = useState(false);
  const [showTransactionHistory, setShowTransactionHistory] = useState(false);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [showLinkWallet, setShowLinkWallet] = useState(false);
  const [showPasswordModal, setshowPasswordModal] = useState(false);
  const [crypto, setCrypto] = useState("USDT");
  const [network, setNetwork] = useState("USDT-TRC20");
  useEffect(() => {
    dispatch(BoostDataUser());
  }, []);
  const profileRecord = useSelector(
    (state) => state?.ProfileRecordSlice?.data?.data
  );
  return (
    <div className="rounded bg-white  shadow w-100 p-4">
      {profileData.map((data) => (
        <div key={data.id}>
          <h5
            className="py-2 mt-3 text-start"
            style={{ borderBottom: "2.5px solid #f7f7f7" }}
          >
            {data.title}
          </h5>
          {data.data.map((li) => (
            <div
              key={li.id}
              className="py-2 d-flex align-items-center  justify-content-between "
              style={{ borderBottom: "2.5px solid #f7f7f7", cursor: "pointer" }}
              onClick={() => {
                if (li.title === "Deposit") {
                  dispatch(depositeModalHide(true));
                  dispatch(GetDepositeAddress());
                } else if (li.title === "Withdraw") {
                  setShowWithdraw((prev) => !prev);
                } else if (li.title === "Transaction History") {
                  setShowTransactionHistory((prev) => !prev);
                } else if (li.title === "Edit Profile") {
                  setShowEditProfile((prev) => !prev);
                } else if (li.title === "Change Password") {
                  setshowPasswordModal((prev) => !prev);
                } else if (li.title === "Link Wallet") {
                  dispatch(
                    GetWithdrawalAddress({ crypto: crypto, network: network })
                  );
                  setShowLinkWallet((prev) => !prev);
                }
              }}
            >
              <div className="d-flex align-items-center justify-content-between w-100">
                <div className="d-flex align-items-center  gap-3">
                  {li.icon && (
                    <li.icon
                      className="fs-3"
                      style={{ color: "var(--primary-color-green)" }}
                    />
                  )}{" "}
                  <p>{li.title}</p>
                </div>{" "}
                <BsChevronRight
                  style={{
                    color: "var(--primary-color-green)",
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      ))}

      {showWithdraw && (
        <>
          {/* {boostDataUser?.withdraw_available === true ? ( */}

          <>
            {profileData?.user_profile?.withdraw_address_link ? (
              <WithDrawalPop
                show={showWithdraw}
                setShow={setShowWithdraw}
                title={"WIDTHDRAW"}
              />
            ) : (
              <AddWithdrawalAddress
                show={showWithdraw}
                setShow={setShowWithdraw}
              />
            )}
          </>

          {/* ) : (
            <UnlockWithDrawalPop
              show={showWithdraw}
              setShow={setShowWithdraw}
              title={"WIDTHDRAW"}
            />
          )} */}
        </>
      )}

      {showEditProfile && (
        <EditProfilePopUp
          heading={"Edit Profile"}
          show={showEditProfile}
          setShow={setShowEditProfile}
        />
      )}
      {showPasswordModal && (
        <PasswordProfile
          heading={"Edit Profile"}
          show={showPasswordModal}
          setShow={setshowPasswordModal}
          // heading={categ.title}
        />
      )}

      {showLinkWallet && (
        <LinkWalletPopup
          setCrypto={setCrypto}
          setNetwork={setNetwork}
          heading={"Link Wallet"}
          show={showLinkWallet}
          setShow={setShowLinkWallet}
        />
      )}
    </div>
  );
};

export default ProfileDetail;
