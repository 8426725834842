import React from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ApiCall } from "../../../PostApiRequest";
const OTP = ({ loginClick, setShowOTP, onClick }) => {
  const handleError = (error) => {
    if (error.response) {
      if (error.response.status === 400) {
        toast.error(error.response?.data?.meta?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else if (error.request) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      console.log("Error:", error.message);
    }
  };
  const initialValues = {
    section: "authentication",
    email_code: "",
    sms_code: "",
  };
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues,
      validate: (values) => {
        const errors = {};
        if (!values.email_code) {
          errors.email_code = "Email code is required";
        }

        if (!values.sms_code) {
          errors.sms_code = "SMS code is required";
        }

        return errors;
      },
      onSubmit: async (values, { setSubmitting }) => {
        try {
          const response = await ApiCall("auth/verify_otp/", "POST", values);
          console.log("response", response);
          if (response.meta.status_code === 200) {
            setShowOTP(false);
            onClick();
            loginClick();
            localStorage.removeItem("userEmail");
            toast.success(response.meta.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setTimeout(() => {
              setSubmitting(false);
            }, 1000);
          } else {
            toast.error(response.meta.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } catch (error) {
          handleError(error);
        }
      },
    });
  const userEmail = localStorage.getItem("userEmail");
  const resendCodeEmail = async () => {
    const formData = new FormData();
    formData.append("otp_field", "email");
    formData.append("email", userEmail);
    try {
      const response = await ApiCall("auth/resend_otp/", "POST", formData);
      if (response.meta.status_code === 200) {
        toast.success(response.meta.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(response.meta.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      handleError(error);
    }
  };
  const resendCodeSMS = async () => {
    const formData = new FormData();
    formData.append("otp_field", "phone");
    formData.append("email", userEmail);
    try {
      const response = await ApiCall("auth/resend_otp/", "POST", formData);
      console.log("response", response);
      if (response.meta.status_code === 200) {
        toast.success(response.meta.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(response.meta.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      handleError(error);
    }
  };
  return (
    <form className="pb-4" onSubmit={handleSubmit}>
      <p className="fw-bold" style={{ color: "var(--primary-color-green)" }}>
        Confirm OTP
      </p>
      <div className="" style={{ height: "60px" }}>
        <input
          type="text"
          name="email_code"
          className="mt-2 "
          placeholder="Email Code"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email_code}
        />
        {touched.email_code && errors.email_code && (
          <p className="error text-danger" style={{ fontSize: "13px" }}>
            {errors.email_code}
          </p>
        )}
      </div>
      <div
        className="d-flex align-items-center gap-3 my-1"
        style={{ fontSize: "14px" }}
      >
        <p className="text-dark ">Don't Recived Email Code? </p>
        <div
          onClick={() => resendCodeEmail()}
          style={{ color: "var(--primary-color-green)", cursor: "pointer" }}
        >
          Resend
        </div>
      </div>
      <div className="" style={{ height: "60px" }}>
        <input
          type="text"
          className=" mt-2 "
          placeholder="SMS Code "
          name="sms_code"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.sms_code}
        />

        {touched.sms_code && errors.sms_code && (
          <p className="error text-danger" style={{ fontSize: "13px" }}>
            {errors.sms_code}
          </p>
        )}
      </div>
      <div
        className="d-flex align-items-center gap-3 my-1"
        style={{ fontSize: "14px" }}
      >
        <p className="text-dark">Don't Recived SMS Code? </p>
        <div
          onClick={() => resendCodeSMS()}
          style={{ color: "var(--primary-color-green)", cursor: "pointer" }}
        >
          Resend
        </div>
      </div>
      <button
        type="submit"
        className="btn trak-sub-btn my-2 text-white ps-5 pe-5 w-100"
        style={{
          background: "#008000",
          boxShadow: `rgba(0, 0, 0, 0.1) 0 5px 5px 2px`,
        }}
      >
        Confirm
      </button>
    </form>
  );
};

export default OTP;
