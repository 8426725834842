import React from "react";
import "./commonCompStyle/Congratulation.css";
import Confetti from "react-confetti";
import prise from "../assets/image/prise.png";
import Modal from "./Modal";

const CongratulationModal = ({
  showCongratulations,
  setShowCongratulations,
  data,
  total_profit,
  vipLevelData,
}) => {
  return (
    <>
      <Modal
        heading={"Data Submission"}
        show={showCongratulations}
        setShow={setShowCongratulations}
      >
        <Confetti className="w-100 h-100" />
        <div style={{ maxWidth: `${500}px` }}>
          <div className=" mx-5 text-dark mt-3">
            <img src={prise} />
            <h1 style={{ color: "var(--primary-color-green)" }}>
              Congratulations
            </h1>
            {vipLevelData?.subscription_type_name ? (
              <p className="mt-2">
                You have subscribed{" "}
                <span
                  style={{
                    fontSize: "15px",
                    fontWeight: "600",
                    color: "var(--primary-color-green)",
                  }}
                >
                  {vipLevelData?.subscription_type_name}
                </span>
                .
              </p>
            ) : (
              <p className="mt-2">
                You finish the{" "}
                <span
                  style={{
                    fontSize: "15px",
                    fontWeight: "600",
                    color: "var(--primary-color-green)",
                  }}
                >
                  {data?.name}
                </span>
                , you earn{" "}
                <span
                  style={{
                    fontSize: "15px",
                    fontWeight: "600",
                    color: "var(--primary-color-green)",
                  }}
                >
                  {total_profit} USDT
                </span>
                .
              </p>
            )}

            <div className="d-flex justify-content-center  align-items-center  mt-3">
              {" "}
              <h4
                onClick={() => {
                  setShowCongratulations(false);
                }}
                className="text-white border-0 px-5 py-1 rounded mt-3"
                style={{
                  background: "var(--primary-color-green)",
                  cursor: "pointer",
                }}
              >
                Ok
              </h4>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CongratulationModal;
