import React from "react";
import { RxCross2 } from "react-icons/rx";
import "./commonCompStyle/DepositeModal.css";
import { useDispatch, useSelector } from "react-redux";
import { depositeModalHide } from "../store/uiSlices/LoginModalSlice";
import { BsChevronRight } from "react-icons/bs";

const DepositeModal = ({
  title,
  children,
  scroll,
  icon,
  setCrypto,
  setNetwork,
  setShowQrImage,
}) => {
  const dispatch = useDispatch();
  const showDepositeModal = useSelector(
    (state) => state.loginModal.isDepositeModalShown
  );

  return (
    <div className=" text-center ">
      {(title || icon) && (
        <div
          onClick={() => {
            dispatch(depositeModalHide(true));
          }}
          style={{ cursor: "pointer" }}
          className="modal-title"
        >
          {icon ? (
            <BsChevronRight
              style={{
                color: "var(--primary-color-green)",
                cursor: "pointer",
              }}
            />
          ) : (
            <p className="">{title}</p>
          )}
        </div>
      )}

      <div
        className={`${showDepositeModal ? "open" : ""} depositeModalOverlay`}
      >
        <div
          className={`${
            showDepositeModal ? "open" : ""
          } depositeModalCard rounded pt-3  pb-4 `}
          style={{
            maxWidth: `${450}px `,
            overflowY: `${scroll && "scroll"}`,
          }}
        >
          <div className="d-flex justify-content-between px-2 ">
            <h4
              className="fs-5 fw-bolder ms-3 mb-3"
              style={{ color: "var(--primary-color-green)" }}
            ></h4>
            <RxCross2
              onClick={() => {
                setCrypto(null);
                setNetwork(null);
                setShowQrImage(false);
                dispatch(depositeModalHide(false));
              }}
              className="me-2 text-danger"
              style={{
                fontSize: "25px",
                cursor: "pointer",
                color: "var(--primary-color-green)",
              }}
            />
          </div>

          <div className=" mx-5 text-dark">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default DepositeModal;
