import { createSlice } from "@reduxjs/toolkit";
const wishItems = JSON.parse(localStorage.getItem("wishlist"));
const initialState = {
  wishlist: wishItems ? wishItems : [],
};

const AddwishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    addWish: (state, action) => {
      const updatedWish = JSON.parse(JSON.stringify(state.wishlist));
      const filterWish = updatedWish.filter(
        (wish) => wish.id !== action.payload.id
      );
      state.wishlist = [...filterWish, action.payload];
      localStorage.setItem(
        "wishlist",
        JSON.stringify([...filterWish, action.payload])
      );
    },
  },
});

export const { addWish } = AddwishlistSlice.actions;
export default AddwishlistSlice.reducer;
