import React from "react";

export const HistroicRecord = ({ data, currentHistory }) => {
  const timestamp = data?.created;
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  console.log(data);

  // Create a formatted string
  const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  console.log("currentHistory", currentHistory);
  return (
    <div
      className="d-flex align-items-center justify-content-between my-4 transaction-history-card py-2 rounded"
      style={{ backgroundColor: "#fff" }}
      key={data?.id}
    >
      <div className="d-flex flex-column justify-content-around">
        <p className="fw-semibold history-rec-card-heading">
          {data?.transaction_type}
        </p>
        <p className="mt-2" style={{ fontSize: "10px" }}>
          {formattedDateTime}
        </p>
      </div>
      <p className="fw-bold">
        <span
          style={{
            color:
              data?.transaction_type === "DEPOSIT"
                ? "var(--primary-color-green)"
                : "red",
            fontSize: "20px",
          }}
        >
          {data?.transaction_type === "DEPOSIT" ? "+" : "-"}
        </span>
        <span
          style={{
            color:
              data?.transaction_type === "DEPOSIT"
                ? "var(--primary-color-green)"
                : "red",
            fontSize: "15px",
          }}
        >
          {data?.transaction_amount}
        </span>
      </p>
    </div>
  );
};
