import React from "react";
import SubscribeNewsFooter from "./SubscribeNewsFooter";
import GetitonFooter from "./GetitonFooter";
import EndFooter from "./EndFooter";

function Footer() {
  return (
    <div>
      <SubscribeNewsFooter />
      <GetitonFooter />
      <EndFooter />
    </div>
  );
}

export default Footer;
