import Carousel from "react-bootstrap/Carousel";

import { BsArrowLeft, BsArrowRight } from "react-icons/bs";

function HeroSlider({ images }) {
  return (
    <Carousel
      fade
      pause={"hover"}
      nextIcon={
        <div className=" hero-slider-btn  rounded-circle  d-flex align-items-center justify-content-center">
          <BsArrowRight className="   " />
        </div>
      }
      prevIcon={
        <div className="hero-slider-btn  rounded-circle  d-flex align-items-center justify-content-center">
          <BsArrowLeft className="   " />
        </div>
      }
    >
      {images?.map((img, index) => (
        <Carousel.Item key={index}>
          <img
            className="d-block w-100"
            src={img.img}
            alt="First slide"
            loading="lazy"
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default HeroSlider;
