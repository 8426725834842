import React, { useEffect, useState } from "react";
import HeadingWithSearchBar from "../../common/HeadingWithSearchBar";
import LargeCard from "../../common/LargeCard";
import GroupCard from "../../common/GroupCard";
import { useSelector } from "react-redux";
import NoResultFound from "../../common/NoResultFound";
import { BASE_URL } from "../../env";

const CategoryEight = ({ homeCategory }) => {
  const categoryProduct = homeCategory?.filter(
    (category) => category?.menu_position === 8
  );

  return (
    <>
      {categoryProduct[0]?.category_name && (
        <div>
          <div className="container py-4">
            <div className="d-flex flex-column ">
              <div>
                <HeadingWithSearchBar
                  heading={`${categoryProduct[0]?.category_name}`}
                />
              </div>
              {categoryProduct[0]?.products?.length > 0 ? (
                <div className="mt-5 d-flex flex-column flex-xl-row gap-4  align-items-stretch  justify-content-center ">
                  <div className=" ">
                    <LargeCard
                      products={categoryProduct[0]?.products}
                      categoryName={categoryProduct[0]?.category_name}
                      categoryId={categoryProduct[0]?.id}
                    />
                  </div>
                  <div className="flex-fill">
                    <GroupCard
                      products={categoryProduct[0]?.products}
                      categoryName={categoryProduct[0]?.category_name}
                      categoryId={categoryProduct[0]?.id}
                    />
                  </div>
                </div>
              ) : (
                <NoResultFound />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CategoryEight;
