import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { loginModalHide } from "../../../../store/uiSlices/LoginModalSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser } from "../../../../store/authenticationSlices/loginSlice";
import { setLoginVal } from "../../../../store/uiSlices/loginStateSlice";
import { LoaderAPICalls } from "../../../LoaderAPICalls";
import { ProfileRecord } from "../../../../store/profileSlice/ProfileRecordSlice";
import { BalanceAndProfit } from "../../../../store/BoostDataSlice/GetBalanceandProfitSlice";
import { AllSetsRecord } from "../../../../store/BoostDataSlice/GetAllSetSlice";
import { VipLevelRecord } from "../../../../store/BoostDataSlice/GetAllVIPLevelRecordSlice";
const LogInForm = ({ showLoginForm, singUpClick, setAdminOTPModal }) => {
  const dispatch = useDispatch();

  const [rememberMe, setRememberMe] = useState(false);
  const [showPassowrd, setShowpassword] = useState(false);
  const [catpcha, setCaptcha] = useState("");
  const [catpchaWithTop, setCaptchaWithTop] = useState([]);
  const [catpchaError, setCaptchaError] = useState("");
  const [catpchaValue, setCaptchaValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const isAuthenticated = useSelector(
    (state) => state.loginModal.isAuthModalShown
  );
  useEffect(() => {
    const storedRememberMe = localStorage.getItem("rememberMe") === "true";
    setRememberMe(storedRememberMe);
  }, []);

  const initialValues = { email: "", password: "", catpchaInput: "" };
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
    isSubmitting,
  } = useFormik({
    initialValues,
    validate: (values) => {
      const errors = {};
      if (!values.email) {
        errors.email = "This field is required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      } else if (!values.password) {
        errors.password = "This field is required";
      } else if (!values.catpchaInput) {
        errors.catpchaInput = "This field is required";
      } else if (/[-]/.test(values.password)) {
        errors.password = "Password cannot contain hyphens";
      } else if (/^\s|\s$/g.test(values.password)) {
        errors.password = "Password cannot contain leading or trailing spaces";
      }
      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      setIsLoading(true);
      if (catpcha === catpchaValue) {
        setCaptchaError("");
        try {
          const returnData = await dispatch(fetchUser(values));
          console.log("returnData", returnData);
          if (returnData?.payload?.meta?.status_code === 200) {
            dispatch(setLoginVal(true));
            dispatch(loginModalHide(false));
            toast.success(returnData?.payload?.meta?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });

            setIsLoading(false);
            dispatch(ProfileRecord());
            dispatch(BalanceAndProfit());
            dispatch(VipLevelRecord());
            dispatch(AllSetsRecord());

            if (rememberMe) {
              localStorage.setItem("rememberMe", "true");
              localStorage.setItem("remembereasmar tamjiddEmail", values.email);
              localStorage.setItem("rememberedPassword", values.password);
            } else {
              localStorage.removeItem("rememberMe");
              localStorage.removeItem("rememberedEmail");
              localStorage.removeItem("rememberedPassword");
            }
            handleReset();
            setTimeout(() => {
              setSubmitting(false);
            }, 1000);
          } else {
            setIsLoading(false);
            toast.error(returnData?.payload?.meta?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          if (
            returnData?.payload?.meta?.message ===
            "We have sent an otp on your registered email, kindly check your email."
          ) {
            dispatch(setLoginVal(false));
            dispatch(loginModalHide(false));
          }
          if (
            returnData?.payload?.meta?.status_code === 203 &&
            returnData?.payload?.data?.user_type === "CUSTOMER"
          ) {
            dispatch(setLoginVal(false));
            dispatch(loginModalHide(false));
            toast.success(returnData?.payload?.meta?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setAdminOTPModal(true);
          }
          if (returnData?.payload?.data?.user_type === "SUPERADMIN") {
            dispatch(loginModalHide(false));
            setAdminOTPModal(true);
          }
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setCaptchaError("Input capcha must be same as captcha.");
      }
    },
  });
  const captchaGenrator = () => {
    const charactor = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    const randomCarArr = [];
    const randomCarArrWithTop = [];
    for (let i = 0; i < 4; i++) {
      const randomNum = Math.floor(Math.random() * 36 + 1);
      const randomChar = charactor.slice(randomNum, randomNum + 1);
      randomCarArr.push(randomChar);
      randomCarArrWithTop.push({
        char: `${randomChar}`,
        top: Math.floor(Math.random() * 28 + 1),
      });
    }
    setCaptchaWithTop(randomCarArrWithTop);
    return randomCarArr.join("");
  };

  useEffect(() => {
    setCaptchaValue(values.catpchaInput);
    if (values.catpchaInput === catpcha || values.catpchaInput === "") {
      setCaptchaError("");
    } else {
      setCaptchaError("Input capcha must be same as captcha.");
    }
  }, [values.catpchaInput]);
  useEffect(() => {
    const newCaptcha = captchaGenrator();
    setCaptcha(newCaptcha);
    handleReset();
  }, [isAuthenticated]);

  const captchaRef = useRef(null);
  return (
    <>
      {isLoading && (
        <div className="loader-container">
          <LoaderAPICalls />
        </div>
      )}
      <div>
        <form onSubmit={handleSubmit}>
          <div
            className={`${
              showLoginForm ? "open" : ""
            } login-accordian text-dark mt-3`}
          >
            <h2 className="fs-4 fw lh-md text-dark  my-2">Login</h2>
            <div className="" style={{ height: "60px" }}>
              <input
                type="text"
                className=" w-100 form-text"
                name="email"
                placeholder="Email"
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="on"
                value={values.email}
              />
              {touched.email && errors.email && (
                <p
                  className="error text-danger form-text"
                  style={{ fontSize: "13px" }}
                >
                  {errors.email}
                </p>
              )}
            </div>
            <div className="" style={{ height: "60px" }}>
              <div>
                <div className="position-relative">
                  <input
                    type={`${showPassowrd ? "text" : "password"}`}
                    className="w-100 form-text"
                    name="password"
                    placeholder="Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="on"
                    value={values.password}
                  />
                  {touched.password && errors.password && (
                    <p
                      className="error text-danger form-text"
                      style={{ fontSize: "13px" }}
                    >
                      {errors.password}
                    </p>
                  )}
                  <div
                    className="position-absolute"
                    style={{ top: "7.5px", right: "10px", cursor: "pointer" }}
                    onClick={() => setShowpassword(!showPassowrd)}
                  >
                    {!showPassowrd ? (
                      <BsEyeSlash className="text-2xl" />
                    ) : (
                      <BsEye className="text-2xl" />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex flex-column align-items-center ">
              <div className="captch-container d-flex align-items-center  justify-content-center mb-2">
                {catpchaWithTop.map((captcha, index) => (
                  <p
                    key={index}
                    ref={captchaRef}
                    style={{
                      paddingTop: `${captcha.top}px`,
                      fontSize: "12px",
                    }}
                    className="text-center captch-heading h-100  w-100 "
                  >
                    {captcha.char}
                  </p>
                ))}
              </div>

              <div className="w-100" style={{ height: "60px" }}>
                <input
                  type={"text"}
                  className="w-100 form-text"
                  name="catpchaInput"
                  placeholder="Enter Captcha"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.catpchaInput}
                />
                {touched.catpchaInput &&
                  errors.catpchaInput &&
                  catpchaError === "" && (
                    <p
                      className="error text-danger form-text"
                      style={{ fontSize: "13px" }}
                    >
                      {errors.catpchaInput}
                    </p>
                  )}

                {catpchaError !== "" && (
                  <p
                    className="error text-danger form-text"
                    style={{ fontSize: "13px" }}
                  >
                    {catpchaError}
                  </p>
                )}
              </div>
              <div
                className="px-3 py-1 form-text"
                onClick={() => {
                  const newCaptcha = captchaGenrator();
                  setCaptcha(newCaptcha);
                }}
                style={{
                  border: "1px solid var(--border-color-gray)",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Reload Captcha
              </div>
            </div>
            <div className="d-flex align-items-center  justify-content-between mt-3">
              <div className="d-flex gap-2  ">
                <input
                  type="checkbox"
                  checked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                />
                <label className="form-text">Remember Me</label>
              </div>
              <Link
                to={"/forgot-password"}
                className="border-0 bg-transparent text-primary form-text"
                onClick={() => {
                  dispatch(loginModalHide(false));
                }}
              >
                Forgot Password?
              </Link>
            </div>
            <button
              type="submit"
              className="btn trak-sub-btn my-2 text-white ps-5 pe-5  w-100 "
              style={{
                background: "#008000",
                boxShadow: `rgba(0, 0, 0, 0.1) 0 5px 5px 2px`,
              }}
            >
              Login
            </button>
            <div className="d-flex align-items-center  justify-content-center gap-3 mt-2">
              <p className="form-text">Don't have an account? </p>
              <div
                className="form-text"
                style={{
                  color: "var(--primary-color-green)",
                  cursor: "pointer",
                }}
                onClick={singUpClick}
              >
                Sign Up.
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default LogInForm;
