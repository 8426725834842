import React from "react";

const specdata = [
  { label: "Brand", value: "Bata" },
  { label: "Model Name", value: "user12" },
  { label: "Memory Storage Capacity", value: "64 GB" },
  { label: "Screen Size", value: "10.2 Inches" },
  { label: "Display Resolution", value: "Maximum 2160x1620 Pixels" },
];

function Specification({ product }) {
  console.log();
  return (
    <div className="my-4 mx-3">
      {product?.item_specification
        ?.split("\n")
        ?.map((spec) => {
          return {
            label: `${spec.split("\t")[0]}`,
            value: `${spec.split("\t")[1]?.replace("\r", "")}`,
          };
        })
        ?.map((item, index) => (
          <div key={index}>
            <h6 className="fw-bolder d-inline">
              {item.label} : <span className="fw-normal">{item.value}</span>
            </h6>
          </div>
        ))}
    </div>
  );
}

export default Specification;
