import React, { useEffect, useState } from "react";
import Modal from "../../../common/Modal";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  commonModalHide,
  confirmationModalHide,
} from "../../../store/uiSlices/LoginModalSlice";
import {
  ApiCall,
  initializeApiWithHeaders,
} from "../../../common/PostApiRequest";
import { toast } from "react-toastify";
import InternalTransferOTP from "./InternalTransferOTP";

const InternalTransfer = ({
  heading,
  title,
  internalTransfer,
  setInternalTransfer,
}) => {
  const [trandferData, setTrandferData] = useState(null);
  const [internalTransferOTPModal, setInternalTransferOTPModal] =
    useState(false);

  const initialValues = {
    balance: "",
    transfer: "E-COMMERCE",
    to: "DRIVE DATA",
    intTransfer: true,
  };
  const numberRegex = "^[0-9]+$";
  const validationSchema = yup.object().shape({
    balance: yup
      .string()
      .matches(numberRegex, "Balance must be a number.")
      .required("This field is required"),
    transfer: yup.string().required("This field is required"),
    to: yup.string().required("This field is required"),
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    handleReset,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setTrandferData(values);
      setInternalTransferOTPModal(true);
      console.log(values);
    },
  });

  useEffect(() => {
    if (values.transfer === "E-COMMERCE") {
      values.to = "DRIVE DATA";
    } else if (values.transfer === "DRIVE DATA") {
      values.to = "E-COMMERCE";
    }
  }, [values.transfer]);

  useEffect(() => {
    if (values.to === "E-COMMERCE") {
      values.transfer = "DRIVE DATA";
    } else if (values.to === "DRIVE DATA") {
      values.transfer = "E-COMMERCE";
    }
  }, [values.to]);
  if (internalTransferOTPModal) {
    return (
      <InternalTransferOTP
        data={trandferData}
        setShow={setInternalTransfer}
        internalTransferOTPModal={internalTransferOTPModal}
        setInternalTransferOTPModal={setInternalTransferOTPModal}
      />
    );
  }

  return (
    <>
      <Modal
        heading={heading}
        title={title}
        width={450}
        show={internalTransfer}
        setShow={setInternalTransfer}
      >
        <form onSubmit={handleSubmit}>
          <div
            className="my-1 d-flex flex-column align-items-start"
            style={{ height: "40px" }}
          >
            <input
              type="text"
              className="w-100"
              placeholder="Amount"
              name="balance"
              value={values.balance}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.balance && errors.balance && (
              <div className="text-danger">{errors.balance}</div>
            )}
          </div>

          <div className="my-3 d-flex flex-column align-items-start">
            <label>Transfer From</label>
            <select
              className="w-100 bg-white me-3"
              style={{ cursor: "pointer" }}
              name="transfer"
              value={values.transfer}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="E-COMMERCE">E-COMMERCE</option>
              <option value="DRIVE DATA">DRIVE DATA</option>
            </select>
            {/* {touched.transfer && errors.transfer && (
              <div className="text-danger">{errors.transfer}</div>
            )} */}
          </div>

          <div className="my-3 d-flex flex-column align-items-start">
            <label>To</label>
            <select
              className="w-100 bg-white me-3"
              style={{ cursor: "pointer" }}
              name="to"
              value={values.to}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="DRIVE DATA">DRIVE DATA</option>
              <option value="E-COMMERCE">E-COMMERCE</option>
            </select>
            {/* {touched.to && errors.to && (
              <div className="text-danger">{errors.to}</div>
            )} */}
          </div>

          <button
            type="submhandleBottomSelectChangeit"
            className="text-white px-4 py-1 rounded mt-3"
            style={{
              background: "var(--primary-color-green)",
              marginRight: "10px",
              border: "1px solid var(--primary-color-green)",
              cursor: "pointer",
            }}
          >
            Submit
          </button>

          <button
            type="button"
            className="text-success px-4 py-1 rounded mt-3"
            style={{
              background: "white",
              border: "1px solid var(--primary-color-green)",
            }}
            onClick={() => {
              setInternalTransfer(false);
            }}
          >
            Cancel
          </button>
        </form>
      </Modal>
    </>
  );
};

export default InternalTransfer;
