import React, { useEffect, useState } from "react";
import HeadingWithSearchBar from "../../common/HeadingWithSearchBar";
import ProductCard from "../../common/ProductCard";
import ViewAllBtnCard from "../../common/ViewAllBtnCard";
import { useSelector } from "react-redux";
import NoResultFound from "../../common/NoResultFound";
import { BASE_URL } from "../../env";

const CategoryOne = ({ homeCategory, searchProduct }) => {
  const [data, setData] = useState([]);

  const categoryProduct = homeCategory?.filter(
    (category) => category?.menu_position === 1
  );
  // const categoryData = useSelector((state) => state?.AllCategoryState);
  // useEffect(() => {
  //   if (allCategory?.length > 0) {
  //     const response = fetch(
  //       `${BASE_URL}catalogue/get-products-by-main-category/${
  //         allCategory?.filter((category) => category.home_screen === true)[0]
  //           ?.id
  //       }/`
  //     )
  //       .then((res) => res.json())
  //       .then((result) => setData(result.data));
  //   }
  // }, [categoryData]);

  console.log("searchProduct", searchProduct);
  return (
    <>
      {categoryProduct[0] && (
        <div className="container py-4  ">
          <div className="d-flex flex-column  gap-4">
            <HeadingWithSearchBar
              heading={`${categoryProduct[0]?.category_name}`}
            />
            {categoryProduct[0]?.products?.length > 1 ? (
              <div className="row ">
                {categoryProduct[0]?.products?.map(
                  (product, index) =>
                    index <= 10 && (
                      <div
                        key={product?.id}
                        className="col-6 col-sm-4 col-md-3 col-lg-2 mb-3 "
                      >
                        <ProductCard
                          categoryId={categoryProduct[0]?.id}
                          home={true}
                          categoryName={categoryProduct[0]?.category_name}
                          product={product}
                          id={product?.id}
                        />
                      </div>
                    )
                )}

                {categoryProduct[0]?.products?.length > 11 && (
                  <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-3 ">
                    <ViewAllBtnCard
                      categoryId={categoryProduct[0]?.id}
                      categoryName={categoryProduct[0]?.category_name}
                      count={categoryProduct[0]?.products?.length - 10}
                    />
                  </div>
                )}
              </div>
            ) : (
              <NoResultFound />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CategoryOne;
