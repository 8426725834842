import { useEffect, useRef, useState } from "react";
import Modal from "./Modal";
import qrCode from "../assets/image/qr.png";
import { PiCopySimpleLight } from "react-icons/pi";
import { BsChevronRight } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import DepositeModal from "./DepositeModal";
import { depositeModalHide } from "../store/uiSlices/LoginModalSlice";
import { useFormik } from "formik";
import * as yup from "yup";
import { ApiCall, initializeApiWithHeaders } from "./PostApiRequest";
import { toast } from "react-toastify";
import { GetNetworkType } from "../store/BoostDataSlice/GetNetworkType";
import { GetCryptoType } from "../store/BoostDataSlice/GetCryptoType";
import { GetDepositeAddress } from "../store/BoostDataSlice/GetdepositeAddress";

const Deposite = ({ heading, title, icon }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetNetworkType());
    dispatch(GetCryptoType());
  }, []);
  const depositAddress = useSelector(
    (state) => state?.GetDepositeAddress?.data?.data
  );
  const cryptoType = useSelector((state) => state?.cryptoType?.data?.data);
  const networkType = useSelector((state) => state?.networkType?.data?.data);
  const [showQrImage, setShowQrImage] = useState(false);
  const [crypto, setCrypto] = useState(null);
  const [network, setNetwork] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);

  const copyRef = useRef(null);
  const [copy, setCopy] = useState(false);
  const copyText = async () => {
    if (copyRef.current) {
      const text = copyRef.current.innerText;
      try {
        await navigator.clipboard.writeText(text);
        setCopy(true);
        setTimeout(() => {
          setCopy(false);
        }, 1000);
      } catch (error) {
        console.log("Context not coped properly");
      }
    }
  };

  useEffect(() => {
    setCrypto(null);
    setNetwork(null);
  }, []);

  useEffect(() => {
    const response = dispatch(
      GetDepositeAddress({ crypto: crypto, network: network })
    ).then((res) => {
      if (res?.payload?.meta?.status_code === 200) {
        setShowQrImage(true);
      }
    });
    // console.log(response);
  }, [crypto && network]);

  const initialValues = {
    transaction_type: "DEPOSIT",
    transaction_amount: "",
    payment_screenshot: null,
  };

  const userSchema = yup.object().shape({
    transaction_amount: yup
      .number()
      .typeError("Must be a number")
      .required("This field is required."),
    // payment_screenshot: yup.mixed().required("This field is required."),
  });
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    handleReset,
  } = useFormik({
    initialValues,
    validationSchema: userSchema,
    onSubmit: async (values) => {
      try {
        initializeApiWithHeaders();
        const formData = new FormData();
        // depositAddress?.address
        formData.append("deposit_address", `${depositAddress?.id}`);
        formData.append("transaction_type", `${values.transaction_type}`);
        formData.append("transaction_amount", `${values?.transaction_amount}`);
        const response = await ApiCall(
          "transaction/add-transaction-data/",
          "POST",
          formData
        );
        if (response.meta.status_code === 201) {
          toast.success(response.meta.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          handleReset();
          setShowConfirm(false);
        } else {
          toast.error(response.meta.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

        console.log(response);
      } catch (error) {
        console.log(error);
      }
    },
  });
  if (showConfirm) {
    return (
      <Modal setShow={setShowConfirm} show={showConfirm}>
        <form onSubmit={handleSubmit}>
          <div className="d-flex flex-column " style={{ height: "95px" }}>
            <label className="text-start">Payment Amount</label>
            <input
              type="text"
              name="transaction_amount"
              value={values.transaction_amount}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-100"
              placeholder="Amount"
            />
            {errors.transaction_amount && touched.transaction_amount && (
              <p
                className="mt-1 text-start"
                style={{ color: "red", fontSize: "14px" }}
              >
                {errors.transaction_amount}
              </p>
            )}
          </div>
          <div className="">
            <p className="text-start w-100"> Upload Payment Screenshoot</p>
            <label
              className="d-flex align-items-center justify-content-center gap-3 mt-2 py-1 rounded  bg-white text-center w-100"
              style={{
                cursor: "pointer",
                border: "1px dashed gray",
              }}
            >
              <input
                type="file"
                className="d-none"
                name="payment_screenshot"
                onChange={(e) => {
                  // setFieldValue("qr_image", e.target.files[0]);
                  console.log(e.target.files[0]);
                }}
                // onBlur={handleBlur}
              />
              Upload Image
            </label>
            {errors.payment_screenshot && touched.payment_screenshot && (
              <p
                className="mt-1 text-start "
                style={{ color: "red", fontSize: "14px" }}
              >
                {errors.payment_screenshot}
              </p>
            )}
          </div>
          <div className="mt-4">
            <button
              className="border-0 rounded px-4 py-1 text-white"
              style={{ background: "var(--primary-color-green)" }}
            >
              Confirm Deposit
            </button>
          </div>
        </form>
      </Modal>
    );
  }

  return (
    <DepositeModal
      heading={heading}
      title={title}
      icon={icon}
      width={450}
      setCrypto={setCrypto}
      setNetwork={setNetwork}
      setShowQrImage={setShowQrImage}
      className="modal"
    >
      <div className="d-flex flex-column gap-4">
        <div>
          <p>Please select Crypto?</p>
          <div className="d-flex gap-3 mt-2">
            <select
              className="w-100 bg-white"
              // value={"USDT"}
              onChange={(e) => {
                setCrypto(e.target.value);
                // if (e.target.value === "USDT") {
                //   setNetwork("USDT-TRC20");
                // }
              }}
            >
              <option value="">Select...</option>
              {cryptoType?.length > 0
                ? cryptoType?.map((crypto) => (
                    <option key={crypto?.id} value={crypto?.id}>
                      {crypto?.crypto_type_code}
                    </option>
                  ))
                : []}
            </select>
          </div>
        </div>

        <div>
          <p>Please select Network?</p>
          <div className="d-flex gap-3 mt-2">
            <select
              className="w-100 bg-white"
              onChange={(e) => {
                setNetwork(e.target.value);
              }}
              // value={"USDT-TRC20"}
            >
              <option value="">Select...</option>
              {networkType?.length > 0
                ? networkType?.map((network) => (
                    <option key={network?.id} value={network?.id}>
                      {network?.network_type_code}
                    </option>
                  ))
                : []}
            </select>
          </div>
        </div>

        {showQrImage && (
          <>
            <>
              <div className="d-flex justify-content-center ">
                {depositAddress?.qr_image && (
                  <img src={depositAddress?.qr_image} width={200} />
                )}
              </div>

              <div className="d-flex gap-2 align-items-center ">
                <p>Deposit Address</p>
                <BsChevronRight />
              </div>
              <div className=" position-relative  d-flex justify-content-between  align-items-center">
                <p ref={copyRef}>
                  {depositAddress?.address ? depositAddress?.address : ""}
                </p>
                <div
                  onClick={() => {
                    copyText();
                  }}
                >
                  <PiCopySimpleLight
                    className="fs-4 me-3 "
                    style={{ cursor: "pointer" }}
                  />
                </div>
                {copy && (
                  <div
                    className="px-2 position-absolute   bg-white shadow bg-light rounded "
                    style={{
                      right: "-45px",
                      color: "var(--primary-color-green)",
                    }}
                  >
                    Copied
                  </div>
                )}
              </div>
            </>
            <div>
              <button
                className="border-0 rounded px-4 py-1 text-white"
                style={{ background: "var(--primary-color-green)" }}
                onClick={() => {
                  setShowConfirm(true);
                  setNetwork(null);
                  setCrypto(null);
                  setShowQrImage(false);
                  dispatch(depositeModalHide(false));
                }}
              >
                Confirm Deposit
              </button>
            </div>
          </>
        )}
      </div>
    </DepositeModal>
  );
};

export default Deposite;
