import brandImage from "../assets/image/brand_image/2919daa6fd55901cf67958f136c6165e.png";
import brandImage2 from "../assets/image/brand_image/6d2c6b152424353e4bd2b6cc9d698f4b.png";
import brandImage3 from "../assets/image/brand_image/9270f0bdbe88532aa77c6ba154a60654.png";
import brandImage4 from "../assets/image/brand_image/94309948373611919e10f4c4ffd4b117.png";
import brandImage5 from "../assets/image/brand_image/9f714d51f5da57299833be31d9179994.png";
import brandImage6 from "../assets/image/brand_image/c8d3aca3905c34b4021b86339a083faa.png";
import brandImage7 from "../assets/image/brand_image/cf9d0940fbdacaf4c820e1a5cb6d9c1d.png";
import brandImage8 from "../assets/image/brand_image/d6a91006348a5dc36dff08a4ccab1754.png";
import brandImage9 from "../assets/image/brand_image/ddb0f3c983faea074c42ba6941583018.png";
import brandImage10 from "../assets/image/brand_image/e8b000fd3a1bd9739e75f232296eb6f4.png";
import brandImage11 from "../assets/image/brand_image/f818264b275b9e2d8737f38432fbf682.png";
import brandImage12 from "../assets/image/brand_image/fef611f67774caf80b1bd4cef3aef083.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
// import Swiper and modules styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

function AutoplaySlider() {
  const slides = [
    { img: brandImage },
    { img: brandImage2 },
    { img: brandImage3 },
    { img: brandImage4 },
    { img: brandImage5 },
    { img: brandImage6 },
    { img: brandImage7 },
    { img: brandImage8 },
    { img: brandImage9 },
    { img: brandImage10 },
    { img: brandImage11 },
    { img: brandImage12 },
  ];

  return (
    <div>
      <div
        className="container my-4 py-2"
        style={{
          borderTop: "1px solid var(--border-color-gray)",
          borderBottom: "1px solid var(--border-color-gray)",
        }}
      >
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          spaceBetween={50}
          slidesPerView={6}
          loop={true}
          autoplay={{
            delay: 1000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            993: {
              slidesPerView: 6,
            },
            768: {
              slidesPerView: 4,
            },
            600: {
              slidesPerView: 3,
            },

            500: {
              slidesPerView: 2,
            },
            400: {
              slidesPerView: 1,
            },

            200: {
              slidesPerView: 1,
            },
          }}
          navigation
          scrollbar={{ draggable: true }}
        >
          {slides.map((brand, index) => (
            <SwiperSlide key={index}>
              <img src={brand.img} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default AutoplaySlider;
