import React from "react";
import ResetPasswordComp from "../components/ResetPasswordComp";

const ResetPassword = () => {
  return (
    <div>
      <ResetPasswordComp />
    </div>
  );
};

export default ResetPassword;
