import React, { useState } from "react";
import gitLogo from "../../../assets/image/logo/logo2.jpeg";
import googlePlayStore from "../../../assets/image/icons8-google-play-store-48.png";
import appStore from "../../../assets/image/app.png";
import { Link } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import { links, socialMediaLinks } from "../../../constant/FooterData";
import "./Footer.css";
import { TrackOrder } from "../../../store/Category/TrackOrderSlice";
import { TrackOrderComponent } from "../TrackOrderComponent";
import { FiUser } from "react-icons/fi";
import { useDispatch } from "react-redux";
import Modal from "../../Modal";
const GetitonFooter = () => {
  const dispatch = useDispatch();
  const [hideTrackModal, sethideTrackModal] = useState(false);
  const onHideTrackModal = () => {
    sethideTrackModal(!hideTrackModal);
  };
  const [values, setValues] = useState({ order_tracking_id: "" });
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState("");
  const [showTrakeMyOrderResponse, setShowTrakeMyOrderResponse] =
    useState(false);
  const handleReset = () => {
    setValues({ order_tracking_id: "" });
    setErrors({});
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!values.order_tracking_id) {
      setErrors({ order_tracking_id: "Order No is required" });
      return;
    }
    setErrors({});
    let orderTrackingId = values.order_tracking_id;
    dispatch(TrackOrder(orderTrackingId))
      .then((result) => {
        if (result?.payload?.meta?.status_code === 200) {
          handleReset();
          setShowTrakeMyOrderResponse(true);
          setMessage(result?.payload?.meta?.message);
          sethideTrackModal(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
    if (value) {
      setErrors({ ...errors, [name]: "" });
    }
  };
  const FooterSubHeading = ({ heading }) => {
    return (
      <div className="d-flex align-items-center justify-content-center  gap-2">
        <div
          className=""
          style={{ height: "2px", background: "#008000", width: "20px" }}
        />
        <h4
          className="fw-bold"
          style={{ fontSize: "16px", textTransform: "uppercase" }}
        >
          {heading}
        </h4>
      </div>
    );
  };
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center "
      style={{
        borderTop: "1px solid var(--border-color-gray)",
        borderBottom: "1px solid var(--border-color-gray)",
      }}
    >
      <div className="container d-flex flex-column justify-content-center align-items-center ">
        <div className="row    ">
          <div className="col-md-6 col-lg-4 col-xl-4 py-3 py-md-5 align-items-center justify-content-center flex-column footer-gio border-bottom-footer-top">
            <div>
              <img src={gitLogo} className=" mb-2  w-50 " />
              <p className="mb-2 footer-text">Multistore E-Commerce system.</p>
            </div>

            <div>
              <p className="footer-text">Mobile: +44 7988 720466</p>
              <p className="footer-text">Email: customer@get-it-on.app</p>
              <a className="footer-text" href="https://get-it-on.app">
                Website: https://get-it-on.app
              </a>
              <p className="footer-text">
                Address: Riverway Retail Park, Irvine, North Ayrshire, Scotland,
                KA12 8EJ, United Kingdom
              </p>
            </div>
          </div>
          <div
            className="col-sm-6 col-md-4 col-lg-4 col-xl-2 py-3 py-md-5 d-flex flex-column align-items-center justify-content-center  footer-gio  border-left-social-icon"
            style={{ borderLeft: "1px solid var(--border-color-gray)" }}
          >
            <FooterSubHeading heading={"Our Communities"} />
            <ul className="footer-social-media footer-list">
              {socialMediaLinks.map((link, index) => (
                <li key={index}>
                  <Link
                    to={link.url}
                    target="_"
                    className="my-3  d-flex align-items-center gap-3 footer-text"
                  >
                    <div
                      className="d-flex   justify-content-center"
                      style={{
                        height: "32px",
                        width: "32px",
                        borderRadius: "3px",
                        background: link.backgroundColor,
                      }}
                    >
                      {link.icon}
                    </div>
                    <p className="d-none d-sm-block footer-text">
                      {link.label}
                    </p>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className=" col-sm-6 col-md-6 col-lg-4 col-xl-2 py-3 py-md-5 d-flex flex-column align-items-center align-items-md-start justify-content-center  footer-gio  border-top-footer border-left-footer">
            <FooterSubHeading heading={"Information"} />
            <ul className="d-flex flex-column gap-2 mt-3 footer-list">
              {links.map((link, index) => (
                <li key={index}>
                  <Link
                    onClick={() => window.scrollTo({ top: 0 })}
                    to={link.route}
                    className=" footer-link footer-text footer-page-link"
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
              {/* ============== track my order =============== */}
              {/* <p
                className="footer-text"
                style={{ cursor: "pointer", color: "black" }}
                onClick={() => {
                  onHideTrackModal();
                  // setHideModal(faltruse);
                }}
              >
                Track My Order
              </p>

              <div
                className={`${
                  hideTrackModal ? "open" : ""
                } signAndTrackMyOrderOverlay`}
              >
                <div
                  className={`${
                    hideTrackModal ? "open" : ""
                  } track-my-order rounded pt-3  pb-4`}
                >
                  <div
                    onClick={() => {
                      onHideTrackModal();
                    }}
                    className="d-flex justify-content-end px-2 "
                  >
                    <RxCross2
                      className="text-dark "
                      style={{ fontSize: "25px", cursor: "pointer" }}
                    />
                  </div>
                  <form className="text-center mx-5 ">
                    <h2 className="fs-4 fw lh-md text-dark  my-2">
                      Track My Order
                    </h2>
                    <input
                      type="text"
                      className=" my-2 w-100 "
                      placeholder="Email"
                    />
                    <input
                      type="text"
                      className="  my-2 w-100"
                      placeholder="Order No"
                    />
                    <button
                      className="btn trak-sub-btn my-2 text-white ps-5 pe-5  w-100 "
                      style={{
                        background: "#008000",
                        boxShadow: `rgba(0, 0, 0, 0.1) 0 5px 5px 2px`,
                      }}
                    >
                      Track My Order
                    </button>
                  </form>
                </div>
              </div> */}
              <div className="col-lg-12 d-flex align-items-center ">
                <div
                  className="d-flex align-items-center gap-1 px-1 "
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    onHideTrackModal();
                  }}
                >
                  <li>Track My Order</li>
                </div>
              </div>
              <div
                className={`${
                  hideTrackModal ? "open" : ""
                } signAndTrackMyOrderOverlay`}
              >
                <div
                  className={`${
                    hideTrackModal ? "open" : ""
                  } track-my-order rounded pt-3  pb-4`}
                >
                  <div
                    onClick={() => {
                      onHideTrackModal();
                      handleReset();
                    }}
                    className="d-flex justify-content-end px-2 "
                  >
                    <RxCross2
                      className="text-dark "
                      style={{ fontSize: "25px", cursor: "pointer" }}
                    />
                  </div>

                  <TrackOrderComponent
                    handleSubmit={handleSubmit}
                    handleChange={handleChange}
                    errors={errors}
                    values={values}
                  />
                </div>
              </div>
            </ul>
          </div>
          {showTrakeMyOrderResponse && (
            <Modal
              show={showTrakeMyOrderResponse}
              setShow={setShowTrakeMyOrderResponse}
            >
              <p style={{ fontSize: "20px" }}>{message}</p>
              <button
                className="btn trak-sub-btn my-2 text-white ps-3 pe-3   "
                style={{
                  background: "#008000",
                  boxShadow: `rgba(0, 0, 0, 0.1) 0 5px 5px 2px`,
                }}
                onClick={() => {
                  setShowTrakeMyOrderResponse(false);
                }}
              >
                ok
              </button>
            </Modal>
          )}
          <div
            className="col-8 col-sm-6 col-md-5 col-lg-4 col-xl-4 py-3 py-md-5 d-flex flex-column align-items-center align-items-md-start  align-items-start  footer-gio footer-gio-download border-top-footer-download"
            style={{ borderLeft: "1px solid var(--border-color-gray)" }}
          >
            <FooterSubHeading heading={"download"} />
            <p className="footer-text">
              Get access to all exclusive offers, discounts and deals by
              download our App !
            </p>
            {/* ==============google playStore btn============= */}

            <div className=" mt-2 w-100">
              <div className="d-flex align-items-center align-items-sm-start    flex-column   flex-sm-row flex-md-column justify-content-sm-center flex-xl-row  align-items-sm-start align-items-xl-start justify-content-xl-start gap-1 justify-content-start w-100">
                <button className="google-paly-btn d-flex align-items-center gap-2">
                  <img src={googlePlayStore} width={25} />
                  <p className="google-play-btn-text">
                    <span
                      style={{
                        display: " block",
                        marginBottom: "-4px",
                        color: "#9da9b9",
                        fontSize: "12px",
                      }}
                    >
                      Download on the
                    </span>
                    <span
                      style={{
                        ontWeight: "500",
                        fontSize: " 14px",
                        textAlign: "left",
                        color: "#007bff",
                      }}
                    >
                      Google Play
                    </span>
                  </p>
                </button>

                <button className="google-paly-btn d-flex align-items-center gap-2">
                  <img src={appStore} width={25} />
                  <p className="google-play-btn-text">
                    <span
                      style={{
                        display: " block",
                        marginBottom: "-4px",
                        color: "#9da9b9",
                        fontSize: "12px",
                      }}
                    >
                      Download on the
                    </span>
                    <span
                      style={{
                        ontWeight: "500",
                        fontSize: " 14px",
                        textAlign: "left",
                        color: "#007bff",
                      }}
                    >
                      App Store
                    </span>
                  </p>
                </button>
              </div>
              <p
                className="mt-1 w-100 "
                style={{ color: "var(--primary-color-green)" }}
              >
                Coming soon
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetitonFooter;
