import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useFormik } from "formik";
import { ApiCall } from "../../../../common/PostApiRequest";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { useSelector } from "react-redux";
const SignUpForm = ({ showSignupForm, onClick, loginClick, setShowOTP }) => {
  const [showPassowrd, setShowpassword] = useState(false);
  const [showPassowrd2, setShowpassword2] = useState(false);
  const isAuthenticated = useSelector(
    (state) => state.loginModal.isAuthModalShown
  );
  const handleError = (error) => {
    if (error.response) {
      if (error.response.status === 400) {
        toast.error(error.response?.data?.meta?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else if (error.request) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      console.log("Error:", error.message);
    }
  };
  const initialValues = {
    email: "",
    password: "",
    repeat_password: "",
    phone_number: "",
    first_name: "",
    last_name: "",
    referral_code: "",
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    handleReset,
    setFieldValue,
  } = useFormik({
    initialValues,
    validate: (values) => {
      const errors = {};

      if (!values.email) {
        errors.email = "This field is required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      }

      if (!values.password) {
        errors.password = "This field is required";
      } else if (/-/.test(values.password)) {
        errors.password = "Password cannot contain hyphens";
      } else if (/^\s|\s$/g.test(values.password)) {
        errors.password = "Password cannot contain leading or trailing spaces";
      }

      if (!values.first_name) {
        errors.first_name = "This field is required";
      }

      if (!values.last_name) {
        errors.last_name = "This field is required";
      }

      if (!values.phone_number) {
        errors.phone_number = "This field is required";
      }
      if (!values.referral_code) {
        errors.referral_code =
          "You need a referral code to sign up. Contact your agent or Customer Service via Whatsapp or Telergam.";
      }
      if (!values.repeat_password) {
        errors.repeat_password = "This field is required";
      } else if (values.repeat_password !== values.password) {
        errors.repeat_password = "Passwords do not match";
      }
      return errors;
    },

    onSubmit: async (values, actions) => {
      try {
        const response = await ApiCall("auth/signup/", "POST", values);
        if (response.meta.status_code === 201) {
          localStorage.setItem("userEmail", values.email);
          toast.success(response.meta.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          actions.resetForm();
          handleReset();
          setTimeout(() => {
            setShowOTP((prev) => !prev);
            onClick();
          }, 1000);
        } else {
          toast.error(response.meta.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        handleError(error);
      }
    },
  });

  useEffect(() => {
    handleReset();
  }, [isAuthenticated]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div
          className={` ${
            showSignupForm ? "open" : ""
          } signup-accordian text-dark mt-3`}
        >
          <h2 className={`fs-4 fw lh-md text-dark my-2`}>Sign Up</h2>
          <div className="" style={{ height: "60px" }}>
            <input
              type="text"
              className="w-100 form-text"
              name="first_name"
              placeholder="First Name"
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="on"
              value={values.first_name}
            />
            {touched.first_name && errors.first_name && (
              <p className="text-danger error" style={{ fontSize: "13px" }}>
                {errors.first_name}
              </p>
            )}
          </div>
          <div className="" style={{ height: "60px" }}>
            <input
              type="text"
              className="w-100 form-text"
              name="last_name"
              placeholder="Last Name"
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="on"
              value={values.last_name}
            />
            {touched.last_name && errors.last_name && (
              <p
                className="error text-danger form-text"
                style={{ fontSize: "13px" }}
              >
                {errors.last_name}
              </p>
            )}
          </div>
          <div className="" style={{ height: "60px" }}>
            <input
              type="text"
              className="w-100 form-text"
              name="email"
              placeholder="Email"
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="on"
              value={values.email}
            />
            {touched.email && errors.email && (
              <p
                className="error text-danger form-text"
                style={{ fontSize: "13px" }}
              >
                {errors.email}
              </p>
            )}
          </div>
          <div className="" style={{ height: "60px" }}>
            <PhoneInput
              country="us"
              inputClass="phone-input"
              buttonClass="phone-input"
              placeholder="Phone"
              className="w-100 form-text"
              value={values.phone_number}
              onChange={(phone) => setFieldValue("phone_number", `+${phone}`)}
              onBlur={handleBlur}
            />
            {touched.phone_number && errors.phone_number && (
              <p
                className="error text-danger form-text"
                style={{ fontSize: "13px" }}
              >
                {errors.phone_number}
              </p>
            )}
          </div>
          <div className="" style={{ height: "60px" }}>
            <div className="position-relative">
              <input
                type={`${showPassowrd ? "text" : "password"}`}
                className="w-100 form-text"
                name="password"
                placeholder="Password"
                autoComplete="off"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
              />
              <div
                className="position-absolute"
                style={{ top: "7.5px", right: "10px", cursor: "pointer" }}
                onClick={() => setShowpassword(!showPassowrd)}
              >
                {!showPassowrd ? (
                  <BsEyeSlash className="text-2xl" />
                ) : (
                  <BsEye className="text-2xl" />
                )}
              </div>
            </div>
            {touched.password && errors.password && (
              <p
                className="error text-danger form-text"
                style={{ fontSize: "13px" }}
              >
                {errors.password}
              </p>
            )}
          </div>
          <div className="" style={{ height: "60px" }}>
            <div className="position-relative">
              <input
                type={`${showPassowrd2 ? "text" : "password"}`}
                className="w-100 form-text"
                name="repeat_password"
                placeholder="Repeat Password"
                autoComplete="off"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.repeat_password}
              />
              <div
                className="position-absolute"
                style={{ top: "7.5px", right: "10px", cursor: "pointer" }}
                onClick={() => setShowpassword2(!showPassowrd2)}
              >
                {!showPassowrd2 ? (
                  <BsEyeSlash className="text-2xl" />
                ) : (
                  <BsEye className="text-2xl" />
                )}
              </div>
            </div>
            {touched.repeat_password && errors.repeat_password && (
              <p
                className="error text-danger form-text"
                style={{ fontSize: "13px" }}
              >
                {errors.repeat_password}
              </p>
            )}
          </div>
          {/* <div className="" style={{ height: "60px" }}> */}
          <div className="position-relative">
            <input
              type="text"
              className="w-100 form-text"
              name="referral_code"
              placeholder="Referral Code"
              autoComplete="on"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.referral_code}
            />
          </div>
          {touched.referral_code && errors.referral_code && (
            <p
              className="error text-danger form-text"
              style={{ fontSize: "13px" }}
            >
              {errors.referral_code}
            </p>
          )}
          {/* </div> */}
          <button
            type="submit"
            className="btn trak-sub-btn my-2 text-white ps-5 pe-5 w-100 "
            style={{
              background: "#008000",
              boxShadow: `rgba(0, 0, 0, 0.1) 0 5px 5px 2px`,
            }}
          >
            Sign Up
          </button>
          <div className="d-flex align-items-center justify-content-center gap-3 mt-2">
            <p className="form-text">Already have an account</p>
            <div
              className="form-text"
              style={{
                color: "var(--primary-color-green)",
                cursor: "pointer",
              }}
              onClick={loginClick}
            >
              Login.
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default SignUpForm;
