import { BsStar } from "react-icons/bs";
import Modal from "./Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  commonModalHide,
  depositeModalHide,
  loginModalHide,
} from "../store/uiSlices/LoginModalSlice";
import { AiOutlineWarning } from "react-icons/ai";
import { useEffect, useMemo, useState } from "react";
import { BoostDataUser } from "../store/BoostDataSlice/GetBoostDataUserSlice";
import { ApiCall, initializeApiWithHeaders } from "./PostApiRequest";
import { toast } from "react-toastify";
import { VipLevelRecord } from "../store/BoostDataSlice/GetAllVIPLevelRecordSlice";
import { AllSetsRecord } from "../store/BoostDataSlice/GetAllSetSlice";
import { BalanceAndProfit } from "../store/BoostDataSlice/GetBalanceandProfitSlice";
import InsufficiantBalance from "../components/categories/boostDataComponent/InsufficientBalance";
import { selectLoginVal } from "../store/uiSlices/loginStateSlice";
import CongratulationModal from "./CongratulationModal";

const VipCard = ({ data }) => {
  const dispatch = useDispatch();
  const boostDataUser = useSelector(
    (state) => state?.GetBoostDataUserSlice?.data?.data
  );
  const profileData = useSelector(
    (state) => state?.ProfileRecordSlice?.data?.data
  );
  const loginVal = useSelector(selectLoginVal);
  const integerValue = parseInt(data?.vip_amount, 10);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showDowngradeModal, setShowDowngradeModal] = useState(false);
  const [currentLevel, setCurrentLevel] = useState("VIP1");
  const [insufficiantBalance, setInsufficiantBalance] = useState(false);
  const [userData, setUserData] = useState(loginVal);
  const [showCongratulations, setShowCongratulations] = useState(false);

  useMemo(() => {
    const uservalue = localStorage.getItem("userLogin");
    const tokenVALUE = JSON.parse(uservalue);
    setUserData(tokenVALUE?.data?.token?.access);
  }, [!loginVal]);

  const currentUserLevel =
    boostDataUser?.subscription_type?.subscription_type_code;
  const canUpgrade = data.subscription_type_code > currentUserLevel;
  const canDowngrade = data.subscription_type_code < currentUserLevel;

  const handleUpgrade = () => {
    setCurrentLevel(data.subscription_type_code);
    setShowUpgradeModal(true);
    setShowDowngradeModal(false);
  };

  const handleDowngrade = () => {
    const previousLevel = `VIP${
      parseInt(data.subscription_type_code.charAt(3)) - 1
    }`;
    setCurrentLevel(previousLevel);
    setShowUpgradeModal(false);
    setShowDowngradeModal(true);
  };

  useEffect(() => {
    dispatch(BoostDataUser());
  }, []);

  const handleContinue = async () => {
    setShowUpgradeModal(false);
    setShowDowngradeModal(false);
    initializeApiWithHeaders();
    let formData = new FormData();

    formData.append("subscription_type", data?.subscription_type_code);
    console.log("formdata", formData);
    try {
      const queryParam = showDowngradeModal ? "downgrade=true" : "";
      const response = await ApiCall(
        `boost-data/subscribe-vip-level/${boostDataUser?.id}/?${queryParam}`,
        "PUT",
        formData
      );
      if (response.meta.status_code === 200) {
        toast.success(response.meta.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setShowCongratulations(true);
        dispatch(VipLevelRecord());
        dispatch(AllSetsRecord());
        dispatch(BalanceAndProfit());
        dispatch(BoostDataUser());
        setTimeout(() => {
          setShowUpgradeModal(false);
          setShowDowngradeModal(false);
        }, 1000);
      } else {
        toast.error(response.meta.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          if (
            error.response?.data?.meta?.message ===
            "You have insufficient balance for this level !"
          ) {
            setInsufficiantBalance(true);
          }
        }
      } else if (error.request) {
        toast.error("Network error. Please try again.");
      } else {
        console.log("Error:", error.message);
      }
    }
  };
  return (
    <div
      className=" mb-3  py-3 rounded d-flex flex-column  px-3  boost-vip-card mt-3"
      style={{
        border: "1px solid var(--border-color-gray)",
      }}
    >
      <div
        style={
          {
            // borderTop: "1px solid var(--border-color-gray)",
          }
        }
      >
        <div className=" d-flex justify-content-end">
          {data?.subscription_type_code ===
          boostDataUser?.subscription_type?.subscription_type_code ? (
            <p
              className=" px-2  rounded-pill "
              style={{
                border: "1px solid var(--border-color-gray)",
                height: "25px",
              }}
            >
              Current
            </p>
          ) : (
            <p
              className=" px-2 py-2 "
              style={{
                height: "20px",
              }}
            ></p>
          )}
        </div>
        <div className="d-flex flex-column align-items-center">
          <img src={data.img} width={190} />
          <b className="fs-4">{data?.subscription_type_name}</b>
        </div>
        <div className="d-flex flex-column align-items-center mb-1 mt-3 gap-1">
          <div className="d-flex align-items-center  gap-2">
            {data.star.map((star) => (
              <div key={star}>
                <BsStar />
              </div>
            ))}
          </div>
          <p className="fw-bolder">{integerValue} USDT</p>
        </div>
      </div>
      {/* ======= detail =======*/}

      <div
        className="vip-card-detail"
        style={{
          borderTop: "1px solid var(--border-color-gray)",
          // padding: "1.25rem 0.5rem",
        }}
      >
        <div className="d-flex flex-column boost-data-vip-card-bottom  ">
          <div className="">
            <div className="d-flex flex-column  align-items-center ">
              <div className="d-flex gap-3 align-items-center ">
                <b>{data?.subscription_type_name}</b>
              </div>
              <p
                style={{ color: "red" }}
                className="my-1 fw-bolder text-danger"
              >
                USDT {integerValue}
              </p>
            </div>
            <p style={{ color: "#777", fontSize: "15px" }}>{data.listTitle}</p>
            <ul style={{ listStyle: "disc" }}>
              {data.list.map((li, index) => (
                <li style={{ color: "#777", fontSize: "13px" }} key={index}>
                  {li}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <p
          className="d-flex justify-content-center  w-100 mt-3"
          style={{
            color: "var(--skyblue-dark)",
            cursor: "pointer",
            minHeight: "50px",
          }}
        >
          {profileData?.account_wallet_summary?.current_balance_amount.split(
            "."
          )[0] < data.reqUsdt ? (
            <>
              <button
                className="rounded-pill starting-btn px-5"
                style={{
                  maxWidth: "150px",
                  padding: "",
                }}
                onClick={handleUpgrade}
              >
                Upgrade
              </button>
              {/* {showUpgradeModal && (
                <Modal
                  show={showUpgradeModal}
                  setShow={setShowUpgradeModal}
                  title={"Upgrade"}
                  heading={"INSUFFICIENT BALANCE"}
                >
                  <div className=" d-flex align-items-center gap-2">
                    <AiOutlineWarning
                      style={{ fontSize: "5em", color: "orange" }}
                    />
                    <h3 className="fs-5" style={{ color: "grey" }}>
                      You do not have enough balance. Please deposit.
                    </h3>
                  </div>
                  <div>
                    <button
                      className="text-white px-4 py-1 rounded border-0"
                      style={{
                        background: "var(--primary-color-green)",
                      }}
                      onClick={() => {
                        dispatch(commonModalHide(false));
                        dispatch(depositeModalHide(true));
                      }}
                    >
                      Deposit Now
                    </button>
                  </div>
                </Modal>
              )} */}
            </>
          ) : (
            <>
              {!userData ? (
                <button
                  className="rounded-pill starting-btn px-5"
                  onClick={() => dispatch(loginModalHide(true))}
                >
                  Get Started
                </button>
              ) : (
                canUpgrade && (
                  // || canDowngrade
                  <div>
                    {canUpgrade && (
                      <button
                        className="rounded-pill starting-btn px-5"
                        onClick={handleUpgrade}
                      >
                        Upgrade
                      </button>
                    )}
                    {/* {canDowngrade && (
                      <button
                        className="rounded-pill starting-btn px-5"
                        onClick={handleDowngrade}
                      >
                        Downgrade
                      </button>
                    )} */}
                  </div>
                )
              )}

              {showUpgradeModal && (
                <Modal
                  show={showUpgradeModal}
                  setShow={setShowUpgradeModal}
                  title="Upgrade"
                  heading="Upgrade"
                >
                  <div className="my-4">
                    <p>
                      Do you want to upgrade your account{" "}
                      <span
                        className="fw-bold"
                        style={{ color: "var(--primary-color-green)" }}
                      >
                        {integerValue}$
                      </span>{" "}
                      will be deducted from your balance.
                    </p>
                  </div>
                  <div className="d-flex justify-content-center gap-3 mt-3">
                    <button
                      className="border-0 text-white rounded py-1"
                      style={{
                        minWidth: "100px ",
                        background: "var(--primary-color-green)",
                      }}
                      onClick={handleContinue}
                    >
                      Continue
                    </button>
                    <button
                      className="border-0 text-white rounded py-1 bg-danger"
                      style={{ minWidth: "100px" }}
                      onClick={() => setShowUpgradeModal(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </Modal>
                // )
                //  : (
                //   showDowngradeModal && (
                //     <Modal
                //       show={showDowngradeModal}
                //       setShow={setShowDowngradeModal}
                //       title="Downgrade"
                //       heading="Downgrade"
                //     >
                //       <div className="my-4">
                //         <p>
                //           Do you want to Downgrade your account{" "}
                //           <span
                //             className="fw-bold"
                //             style={{ color: "var(--primary-color-green)" }}
                //           >
                //             {integerValue}$
                //           </span>{" "}
                //           will be deducted from your balance.
                //         </p>
                //       </div>
                //       <div className="d-flex justify-content-center gap-3 mt-3">
                //         <button
                //           className="border-0 text-white rounded py-1"
                //           style={{
                //             minWidth: "100px ",
                //             background: "var(--primary-color-green)",
                //           }}
                //           onClick={handleContinue}
                //         >
                //           Continue
                //         </button>
                //         <button
                //           className="border-0 text-white rounded py-1 bg-danger"
                //           style={{ minWidth: "100px" }}
                //           onClick={() => setShowDowngradeModal(false)}
                //         >
                //           Cancel
                //         </button>
                //       </div>
                //     </Modal>
                //   )
              )}
              {insufficiantBalance && (
                <InsufficiantBalance
                  insufficiantBalance={insufficiantBalance}
                  setInsufficiantBalance={setInsufficiantBalance}
                />
              )}
              {showCongratulations && (
                <CongratulationModal
                  vipLevelData={data}
                  showCongratulations={showCongratulations}
                  setShowCongratulations={setShowCongratulations}
                />
              )}
            </>
            // )
          )}
        </p>
      </div>
    </div>
  );
};

export default VipCard;
