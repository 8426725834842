import { configureStore } from "@reduxjs/toolkit";
import navbarReducer from "./uiSlices/NavSlice";
import DarkBtnSlice from "./uiSlices/DarkBtnSlice";
import LoginModalSlice from "./uiSlices/LoginModalSlice";
import loginSlice from "../store/authenticationSlices/loginSlice";
import loginStateSlice from "./uiSlices/loginStateSlice";
import ProfileRecordSlice from "./profileSlice/ProfileRecordSlice";
import AddToCartSlice from "./uiSlices/AddToCartSlice";
import RefreshTokenSlice from "./RefreshToken/RefreshTokenSlice";
import GetAllCategory from "./Category/GetAllCategory";
import CategoryProduct from "./Category/CategoryProduct";
import GetAllSetSlice from "./BoostDataSlice/GetAllSetSlice";
import GetProductSetsSlice from "./BoostDataSlice/GetProductSetsSlice";
import GetBoostDataUserSlice from "./BoostDataSlice/GetBoostDataUserSlice";
import GetProductMainCateg from "./Product/GetProductMainCateg";
import GetAllRecords from "./BoostDataSlice/GetAllRecordsSlice";
import GetBalanceRecords from "./BoostDataSlice/GetBalanceandProfitSlice";
import incorrectTypeSlice from "./uiSlices/IncorrectTypeSlice";
import addTaskSlice from "./uiSlices/AddTaskSlice";
import previousSubmissionSlice from "./uiSlices/PreviousSubmissionSlice";
import GetProductByCategory from "./Product/GetProductByCategory";
import GetdepositeAddress from "./BoostDataSlice/GetdepositeAddress";
import AddWishlist from "./uiSlices/AddWishlist";
import ShowMainCategory from "./uiSlices/ShowMainCategory";
import GetWishlist from "./Wishlist/GetWishlist";
import historic from "./BoostDataSlice/HistoricSlice";
import SubCategoryId from "./uiSlices/SubCategoryId";
import AllcategoryStateTrue from "./uiSlices/AllcategoryStateTrue";
import PriceRangeFirstLoading from "./uiSlices/PriceRangeFirstLoading";
import ShowAllCategoryNav from "./uiSlices/ShowAllCategoryNav";
import vipLevelRecord from "./BoostDataSlice/GetAllVIPLevelRecordSlice";
import TrackOrderSlice from "./Category/TrackOrderSlice";
import GetCryptoType from "./BoostDataSlice/GetCryptoType";
import GetNetworkType from "./BoostDataSlice/GetNetworkType";
import logout from "./uiSlices/LogoutSlice";
import HomeCategorySlice from "./Category/HomeCategorySlice";
import AdminOTPSlice from "../store/authenticationSlices/AdminOTPSlice";
import GetWithdrawalAddress from "./BoostDataSlice/GetWithdrawalAddress";
import SearchProductSlice from "./Product/SearchProductSlice";
export const Store = configureStore({
  reducer: {
    nav: navbarReducer,
    darkLight: DarkBtnSlice,
    loginModal: LoginModalSlice,
    loginSlice: loginSlice,
    login: loginStateSlice,
    ProfileRecordSlice: ProfileRecordSlice,
    AddToCartSlice: AddToCartSlice,
    Refreshtoken: RefreshTokenSlice,
    AllCategory: GetAllCategory,
    CategoryWithProduct: CategoryProduct,
    GetAllSetSlice: GetAllSetSlice,
    GetProductSetsSlice: GetProductSetsSlice,
    GetBoostDataUserSlice: GetBoostDataUserSlice,
    ProductWithMainCateg: GetProductMainCateg,
    GetAllRecords: GetAllRecords,
    GetProductByCategory: GetProductByCategory,
    GetBalanceRecords: GetBalanceRecords,
    GetDepositeAddress: GetdepositeAddress,
    GetWithdrawalAddress: GetWithdrawalAddress,
    incorrectType: incorrectTypeSlice,
    addTask: addTaskSlice,
    previousSubmission: previousSubmissionSlice,
    wishList: AddWishlist,
    isMainCategoryShow: ShowMainCategory,
    GetWishlist: GetWishlist,
    historic: historic,
    SubCategoryId: SubCategoryId,
    AllCategoryState: AllcategoryStateTrue,
    priceRangeLoading: PriceRangeFirstLoading,
    showAllCategoryNav: ShowAllCategoryNav,
    vipLevelRecord: vipLevelRecord,
    trackOrder: TrackOrderSlice,
    HomeCategory: HomeCategorySlice,
    cryptoType: GetCryptoType,
    networkType: GetNetworkType,
    logout: logout,
    AdminOTPSlice: AdminOTPSlice,
    searchProduct: SearchProductSlice,
  },
});
