import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../env";

export const BalanceAndProfit = createAsyncThunk(
  "BalanceAndProfit",
  async () => {
    const uservalue = localStorage.getItem("userLogin");
    const tokenVALUE = JSON.parse(uservalue);
    const config = {
      headers: {
        Authorization: `Bearer ${tokenVALUE?.data?.token?.access}`,
      },
      timeout: 30000,
      cancelToken: new axios.CancelToken((cancel) => {
        // Create a cancel token and function
        setTimeout(() => {
          cancel("Request timed out");
        }, 30000);
      }),
    };
    try {
      const response = await axios.get(
        `${BASE_URL}boost-data/get-balance-and-profit/${tokenVALUE?.data?.id}/`,
        config
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
const BalanceAndProfitSlice = createSlice({
  name: "BalanceAndProfit",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(BalanceAndProfit.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(BalanceAndProfit.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(BalanceAndProfit.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export default BalanceAndProfitSlice.reducer;
