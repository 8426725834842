import React from "react";

import InternalTransferOTP from "../components/categories/boostDataComponent/InternalTransferOTP";
import { useEffect, useRef, useState } from "react";
import Modal from "./Modal";
import qrCode from "../assets/image/qr.png";
import { PiCopySimpleLight } from "react-icons/pi";
import { BsChevronRight } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { depositeModalHide } from "../store/uiSlices/LoginModalSlice";
import { useFormik } from "formik";
import * as yup from "yup";
import { ApiCall, initializeApiWithHeaders } from "./PostApiRequest";
import { toast } from "react-toastify";
import { GetNetworkType } from "../store/BoostDataSlice/GetNetworkType";
import { GetCryptoType } from "../store/BoostDataSlice/GetCryptoType";
import { GetWithdrawalAddress } from "../store/BoostDataSlice/GetWithdrawalAddress";
const WithDrawalPop = ({ show, setShow }) => {
  const [timeLeft, setTimeLeft] = useState(0);

  const profileData = useSelector(
    (state) => state?.ProfileRecordSlice?.data?.data
  );

  const isAuthModalShow = useSelector(
    (state) => state.loginModal.isAuthModalShown
  );
  const isCommonModalShow = useSelector(
    (state) => state.loginModal.isCommonModalShown
  );
  const [showSendButton, setShowSendButton] = useState(true);
  const [showResendButton, setShowResendButton] = useState(false);
  const [widthdrawData, setwidthDrawData] = useState(null);
  const [internalTransferOTPModal, setInternalTransferOTPModal] =
    useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetNetworkType());
    dispatch(GetCryptoType());
  }, []);
  const withdrawalAddress = useSelector(
    (state) => state?.GetWithdrawalAddress?.data?.data
  );
  const cryptoType = useSelector((state) => state?.cryptoType?.data?.data);
  const networkType = useSelector((state) => state?.networkType?.data?.data);
  const [showQrImage, setShowQrImage] = useState(false);
  const [crypto, setCrypto] = useState(null);
  const [network, setNetwork] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);

  const copyRef = useRef(null);
  const [copy, setCopy] = useState(false);
  const copyText = async () => {
    if (copyRef.current) {
      const text = copyRef.current.innerText;
      try {
        await navigator.clipboard.writeText(text);
        setCopy(true);
        setTimeout(() => {
          setCopy(false);
        }, 1000);
      } catch (error) {
        console.log("Context not coped properly");
      }
    }
  };

  useEffect(() => {
    setCrypto(null);
    setNetwork(null);
  }, []);

  useEffect(() => {
    const response = dispatch(
      GetWithdrawalAddress({ crypto: crypto, network: network })
    ).then((res) => {
      if (res?.payload?.meta?.status_code === 200) {
        setShowQrImage(true);
      }
    });
    // console.log(response);
  }, [crypto && network]);

  useEffect(() => {
    setTimeLeft(0);
    setShowSendButton(true);
    setShowResendButton(false);
    return () => {
      // Reset the state when the component is unmounted
      setTimeLeft(0);
      setShowSendButton(true);
      setShowResendButton(false);
    };
  }, [isAuthModalShow, isCommonModalShow]);

  useEffect(() => {
    let timer;

    const decrementTime = () => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          setShowResendButton(true);
        }
        return prevTime > 0 ? prevTime - 1 : 0;
      });
    };

    if (timeLeft > 0) {
      timer = setInterval(decrementTime, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [timeLeft]);

  const initialValues = {
    widthdraw_amount: "",

    widthDraw: true,
  };

  console.log(withdrawalAddress);
  const userSchema = yup.object().shape({
    widthdraw_amount: yup
      .number()
      .typeError("Must be a number")
      .max(
        Number(
          profileData?.boosted_account_wallet_summary?.current_balance_amount.split(
            "."
          )[0]
        ),
        "Amount must be less or equal to your wallet balance."
      )
      .min(50, "Minimum amount to withdraw should be 50 USDT")
      .required("This field is required."),
  });

  useEffect(() => {
    setShowQrImage(false);
  }, [show]);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema: userSchema,
    onSubmit: (values) => {
      setwidthDrawData(values);
      setInternalTransferOTPModal(true);
      setShowQrImage(false);
    },
  });

  if (internalTransferOTPModal) {
    return (
      <InternalTransferOTP
        setShow={setShow}
        data={widthdrawData}
        id={withdrawalAddress?.id}
        internalTransferOTPModal={internalTransferOTPModal}
        setInternalTransferOTPModal={setInternalTransferOTPModal}
      />
    );
  }

  return (
    <Modal scroll={true} show={show} setShow={setShow} heading={"Withdraw"}>
      <div className="d-flex flex-column gap-4">
        <div>
          <p>Please select Crypto?</p>
          <div className="d-flex gap-3 mt-2">
            <select
              className="w-100 bg-white"
              // value={"USDT"}
              onChange={(e) => {
                setCrypto(e.target.value);
                // if (e.target.value === "USDT") {
                //   setNetwork("USDT-TRC20");
                // }
              }}
            >
              <option value="">Select...</option>
              {cryptoType?.length > 0
                ? cryptoType?.map((crypto) => (
                    <option key={crypto?.id} value={crypto?.id}>
                      {crypto?.crypto_type_code}
                    </option>
                  ))
                : []}
            </select>
          </div>
        </div>

        <div>
          <p>Please select Network?</p>
          <div className="d-flex gap-3 mt-2">
            <select
              className="w-100 bg-white"
              onChange={(e) => {
                setNetwork(e.target.value);
              }}
              // value={"USDT-TRC20"}
            >
              <option value="">Select...</option>
              {networkType?.length > 0
                ? networkType?.map((network) => (
                    <option key={network?.id} value={network?.id}>
                      {network?.network_type_code}
                    </option>
                  ))
                : []}
            </select>
          </div>
        </div>

        {showQrImage && (
          <>
            <div className="d-flex justify-content-center ">
              {withdrawalAddress?.qr_image && (
                <img src={withdrawalAddress?.qr_image} width={200} />
              )}
            </div>

            <div className="d-flex gap-2 align-items-center ">
              <p>Withdraw Address</p>
              <BsChevronRight />
            </div>
            <div className=" position-relative  d-flex justify-content-between  align-items-center">
              <p ref={copyRef}>
                {withdrawalAddress?.address ? withdrawalAddress?.address : ""}
              </p>
              <div
                onClick={() => {
                  copyText();
                }}
              ></div>
            </div>
          </>
        )}
      </div>

      <form onSubmit={handleSubmit} className="mt-4">
        <div style={{ height: "85px" }}>
          <label className="text-start w-100">Withdraw Amount</label>
          <input
            type="text"
            name="widthdraw_amount"
            value={values.widthdraw_amount}
            onChange={handleChange}
            onBlur={handleBlur}
            className="w-100"
            placeholder="Amount"
          />
          {errors.widthdraw_amount && touched.widthdraw_amount && (
            <p
              className="text-start mt-1"
              style={{ fontSize: "14px", color: "red" }}
            >
              {errors.widthdraw_amount}
            </p>
          )}
        </div>
        <button
          type="submit"
          className="btn trak-sub-btn my-2 text-white ps-5 pe-5 w-100 "
          style={{
            background: "#008000",
            boxShadow: `rgba(0, 0, 0, 0.1) 0 5px 5px 2px`,
          }}
        >
          Withdraw
        </button>
      </form>
      {/* </form> */}
    </Modal>
  );
};

export default WithDrawalPop;
