import React, { useEffect, useRef } from "react";
import Layout from "../common/layout/Layout";
import Newbies from "../components/categories/boostDataComponent/Newbies";
import HeroSlider from "../common/HeroSlider";
import Expert from "../components/categories/boostDataComponent/Expert";
import AutoplaySlider from "../common/AutoPlaySlider";
import VipLevel from "../components/categories/boostDataComponent/VipLevel";
import { useDispatch, useSelector } from "react-redux";
import { showNavabr } from "../store/uiSlices/NavSlice";
import BreadCrumbs from "../common/BreadCrumbs";
import FaqSec from "../components/categories/boostDataComponent/FaqSec";
import { ProfileRecord } from "../store/profileSlice/ProfileRecordSlice";
import image1 from "../assets/image/slider image/image (2).png";
import image2 from "../assets/image/slider image/image (3).png";
import image3 from "../assets/image/slider image/image (4).png";
import image4 from "../assets/image/slider image/image (5).png";
import image5 from "../assets/image/slider image/image (6).png";
import BoostDataNavLayout from "../common/BoostDataNavLayout";
import {
  setHideAllCategoryNav,
  setshowAllCategoryNav,
} from "../store/uiSlices/ShowAllCategoryNav";

const BoostDataPage = () => {
  const images = [
    { img: image1 },
    { img: image2 },
    { img: image3 },
    { img: image4 },
    { img: image5 },
  ];
  const isNavbarVisible = useSelector((state) => state.nav.isNavbarVisible);
  const dispatch = useDispatch();
  const boostDataRef = useRef(null);
  const categoryName = "GET STARTED";
  const route = "/boost-data";
  const showAllCategoriesNav = useSelector(
    (state) => state?.showAllCategoryNav?.show
  );
  const isUserLogin = JSON.parse(localStorage.getItem("userLogin"));
  useEffect(() => {
    const option = {
      root: null,
      threshold: 0,
    };
    const heroObsrver = (enteries) => {
      const entery = enteries[0];
      if (entery.isIntersecting) {
        dispatch(showNavabr(true));
      } else {
        dispatch(showNavabr(false));
      }
    };
    const observer = new IntersectionObserver(heroObsrver, option);
    if (boostDataRef.current) {
      observer.observe(boostDataRef.current);
    }
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [boostDataRef]);

  useEffect(() => {
    dispatch(ProfileRecord());
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth <= 992) {
        dispatch(setHideAllCategoryNav());
      }
    });
  }, [window.innerWidth]);

  return (
    <Layout>
      {!isNavbarVisible && isUserLogin && (
        <div
          className="pt-2 position-fixed d-flex justify-content-center align-items-center bg-white w-100 shadow "
          style={{
            top: `${showAllCategoriesNav ? "140px" : "70px"}`,

            height: "120px",
            zIndex: "100",
          }}
        >
          <BoostDataNavLayout />
        </div>
      )}
      {/* <BreadCrumbs link={route} category={categoryName} prodname="" /> */}
      <div ref={boostDataRef}>
        <HeroSlider images={images} />
      </div>
      <Newbies />

      <AutoplaySlider />

      <div style={{ background: "#eee" }}>
        <Expert />
      </div>
      <VipLevel />
      <div style={{ background: "#eee" }}>
        <FaqSec />
      </div>
    </Layout>
  );
};

export default BoostDataPage;
