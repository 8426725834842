import React, { useEffect, useMemo, useRef, useState } from "react";
import { TfiViewGrid } from "react-icons/tfi";
import { RxChevronDown } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TbChevronDown } from "react-icons/tb";
import { isShowMainCategories } from "../../../store/uiSlices/ShowMainCategory";
import { GetProductByCateg } from "../../../store/Product/GetProductByCategory";
import { setSubCategoryId } from "../../../store/uiSlices/SubCategoryId";
import { setPriceRangeFirstLoading } from "../../../store/uiSlices/PriceRangeFirstLoading";

function AllCategoriesNavbar({ showAllCategoriesNav }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showMore, setShowMore] = useState(false);
  const [showMoreNavDropdown, setShowMoreNavDropdown] = useState(false);
  const [showMoreData, setShowMoreData] = useState([]);
  const [currentId, setCurrentId] = useState("");
  const isLight = useSelector((state) => state.darkLight);
  const [showDropDown, setShowDropDown] = useState(false);
  const [categDetail, setcategDetail] = useState(false);
  let navigation = [];
  const [navDataForMore, setDataForMore] = useState([]);
  const [navData, setNavData] = useState([]);
  const [navMoreData, setNavMoreData] = useState([]);
  const [currentSubCateg, setcurrentSubCateg] = useState(1);
  const dropDownRef = useRef();
  const allCategories = useSelector((state) => state?.AllCategory?.data?.data);

  useMemo(() => {
    if (allCategories) {
      navigation.push({
        category_name: "Home",
        route: true,
        link: "",
      });
      allCategories?.forEach((element) => {
        if (
          element.top_menu === "True" &&
          element.category_name !== "Beauty & Health"
        ) {
          navigation.push(element);
        }
      });

      navigation.push({
        category_name: "GET STARTED",
        route: true,
        link: "drive-data",
      });

      if (navigation?.length > 0) {
        setNavData(navigation);
        setDataForMore(navigation);
      }
    }
  }, [allCategories]);
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (dropDownRef.current && !dropDownRef.current.contains(e.target)) {
        setShowDropDown(false);
      } else if (
        !dropDownRef.current.contains(e.target) &&
        e.target.closest(".categories-btn").classList.contains("categories-btn")
      ) {
        setShowDropDown((prev) => !prev);
      }
    };
    document.addEventListener("click", handleOutsideClick, true);
    return () => {
      document.removeEventListener("click", handleOutsideClick, true);
    };
  }, [dropDownRef]);

  useMemo(() => {
    setShowMore(true);
    const filterData = navigation?.slice(0, 6);
    const filterMoreData = navigation?.slice(6);
    setShowMoreData(filterMoreData);
    setNavMoreData(filterData);
  }, [allCategories]);

  const moreNavMenuRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        moreNavMenuRef.current &&
        !moreNavMenuRef.current.contains(e.target)
      ) {
        setShowMoreNavDropdown(false);
      }
    };

    document.addEventListener("click", handleOutsideClick, true);
    return () => {
      document.removeEventListener("click", handleOutsideClick, true);
    };
  }, [moreNavMenuRef]);

  const handleSubCategClick = (id) => {
    dispatch(isShowMainCategories(false));
    dispatch(GetProductByCateg(id));
    dispatch(setPriceRangeFirstLoading(false));
    setcategDetail(false);
    setShowDropDown(false);

    const currentCateg = allCategories?.filter(
      (cat) => cat.id === currentSubCateg
    )[0];

    navigate(
      `/${currentCateg?.category_name
        .split("'")
        .join("")
        .replace(",", "")
        .replace("&", "")
        .split(" ")
        .filter((char) => char !== "")
        .join("-")
        .toLowerCase()}`,
      {
        state: {
          id: currentCateg?.id,
          categoryName: currentCateg?.category_name,
        },
      }
    );
  };

  return (
    <div
      className={`${
        !showAllCategoriesNav ? "container" : ""
      } hidden-categories pb-3 `}
    >
      <div className="position-relative d-flex align-items-center  mt-3">
        <div ref={dropDownRef} className="deopdown-menucategory">
          <div className="position-relative">
            <div
              className="d-flex align-items-center gap-2 border-0 categories-btn"
              style={{
                cursor: "pointer",
                outline: "none",
                background: "transparent",
              }}
              onClick={() => {
                setShowDropDown((prev) => !prev);
              }}
            >
              <TfiViewGrid style={{ fontSize: "1.15rem" }} />
              <p>Categories</p>
              <RxChevronDown />
            </div>
            {/* ========== collapse dropdown menu ============= */}

            <div
              className={`${
                showDropDown ? "open" : ""
              } position-absolute collapse-menue `}
              onMouseEnter={() => {
                setcategDetail(true);
              }}
              onMouseLeave={() => {
                setcategDetail(false);
              }}
            >
              <ul className="">
                {allCategories?.map(
                  (categ, index) =>
                    categ.category_name !== "Gadgets and Gear" && (
                      <li
                        className={`py-2 d-flex align-items-center gap-2 ${
                          isLight.lightDark ? "text-light" : "text-dark-50"
                        }`}
                        style={{ fontSize: "14px", cursor: "pointer" }}
                        key={index}
                        onMouseEnter={() => {
                          setcurrentSubCateg(categ.id);
                        }}
                      >
                        <>
                          <img src={categ.category_icon} width={12} />
                          <p
                            onClick={() => {
                              dispatch(isShowMainCategories(true));
                              dispatch(setSubCategoryId(null));
                              setShowDropDown(false);
                              dispatch(setPriceRangeFirstLoading(false));
                              navigate(
                                `/${categ?.category_name
                                  ?.split("'")
                                  .join("")
                                  .replace(",", "")
                                  .replace("&", "")
                                  .split(" ")
                                  .filter((char) => char !== "")
                                  .join("-")
                                  .toLowerCase()}`,
                                {
                                  state: {
                                    id: categ?.id,
                                    categoryName: categ?.category_name,
                                  },
                                }
                              );
                            }}
                            className={`${
                              isLight.lightDark ? "text-black" : "text-black"
                            }`}
                          >
                            {categ.category_name}
                          </p>
                        </>
                      </li>
                    )
                )}
              </ul>
            </div>
          </div>
          {/* ============== categories details menu ============== */}
          {allCategories?.filter((cat) => cat.id === currentSubCateg)[0]
            ?.sub_category?.length > 0 &&
            categDetail && (
              <div
                className={`position-absolute all-category-detail  row overflow-y-scroll`}
                style={{
                  height: "431px",
                  top: "40px",
                  left: "200px",
                  background: "white",
                  border: "1px solid #d3d1d1",
                  marginLeft: "-2px",
                  padding: "10px 20px",
                  zIndex: "1000",
                  maxWidth: "700px",
                }}
                onMouseEnter={() => {
                  setcategDetail(true);
                }}
                onMouseLeave={() => {
                  setcategDetail(false);
                }}
              >
                {allCategories
                  ?.filter((cat) => cat.id === currentSubCateg)[0]
                  .sub_category.map((subCat, index) => (
                    <ul
                      key={index}
                      className="col-4"
                      style={{ minWidth: "200px", maxWidth: "250px" }}
                    >
                      <div className="mb-2">
                        <p style={{ fontWeight: "600" }}>
                          {subCat.category_name}
                        </p>
                        {subCat?.sub_category?.map((sub, index) => (
                          <p
                            key={index}
                            className={`${
                              isLight.lightDark ? "text-black" : "text-black"
                            }`}
                            style={{
                              fontSize: "14px",
                              marginLeft: "10px",
                              cursor: "pointer",
                              padding: "2px 0",
                              color: `${
                                isLight.lightDark ? "text-black" : "text-black"
                              }`,
                            }}
                            onClick={() => {
                              handleSubCategClick(sub.id);
                            }}
                          >
                            {sub.category_name}
                          </p>
                        ))}
                      </div>
                    </ul>
                  ))}
              </div>
            )}
        </div>
        {/* ============== nav bar menu ============== */}
        <ul className="nav-bar  align-items-center gap-3 ">
          {navData?.map((navLink, index) => (
            <div key={index}>
              <li className="position-relative  ">
                {navLink?.route ? (
                  <Link
                    to={`/${navLink.link}`}
                    className="nav-link"
                    onMouseEnter={() => {
                      setCurrentId(navLink.id);
                    }}
                    onMouseLeave={() => {
                      setCurrentId("");
                    }}
                    style={{
                      color: `${isLight.lightDark ? "black" : "white"}`,
                      textDecoration: "none",
                      cursor: "pointer",
                      fontSize: "15px",
                      fontWeight: "400",
                      textTransform: "uppercase",
                    }}
                  >
                    {navLink?.category_name?.toUpperCase()}
                  </Link>
                ) : (
                  <div
                    onClick={() => {
                      dispatch(isShowMainCategories(true));
                      dispatch(setSubCategoryId(null));
                      dispatch(setPriceRangeFirstLoading(false));
                      navigate(
                        `/${navLink?.category_name
                          ?.split("'")
                          .join("")
                          .replace(",", "")
                          .replace("&", "")
                          .split(" ")
                          .filter((char) => char !== "")
                          .join("-")
                          .toLowerCase()}`,
                        {
                          state: {
                            id: navLink?.id,
                            categoryName: navLink?.category_name,
                          },
                        }
                      );
                    }}
                    className="nav-link"
                    onMouseEnter={() => {
                      setCurrentId(navLink.id);
                    }}
                    onMouseLeave={() => {
                      setCurrentId("");
                    }}
                    style={{
                      color: `${isLight.lightDark ? "black" : "white"}`,
                      textDecoration: "none",
                      cursor: "pointer",
                      fontSize: "15px",
                      fontWeight: "400",
                      textTransform: "uppercase",
                    }}
                  >
                    <p>{navLink?.category_name?.toUpperCase()}</p>
                  </div>
                )}
              </li>
            </div>
          ))}
        </ul>

        {/* ============= small desktop view =========== */}

        <ul className="small-desktop-nav align-items-center gap-3 ">
          {navMoreData?.map((navLink, index) => (
            <div key={index}>
              <li className="position-relative  ">
                {navLink?.route ? (
                  <Link
                    to={`/${navLink.link}`}
                    className="nav-link"
                    onMouseEnter={() => {
                      setCurrentId(navLink.id);
                    }}
                    onMouseLeave={() => {
                      setCurrentId("");
                    }}
                    style={{
                      color: `${isLight.lightDark ? "black" : "white"}`,
                      textDecoration: "none",
                      cursor: "pointer",
                      fontSize: "15px",
                      fontWeight: "400",
                      textTransform: "uppercase",
                    }}
                  >
                    {navLink?.category_name?.toUpperCase()}
                  </Link>
                ) : (
                  <div
                    onClick={() => {
                      dispatch(isShowMainCategories(true));
                      dispatch(setSubCategoryId(null));
                      dispatch(setPriceRangeFirstLoading(false));
                      navigate(
                        `/${navLink?.category_name
                          ?.split("'")
                          .join("")
                          .replace(",", "")
                          .replace("&", "")
                          .split(" ")
                          .filter((char) => char !== "")
                          .join("-")
                          .toLowerCase()}`,
                        {
                          state: {
                            id: navLink?.id,
                            categoryName: navLink?.category_name,
                          },
                        }
                      );
                    }}
                    className="nav-link"
                    onMouseEnter={() => {
                      setCurrentId(navLink.id);
                    }}
                    onMouseLeave={() => {
                      setCurrentId("");
                    }}
                    style={{
                      color: `${isLight.lightDark ? "black" : "white"}`,
                      textDecoration: "none",
                      cursor: "pointer",
                      fontSize: "15px",
                      fontWeight: "400",
                      textTransform: "uppercase",
                    }}
                  >
                    <p> {navLink?.category_name?.toUpperCase()}</p>
                  </div>
                )}
              </li>
            </div>
          ))}

          {showMore && (
            <div ref={moreNavMenuRef} className="position-relative">
              <div
                className="d-flex align-items-center  "
                onClick={() => {
                  setShowMoreNavDropdown((prev) => !prev);
                }}
                style={{ cursor: "pointer" }}
              >
                <p
                  className="me-2 nav-link"
                  style={{
                    textDecoration: "none",
                    cursor: "pointer",
                    fontSize: "15px",
                    fontWeight: "400",
                    textTransform: "uppercase",
                  }}
                >
                  MORE
                </p>
                <TbChevronDown />
              </div>
              {showMoreNavDropdown && (
                <div
                  className="p-3 position-absolute rounded d-flex flex-column moreDropdown-contianer"
                  style={{
                    background: "#eee",
                    zIndex: "100000",

                    right: "0",
                  }}
                >
                  {showMoreData.map((data, index) => (
                    <>
                      {data?.route ? (
                        <Link
                          to={`/${data.link}`}
                          className="nav-link"
                          onMouseEnter={() => {
                            setCurrentId(data.id);
                          }}
                          onMouseLeave={() => {
                            setCurrentId("");
                          }}
                          style={{
                            color: `${isLight.lightDark ? "black" : "white"}`,
                            textDecoration: "none",
                            cursor: "pointer",
                            fontSize: "15px",
                            fontWeight: "400",
                            textTransform: "uppercase",
                          }}
                        >
                          {data?.category_name?.toUpperCase()}
                        </Link>
                      ) : (
                        <div
                          onClick={() => {
                            dispatch(isShowMainCategories(true));
                            dispatch(setPriceRangeFirstLoading(false));
                            navigate(
                              `/${data?.category_name
                                ?.split("'")
                                .join("")
                                .replace(",", "")
                                .replace("&", "")
                                .split(" ")
                                .filter((char) => char !== "")
                                .join("-")
                                .toLowerCase()}`,
                              {
                                state: {
                                  id: navLink?.id,
                                  categoryName: navLink?.category_name,
                                },
                              }
                            );
                          }}
                          className="nav-link"
                          onMouseEnter={() => {
                            setCurrentId(data.id);
                          }}
                          onMouseLeave={() => {
                            setCurrentId("");
                          }}
                          style={{
                            color: `${isLight.lightDark ? "black" : "white"}`,
                            textDecoration: "none",
                            cursor: "pointer",
                            fontSize: "15px",
                            fontWeight: "400",
                            textTransform: "uppercase",
                          }}
                        >
                          <p>{data?.category_name?.toUpperCase()}</p>
                        </div>
                      )}
                    </>
                  ))}
                </div>
              )}
            </div>
          )}
        </ul>
      </div>
    </div>
  );
}

export default AllCategoriesNavbar;
