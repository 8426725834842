import React, { useEffect, useState } from "react";
import DetailPagesfirstSection from "../../common/detailPage/DetailPagesfirstSection";
import Ratingsection from "../../common/detailPage/Ratingsection";
import { HiChevronDoubleRight } from "react-icons/hi";
import BreadCrumbs from "../../common/BreadCrumbs";
import RelatedProducts from "../../common/detailPage/RelatedProducts";
import { Link } from "react-router-dom";
import { GetProductByCateg } from "../../store/Product/GetProductByCategory";
import { useDispatch, useSelector } from "react-redux";
const DetailPageComp = ({ product, categoryName, categoryId }) => {
  const dispatch = useDispatch();
  const [showMore, setShowMore] = useState(false);
  const showMoreClick = () => {
    setShowMore(!showMore);
  };

  useEffect(() => {
    dispatch(GetProductByCateg(product?.category?.id));
  }, []);

  const relatedProduct = useSelector(
    (state) => state?.GetProductByCategory?.data?.data
  );

  return (
    <div className="container">
      <div>
        {/* <BreadCrumbs /> */}
        <DetailPagesfirstSection img={product?.images} product={product} />
        <Ratingsection product={product} />
      </div>
      {/* ===================================`related items`==================================== */}
      <div></div>
      <hr />
      <h4 className="fw-bold mt-5 mb-3">
        Checkout Some Related Items Besed on your Choice
      </h4>

      <RelatedProducts
        categoryName={categoryName}
        categoryId={categoryId}
        product={relatedProduct}
        currentProductId={product?.id}
      />

      {showMore && <RelatedProducts />}
      {relatedProduct?.length > 8 && (
        <Link
          onClick={showMoreClick}
          className="fs-5 d-flex  align-items-center justify-content-center  border-0  my-4 text-primary "
        >
          <p className="px-3 py-1 text-light view-all">
            {showMore ? "Hide More" : " Show More Items"}{" "}
            <HiChevronDoubleRight />
          </p>
        </Link>
      )}
    </div>
  );
};

export default DetailPageComp;
