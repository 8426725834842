import React, { useEffect, useMemo, useRef, useState } from "react";
import gitLogo from "../../../assets/image/logo/logo2.jpeg";
import { CiSearch } from "react-icons/ci";
import NotifyBadge from "../../NotifyBadge";
import { FiHeart } from "react-icons/fi";
import { BsGrid } from "react-icons/bs";
import { FaCartPlus } from "react-icons/fa";
import { RxCross2, RxHamburgerMenu } from "react-icons/rx";
import MenuModal from "./headerGetioComponent/MenuModal";
import { useSelector, useDispatch } from "react-redux";
import AllCategoriesNavbar from "./AllCategoriesNavbar";
import { Link, useNavigate } from "react-router-dom";
import { BiChevronDown, BiLogOutCircle, BiUser } from "react-icons/bi";
import { ApiCall, initializeApiWithHeaders } from "../../PostApiRequest";
import { toast } from "react-toastify";
import {
  selectLoginVal,
  setLoginVal,
} from "../../../store/uiSlices/loginStateSlice.jsx";
import AddToCart from "../../../components/AddToCart";
import WishlistCard from "../../WishlistCard";
import { showNavabr } from "../../../store/uiSlices/NavSlice";
import { ProfileRecord } from "../../../store/profileSlice/ProfileRecordSlice";
import { loginModalHide } from "../../../store/uiSlices/LoginModalSlice";
import { GetWishlist } from "../../../store/Wishlist/GetWishlist";
import NoResultFound from "../../NoResultFound";
import { setshowAllCategoryNav } from "../../../store/uiSlices/ShowAllCategoryNav";
import { logout } from "../../../store/uiSlices/LogoutSlice";
import {
  GetSearchProduct,
  setSearchProduct,
} from "../../../store/Product/SearchProductSlice.jsx";

const GetitonNavbar = ({
  searchTerm,
  setSearchTerm,
  handleSearchChange,
  handleSearch,
}) => {
  const isLight = useSelector((state) => state.darkLight);
  const addToCartItems = useSelector((state) => state?.AddToCartSlice?.items);
  const wishlist = useSelector(
    (state) => state?.GetWishlist?.data?.data?.items
  );
  const profileData = useSelector(
    (state) => state?.ProfileRecordSlice?.data?.data
  );
  const isUserLogin = JSON.parse(localStorage.getItem("userLogin"));
  const [totalPrice, setTotalPrice] = useState(0);
  const [isClearLocalStorage, setIsClearLocalStorage] = useState(false);
  const dispatch = useDispatch();
  const loginVal = useSelector(selectLoginVal);
  const [route, setRoute] = useState("/");
  const isNavbarVisible = useSelector((state) => state.nav.isNavbarVisible);
  const [show, setShow] = useState(false);
  const [cartPage, setCartpage] = useState(false);
  const [wishlistPage, setWishlistpage] = useState(false);
  const [profileDropdown, setProfileDropdown] = useState(false);
  const [userData, setUserData] = useState("");

  console.log("searchTerm", searchTerm);
  const showAllCategoriesNav = useSelector(
    (state) => state?.showAllCategoryNav?.show
  );
  const onHide = () => {
    setShow((prev) => !prev);
  };

  const dataArray = [
    {
      icon: FiHeart,
      smallTitle: "Favorite",
      title: "Wishlist",
      dataCount: wishlist?.length,
    },
    {
      icon: FaCartPlus,
      smallTitle: "My Cart",
      title: `$ ${totalPrice.toFixed(2)}`,
      dataCount: addToCartItems?.length,
    },
  ];

  const handleCateg = () => {
    dispatch(setshowAllCategoryNav());
  };

  useEffect(() => {
    if (isClearLocalStorage === true) {
      localStorage.removeItem("userLogin");
    }
  }, [isClearLocalStorage === true]);

  useEffect(() => {
    const response = dispatch(ProfileRecord()).then((result) => {
      if (result?.error?.message === "Request failed with status code 401") {
        dispatch(setLoginVal(false));
        localStorage.removeItem("userLogin");
      }
    });
    dispatch(GetWishlist()).then((result) => {
      if (result?.error?.message === "Request failed with status code 401") {
        dispatch(setLoginVal(false));
        localStorage.removeItem("userLogin");
      }
    });
  }, []);

  const profileRef = useRef(null);
  const uservalue = localStorage.getItem("userLogin");
  const tokenVALUE = JSON.parse(uservalue);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (profileRef.current && !profileRef.current.contains(e.target)) {
        setProfileDropdown(false);
      }
    };

    document.addEventListener("click", handleOutsideClick, true);
    return () => {
      document.removeEventListener("click", handleOutsideClick, true);
    };
  }, [profileRef]);
  const handleLogout = async () => {
    try {
      const returnData = await dispatch(logout());

      if (returnData?.payload?.meta?.status_code === 200) {
        setIsClearLocalStorage(true);
        toast.success(returnData?.payload?.meta?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        dispatch(setLoginVal(false));
      } else {
        toast.error(returnData?.payload?.meta?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const cartRef = useRef(null);
  const wishRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (cartRef.current && !cartRef.current.contains(e.target)) {
        setCartpage(false);
      }
    };
    document.addEventListener("click", handleOutsideClick, true);
    return () =>
      document.removeEventListener("click", handleOutsideClick, true);
  }, [cartRef, wishRef]);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (wishRef.current && !wishRef.current.contains(e.target)) {
        setWishlistpage(false);
      }
    };
    document.addEventListener("click", handleOutsideClick, true);
    return () =>
      document.removeEventListener("click", handleOutsideClick, true);
  }, [wishRef]);

  useEffect(() => {
    if (addToCartItems?.length > 0) {
      let total = 0;
      addToCartItems?.forEach((item) => {
        return (total += Number(item.itemTotalPrice));
      });

      setTotalPrice(total);
    }
  }, [addToCartItems]);

  return (
    <>
      <div style={{ background: isLight.lightDark ? "white" : "black" }}>
        <div className="container">
          <div
            className={`row py-3 align-items-center justify-content-between  ${
              isNavbarVisible ? "gap-3 " : "gap-0"
            }`}
          >
            <div className={`col-lg-2 col-2 me-2 pe-2  `}>
              {route === "/" ? (
                <Link to="/">
                  <img
                    src={gitLogo}
                    className=" nav-logo-img "
                    alt="Get It On Logo"
                  />
                </Link>
              ) : (
                <Link to="/">
                  <img
                    src={ish}
                    className=" nav-logo-img"
                    alt="Get It On Logo"
                  />
                </Link>
              )}
            </div>
            {/* =============top bar search bar ============== */}
            <div
              className="col-xxl-5 col-md-4  hide-nav-search "
              style={{ minWidth: "370px" }}
            >
              <form className="h-100  w-100">
                <div
                  className={`border  position-relative  d-flex align-items-center focus-border-primary justify-content-between  h-100  `}
                  style={{ borderRadius: "5px", border: "2px solid" }}
                >
                  <CiSearch
                    className={`position-absolute start-0  text-dark ms-1 `}
                    style={{ translate: "5px 0" }}
                  />
                  <input
                    type="text"
                    placeholder="Search"
                    className={`border-0 w-100 h-100 py-3 `}
                    value={searchTerm}
                    onChange={(event) => {
                      handleSearch(event);
                    }}
                    style={{
                      outline: "none",
                      borderRadius: "5px",

                      paddingLeft: "30px",
                      background: isLight.lightDark ? "white" : "#343541",
                    }}
                    autoComplete="on"
                  />
                  <div
                    className="button-primary align-self-stretch border-0 px-3 text-white d-flex align-items-center justify-content-center"
                    style={{
                      background: "#008000",
                      borderTopRightRadius: "3px",
                      borderEndEndRadius: "3px",
                      cursor: "pointer",
                    }}
                    onClick={handleSearchChange}
                  >
                    Search
                  </div>
                </div>
              </form>
            </div>

            {/* =============small screen aviator view ============== */}

            <div className=" col-8 col-sm-6 col-md-4  col-lg ps-2 gap-3  ">
              <div className="w-100 h-100 d-flex  align-items-center justify-content-end   notify-profile-icon">
                {!isNavbarVisible && (
                  <BsGrid
                    className="d-none d-lg-block  burder-menu"
                    onClick={handleCateg}
                    style={{ color: "gray", cursor: "pointer" }}
                    title="Show Categories"
                  />
                )}
                <div
                  style={{ color: "gray", fontSize: "25px" }}
                  className="d-lg-none"
                  onClick={onHide}
                >
                  <RxHamburgerMenu className="burder-menu ms-2" />
                </div>
                {/* =============notify badge avitor ============== */}
                {dataArray.map((data, index) => (
                  <div key={index}>
                    <NotifyBadge
                      data={data}
                      setCartpage={setCartpage}
                      setWishlistpage={setWishlistpage}
                    />
                  </div>
                ))}
                {tokenVALUE?.data?.token?.access && (
                  <div ref={profileRef} className="position-relative">
                    <div>
                      <div
                        className="d-flex align-items-center "
                        onClick={() => {
                          setProfileDropdown((prev) => !prev);
                        }}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <div
                          className=" position-relative d-flex align-items-center   justify-content-center rounded-circle notify-badge"
                          style={{
                            background: "#eee",
                            cursor: "pointer",
                          }}
                        >
                          {profileData?.user_profile?.profile_picture ? (
                            <div className="rounded-circle overflow-hidden d-flex align-items-center justify-content-center w-100 h-100">
                              <img
                                src={profileData?.user_profile?.profile_picture}
                                className="h-100 w-100 object-fit-cover "
                              />
                            </div>
                          ) : (
                            <BiUser />
                          )}
                        </div>
                        <div>
                          <div className="d-flex">
                            <div
                              className=" align-items-center d-none d-xl-flex  gap-1  px-2 my-1 profile-drop-down-item"
                              style={{
                                cursor: "pointer",
                                borderRadius: "2.5px",
                              }}
                            >
                              {profileData?.is_active && (
                                <div
                                  className="rounded-circle "
                                  style={{
                                    background: "var(--primary-color-green)",
                                    minHeight: "7px",
                                    minWidth: "7px",
                                  }}
                                />
                              )}
                              <p className="top-bar-text  ">
                                <span className="me-1">
                                  {profileData?.first_name
                                    ? profileData?.first_name
                                    : "-"}
                                </span>
                                <span>
                                  {profileData?.last_name
                                    ? profileData?.last_name
                                    : "-"}
                                </span>
                              </p>
                            </div>
                            <BiChevronDown
                              style={{ fontSize: "25px", color: "#777" }}
                            />
                          </div>

                          <p
                            className="fw-semibold d-none d-xl-flex  ms-1 w-100"
                            style={{ fontSize: "12px" }}
                          >
                            <span className="ms-1">
                              ${" "}
                              {profileData?.ecommerce_account_wallet_summary
                                ?.current_balance_amount
                                ? profileData?.ecommerce_account_wallet_summary
                                    ?.current_balance_amount
                                : "0"}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    {profileDropdown && (
                      <div
                        className="position-absolute px-2 py-1  shadow-sm"
                        style={{
                          background: "#eee",
                          top: "105%",
                          right: "0",
                          borderRadius: "2.5px",
                          zIndex: "1000",
                        }}
                      >
                        <div
                          className="d-flex align-items-center  d-xl-none  gap-1  px-2 my-1 profile-drop-down-item"
                          style={{ cursor: "pointer", borderRadius: "2.5px" }}
                        >
                          <div
                            className="rounded-circle "
                            style={{
                              background: "var(--primary-color-green)",
                              minHeight: "7px",
                              minWidth: "7px",
                            }}
                          />
                          <p className="top-bar-text  ">
                            <span className="me-1">
                              {profileData?.first_name
                                ? profileData?.first_name
                                : "-"}
                            </span>
                            <span>
                              {profileData?.last_name
                                ? profileData?.last_name
                                : "-"}
                            </span>
                          </p>
                        </div>

                        <p
                          className="fw-semibold d-flex my-2 ms-1 d-xl-none   w-100"
                          style={{ fontSize: "12px" }}
                        >
                          <span className="ms-1">
                            ${" "}
                            {
                              profileData?.ecommerce_account_wallet_summary
                                ?.current_balance_amount
                            }
                          </span>
                        </p>
                        <div
                          className="d-flex align-items-center gap-1 bg-white px-2 profile-drop-down-item"
                          style={{ cursor: "pointer", borderRadius: "2.5px" }}
                          onClick={() => {
                            handleLogout();
                          }}
                        >
                          <p
                            className="logout-text"
                            style={{ color: "var(--primary-color-green)" }}
                          >
                            {" "}
                            Logout
                          </p>{" "}
                          <BiLogOutCircle
                            style={{ color: "var(--primary-color-green)" }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/*================= small screen modal menu and search bar============== */}
          <MenuModal show={show} setShow={setShow} onHide={onHide} />
          {/*================= After threshold ============== */}
          {!isNavbarVisible && showAllCategoriesNav && (
            <AllCategoriesNavbar showAllCategoriesNav={showAllCategoriesNav} />
          )}

          {/* =============== shoping cart =================== */}
          <div
            ref={cartRef}
            className={`  d-flex flex-column justify-content-between position-fixed cart-page shadow bg-white p-4 ${
              cartPage ? "open" : ""
            }`}
          >
            <div>
              <div className="d-flex align-items-center  justify-content-between">
                <h3 style={{ color: "var(--primary-color-green)" }}>
                  Shopping Cart
                </h3>
                <div
                  style={{
                    width: "25px",
                    height: "25px",
                    background: "#eee",
                    cursor: "pointer",
                  }}
                  className="rounded  d-flex align-items-center  justify-content-center "
                  onClick={() => {
                    setCartpage(false);
                  }}
                >
                  <RxCross2 className="fs-4" style={{ color: "#7f7f7f" }} />
                </div>
              </div>
              <div
                className="mt-4 overflow-y-scroll add-to-card-product"
                style={{ maxHeight: "77.5vh" }}
              >
                <AddToCart />
              </div>
            </div>
            <div>
              <div>
                <div className="d-flex align-items-center  justify-content-between ">
                  <p className="fw-semibold " style={{ color: "darkgray" }}>
                    Total
                  </p>
                  <p
                    style={{ color: "var(--primary-color-green)" }}
                    className="fw-semibold"
                  >
                    ${totalPrice.toFixed(2)}
                  </p>
                </div>
              </div>
              <div className="w-100 d-flex justify-content-end mt-2">
                {isUserLogin ? (
                  addToCartItems?.length > 0 && (
                    <Link
                      to={"/checkout"}
                      onClick={() => {
                        dispatch(showNavabr(true));
                        setCartpage(false);
                      }}
                      className="text-white rounded border-0 px-3 py-1"
                      style={{ background: "var(--primary-color-green)" }}
                    >
                      Place Order
                    </Link>
                  )
                ) : (
                  <>
                    {
                      <button
                        onClick={() => {
                          dispatch(loginModalHide(true));
                        }}
                        className="text-white rounded border-0 px-3 py-1"
                        style={{ background: "var(--primary-color-green)" }}
                      >
                        Place Order
                      </button>
                    }
                  </>
                )}
              </div>
            </div>
          </div>

          {/* =============== wishlist page =================== */}
          <div
            ref={wishRef}
            className={`d-flex flex-column justify-content-between position-fixed wishlist-page shadow bg-white p-4 ${
              wishlistPage ? "open" : ""
            }`}
          >
            <div>
              <div className="d-flex align-items-center  justify-content-between">
                <h3 style={{ color: "var(--primary-color-green)" }}>
                  Wishlist
                </h3>
                <div
                  style={{
                    width: "25px",
                    height: "25px",
                    background: "#eee",
                    cursor: "pointer",
                  }}
                  className="rounded  d-flex align-items-center  justify-content-center "
                  onClick={() => {
                    setWishlistpage(false);
                  }}
                >
                  <RxCross2 className="fs-4" style={{ color: "#7f7f7f" }} />
                </div>
              </div>
              <div
                className={`mt-4  ${
                  wishlist?.length > 8 && "overflow-y-scroll"
                }`}
                style={{ height: "80vh" }}
              >
                {wishlist?.length > 0 ? (
                  wishlist?.map((product, index) => (
                    <div key={index}>
                      <WishlistCard wishPrd={product} />
                    </div>
                  ))
                ) : (
                  <div
                    style={{ height: "90vh" }}
                    className=" d-flex align-items-center justify-content-center"
                  >
                    {" "}
                    <NoResultFound />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetitonNavbar;
