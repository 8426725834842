import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import axios from "axios";
import Layout from "../common/layout/Layout";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../env";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { loginModalHide } from "../store/uiSlices/LoginModalSlice";
import { useDispatch } from "react-redux";
const ResetPasswordComp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [params, setParams] = useState({});
  const [showPassowrd, setShowPassowrd] = useState(false);
  const [showpasswordVerify, setShowPasswordVerify] = useState(false);
  const [passwordVerify, setPasswordVerify] = useState("");

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const params = {};
    for (let key of urlParams.keys()) {
      params[key] = urlParams.get(key);
    }
    setParams(params);
    if (params.uid !== undefined || params.token !== undefined) {
      navigate(
        `/reset-password-confirm/?uid=${params?.uid}&token=${params?.token}`

      );
    }
  }, []);
  const functionAPI = async (values) => {
    const formData = new FormData();
    formData.append("password", values.password);
    formData.append("new_password", values.new_password);
    formData.append("uid", params.uid);
    formData.append("token", params.token);
    try {
      const response = await axios.patch(
        `${BASE_URL}auth/reset-password-confirm/`,
        formData
      );

      if (response.status === 200) {
        formik.handleReset();
        toast.success("Password reset successful");
        dispatch(loginModalHide(true));
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          toast.error(error.response?.data?.meta?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else if (error.request) {
        toast.error("Network error. Please try again.");
      } else {
        console.log("Error:", error.message);
      }
    }
  };

  const initialValues = { password: "", new_password: "" };
  const formik = useFormik({
    initialValues,
    validate: (values) => {
      const errors = {};
      if (!values.password) {
        errors.password = "This field is required.";
      } else if (!values.new_password) {
        errors.new_password = "This field is required.";
      }
      return errors;
    },
    onSubmit: (values) => {
      functionAPI(values);
    },
  });

  useEffect(() => {
    if (formik.values.password !== "" && formik.values.new_password !== "") {
      if (formik.values.password !== formik.values.new_password) {
        setPasswordVerify(
          "Current password doesn't match the confirm password."
        );
      } else {
        setPasswordVerify("");
      }
    } else {
      setPasswordVerify("");
    }
  }, [formik.values.password, formik.values.new_password]);

  return (
    <Layout>
      <div className="container py-4 d-flex justify-content-center">
        <form
          onSubmit={formik.handleSubmit}
          className="d-flex flex-column gap-4 mt-3"
          style={{ maxWidth: "400px", width: "100%", marginBottom: "3rem" }}
        >
          <h4 className="mb-1 text-2xl font-bold">Set new password</h4>
          <p>Your new password must different to previous password.</p>

          <div className="" style={{ height: "40px" }}>
            {/* <input
              type="password"
              className="w-100"
              name="password"
              placeholder="Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            /> */}

            <div className="position-relative">
              <input
                type={`${showPassowrd ? "text" : "password"}`}
                className="w-100"
                name="password"
                placeholder="New Password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              <div
                className="position-absolute"
                style={{ top: "7.5px", right: "10px", cursor: "pointer" }}
                onClick={() => setShowPassowrd(!showPassowrd)}
              >
                {!showPassowrd ? (
                  <BsEyeSlash className="text-2xl" />
                ) : (
                  <BsEye className="text-2xl" />
                )}
              </div>
            </div>
            {passwordVerify === "" &&
              formik.errors.password &&
              formik.touched.password && (
                <div className="flex items-center">
                  <p
                    className="text-danger text-[14px]"
                    style={{ fontSize: "13px" }}
                  >
                    {formik.errors.password}
                  </p>
                </div>
              )}
            {passwordVerify !== "" && (
              <div className="flex items-center  ">
                <p className="text-danger" style={{ fontSize: "13px" }}>
                  {passwordVerify}
                </p>
              </div>
            )}
          </div>
          <div className="" style={{ height: "40px" }}>
            {/* <input
              type="password"
              className="w-100"
              name="new_password"
              placeholder="New Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.new_password}
            /> */}

            <div className="position-relative">
              <input
                type={`${showpasswordVerify ? "text" : "password"}`}
                className="w-100"
                name="new_password"
                placeholder="Confirm Password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.new_password}
              />
              <div
                className="position-absolute"
                style={{ top: "7.5px", right: "10px", cursor: "pointer" }}
                onClick={() => setShowPasswordVerify(!showpasswordVerify)}
              >
                {!showpasswordVerify ? (
                  <BsEyeSlash className="text-2xl" />
                ) : (
                  <BsEye className="text-2xl" />
                )}
              </div>
            </div>
            {passwordVerify === "" &&
              formik.errors.new_password &&
              formik.touched.new_password && (
                <div className="flex items-center">
                  <p className="text-danger" style={{ fontSize: "13px" }}>
                    {formik.errors.new_password}
                  </p>
                </div>
              )}
            {passwordVerify !== "" && (
              <div className="flex items-center">
                <p className="text-danger" style={{ fontSize: "13px" }}>
                  {passwordVerify}
                </p>
              </div>
            )}
          </div>
          <button
            type="submit"
            className="w-100 text-center border-0  text-white btn-hover"
            style={{
              background: "var(--primary-color-green)",
              borderRadius: "5px",
              padding: "12.5px 0",
            }}
          >
            Reset Password
          </button>
        </form>
      </div>
    </Layout>
  );
};

export default ResetPasswordComp;
