import { createSlice } from "@reduxjs/toolkit";

const incorrectTypeSlice = createSlice({
  name: "incorrectType",
  initialState: false,
  reducers: {
    setIncorrectType: (state, action) => {
      return action.payload;
    },
  },
});

export const { setIncorrectType } = incorrectTypeSlice.actions;
export const selectIncorrectType = (state) => state.incorrectType;

export default incorrectTypeSlice.reducer;
