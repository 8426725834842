import React, { useState } from "react";
import "./commonCompStyle/Switch.css";
import { FiSun } from "react-icons/fi";
import { BsMoon } from "react-icons/bs";
import { darkLightAction } from "../store/uiSlices/DarkBtnSlice";
import { useDispatch, useSelector } from "react-redux";
const DarkLightBtn = () => {
  const dispatch = useDispatch();
  const isLight = useSelector((state) => state.darkLight);
  const [light, setLight] = useState(true);
  const handleLightDark = () => {
    localStorage.setItem("light", light ? false : true);
    setLight((prev) => !prev);
    dispatch(darkLightAction.darkLightAction(!light));
  };

  return (
    <div>
      <div
        className={`switch-cont d-flex align-items-center ${
          !light ? "bg-dark" : "bg-light "
        }`}
        onClick={handleLightDark}
        // onClick={() => {
        //   setLight((prev) => !prev);
        // }}
        style={{ cursor: "pointer" }}
      >
        <div
          className={`dot ${
            light ? "on" : "off"
          } d-flex align-items-center  justify-content-center `}
        >
          {light ? (
            <FiSun style={{ color: "#F97C10" }} />
          ) : (
            <BsMoon className="" style={{ fontSize: "14px" }} />
          )}
        </div>
      </div>
    </div>
  );
};

export default DarkLightBtn;
