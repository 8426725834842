import axios from "axios";
import { BASE_URL } from "../env";
const BASE_URL_API = BASE_URL;
// Create a common Axios instance with headers
const api = axios.create({
  baseURL: BASE_URL_API,
});
// Utility function to set headers for the Axios instance
export const setApiHeaders = (token) => {
  api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  api.defaults.headers.common["Accept"] = "application/json";
  api.defaults.headers.common["Content-Type"] = "application/json";
};
// Call this function to set headers before making API requests
export const initializeApiWithHeaders = () => {
  const uservalue = localStorage.getItem("userLogin");
  const tokenVALUE = JSON.parse(uservalue);
  if (tokenVALUE?.data?.token?.access) {
    setApiHeaders(tokenVALUE.data.token.access);
  } else {
    // Handle the case when there's no valid token
    console.error("No valid token available.");
  }
};
// Utility function to handle API requests using Axios
export const ApiCall = async (url, method, data = null) => {
  try {
    const response = await api({
      method,
      url,
      data,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
