import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../env";
const initialState = {
  isLoading: false,
  data: null,
  isError: false,
};

export const RefeshToken = createAsyncThunk("RefeshToken", async () => {
  const uservalue = localStorage.getItem("userLogin");
  const tokenVALUE = JSON.parse(uservalue);

  const formdata = new FormData();
  formdata.append("refresh", `${tokenVALUE?.data?.token?.refresh}`);
  const config = {
    headers: {
      Authorization: `Bearer ${tokenVALUE?.data?.token?.access}`,
      "content-type": "application/json",
    },
    timeout: 5000,
    cancelToken: new axios.CancelToken((cancel) => {
      // Create a cancel token and function
      setTimeout(() => {
        cancel("Request timed out");
      }, 5000);
    }),
  };
  try {
    const response = await axios.post(
      `${BASE_URL}auth/refresh-token/`,
      formdata,
      config
    );

    return response.data;
  } catch (error) {
    // throw error;
  }
});

const refreshTokenSlice = createSlice({
  name: "refresh-token-slice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(RefeshToken.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(RefeshToken.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(RefeshToken.rejected, (state, action) => {
      state.isError = true;
    });
  },
});

export default refreshTokenSlice.reducer;
