import React, { useEffect, useState } from "react";
import BottomFullModal from "../../../common/BottomFullModal";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import RecordCard from "../../../common/RecordCard";
import { GetAllRecords } from "../../../store/BoostDataSlice/GetAllRecordsSlice";
import NoResultFound from "../../../common/NoResultFound";

const RecordsPopUp = ({ title, show, setShow }) => {
  const dispatch = useDispatch();
  const recordStste = useSelector((state) => state?.GetAllRecords?.data?.data);
  const [isLoading, setIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [currentRecord, setCurrentRecord] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [record, setRecord] = useState([]);
  const tabs = [
    {
      id: 1,
      title: "All",
    },
    {
      id: 2,
      title: "Pending",
    },
    {
      id: 3,
      title: "In progress",
    },
    {
      id: 4,
      title: "Completed",
    },
  ];

  useEffect(() => {
    dispatch(GetAllRecords())
      .then((response) => {
        if (response && response?.payload?.data) {
          const recordsFromApi = response?.payload?.data;
          setPageCount(Math.ceil(recordsFromApi.length / 5));
          // setCurrentRecord(recordsFromApi); // Store all records initially
        }
      })
      .catch((error) => {
        console.error("Error fetching records:", error);
      });
  }, []);
  useEffect(() => {
    if (recordStste !== undefined) {
      setRecord(recordStste);
      console.log("recordStste", recordStste);
    }
  }, [recordStste]);

  const handleTabClick = (tabId) => {
    setCurrentTab(tabId);
    setCurrentPage(0);
  };

  const filteredRecords = record?.filter((rec) => {
    if (currentTab === 1) {
      return true;
    } else if (currentTab === 2) {
      return rec.status === "Pending";
    } else if (currentTab === 3) {
      return rec.status === "In Progress";
    } else if (currentTab === 4) {
      return rec.status === "Completed";
    }
    return false;
  });

  return (
    <BottomFullModal title={title} show={show} setShow={setShow}>
      <div className="h-100 container" style={{ background: "#f8f8f8" }}>
        <div className="my-4 history-rec-subnav-title py-4 h-100">
          <div className="d-flex flex-column justify-content-between h-100">
            <h2 className="d-flex fw-semibold his-rec-pop-heading mb-3">
              Records
            </h2>
            {/* Render the tabs */}
            <div className="d-flex w-100 justify-content-between">
              {tabs.map((tab) => (
                <p
                  className="history-rec-subnav-title"
                  key={tab.id}
                  style={{
                    cursor: "pointer",
                    color: `${
                      tab.id === currentTab ? "var(--primary-color-green)" : ""
                    }`,
                    borderBottom: `${
                      tab.id === currentTab
                        ? "2px solid var(--primary-color-green)"
                        : "none"
                    }`,
                  }}
                  onClick={() => handleTabClick(tab.id)}
                >
                  {tab.title}
                </p>
              ))}
            </div>
            <div>
              {filteredRecords.length > 0 ? (
                // Filter and display records based on the currentTab
                filteredRecords
                  .slice(currentPage * 5, (currentPage + 1) * 5)
                  .map((rec) => (
                    <RecordCard
                      isLoading={isLoading}
                      key={rec.id}
                      record={rec}
                    />
                  ))
              ) : (
                <div className="my-5">
                  <NoResultFound />
                </div>
              )}
            </div>
          </div>

          {/* Pagination */}
          {filteredRecords.length > 5 && (
            <div className="pagination-style">
              <ReactPaginate
                breakLabel="..."
                nextLabel={<span style={{ color: "green" }}>Next</span>}
                onPageChange={(selectedPage) =>
                  setCurrentPage(selectedPage.selected)
                }
                pageRangeDisplayed={3}
                pageCount={Math.ceil(filteredRecords.length / 5)}
                previousLabel={<span style={{ color: "green" }}>Previous</span>}
                containerClassName="d-flex gap-1 gap-sm-3 font-class p-3 align-items-center justify-content-center justify-content-sm-end text-secondary"
                activeClassName="border px-2 btn-class text-light"
                previousClassName="text-color"
                nextClassName="text-color"
              />
            </div>
          )}
        </div>
      </div>
    </BottomFullModal>
  );
};

export default RecordsPopUp;
