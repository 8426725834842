import React, { useEffect, useRef } from "react";
import illustration from "../assets/image/link_page/termsAndCond.jpg";
import { useInView } from "react-intersection-observer";
import { useDispatch } from "react-redux";
import { showNavabr } from "../store/uiSlices/NavSlice";
import Layout from "../common/layout/Layout";
import HelmetComp from "../common/HelmetComp";
import IlustrationPagedummyComp from "../common/IlustrationPagedummyComp";
import watchImage from "../assets/image/slider/156c27224ff4389c0bbe6ca8e3daeb7c.png";
import chairImage from "../assets/image/slider/c1c7140f4146c8d083042e49465f0c62.png";
import headPhones from "../assets/image/slider/a306c6a84a161de39e219011a9315d20.png";
import BreadCrumbs from "../common/BreadCrumbs";
import HeroSlider from "../common/HeroSlider";
const TermsAndConditions = () => {
  const images = [
    { img: watchImage },
    { img: chairImage },
    { img: headPhones },
  ];
  const dispatch = useDispatch();
  const termCondotionRef = useRef(null);

  useEffect(() => {
    const option = {
      root: null,
      threshold: 0,
    };

    const heroObsrver = (enteries) => {
      const entery = enteries[0];
      if (entery.isIntersecting) {
        dispatch(showNavabr(true));
      } else {
        dispatch(showNavabr(false));
      }
    };

    const observer = new IntersectionObserver(heroObsrver, option);
    if (termCondotionRef.current) {
      observer.observe(termCondotionRef.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [termCondotionRef]);
  return (
    <Layout>
      <HelmetComp title={"Terms And Conditions"} />
      <div ref={termCondotionRef}>
        <HeroSlider images={images} />
        {/* <BreadCrumbs
          link={"/terms-conditions"}
          category={"Terms And Conditions"}
        /> */}
      </div>
      <IlustrationPagedummyComp
        illustration={illustration}
        pageTitle={"Terms And Conditions"}
        heading={`Please read these terms and conditions of sale (the "Terms") carefully before using the website [www.votre-site.com] (the "Site") or placing an order with [Your Company Name]. By using the Site or placing an order, you agree to be bound by these Terms.`}
        description={
          <>
            <div>
              <span style={{ fontWeight: "600", fontSize: "20px" }}>
                Orders and Payment
              </span>
              <br />
              <span style={{ fontWeight: "600", fontSize: "15px" }}>
                Orders:
              </span>{" "}
              When you place an order on our Site, you confirm that you are
              authorized to make that purchase and that you agree to pay the
              price indicated for the products or services ordered.
            </div>
            <div>
              {" "}
              <span style={{ fontWeight: "600", fontSize: "15px" }}>
                {" "}
                Prices and Payment:
              </span>{" "}
              Prices for products and services are shown on the Site. Payments
              are processed via secure payment methods. We reserve the right to
              change prices at any time without notice. Delivery and Returns
            </div>
            <div>
              <span style={{ fontWeight: "600", fontSize: "15px" }}>
                Delivery:
              </span>{" "}
              Delivery times are estimated and may vary depending on
              destination. We are not responsible for delays in delivery beyond
              our control.
            </div>
            <div>
              <span style={{ fontWeight: "600", fontSize: "15px" }}>
                Returns:
              </span>{" "}
              See our returns policy [link to returns policy] for information on
              how to return products and request refunds. Intellectual Property
              Rights
            </div>
            <div>
              <span style={{ fontWeight: "600", fontSize: "15px" }}>
                Intellectual Property:
              </span>{" "}
              All content on the Site, including images, text, logos and
              trademarks, is protected by intellectual property rights held by
              [Your Company Name] or its suppliers. You may not reproduce,
              distribute or use these contents without written authorisation.
              Limitation of liability
            </div>
            <div>
              <span style={{ fontWeight: "600", fontSize: "15px" }}>
                Liability:
              </span>{" "}
              [Your Company Name] shall not be liable for any direct, indirect,
              special or consequential damages arising out of the use of the
              Site or the purchase of products or services from the Site.
              Personal data
            </div>
            <div>
              {" "}
              <span style={{ fontWeight: "600", fontSize: "15px" }}>
                {" "}
                Personal Data:
              </span>{" "}
              See our privacy policy [link to privacy policy] to find out more
              about how we collect, use and protect your personal data.
            </div>
            <div>
              <span style={{ fontWeight: "600", fontSize: "15px" }}>
                {" "}
                Modification of the Terms Modification:
              </span>{" "}
              We reserve the right to modify these Terms at any time. Changes
              will be posted on the Site and will take effect immediately. You
              are responsible for regularly reviewing the Terms.
            </div>
            <div>
              <span style={{ fontWeight: "600", fontSize: "15px" }}>
                Governing Law and Dispute Resolution Applicable Law:
              </span>{" "}
              These Terms are governed by the laws in force in the European
              Union (EU). Any dispute arising out of these Terms shall be
              subject to the exclusive jurisdiction of the courts of Luxembourg.
              By using our Site and placing an order, you agree to these Terms.
              If you have any questions or concerns, please contact us via
              Whatsapp or Telegram.
            </div>
            Thank you for placing your trust in us. We look forward to providing
            you with an exceptional shopping experience.
          </>
        }
      />
    </Layout>
  );
};

export default TermsAndConditions;
