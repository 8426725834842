import React from "react";
import { Link } from "react-router-dom";
import { FaRegHandPointLeft } from "react-icons/fa";
import ProductCard from "./ProductCard";
import { useSelector } from "react-redux";
import NoResultFound from "./NoResultFound";
const RightSideCards = ({
  productData,
  quantity,
  categoryName,
  urlCateg,
  categoryId,
}) => {
  const isLight = useSelector((state) => state.darkLight);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="d-flex  align-items-center ">
          <h3 className="product-heading mb-0 d-inline-block">
            {categoryName}
          </h3>
          <span
          // className={`${isLight.lightDark ? "text-light" : "text-dark-50"}`}
          >
            -{quantity} items
          </span>
        </div>
        <Link
          to={"/"}
          className=" btnPrimaryGreen  d-flex align-items-center btn  gap-sm-2 "
        >
          <FaRegHandPointLeft className="" />
          <p className="fs-lg-5 back-to-home-btn"> Back to home</p>
        </Link>
      </div>

      {productData?.length > 1 ? (
        <div className="row  ">
          {productData?.map(
            (product) =>
              product.id && (
                <div
                  key={product.id}
                  className="col-6 col-sm-4 col-md-3  mb-3 "
                >
                  <ProductCard
                    categoryId={categoryId}
                    relatedProduct={productData}
                    categoryName={categoryName}
                    product={product}
                    urlCateg={urlCateg}
                    id={product.id}
                  />
                </div>
              )
          )}
        </div>
      ) : (
        <NoResultFound />
      )}
    </>
  );
};

export default RightSideCards;
