// import Swiper and modules styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import AutoplaySlider from "../../common/AutoPlaySlider";

function CardAutoplaySlider() {
  return (
    <div>
      <AutoplaySlider />
    </div>
  );
}

export default CardAutoplaySlider;
