import React from "react";
import { useDispatch, useSelector } from "react-redux";
import productDummyImage from "../assets/image/product.jpeg";
import { setPriceRangeFirstLoading } from "../store/uiSlices/PriceRangeFirstLoading";
import { useNavigate } from "react-router-dom";
const LargeCard = ({ products, categoryName, categoryId }) => {
  const isLight = useSelector((state) => state.darkLight);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className="h-100">
      <div className="d-flex  flex-column justify-content-center gap-3 flex-md-row w-100 h-100">
        <div
          className="last-section-large-card  card-hover py-5  px-4  bg-white text-center w-100"
          style={{
            border: "1px solid var(--border-color-gray)",
          }}
        >
          <div className="mb-4">
            <h5
              className=" fw-bold  style={{marginTop:'30px'}}mb-0"
              style={{ cursor: "pointer" }}
              onClick={() => {
                dispatch(setPriceRangeFirstLoading(false));
                navigate(
                  `${`/${categoryName}/${products[0]?.item_name
                    .split("/")
                    .join(" ")}`}`,
                  {
                    state: {
                      path: categoryName,
                      id: categoryId,
                      product: products[0],
                    },
                  }
                );
              }}
            >
              {products[0]?.item_name.length > 30
                ? `${products[0]?.item_name.slice(0, 30)}...`
                : products[0]?.item_name}
            </h5>
          </div>
          <div>
            {products[0]?.product_media?.length > 0 &&
              products[0]?.product_media[0]?.image && (
                <img
                  src={
                    products[0]?.product_media?.length > 0 &&
                    products[0]?.product_media[0]?.image
                  }
                  height={222}
                  className=""
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    dispatch(setPriceRangeFirstLoading(false));
                    navigate(
                      `${`/${categoryName}/${products[0]?.item_name
                        .split("/")
                        .join(" ")}`}`,
                      {
                        state: {
                          path: categoryName,
                          id: categoryId,
                          product: products[0],
                        },
                      }
                    );
                  }}
                />
              )}
            <p>
              {products[0]?.item_description?.length > 120
                ? `${products[0]?.item_description?.slice(0, 120)}...`
                : products[0]?.item_description}
            </p>
          </div>{" "}
        </div>
        <div className="pt-3 fourItem-group-card border border-1 d-flex flex-column  justify-content-center gap-lg-4 gap-xl-0   justify-content-xl-evenly justify-content-xxl-evenly py-1 w-100 px-3  px-xl-2 card-hover">
          <h5 className="fw-bold  style={{marginTop:'30px'}} ">
            Made in Italy:Fashion
          </h5>
          <div>
            <div className="row h-100">
              <div className="col-6 last-section-small-card-product">
                <div className="fourItem-group-card-img-cont">
                  {" "}
                  {products[1]?.product_media?.length > 0 &&
                  products[1]?.product_media[0]?.image ? (
                    <img
                      src={
                        products[1]?.product_media?.length > 0 &&
                        products[1]?.product_media[0]?.image
                      }
                      className=" mt-2 w-100 h-100"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        dispatch(setPriceRangeFirstLoading(false));
                        navigate(
                          `${`/${categoryName}/${products[1]?.item_name
                            .split("/")
                            .join(" ")}`}`,
                          {
                            state: {
                              path: categoryName,
                              id: categoryId,
                              product: products[1],
                            },
                          }
                        );
                      }}
                    />
                  ) : (
                    <img
                      src={productDummyImage}
                      className=" mt-2 w-100 h-100"
                    />
                  )}
                </div>
                <h6
                  className="fw-bold"
                  style={{ marginTop: "30px", cursor: "pointer" }}
                  onClick={() => {
                    dispatch(setPriceRangeFirstLoading(false));
                    navigate(
                      `${`/${categoryName}/${products[1]?.item_name
                        .split("/")
                        .join(" ")}`}`,
                      {
                        state: {
                          path: categoryName,
                          id: categoryId,
                          product: products[1],
                        },
                      }
                    );
                  }}
                >
                  {products[1]?.item_name.length > 20
                    ? `${products[0]?.item_name.slice(0, 20)}...`
                    : products[1]?.item_name}
                </h6>
              </div>
              <div className="col-6 last-section-small-card-product">
                <div className="fourItem-group-card-img-cont">
                  {products[2]?.product_media?.length > 0 &&
                  products[2]?.product_media[0]?.image ? (
                    <img
                      src={
                        products[2]?.product_media?.length > 0 &&
                        products[2]?.product_media[0]?.image
                      }
                      className=" mt-2 w-100 h-100"
                      style={{ marginTop: "30px", cursor: "pointer" }}
                      onClick={() => {
                        dispatch(setPriceRangeFirstLoading(false));
                        navigate(
                          `${`/${categoryName}/${products[2]?.item_name
                            .split("/")
                            .join(" ")}`}`,
                          {
                            state: {
                              path: categoryName,
                              id: categoryId,
                              product: products[2],
                            },
                          }
                        );
                      }}
                    />
                  ) : (
                    <img
                      src={productDummyImage}
                      className=" mt-2 w-100 h-100"
                    />
                  )}
                </div>
                <h6
                  className="fw-bold"
                  style={{ marginTop: "30px", cursor: "pointer" }}
                  onClick={() => {
                    dispatch(setPriceRangeFirstLoading(false));
                    navigate(
                      `${`/${categoryName}/${products[2]?.item_name
                        .split("/")
                        .join(" ")}`}`,
                      {
                        state: {
                          path: categoryName,
                          id: categoryId,
                          product: products[2],
                        },
                      }
                    );
                  }}
                >
                  {products[2]?.item_name.length > 20
                    ? `${products[0]?.item_name.slice(0, 20)}...`
                    : products[2]?.item_name}
                </h6>
              </div>
              <div className="col-6 last-section-small-card-product">
                <div className="fourItem-group-card-img-cont">
                  {products[3]?.product_media?.length > 0 &&
                  products[3]?.product_media[0]?.image ? (
                    <img
                      src={
                        products[3]?.product_media?.length > 0 &&
                        products[3]?.product_media[0]?.image
                      }
                      className=" mt-2 w-100 h-100"
                      style={{ marginTop: "30px", cursor: "pointer" }}
                      onClick={() => {
                        dispatch(setPriceRangeFirstLoading(false));
                        navigate(
                          `${`/${categoryName}/${products[3]?.item_name
                            .split("/")
                            .join(" ")}`}`,
                          {
                            state: {
                              path: categoryName,
                              id: categoryId,
                              product: products[3],
                            },
                          }
                        );
                      }}
                    />
                  ) : (
                    <img
                      src={productDummyImage}
                      className=" mt-2 w-100 h-100"
                    />
                  )}
                </div>
                <h6
                  className="fw-bold"
                  style={{ marginTop: "30px", cursor: "pointer" }}
                  onClick={() => {
                    dispatch(setPriceRangeFirstLoading(false));
                    navigate(
                      `${`/${categoryName}/${products[3]?.item_name
                        .split("/")
                        .join(" ")}`}`,
                      {
                        state: {
                          path: categoryName,
                          id: categoryId,
                          product: products[3],
                        },
                      }
                    );
                  }}
                >
                  {products[3]?.item_name.length > 20
                    ? `${products[0]?.item_name.slice(0, 20)}...`
                    : products[3]?.item_name}
                </h6>
              </div>
              <div className="col-6 last-section-small-card-product">
                <div className="fourItem-group-card-img-cont">
                  {products[4]?.product_media?.length > 0 &&
                  products[4]?.product_media[0]?.image ? (
                    <img
                      src={
                        products[4]?.product_media?.length > 0 &&
                        products[4]?.product_media[0]?.image
                      }
                      className=" mt-2 w-100 h-100"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        dispatch(setPriceRangeFirstLoading(false));
                        navigate(
                          `${`/${categoryName}/${products[4]?.item_name
                            .split("/")
                            .join(" ")}`}`,
                          {
                            state: {
                              path: categoryName,
                              id: categoryId,
                              product: products[4],
                            },
                          }
                        );
                      }}
                    />
                  ) : (
                    <img
                      src={productDummyImage}
                      className=" mt-2 w-100 h-100"
                    />
                  )}
                </div>
                <h6
                  className="fw-bold"
                  style={{ marginTop: "30px", cursor: "pointer" }}
                  onClick={() => {
                    dispatch(setPriceRangeFirstLoading(false));
                    navigate(
                      `${`/${categoryName}/${products[4]?.item_name
                        .split("/")
                        .join(" ")}`}`,
                      {
                        state: {
                          path: categoryName,
                          id: categoryId,
                          product: products[4],
                        },
                      }
                    );
                  }}
                >
                  {" "}
                  {products[4]?.item_name.length > 20
                    ? `${products[0]?.item_name.slice(0, 20)}...`
                    : products[4]?.item_name}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LargeCard;
