import React from "react";
import { BsSliders2Vertical } from "react-icons/bs";

const SideCategoryNavHeader = ({ category }) => {
  return (
    <div className="d-flex px-3  border-bottom  justify-content-between">
      <p>{category}</p>
      <BsSliders2Vertical />
    </div>
  );
};

export default SideCategoryNavHeader;
