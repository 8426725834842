import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../env";

export const GetWithdrawalAddress = createAsyncThunk(
  "GetWithdrawalAddress",
  async (data) => {
    const uservalue = localStorage.getItem("userLogin");
    const tokenVALUE = JSON.parse(uservalue);
    const config = {
      headers: {
        Authorization: `Bearer ${tokenVALUE?.data?.token?.access}`,
      },
      timeout: 30000,
      cancelToken: new axios.CancelToken((cancel) => {
        // Create a cancel token and function
        setTimeout(() => {
          cancel("Request timed out");
        }, 30000);
      }),
    };
    let url = "lookups/get-withdrawal-address/";
    const urlArr = [];
    if (data) {
      for (let [key, val] of Object.entries(data)) {
        console.log(key, val);
        if (key === "crypto" && val) {
          urlArr.push(`crypto_type=${val}`);
        } else {
          urlArr.push(`network_type=${val}`);
        }
      }
    }

    if (urlArr?.length > 0) {
      url += `?${urlArr.join("&")}`;
    }
    console.log(url);
    try {
      const response = await axios.get(`${BASE_URL}${url}`, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
const GetWithdrawalAddressSlice = createSlice({
  name: "GetWithdrawalAddressSlice",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(GetWithdrawalAddress.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetWithdrawalAddress.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(GetWithdrawalAddress.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export default GetWithdrawalAddressSlice.reducer;
