// LinkWalletPopup

import { useEffect, useRef, useState } from "react";
import Modal from "./Modal";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { initializeApiWithHeaders } from "./PostApiRequest";
import { toast } from "react-toastify";
import { BASE_URL } from "../env";
import { GetNetworkType } from "../store/BoostDataSlice/GetNetworkType";
import { GetCryptoType } from "../store/BoostDataSlice/GetCryptoType";

const LinkWalletPopup = ({
  heading,
  title,
  icon,
  show,
  setShow,
  setNetwork,
  setCrypto,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetNetworkType());
    dispatch(GetCryptoType());
  }, []);
  const isCommonModalShow = useSelector(
    (state) => state.loginModal.isCommonModalShown
  );
  const cryptoType = useSelector((state) => state?.cryptoType?.data?.data);
  const networkType = useSelector((state) => state?.networkType?.data?.data);
  const [walletData, setWalletData] = useState(null);

  const withdrawalAddress = useSelector(
    (state) => state?.GetWithdrawalAddress?.data?.data
  );
  console.log("withdrawalAddress", withdrawalAddress);

  useEffect(() => {
    if (!isCommonModalShow) {
      setCrypto("");
      setNetwork("");
    }
  }, [isCommonModalShow]);

  const initialValues = {
    crypto: "USDT",
    network: "USDT-TRC20",
    address: walletData ? walletData?.address : "",
    qr_image: walletData ? walletData?.qr_image : null,
  };

  const userSchema = yup.object().shape({
    crypto: yup.string().required("This field is required"),
    network: yup.string().required("This field is required"),
    address: yup.string().required("This field is required"),
  });

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: userSchema,
    onSubmit: async (values) => {
      console.log(values);
      setWalletData(values);

      initializeApiWithHeaders();
      const token = JSON.parse(localStorage.getItem("userLogin")).data.token
        .access;
      let headers = new Headers();
      headers.append("Authorization", `Bearer ${token}`);
      const formData = new FormData();
      formData.append("crypto", values.crypto);
      formData.append("network", values.network);
      formData.append("address", values.address);
      formData.append("qr_image", values.qr_image);
      try {
        const response = await fetch(
          `${BASE_URL}lookups/add-withdrawal-address/`,
          {
            method: "POST",
            headers,
            body: formData,
          }
        );
        const result = await response.json();
        if (result.meta.status_code === 200) {
          toast.success(result.meta.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setShow(false);
        } else {
          toast.error(result.meta.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        console.log(result);
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <div>
      <Modal
        heading={heading}
        title={title}
        icon={icon}
        width={450}
        show={show}
        setShow={setShow}
      >
        <form onSubmit={handleSubmit} className="d-flex flex-column  gap-4">
          <div>
            <p className="text-start">Please select Crypto?</p>
            <div className="d-flex gap-3 mt-2">
              <select
                className="w-100 bg-white"
                name="crypto"
                value={values.crypto}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="">Select...</option>
                {cryptoType?.length > 0
                  ? cryptoType?.map((crypto) => (
                      <option key={crypto?.id} value={crypto?.id}>
                        {crypto?.crypto_type_code}
                      </option>
                    ))
                  : []}
              </select>
            </div>
            {errors.crypto && touched.crypto && (
              <p
                className="text-start mt-1"
                style={{ color: "red", fontSize: "14px" }}
              >
                {errors.crypto}
              </p>
            )}
          </div>

          <div>
            <p className="text-start">Please select Network?</p>
            <div className="d-flex gap-3 mt-2">
              <select
                className="w-100 bg-white"
                name="network"
                value={values.network}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="">Select...</option>
                {networkType?.length > 0
                  ? networkType?.map((network) => (
                      <option key={network?.id} value={network?.id}>
                        {network?.network_type_code}
                      </option>
                    ))
                  : []}
              </select>
            </div>
            {errors.network && touched.network && (
              <p
                className="text-start mt-1"
                style={{ color: "red", fontSize: "14px" }}
              >
                {errors.network}
              </p>
            )}
          </div>

          <div className="">
            <p className="text-start w-100">Wallet Address</p>
            <div className="d-flex gap-3 mt-2">
              <input
                type="text"
                className="w-100"
                placeholder="Enter Wallet Address"
                name="address"
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            {errors.address && touched.address && (
              <p
                className="text-start mt-1"
                style={{ color: "red", fontSize: "14px" }}
              >
                {errors.address}
              </p>
            )}
          </div>

          <div className="">
            <p className="text-start w-100"> Upload QR Wallet Address</p>
            <label
              className="d-flex align-items-center justify-content-center gap-3 mt-1 py-1 rounded  bg-white text-center w-100"
              style={{
                cursor: "pointer",
                border: "1px dashed gray",
              }}
            >
              <input
                type="file"
                className="d-none"
                name="qr_image"
                onChange={(e) => {
                  setFieldValue("qr_image", e.target.files[0]);
                }}
                onBlur={handleBlur}
              />
              Upload Image
            </label>
            <div className="d-flex justify-content-center  mt-2">
              {withdrawalAddress?.qr_image && (
                <img src={withdrawalAddress?.qr_image} width={300} />
              )}
            </div>
          </div>

          <button
            type="submit"
            className="border-0 rounded py-2 text-white ms-auto me-auto "
            style={{
              background: "var(--primary-color-green)",
              minWidth: "150px",
            }}
          >
            Submit
          </button>
        </form>
      </Modal>
    </div>
  );
};

export default LinkWalletPopup;
