import React, { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { PiCopySimpleLight } from "react-icons/pi";
import qrCode from "../../assets/image/qr.png";
import { BsChevronRight } from "react-icons/bs";
import { GetDepositeAddress } from "../../store/BoostDataSlice/GetdepositeAddress";
import { toast } from "react-toastify";
import InternalTransferOTP from "../categories/boostDataComponent/InternalTransferOTP";

const PaymentFrom = ({ prevFucntion, nextFucntion, prev, next, currentId }) => {
  const cartItems = useSelector((state) => state?.AddToCartSlice?.items);
  const profileData = useSelector(
    (state) => state?.ProfileRecordSlice?.data?.data
  );
  const depositAddress = useSelector(
    (state) => state?.GetDepositeAddress?.data?.data
  );
  const dispatch = useDispatch();
  const [total, setTotal] = useState(0);
  const [crypto, setCrypto] = useState("USDT");
  const [network, setNetwork] = useState("");
  const copyRef = useRef(null);
  const [copy, setCopy] = useState(false);
  const [trandferData, setTrandferData] = useState(null);
  const [internalTransferOTPModal, setInternalTransferOTPModal] =
    useState(false);
  const copyText = async () => {
    if (copyRef.current) {
      const text = copyRef.current.innerText;
      try {
        await navigator.clipboard.writeText(text);
        setCopy(true);
        setTimeout(() => {
          setCopy(false);
        }, 1000);
        console.log("Context coped successfully");
      } catch (error) {
        console.log("Context not coped properly");
      }
    }
  };
  useEffect(() => {
    setCrypto("");
    setNetwork("");
  }, []);
  useEffect(() => {
    if (cartItems?.length > 0) {
      let total = 0;
      cartItems?.forEach((item) => {
        return (total += Number(item.itemTotalPrice));
      });

      setTotal(total);
    }
  }, [cartItems]);
  const initialValues = {
    balance: "",
    transfer: "",
    to: "",
    intTransfer: true,
  };

  const numberRegex = "^[0-9]+$";

  const validationSchema = yup.object().shape({
    balance: yup
      .string()
      .matches(numberRegex, "Postcode must be number.")
      .required("This field is required"),
    transfer: yup.string().required("This field is required"),
    to: yup.string().required("This field is required"),
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    handleReset,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      console.log(values);
      setTrandferData(values);
      setInternalTransferOTPModal(true);
    },
  });

  useEffect(() => {
    if (values.transfer === "E-COMMERCE") {
      values.to = "DRIVE DATA";
    } else if (values.transfer === "DRIVE DATA") {
      values.to = "E-COMMERCE";
    }
  }, [values.transfer]);

  useEffect(() => {
    if (values.to === "E-COMMERCE") {
      values.transfer = "DRIVE DATA";
    } else if (values.to === "DRIVE DATA") {
      values.transfer = "E-COMMERCE";
    }
  }, [values.to]);

  if (internalTransferOTPModal) {
    return (
      <InternalTransferOTP
        data={trandferData}
        nextFucntion={nextFucntion}
        internalTransferOTPModal={internalTransferOTPModal}
        setInternalTransferOTPModal={setInternalTransferOTPModal}
      />
    );
  }

  return (
    <div
      className="h-100 d-flex flex-column justify-content-between"
      style={{ maxWidth: "600px", marginLeft: "auto", marginRight: "auto" }}
    >
      <h4 className="my-3 checkout-from-heading">Enter Your Payment Detail</h4>
      {Number(
        profileData?.ecommerce_account_wallet_summary?.current_balance_amount?.split(
          "."
        )[0]
      ) < total &&
        Number(
          profileData?.ecommerce_account_wallet_summary?.current_balance_amount?.split(
            "."
          )[0]
        ) +
          Number(
            profileData?.boosted_account_wallet_summary?.current_balance_amount?.split(
              "."
            )[0]
          ) >=
          total && (
          <form
            onSubmit={handleSubmit}
            className="flex-fill d-flex flex-column justify-content-between h-100"
          >
            <div>
              <div style={{ height: "80px" }}>
                <input
                  type="text"
                  className="w-100 mt-3"
                  placeholder="Balance"
                  name="balance"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.balance && touched.balance && (
                  <p
                    style={{ color: "red", fontSize: "14px" }}
                    className="mt-1"
                  >
                    {errors.balance}
                  </p>
                )}
              </div>

              <div className="my-3 d-flex flex-column align-items-start">
                <label>Transfer From</label>
                <select
                  className="w-100 bg-white me-3"
                  style={{ cursor: "pointer" }}
                  name="transfer"
                  value={values.transfer}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="E-COMMERCE">E-COMMERCE</option>
                  <option value="DRIVE DATA">DRIVE DATA</option>
                </select>
                {/* {touched.transfer && errors.transfer && (
                  <div className="text-danger">{errors.transfer}</div>
                )} */}
              </div>

              <div className="my-3 d-flex flex-column align-items-start">
                <label>To</label>
                <select
                  className="w-100 bg-white me-3"
                  style={{ cursor: "pointer" }}
                  name="to"
                  value={values.to}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="DRIVE DATA">DRIVE DATA</option>
                  <option value="E-COMMERCE">E-COMMERCE</option>
                </select>
                {/* {touched.to && errors.to && (
                  <div className="text-danger">{errors.to}</div>
                )} */}
              </div>
            </div>
            <div className="w-100 d-flex justify-content-between mb-3">
              <button
                type="button"
                className="border-0 px-3 py-2 rounded "
                style={{
                  color: `${prev ? "var(--primary-color-green)" : "#000"}`,
                }}
                onClick={prevFucntion}
              >
                Previous
              </button>
              <button
                type="submit"
                className="border-0 px-3 py-2 rounded "
                style={{
                  color: `${next ? "var(--primary-color-green)" : "#000"}`,
                }}
              >
                {currentId === 3 ? "Submit" : "Next"}
              </button>
            </div>
          </form>
        )}

      {Number(
        profileData?.ecommerce_account_wallet_summary?.current_balance_amount?.split(
          "."
        )[0]
      ) < total &&
        Number(
          profileData?.ecommerce_account_wallet_summary?.current_balance_amount?.split(
            "."
          )[0]
        ) +
          Number(
            profileData?.boosted_account_wallet_summary?.current_balance_amount?.split(
              "."
            )[0]
          ) <
          total && (
          <div className="d-flex flex-column gap-4">
            <div>
              <p>Please select Crypto?</p>
              <div className="d-flex gap-3 mt-2">
                <select
                  className="w-100 bg-white"
                  value={"USDT"}
                  onChange={(e) => {
                    setCrypto(e.target.value);
                    if (e.target.value === "USDT") {
                      setNetwork("USDT-TRC20");
                    }
                  }}
                >
                  <option value="">Select Crypto</option>
                  <option value="USDT">USDT</option>
                </select>
              </div>
            </div>

            <div>
              <p>Please select Network?</p>
              <div className="d-flex gap-3 mt-2">
                <select
                  className="w-100 bg-white"
                  onChange={(e) => {
                    setNetwork(e.target.value);
                  }}
                  value={"USDT-TRC20"}
                >
                  <option value="">Select Network</option>
                  <option value="USDT-TRC20">USDT-TRC20</option>
                </select>
              </div>
            </div>

            <>
              <div className="d-flex justify-content-center ">
                <img src={qrCode} width={200} />
              </div>

              <div className="d-flex gap-2 align-items-center ">
                <p>Deposit Address</p>
                <BsChevronRight />
              </div>
              <div className=" position-relative  d-flex justify-content-between  align-items-center">
                <p ref={copyRef}>
                  {depositAddress?.address ? depositAddress?.address : ""}
                </p>{" "}
                <div
                  onClick={() => {
                    copyText();
                  }}
                >
                  <PiCopySimpleLight
                    className="fs-4 me-3 "
                    style={{ cursor: "pointer" }}
                  />
                </div>
                {copy && (
                  <div
                    className="px-2 position-absolute   bg-white shadow bg-light rounded "
                    style={{
                      right: "-45px",
                      color: "var(--primary-color-green)",
                    }}
                  >
                    Copied
                  </div>
                )}
              </div>
            </>

            <div className="w-100 d-flex justify-content-between mb-3">
              <button
                type="button"
                className="border-0 px-3 py-2 rounded "
                style={{
                  color: `${prev ? "var(--primary-color-green)" : "#000"}`,
                }}
                onClick={prevFucntion}
              >
                Previous
              </button>
              <button
                className="border-0 px-3 py-2 rounded "
                style={{
                  color: `${next ? "var(--primary-color-green)" : "#000"}`,
                }}
                onClick={async () => {
                  if (
                    Number(
                      profileData?.ecommerce_account_wallet_summary?.current_balance_amount?.split(
                        "."
                      )[0]
                    ) >= total
                  ) {
                    nextFucntion();
                  } else {
                    const res = await dispatch(GetDepositeAddress());
                    if (res?.payload?.meta?.status_code === 200) {
                      toast.success(res.payload.meta.message);
                    } else {
                      toast.error(res.payload.meta.message);
                    }
                  }
                }}
              >
                {currentId === 3 ? "Submit" : "Next"}
              </button>
            </div>
          </div>
        )}
    </div>
  );
};

export default PaymentFrom;
