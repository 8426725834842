import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../env";

export const GetProductByCateg = createAsyncThunk(
  "GetProductByCateg",
  async (id) => {
    const uservalue = localStorage.getItem("userLogin");
    const tokenVALUE = JSON.parse(uservalue);
    const config = {
      timeout: 5000,
      cancelToken: new axios.CancelToken((cancel) => {
        // Create a cancel token and function
        setTimeout(() => {
          cancel("Request timed out");
        }, 5000);
      }),
    };
    try {
      const response = await axios.get(
        `${BASE_URL}catalogue/get-products/${id}/`,
        config
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
const GetProductByCategSlice = createSlice({
  name: "GetProductByCateg",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(GetProductByCateg.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetProductByCateg.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(GetProductByCateg.rejected, (state, action) => {
      // console.log(action.error.message);
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export default GetProductByCategSlice.reducer;
