import React, { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import "./commonCompStyle/BottomFullModal.css";
import { useSelector } from "react-redux";

const BottomFullModal = ({ children, heading, show, setShow }) => {
  return (
    <div className="position-relative  text-center ">
      <div className={`${show ? "open" : ""} bottomModalOverlay`}>
        <div
          className={`${
            show ? "open" : ""
          } bottomModalCard rounded pt-3  pb-5 overflow-x-hidden`}
          style={{ maxWidth: `${100}%` }}
        >
          <div className="d-flex justify-content-between px-2 ">
            <h4
              className="fs-5 fw-bolder ms-3 mb-3"
              style={{ color: "var(--primary-color-green)" }}
            >
              {heading}
            </h4>
            <RxCross2
              onClick={() => {
                setShow(false);
              }}
              className="me-2 text-danger"
              style={{
                fontSize: "25px",
                cursor: "pointer",
                color: "var(--primary-color-green)",
              }}
            />
          </div>

          <div className="modal-children-cont text-dark over">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default BottomFullModal;
