import React from "react";
import { RotatingLines } from "react-loader-spinner";
export const LoaderAPICalls = () => {
  return (
    <>
      <div
        className=""
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100px",
          backgroundColor: "#4c4c4c",
          height: "10vh",
        }}
      >
        <RotatingLines
          strokeColor="white"
          strokeWidth="5"
          animationDuration="0.75"
          width="30"
          visible={true}
        />
        <p style={{ color: "white" }}>Loading</p>
      </div>
    </>
  );
};
