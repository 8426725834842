import React from "react";
import { BiLogoTelegram } from "react-icons/bi";
import { IoLogoWhatsapp } from "react-icons/io";
import { Link } from "react-router-dom";
const waNum = import.meta.env.VITE_REACT_APP_PHONE_NUM;

const WhatsAppAndLiveChat = () => {
  const chatIconsStyle = {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    zIndex: 1000, // Adjust the z-index if necessary
  };
  const iconContainerStyle = {
    width: "50px",
    height: "50px",
    cursor: "pointer",
    // border: "1px solid #939393",
  };
  const handleTelegramRedirect = () => {
    const telegramUsername = "GIO73738";
    // Construct the Telegram URL
    const telegramURL = `https://t.me/${telegramUsername}`;
    // Open the Telegram chat URL in a new tab
    window.open(telegramURL, "_blank");
  };

  return (
    <>
      <div style={chatIconsStyle}>
        <div
          onClick={handleTelegramRedirect}
          title="Telegram Chat"
          // style={iconContainerStyle}
          className="WaAndLiveChat d-flex align-items-center justify-content-center bg-white text-primary border rounded-circle mb-4 chat-btns"
        >
          <BiLogoTelegram style={{ cursor: "pointer" }} className="fs-2" />
        </div>

        <Link
          title="What's App"
          // style={iconContainerStyle}
          className="WaAndLiveChat d-flex align-items-center justify-content-center bg-white border rounded-circle mb-2 chat-btns"
          to={`https://wa.me/${waNum}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <IoLogoWhatsapp
            style={{ color: "#41D251", cursor: "pointer" }}
            className="fs-2"
          />
        </Link>
      </div>
    </>
  );
};

export default WhatsAppAndLiveChat;
