import React from "react";
import { RxCross2 } from "react-icons/rx";
import "./commonCompStyle/Modal.css";
const Modal = ({ children, show, setShow, heading, scroll }) => {
  return (
    <div className=" text-center ">
      <div className={`${show ? "open" : ""} modalOverlay`}>
        <div
          className={`${
            show ? "open" : ""
          } modalCard rounded pt-3  pb-4 category-filter`}
          style={{
            maxWidth: `${450}px `,
            overflowY: `${scroll && "scroll"}`,
            maxHeight: "600px",
          }}
        >
          <div className="d-flex justify-content-between px-2 ">
            <h4
              className="fs-5 fw-bolder ms-3 mb-3"
              style={{ color: "var(--primary-color-green)" }}
            >
              {heading}
            </h4>
            <div
              onClick={() => {
                setShow(false);
              }}
            >
              <RxCross2
                className="me-2 text-danger"
                style={{
                  fontSize: "25px",
                  cursor: "pointer",
                  color: "var(--primary-color-green)",
                }}
              />
            </div>
          </div>

          <div className=" mx-5 text-dark">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
