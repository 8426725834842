import React, { useEffect, useRef } from "react";
import Layout from "../../common/layout/Layout";
import HelmetComp from "../../common/HelmetComp";
import HeroSlider from "../../common/HeroSlider";
import ListProductSlider from "../../components/homeComponent/ListProductSlider";
import CardAutoplaySlider from "../../components/homeComponent/CardAutoplaySlider";
import { useDispatch, useSelector } from "react-redux";
import { showNavabr } from "../../store/uiSlices/NavSlice";
import { useLocation } from "react-router-dom";
import BookSlider from "../../components/categories/BookSlider";
import { CategoryProduct } from "../../store/Category/CategoryProduct";
import watchImage from "../../assets/image/slider/156c27224ff4389c0bbe6ca8e3daeb7c.png";
import chairImage from "../../assets/image/slider/c1c7140f4146c8d083042e49465f0c62.png";
import headPhones from "../../assets/image/slider/a306c6a84a161de39e219011a9315d20.png";
import axios from "axios";
import { BASE_URL } from "../../env";
import { HomeCategory } from "../../store/Category/HomeCategorySlice";
import CategorySix from "../../components/homeComponent/CategorySix";
import CategorySeven from "../../components/homeComponent/CategorySeven";
import CategoryEight from "../../components/homeComponent/CategoryEight";
import CategoryOne from "../../components/homeComponent/CategoryOne";
import CategoryTwo from "../../components/homeComponent/CategoryTwo";
import CategoryFour from "../../components/homeComponent/CategoryFour";
import CategoryFive from "../../components/homeComponent/CategoryFive";
import SearchProductCategory from "../../components/homeComponent/SearchProductCategory";
function Home() {
  const dispatch = useDispatch();
  const homeCategory = useSelector((state) =>
    state?.HomeCategory?.data?.data ? state?.HomeCategory?.data?.data : []
  );
  console.log(homeCategory);
  useEffect(() => {
    dispatch(CategoryProduct());
    dispatch(HomeCategory());
  }, []);
  const fetchDailyData = async () => {
    try {
      const uservalue = localStorage.getItem("userLogin");
      const tokenVALUE = JSON.parse(uservalue);
      const config = {
        headers: {
          Authorization: `Bearer ${tokenVALUE?.data?.token?.access}`,
        },
      };

      const response = await axios.get(
        `${BASE_URL}boost-data/calculate-working-days/`,
        config
      );
      console.log("response response", response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchDataAndSetTimeout = () => {
      fetchDailyData();
      const now = new Date();
      const targetTime = new Date(now);
      targetTime.setHours(12, 0, 0, 0); // Set the time for the daily call (12 PM)
      if (now > targetTime) {
        targetTime.setDate(targetTime.getDate() + 1); // Move to the next day
      }
      const timeoutId = setTimeout(fetchDataAndSetTimeout, targetTime - now);
      // Store the timeoutId in the component's state or a ref if needed
    };

    // Calculate the time until the next 12 PM
    const now = new Date();
    const targetTime = new Date(now);
    targetTime.setHours(12, 0, 0, 0);
    if (now > targetTime) {
      targetTime.setDate(targetTime.getDate() + 1); // Move to the next day
    }
    const timeoutId = setTimeout(fetchDataAndSetTimeout, targetTime - now);
    // Cleanup when the component unmounts
    return () => clearTimeout(timeoutId);
  }, []);

  const images = [
    { img: watchImage },
    { img: chairImage },
    { img: headPhones },
  ];
  const isNavbarVisible = useSelector((state) => state.nav.isNavbarVisible);
  const heroRef = useRef(null);
  useEffect(() => {
    const option = {
      root: null,
      threshold: 0,
    };
    const heroObsrver = (enteries) => {
      const entery = enteries[0];
      if (entery.isIntersecting) {
        dispatch(showNavabr(true));
      } else {
        dispatch(showNavabr(false));
      }
    };
    const observer = new IntersectionObserver(heroObsrver, option);
    if (heroRef.current) {
      observer.observe(heroRef.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);
  const searchProduct = useSelector(
    (state) => state?.searchProduct?.data?.data
  );
  const stateSearchProduct = useSelector(
    (state) => state?.searchProduct?.searchProduct
  );
  console.log("stateSearchProduct", stateSearchProduct);
  return (
    <Layout>
      <HelmetComp title={"Home"} />
      <div ref={heroRef}>
        <HeroSlider images={images} />
      </div>
      {searchProduct?.length > 0 && stateSearchProduct !== "" ? (
        <>
          <SearchProductCategory
            searchProduct={searchProduct}
            stateSearchProduct={stateSearchProduct}
          />
        </>
      ) : (
        <>
          <CategoryOne heading={"Electronic"} homeCategory={homeCategory} />
          <CategoryTwo homeCategory={homeCategory} />
          <BookSlider homeCategory={homeCategory} />
          <CategoryFour
            heading={"Winter Collection"}
            homeCategory={homeCategory}
          />
          <CategoryFive homeCategory={homeCategory} />
          <CategorySix homeCategory={homeCategory} />
          <CategorySeven homeCategory={homeCategory} />
          <CategoryEight homeCategory={homeCategory} />
          <ListProductSlider homeCategory={homeCategory} />
        </>
      )}

      <CardAutoplaySlider />
    </Layout>
  );
}

export default Home;
