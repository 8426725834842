import Modal from "./Modal";
import React from "react";
import { AiOutlineWarning } from "react-icons/ai";
import { loginModalHide } from "../store/uiSlices/LoginModalSlice";
import { useDispatch } from "react-redux";

const CurrentlyLoggedInComponent = ({
  showCurrentlyLoggedIn,
  setShowCurrentlyLoggedIn,
}) => {
  const dispatch = useDispatch();
  return (
    <>
      <Modal
        heading={"Data Submission"}
        show={showCurrentlyLoggedIn}
        setShow={setShowCurrentlyLoggedIn}
      >
        <div className="my-5 d-flex align-items-center gap-2">
          <AiOutlineWarning className="fs-3" style={{ color: "orange" }} />{" "}
          <h3 className="fs-5" style={{ color: "grey" }}>
            You are not currently logged in.
          </h3>
        </div>
        <div>
          <button
            className="text-white px-4 py-1 rounded border-0"
            style={{
              background: "var(--primary-color-green)",
            }}
            onClick={() => {
              dispatch(loginModalHide(true));
              setShowCurrentlyLoggedIn(false);
            }}
          >
            Login
          </button>
        </div>
      </Modal>
    </>
  );
};
export default CurrentlyLoggedInComponent;
