import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { initializeApiWithHeaders } from "./PostApiRequest";
import { BoostDataUser } from "../store/BoostDataSlice/GetBoostDataUserSlice";
import { ProductSets } from "../store/BoostDataSlice/GetProductSetsSlice";
import { BalanceAndProfit } from "../store/BoostDataSlice/GetBalanceandProfitSlice";
import { BASE_URL } from "../env";
import { AllSetsRecord } from "../store/BoostDataSlice/GetAllSetSlice";
import productDummyImage from "../assets/image/product.jpeg";
import {
  selectIncorrectType,
  setIncorrectType,
} from "../store/uiSlices/IncorrectTypeSlice";
import { selectAddTask, setAddTask } from "../store/uiSlices/AddTaskSlice";
import {
  selectPreviousSubmission,
  setPreviousSubmission,
} from "../store/uiSlices/PreviousSubmissionSlice";

const StartingModal = ({
  showStartingModal,
  setShowStartingModal,
  amount,
  setShowCongratulations,
  data,
  recordId,
}) => {
  const dispatch = useDispatch();
  const addTask = useSelector(selectAddTask);
  const incorrectType = useSelector(selectIncorrectType);
  const previousSubmissionModal = useSelector(selectPreviousSubmission);

  const productSets = useSelector(
    (state) => state?.GetProductSetsSlice?.data?.data
  );
  useEffect(() => {
    dispatch(BoostDataUser());
  }, []);

  const handleError = (error) => {
    if (error.response) {
      if (error.response.status === 400) {
      }
    } else if (error.request) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
    }
  };

  const submitNow = async () => {
    setShowCongratulations(false);
    const token = JSON.parse(localStorage.getItem("userLogin")).data.token
      .access;
    let headers = new Headers();
    headers.append("Authorization", `Bearer ${token}`);
    let formData = new FormData();
    formData.append("status", "Completed");
    try {
      initializeApiWithHeaders();
      const response = await fetch(
        `${BASE_URL}boost-data/update-record/${recordId}/`,
        {
          method: "PUT",
          headers: headers,
          body: formData,
        }
      );

      const res = await response.json();
      if (res.meta.status_code === 200) {
        toast.success(res?.meta?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setShowStartingModal(false);

        dispatch(AllSetsRecord());
        dispatch(BalanceAndProfit());
        if (data?.submitted_task === data?.assigned_tasks) {
          setShowCongratulations(true);
        }
        if (amount !== "") {
          dispatch(ProductSets(amount));
        }
        dispatch(setAddTask(false));
        dispatch(setIncorrectType(false));
        dispatch(setPreviousSubmission(false));
      } else {
        toast.error(res?.meta?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      handleError(error);
    }
  };
  const timestamp = productSets?.created;
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${
    day < 10 ? "0" : ""
  }${day}`;
  const formattedTime = `${hours < 10 ? "0" : ""}${hours}:${
    minutes < 10 ? "0" : ""
  }${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;

  const formattedTimestamp = `${formattedDate} ${formattedTime}`;
  console.log("product sets", productSets);
  const defaultImages = productSets?.boost_product?.product_media?.filter(
    (media) => media.default_image === true
  );
  console.log("productSets", productSets);

  return (
    <div>
      <div className="w-100 d-flex align-items-center  justify-content-center py-1 rounded-2 ">
        <>
          {incorrectType && (
            <Modal
              heading={"Data Submission"}
              show={showStartingModal}
              setShow={setShowStartingModal}
            >
              <div>
                <p className="fw-bold">No product available at the moment</p>
              </div>
              <button
                className="text-white border-0 px-5 py-1 rounded mt-3"
                style={{ background: "var(--primary-color-green)" }}
                onClick={() => {
                  setShowStartingModal(false);
                }}
              >
                Ok
              </button>
            </Modal>
          )}
          {previousSubmissionModal && (
            <Modal
              heading={"Data Submission"}
              show={showStartingModal}
              setShow={setShowStartingModal}
            >
              <div>
                <p className="fw-bold">
                  You cannot submit your new task until you have completed your previous pending task. !
                </p>
              </div>

              <button
                className=" text-white border-0 px-5 py-1 rounded mt-3"
                style={{ background: "var(--primary-color-green)" }}
                onClick={() => {
                  setShowStartingModal(false);
                }}
              >
                Confirm
              </button>
            </Modal>
          )}{" "}
          <div>
            {addTask && (
              <Modal
                heading={"Data Submission"}
                show={showStartingModal}
                setShow={setShowStartingModal}
              >
                <div
                  className="overflow-y-scroll category-filter"
                  style={{ maxHeight: "800px" }}
                >
                  {productSets?.boost_product ? (
                    <div>
                      {defaultImages && defaultImages.length > 0 ? (
                        defaultImages.map((media, index) => (
                          <div key={index}>
                            <img
                              src={media.image}
                              alt="media"
                              height={100}
                              width={100}
                            />
                          </div>
                        ))
                      ) : (
                        <div>
                          <img
                            src={productDummyImage}
                            alt="media"
                            height={100}
                            width={100}
                          />
                        </div>
                      )}

                      <p className="fw-bold">
                        {productSets?.boost_product?.item_name?.length > 50
                          ? `${productSets?.boost_product?.item_name.slice(
                              0,
                              49
                            )}...`
                          : productSets?.boost_product?.item_name}
                      </p>
                    </div>
                  ) : (
                    <div>
                      {productSets?.product && productSets.product[0] && (
                        <div>
                          {productSets.product[0]?.product_media &&
                            productSets.product[0].product_media.length > 0 && (
                              <img
                                key={0}
                                src={
                                  productSets.product[0].product_media[0]
                                    .default_image === true
                                    ? productSets.product[0].product_media[0]
                                        .image
                                    : productDummyImage
                                }
                                height={100}
                                width={100}
                              />
                            )}
                        </div>
                      )}

                      {productSets?.product &&
                        productSets?.product.map((product_name) => (
                          <p className="fw-bold">
                            {product_name?.item_name?.length > 50
                              ? `${product_name?.item_name.slice(0, 49)}...`
                              : product_name?.item_name}
                          </p>
                        ))}
                    </div>
                  )}

                  <div className="d-flex justify-content-between  align-items-center my-3">
                    <div className="text-center">
                      <p
                        className=""
                        style={{ color: "#777", fontSize: "12px" }}
                      >
                        Data Value
                      </p>
                      <p className="fw-semibold">
                        USDT {""}
                        <span>
                          {productSets?.data_value
                            ? productSets?.data_value
                            : "-"}
                        </span>
                      </p>
                    </div>
                    <div className="text-center">
                      <p
                        className=""
                        style={{ color: "#777", fontSize: "12px" }}
                      >
                        Commission Value
                      </p>
                      <p className="fw-semibold">
                        USDT {""}
                        <span>
                          {productSets?.commission_value
                            ? productSets?.commission_value
                            : "-"}
                        </span>
                      </p>
                    </div>
                  </div>

                  <div
                    style={{
                      borderBottom: "1px solid var(--border-color-gray)",
                      borderTop: "1px solid var(--border-color-gray)",
                    }}
                  >
                    <div
                      style={{
                        borderBottom: "1px solid var(--border-color-gray)",
                      }}
                      className="d-flex align-items-center  justify-content-between my-3"
                    >
                      <p style={{ color: "#777", fontSize: "12px" }}>
                        Creation time
                      </p>
                      <p
                        style={{
                          color: "#777",
                          fontSize: "12px",
                          fontWeight: "700",
                        }}
                      >
                        {formattedTimestamp ? formattedTimestamp : "-"}
                      </p>
                    </div>{" "}
                    <div className="d-flex align-items-center  justify-content-between my-3">
                      <p style={{ color: "#777", fontSize: "12px" }}>Data No</p>
                      <>
                        <p
                          style={{
                            color: "#777",
                            fontSize: "12px",
                            fontWeight: "700",
                          }}
                        >
                          {productSets?.product?.[0]?.data_number
                            ? productSets?.product?.[0]?.data_number
                            : productSets?.boost_product?.data_number}
                        </p>
                      </>
                    </div>
                  </div>

                  <button
                    className=" text-white border-0 px-5 py-1 rounded mt-3"
                    style={{ background: "var(--primary-color-green)" }}
                    onClick={() => {
                      submitNow();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </Modal>
            )}
          </div>
        </>
      </div>
    </div>
  );
};

export default StartingModal;
