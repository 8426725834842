import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../env";

export const TrackOrder = createAsyncThunk(
  "TrackOrder",
  async (orderTrackingId, { rejectWithValue }) => {
    const uservalue = localStorage.getItem("userLogin");
    const tokenVALUE = JSON.parse(uservalue);
    const config = {
      headers: {
        Authorization: `Bearer ${tokenVALUE?.data?.token?.access}`,
      },
      timeout: 30000,
      cancelToken: new axios.CancelToken((cancel) => {
        // Create a cancel token and function
        setTimeout(() => {
          cancel("Request timed out");
        }, 30000);
      }),
    };

    try {
      const response = await axios.get(
        `${BASE_URL}order/track-order/?order_tracking_id=${orderTrackingId}`,
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

const TrackOrderSlice = createSlice({
  name: "TrackOrder",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(TrackOrder.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(TrackOrder.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(TrackOrder.rejected, (state, action) => {
      // console.log(action.error.message);
      state.isLoading = false;
      state.isError = true;
      state.error = action.error.message;
    });
  },
});

export default TrackOrderSlice.reducer;
