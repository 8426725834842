import React from "react";
import illustration from "../assets/image/link_page/privacyPolicy.jpg";
import IlustrationPagedummyComp from "../common/IlustrationPagedummyComp";
const PrivacyPolicyComp = () => {
  return (
    <>
      <IlustrationPagedummyComp
        illustration={illustration}
        pageTitle={"Privacy Policy"}
        heading={
          ""
        }
        description={
            <>
                <div>
                    {" "}
                    <span style={{fontWeight: "600", fontSize: "15px"}}>
                {" "}
                        Conditions of Use:
              </span>{" "}
                    Welcome to GIO. GIO Services LLC and/or its affiliates ("Get It On") provide website features and
                    other products and services to you when you visit or shop at https://get-it-on.app/, use GIO
                    products or services, use GIO applications for mobile, or use software provided by GIO in connection
                    with any of the foregoing (collectively, "GIO Services"). By using the GIO Services, you agree, on
                    behalf of yourself and all members of your household and others who use any Service under your
                    account, to the following conditions.
                </div>
                <div>
                    {" "}
                    <span style={{fontWeight: "600", fontSize: "15px"}}>
                {" "}
                        Please read these conditions carefully
              </span>{" "}
                    We offer a wide range of GIO Services, and sometimes additional terms may apply. When you use an GIO
                    Service (for example, Your Profile, Gift Cards, GIO Video, Your Media Library, GIO devices, or GIO
                    applications) you also will be subject to the guidelines, terms and agreements applicable to that
                    GIO Service ("Service Terms"). If these Conditions of Use are inconsistent with the Service Terms,
                    those Service Terms will control.
                </div>
                <div>
              <span style={{fontWeight: "600", fontSize: "15px"}}>
                PRIVACY:
              </span>{" "}
                    Please review our Privacy Notice, which also governs your use of GIO Services, to understand our
                    practices.
                </div>

            </>
        }
      />
            <div className='container'>
                <div className="row">
                    <div className="col-md-6">
              <span style={{fontWeight: "600", fontSize: "15px"}}>
                ELECTRONIC COMMUNICATIONS:
              </span>{" "}
                        When you use GIO Services, or send e-mails, text messages, and other communications from your
                        desktop or mobile device to us, you may be communicating with us electronically. You consent to
                        receive communications from us electronically, such as e-mails, texts, mobile push notices, or
                        notices and messages on this site or through the other GIO Services, such as our Message Center,
                        and
                        you can retain copies of these communications for your records. You agree that all agreements,
                        notices, disclosures, and other communications that we provide to you electronically satisfy any
                        legal requirement that such communications be in writing.
                    </div>

                <div className="col-md-6">
              <span style={{fontWeight: "600", fontSize: "15px"}}>
                COPYRIGHT:
              </span>{" "}
                    All content included in or made available through any GIO Service, such as text, graphics, logos,
                    button icons, images, audio clips, digital downloads, data compilations, and software is the
                    property of Amazon or its content suppliers and protected by United States and international
                    copyright laws. The compilation of all content included in or made available through any GIO Service
                    is the exclusive property of GIO and protected by U.K. and international copyright laws.
                </div>
                </div>
                <div className="row">
                <div className="col-md-6">
                    <span style={{fontWeight: "600", fontSize: "15px"}}>
                REVIEWS, COMMENTS, COMMUNICATIONS, AND OTHER CONTENT:
              </span>{" "}
                    You may post reviews, comments, photos, videos, and other content; send e-cards and other
                    communications; and submit suggestions, ideas, comments, questions, or other information, so long as
                    the content is not illegal, obscene, threatening, defamatory, invasive of privacy, infringing of
                    intellectual property rights (including publicity rights), or otherwise injurious to third parties
                    or objectionable, and does not consist of or contain software viruses, political campaigning,
                    commercial solicitation, chain letters, mass mailings, or any form of "spam" or unsolicited
                    commercial electronic messages. You may not use a false e-mail address, impersonate any person or
                    entity, or otherwise mislead as to the origin of a card or other content. GIO reserves the right
                    (but not the obligation) to remove or edit such content, but does not regularly review posted
                    content.
                    If you do post content or submit material, and unless we indicate otherwise, you grant GIO a
                    nonexclusive, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce,
                    modify, adapt, publish, perform, translate, create derivative works from, distribute, and display
                    such content throughout the world in any media. You grant GIO and sublicensees the right to use the
                    name that you submit in connection with such content, if they choose. You represent and warrant that
                    you own or otherwise control all of the rights to the content that you post; that the content is
                    accurate; that use of the content you supply does not violate this policy and will not cause injury
                    to any person or entity; and that you will indemnify GIO for all claims resulting from content you
                    supply. GIO has the right but not the obligation to monitor and edit or remove any activity or
                    content. GIO takes no responsibility and assumes no liability for any content posted by you or any
                    third party.
                </div>
                <div className="col-md-6">
                    {" "}
                    <span style={{fontWeight: "600", fontSize: "15px"}}>
                {" "}
                        LICENSE AND ACCESS:
              </span>{" "}
                    Subject to your compliance with these Conditions of Use and any Service Terms, and your payment of
                    any applicable fees, GIO or its content providers grant you a limited, non-exclusive,
                    non-transferable, non-sublicensable license to access and make personal and non-commercial use of
                    the GIO Services. This license does not include any resale or commercial use of any GIO Service, or
                    its contents; any collection and use of any product listings, descriptions, or prices; any
                    derivative use of any GIO Service or its contents; any downloading, copying, or other use of account
                    information for the benefit of any third party; or any use of data mining, robots, or similar data
                    gathering and extraction tools. All rights not expressly granted to you in these Conditions of Use
                    or any Service Terms are reserved and retained by GIO or its licensors, suppliers, publishers,
                    rightsholders, or other content providers. No GIO Service, nor any part of any GIO Service, may be
                    reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial
                    purpose without express written consent of GIO. You may not frame or utilize framing techniques to
                    enclose any trademark, logo, or other proprietary information (including images, text, page layout,
                    or form) of GIO without express written consent. You may not use any meta tags or any other "hidden
                    text" utilizing GIO's name or trademarks without the express written consent of GIO. You may not
                    misuse the GIO Services. You may use the GIO Services only as permitted by law. The licenses granted
                    by GIO terminate if you do not comply with these Conditions of Use or any Service Terms.
                </div>
                </div>
                <div className="row">
                <div className="col-md-6">
              <span style={{fontWeight: "600", fontSize: "15px"}}>
                {" "}
                  YOUR ACCOUNT:
              </span>{" "}
                    You may need your own GIO account to use certain GIO Services, and you may be required to be logged
                    in to the account and have a valid payment method associated with it. If there is a problem charging
                    your selected payment method, we may charge any other valid payment method associated with your
                    account. Visit Your Payments to manage your payment options. You are responsible for maintaining the
                    confidentiality of your account and password and for restricting access to your account, and you
                    agree to accept responsibility for all activities that occur under your account or password. GIO
                    does sell products for children, but it sells them to adults, who can purchase with a credit card or
                    other permitted payment method. If you are under 18, you may use the GIO Services only with
                    involvement of a parent or guardian. Parents and guardians may create profiles for teenagers in
                    their GIO Household. Alcohol listings on GIO are intended for adults. You must be at least 21 years
                    of age to purchase alcohol, or use any site functionality related to alcohol. GIO reserves the right
                    to refuse service, terminate accounts, terminate your rights to use GIO Services, remove or edit
                    content, or cancel orders in its sole discretion.
                </div>
                <div className="col-md-6">
                <span style={{fontWeight: "600", fontSize: "15px"}}>
              PRICING:
              </span>{" "}
                          "List Price" means the suggested retail price of a product as provided by a manufacturer,
                        supplier,
                        or seller. We regularly check List Prices against prices recently found on GIO and other
                        retailers.
                        Certain products may have a "Was Price" displayed, which is determined using recent price
                        history of
                        the product on GIO.
                        With respect to items sold by GIO, we cannot confirm the price of an item until you order.
                        Despite
                        our best efforts, a small number of the items in our catalog may be mispriced. If the correct
                        price
                        of an item sold by GIO is higher than our stated price, we will, at our discretion, either
                        contact
                        you for instructions before shipping or cancel your order and notify you of such cancellation.
                        Other
                        merchants may follow different policies in the event of a mispriced item.
                        We generally do not charge your credit card until after your order has entered the shipping
                        process
                        or, for digital products, until we make the digital product available to you.
                    <div style={{fontWeight: "600", fontSize: "15px"}}>
              Request Your Personal Information:
              </div>{" "}
                    You can submit a data request in addition to accessing a lot of your personal information in
                    Your
                    Account.

                </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
              <span style={{fontWeight: "600", fontSize: "15px"}}>
                INTELLECTUAL PROPERTY COMPLAINTS:
              </span>{" "}
                        GIO respects the intellectual property of others. If you believe that your intellectual property
                        rights are being infringed, please follow our Notice and Procedure for Making Claims of
                        Copyright
                        Infringement.
                    </div>
                    <div className="col-md-6">
              <span style={{fontWeight: "600", fontSize: "15px"}}>
                RISK OF LOSS:
              </span>{" "}
                        All purchases of physical items from GIO are made pursuant to a shipment contract. This means
                        that
                        the risk of loss and title for such items pass to you upon our delivery to the carrier.
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
              <span style={{fontWeight: "600", fontSize: "15px"}}>
              RETURNS, REFUNDS AND TITLE:
              </span>{" "}
                        GIO does not take title to returned items until the item arrives at our fulfillment center. At
                        our
                        discretion, a refund may be issued without requiring a return. In this situation, GIO does not
                        take
                        title to the refunded item. For more information about our returns and refunds, please see our
                        Returns Center.
                    </div>
                    <div className="col-md-6">
              <span style={{fontWeight: "600", fontSize: "15px"}}>
              PRODUCT DESCRIPTIONS:
              </span>{" "}
                        GIO attempts to be as accurate as possible. However, GIO does not warrant that product
                        descriptions
                        or other content of any GIO Service is accurate, complete, reliable, current, or error-free. If
                        a
                        product offered by GIO itself is not as described, your sole remedy is to return it in unused
                        condition.
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                           <span style={{fontWeight: "600", fontSize: "15px"}}>
                        SANCTIONS AND EXPORT POLICY:
                    </span>{" "}
                    You may not use any GIO Service if you are the subject of U.K. sanctions or of sanctions
                    consistent
                    with U.S. law imposed by the governments of the country where you are using GIO Services. You
                    must
                    comply with all U.K. or other export and re-export restrictions that may apply to goods,
                    software
                    (including GIO Software), technology, and services.

                    </div>
                    <div className="col-md-6">
              <span style={{fontWeight: "600", fontSize: "15px"}}>
              APP PERMISSIONS:
              </span>{" "}
                        When you use apps created by GIO, such as the GIO App or Kindle App, you may grant certain
                        permissions to us for your device. Most mobile devices provide you with information about these
                        permissions. To learn more about these permissions, click here.
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                    <span style={{fontWeight: "600", fontSize: "15px"}}>
                        TRADEMARKS
                    </span>{" "}
                  Click here to see a non-exhaustive list of GIO trademarks. In addition, graphics, logos, page
                    headers, button icons, scripts, and service names included in or made available through any GIO
                    Service are trademarks or trade dress of GIO in the U.S., UK and other countries. GIO's trademarks
                    and trade dress may not be used in connection with any product or service that is not GIO's, in any
                    manner that is likely to cause confusion among customers, or in any manner that disparages or
                    discredits GIO. All other trademarks not owned by GIO that appear in any GIO Service are the
                    property of their respective owners, who may or may not be affiliated with, connected to, or
                    sponsored by GIO.

                    </div>

                    <div className="col-md-6">
              <span style={{fontWeight: "600", fontSize: "15px"}}>
              OTHER BUSINESSES:
              </span>{" "}
                        Parties other than GIO operate stores, provide services or software, or sell product lines
                        through
                        the GIO Services. In addition, we provide links to the sites of affiliated companies and certain
                        other businesses. If you purchase any of the products or services offered by these businesses or
                        individuals, you are purchasing directly from those third parties, not from GIO. We are not
                        responsible for examining or evaluating, and we do not warrant, the offerings of any of these
                        businesses or individuals (including the content of their Web sites). GIO does not assume any
                        responsibility or liability for the actions, product, and content of all these and any other
                        third
                        parties. You should carefully review their privacy statements and other conditions of use.
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
              <span style={{fontWeight: "600", fontSize: "15px"}}>
              DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY:
              </span>{" "}
                        THE GIO SERVICES AND ALL INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) AND
                        OTHER
                        SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE GIO SERVICES ARE PROVIDED BY
                        GIO
                        ON AN "AS IS" AND "AS AVAILABLE" BASIS, UNLESS OTHERWISE SPECIFIED IN WRITING. GIO MAKES NO
                        REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE GIO
                        SERVICES, OR THE INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR OTHER
                        SERVICES
                        INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE GIO SERVICES, UNLESS OTHERWISE
                        SPECIFIED
                        IN WRITING. YOU EXPRESSLY AGREE THAT YOUR USE OF THE GIO SERVICES IS AT YOUR SOLE RISK.
                        TO THE FULL EXTENT PERMISSIBLE BY LAW, GIO DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED,
                        INCLUDING,
                        BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
                        GIO
                        DOES NOT WARRANT THAT THE GIO SERVICES, INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING
                        SOFTWARE) OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE GIO
                        SERVICES,
                        GIO'S SERVERS OR ELECTRONIC COMMUNICATIONS SENT FROM GIO ARE FREE OF VIRUSES OR OTHER HARMFUL
                        COMPONENTS. TO THE FULL EXTENT PERMISSIBLE BY LAW, GIO WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY
                        KIND ARISING FROM THE USE OF ANY GIO SERVICE, OR FROM ANY INFORMATION, CONTENT, MATERIALS,
                        PRODUCTS
                        (INCLUDING SOFTWARE) OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH
                        ANY
                        GIO SERVICE, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND
                        CONSEQUENTIAL
                        DAMAGES, UNLESS OTHERWISE SPECIFIED IN WRITING.
                    </div>
                    <div className="col-md-6">
                        <span style={{fontWeight: "600", fontSize: "15px"}}>
              Get It On (GIO) Privacy Notice:
              </span>{" "}
                        We know that you care how information about you is used and shared, and we appreciate your trust
                        that we will do so carefully and sensibly. This Privacy Notice describes how GIO and its
                        affiliates
                        (collectively "GIO") collect and process your personal information through GIO websites,
                        devices,
                        products, services, online and physical stores, and applications that reference this Privacy
                        Notice
                        (together "GIO Services"). By using GIO Services, you are consenting to the practices described
                        in
                        this Privacy Notice.
                        <div>
                            About Identifying Whether an Email, Phone Call, Text Message, or Webpage is from GIO
                            Identifying Whether an Email, Phone Call, Text Message, or Webpage is from GIO

                        </div>
                        <div> Here are some tips to determine if an email, phone call, text message, or web page is
                            authentic.
                        </div>
                        <div>
                            If you received correspondence regarding an order you didn't place, it likely wasn't from GIO (
                            https://get-it-on.app/). Go to Your Orders to review your order history. To report suspicious
                            communication, go to customer support.

                        </div>
                        <div>

                            If you received suspicious communication pretending to be from GIO and you don’t have an account
                            with us, report it to us at stop-spoofing@GIO.com.
                            Don't share any personal information and report it immediately.
                        </div>
                        <div>
                            Additional Information About Emails, Text Messages, and Web Pages
                            Occasionally, GIO will send you an email with an attachment. At the top of your message,
                            attachments
                            are linked in a box that reads, "Attachments(s) protected by GIO." These specific links are
                            safe.
                            Select these links to open and download attachments to your default Downloads folder.
                        </div>
                        <div>
                            If you receive an email or text message with links or attachments that appear suspicious, do not
                            open them. If you've already opened an attachment or selected a suspicious link, go to Protect
                            Your
                            System. To increase the security of your account, we recommend enabling Two-Step Verification.
                            For
                            more information, see Enable Two-Step Verification.
                        </div>

                    </div>
            </div>
            <div className="row">
            <div className="col-md-6">
              <span style={{fontWeight: "600", fontSize: "15px"}}>
              APPLICABLE LAW:
              </span>{" "}
                By using any GIO Service, you agree that applicable federal law, and the laws of the state of
                Washington, without regard to principles of conflict of laws, will govern these Conditions of
                Use
                and any dispute of any sort that might arise between you and GIO.
                <div style={{fontWeight: "600", fontSize: "15px"}}>
              Your Privacy Questions:
              </div>{" "}
                For more information on how to contact us
            </div>
            <div className="col-md-6">
              <span style={{fontWeight: "600", fontSize: "15px"}}>
              SITE POLICIES, MODIFICATION, AND SEVERABILITY:
              </span>{" "}
                Please review our other policies, such as our pricing policy, posted on this site. These
                policies
                also govern your use of GIO Services. We reserve the right to make changes to our site,
                policies,
                Service Terms, and these Conditions of Use at any time. If any of these conditions shall be
                deemed
                invalid, void, or for any reason unenforceable, that condition shall be deemed severable and
                shall
                not affect the validity and enforceability of any remaining condition.
            </div>
            </div>
                <div className="row">
            <div className="col-md-6">
              <span style={{fontWeight: "600", fontSize: "15px"}}>
              OUR ADDRESS:
              </span>{" "}
                Riverway Retail Park, Irvine, North Ayrshire, Scotland, KA12 8EJ, United Kingdom.
                <div style={{fontWeight: "600", fontSize: "15px"}}>
              Security and Privacy:
              </div>{" "}
                GIO and Your Personal Information
                Protecting your privacy and the security of your personal information is and has always been, a
                top
                priority for GIO.
            </div>
            <div className="col-md-6">

                    <span style={{fontWeight: "600", fontSize: "15px"}}>
              DISPUTES:
              </span>{" "}
                Any dispute or claim relating in any way to your use of any GIO Service will be adjudicated in
                the
                state or Federal courts in King County, Washington, and you consent to exclusive jurisdiction
                and
                venue in these courts. We each waive any right to a jury trial.
            </div>
            </div>
                <div className="row">
                    <div className='col-md-6'>
              <span style={{fontWeight: "600", fontSize: "15px"}}>
              Suspicious or fraudulent emails, text messages, or web pages not from GIO may contain:
              </span>{" "}
                        Links to websites that look like https://get-it-on.app/, but aren't GIO.
                        <div style={{fontWeight: "600", fontSize: "15px"}}>Note:</div>
                        Legitimate GIO websites have a dot before "https://get-it-on.app/" such as
                        http://"something"https://get-it-on.app/. For example, GIO Pay website is
                        https://get-it-on.app/.
                        We'll never send emails with links to an IP address (string of numbers), such as
                        http://123.456.789.123 get-it-on.app//. If the link takes you to a site that is not a legitimate
                        GIO
                        domain, then it is likely phishing.
                        <div>
                            An order confirmation for an item you didn't purchase or an attachment to an order
                            confirmation.
                        </div>
                        <div>
                            <div style={{fontWeight: "600", fontSize: "15px"}}> Note:</div>
                            Go to Your Orders to see if there is an order that matches the details in the
                            correspondence.
                            If it doesn't match an order in Your Account, the message isn't from GIO.
                            Requests to update payment information that are not linked to an GIO order you placed or a
                            GIO
                            service you subscribed to.
                        </div>
                        <div>
                            <div style={{fontWeight: "600", fontSize: "15px"}}>Note:</div>
                             Go to Your Orders. If you aren't prompted to update your payment method on that
                            screen,
                            the
                            message isn't from GIO.
                            Prompts to install software on your device.
                            Typos or grammatical errors.
                            Forged email addresses to make it look like the email is coming from GIO.
                            When you receive an email from a GIO sender and it contains the GIO logo beside the email,
                            the
                            message is really from us.
                        </div>
                        <div style={{fontWeight: "600", fontSize: "15px"}}>
              How GIO Protects Your Personal Information:
              </div>{" "}
                        We design all of our systems and devices with your privacy in mind. We have invested heavily in
                        ensuring the security of your personal information across our business.
                        We maintain physical, electronic, and procedural safeguards for the collection, storage, and
                        disclosure of personal information. Our security procedures mean that we may ask you to verify
                        your
                        identity before we disclose personal information to you. You can find more details in our
                        Privacy
                        Notice.
                        Information about our customers is an important part of our business and we never sell
                        individual
                        customer data. We may share your personal information with third parties.
                        You can find examples of this sharing in our Privacy Notice.
                        <div style={{fontWeight: "600", fontSize: "15px"}}>
              Note:
              </div>{" "}
                        We recommend that you implement simple measures to ensure your protection against
                        unauthorized
                        access to your password, your computers, devices, and applications, such as the following:
                        Using a unique password for your GIO account that is not used for other online accounts.
                        Signing out when you finish using a shared computer. Read our instructions on how to Sign
                        out of
                        Your GIO Account on the GIO Website.

                    </div>

                    <div className='col-md-6'>
                          <span style={{fontWeight: "600", fontSize: "15px"}}>
              How GIO Uses Your Personal Information:
              </span>{" "}
                        We use your personal information to deliver and communicate with you about your purchases of
                        products and services. Additionally, we use your information to improve and personalize your GIO
                        experience, and comply with legal obligations, among others.
                        <div>
                            We also use personal information to display interest-based ads for features, products, and
                            services
                            that might interest you. You can learn more in our Interest-Based Ads notice.
                        </div>
                        <div>
                            We use cookies and other identifiers to enable our systems to recognize your browser or
                            device.
                            We
                            use these identifiers to be able to provide our services to you. Please read our Cookies
                            Notice
                            for
                            more information.
                        </div>
                        <div>
                            You can find a detailed list of the ways we use your personal information in our Privacy
                            Notice.
                            We maintain physical, electronic, and procedural safeguards for the collection, storage, and
                            disclosure of personal information. Our security procedures mean that we may ask you to
                            verify
                            your
                            identity before we disclose personal information to you. You can find more details in our
                            Privacy
                            Notice.
                            Information about our customers is an important part of our business and we never sell
                            individual
                            customer data. We may share your personal information with third parties. We only share the
                            information to provide you with services or to comply with applicable laws. You can find
                            examples of
                            this sharing in our Privacy Notice.
                        </div>
                        <div>
                           <div  style={{fontWeight: "600", fontSize: "15px"}}>Note:</div>
                            We also recommend that you implement simple measures to ensure your protection against
                            unauthorized access to your password, your computers, devices, and applications, such as:
                            Using a unique password for your GIO account that is not used for other online accounts;
                            Signing out when you finish using a shared computer. Read our instructions on how to Sign
                            out of
                            Your GIO Account on the GIO Website.
                            Learn more about how to operate safely online on our Security and Privacy help pages.
                        </div>
                        <div>
                            How GIO Collects Your Personal Information
                            In addition to information you may provide us, we also collect information automatically to
                            improve
                            your GIO experience. This automatic collection happens, for example, when you interact with
                            our
                            website, our products, and services.
                            There are common ways in which you provide us with information. Those common ways include
                            searching
                            for products or services, placing an order, or contacting us for help. Some examples of
                            information
                            you might provide us with include your contact and delivery details, payment information, or
                            other
                            preferences.
                            You can find more details about how we collect your personal information in our Privacy
                            Notice.
                        </div>


                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
              <span style={{fontWeight: "600", fontSize: "15px"}}>
              Information You Provide To Us:
              </span>{" "}
                        When you interact with our products and services, you provide us with information. We securely
                        store
                        and use this information to improve your GIO experience. For more information, visit How GIO
                        Collects Your Personal Information.
                        <div>
                            Examples Of How We Use And Look After Your Personal Information
                            As explained in our Privacy Notice, we use your personal information to provide and improve
                            our
                            services. Here are the some of the ways that we use it:
                            to handle your orders and payments, deliver your items and provide you with services
                            to prevent or detect fraud and abuse in order to protect your security
                            to analyze our performance, so we can fix errors, build features, and to improve our
                            services
                            for
                            you
                            to recommend features, products, and services that might be of interest to you and
                            personalize
                            your
                            GIO experience
                            to respond to your voice requests correctly and improve your Alexa experience. To learn more
                            visit
                            the GIO, Echo Devices and Your Privacy page.
                            to communicate with you about GIO
                            to enable our systems to recognize your browser or device and to provide our services to
                            you, we
                            use
                            cookies. For more information about cookies and how we use them, please read our Cookies
                            Notice.
                            Your privacy and the security of your data is important to us. We are not in the business of
                            selling
                            our customers' personal information to others. In some instances, we may share your personal
                            information with third parties. For more information, visit How GIO Protects Your Personal
                            Information.

                        </div>
                        <div>
                            The legal basis for our use of your personal information varies depending on why we need to
                            use
                            your
                            information. When you purchase a product on our marketplace, we need your information to
                            perform
                            a
                            contract with you. We retain records related to your purchase to comply with tax-related
                            legal
                            obligations. We also detect, prevent, and respond to fraud; this usage is based on our and
                            our
                            customer's legitimate interests.
                            We design all of our systems and devices with your privacy in mind. For more information,
                            visit
                            How
                            GIO Protects Your Personal Information.
                        </div>
                    </div>
                    <div className="col-md-6">
                      <span style={{fontWeight: "600", fontSize: "15px"}}>
              To access personal information that is not available in Your Account or in your device (such as your search history):
              </span>{" "}
                    Go to Request My Data.
                        Select the information that you want to receive.
                        Select Submit Request when you're done. Please note that you need to be signed into the account
                        for
                        which you are submitting your request.
                        Select the validation link in your confirmation email.
                        Once your request is processed, a secure download link will be sent to the email address
                        associated
                        with the account.
                    </div>
                    <div>
                        Legal Policies
                    </div>
                    <div>
                        Conditions of Use
                    </div>
                    <div>
                        GIO Privacy Notice
                    </div>
                    <div>
                        Additional State-Specific Privacy Disclosures
                    </div>
                    <div>
                        GIO Gift Card and Electronic Message Customization Service Terms
                    </div>
                    <div>
                        GIO Trademark Usage Guidelines
                    </div>
                    <div>
                        Non-Exhaustive List of GIO Trademarks
                    </div>
                </div>
            </div>


        </>
        );
};

export default PrivacyPolicyComp;
