import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  allCategory: false,
};
const AllcateogryStatetrue = createSlice({
  name: "allcategory",
  initialState,
  reducers: {
    allcategoryState: (state, action) => {
      state.allCategory = action.payload;
    },
  },
});

export const { allcategoryState } = AllcateogryStatetrue.actions;
export default AllcateogryStatetrue.reducer;
