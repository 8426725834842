import React from "react";
import Modal from "../../../common/Modal";
import { AiOutlineWarning } from "react-icons/ai";
import { depositeModalHide } from "../../../store/uiSlices/LoginModalSlice";
import { useDispatch } from "react-redux";
const InsufficiantBalance = ({
  insufficiantBalance,
  setInsufficiantBalance,
}) => {
  const dispatch = useDispatch();
  return (
    <>
      <Modal
        show={insufficiantBalance}
        setShow={setInsufficiantBalance}
        heading={"INSUFFICIENT BALANCE"}
      >
        <div className="my-5 d-flex align-items-center gap-2">
          <AiOutlineWarning style={{ fontSize: "5em", color: "orange" }} />{" "}
          <h3 className="fs-5" style={{ color: "grey" }}>
            You do not have enough balance. Please deposit.
          </h3>
        </div>
        <div>
          <button
            className="text-white px-4 py-1 rounded border-0"
            style={{
              background: "var(--primary-color-green)",
            }}
            onClick={() => {
              //   setInsufficiantBalance(false);
              setInsufficiantBalance(false);
              dispatch(depositeModalHide(true));
            }}
          >
            Deposit Now
          </button>
        </div>
      </Modal>
    </>
  );
};

export default InsufficiantBalance;
