import React, { useEffect, useState } from "react";
import { initializeApiWithHeaders } from "./PostApiRequest";
import { GetAllRecords } from "../store/BoostDataSlice/GetAllRecordsSlice";
import { toast } from "react-toastify";
import { BASE_URL } from "../env";
import { useDispatch, useSelector } from "react-redux";
import { LoaderAPICalls } from "./LoaderAPICalls";
import { BalanceAndProfit } from "../store/BoostDataSlice/GetBalanceandProfitSlice";
import { AllSetsRecord } from "../store/BoostDataSlice/GetAllSetSlice";
import { setAddTask } from "../store/uiSlices/AddTaskSlice";
import { setIncorrectType } from "../store/uiSlices/IncorrectTypeSlice";
import { setPreviousSubmission } from "../store/uiSlices/PreviousSubmissionSlice";
import productDummyImage from "../assets/image/product.jpeg";
import InsufficiantBalance from "../components/categories/boostDataComponent/InsufficientBalance";
const RecordCard = ({ record, isLoading }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const [insufficiantBalance, setInsufficiantBalance] = useState(false);
  const [commonElementID, setcommonElementID] = useState([]);
  const timestamp = record?.submitted_datetime;
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${
    day < 10 ? "0" : ""
  }${day}`;
  const formattedTime = `${hours < 10 ? "0" : ""}${hours}:${
    minutes < 10 ? "0" : ""
  }${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;

  const formattedTimestamp = `${formattedDate} ${formattedTime}`;
  useEffect(() => {
    dispatch(GetAllRecords());
    dispatch(BalanceAndProfit());
  }, []);
  console.log(" state?.GetAllRecords?.data?.data", state);
  useEffect(() => {
    if (state?.GetAllRecords?.data?.data !== null) {
      for (
        let index = 0;
        index < state?.GetAllRecords?.data?.data.length;
        index++
      ) {
        if (state?.GetAllRecords?.data?.data[index]?.id === record?.id) {
          setcommonElementID(state?.GetAllRecords?.data?.data[index]?.id);

          break;
        }
      }
    }
  }, [state?.GetAllRecords?.data?.data]);

  const submitNow = async () => {
    if (userBalance?.total_balance < 0) {
      setInsufficiantBalance(true);
    }
    const token = JSON.parse(localStorage.getItem("userLogin")).data.token
      .access;
    let headers = new Headers();
    headers.append("Authorization", `Bearer ${token}`);
    let formData = new FormData();
    formData.append("status", "Completed");
    try {
      initializeApiWithHeaders();
      const response = await fetch(
        `${BASE_URL}boost-data/update-record/${commonElementID}/`,
        {
          method: "PUT",
          headers: headers,
          body: formData,
        }
      );
      const res = await response.json();
      if (res.meta.status_code === 200) {
        toast.success(res?.meta?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        dispatch(AllSetsRecord());
        dispatch(GetAllRecords());
        dispatch(BalanceAndProfit());
        dispatch(BoostDataUser());
        dispatch(setAddTask(false));
        dispatch(setIncorrectType(false));
        dispatch(setPreviousSubmission(false));
      } else {
        toast.error(res?.meta?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      handleError(error);
    }
  };
  const handleError = (error) => {
    if (error.response) {
      if (error.response.status === 400) {
        toast.error(error.response?.data?.meta?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else if (error.request) {
      toast(error.message);
    } else {
      console.log("Error:", error.message);
    }
  };
  console.log("records", record);
  const userBalance = useSelector(
    (state) => state?.GetBalanceRecords?.data?.data
  );
  return (
    <>
      {" "}
      {isLoading && (
        <div className="loader-container">
          <LoaderAPICalls />
        </div>
      )}
      <div
        className="row my-3 px-2 pt-3 pb-1  rounded "
        style={{ background: "#ffff" }}
      >
        <div className="col-lg-6">
          {record?.merge_product ? (
            <div className="d-flex flex-column  flex-sm-row gap-2 ">
              {record?.merge_product?.product &&
                record?.merge_product?.product[0] && (
                  <div>
                    {record?.merge_product?.product[0]?.product_media &&
                      record?.merge_product?.product[0].product_media.length >
                        0 && (
                        <img
                          key={0}
                          src={
                            record?.merge_product?.product[0].product_media[0]
                              .default_image === true
                              ? record?.merge_product?.product[0]
                                  .product_media[0].image
                              : productDummyImage
                          }
                          height={100}
                          width={100}
                        />
                      )}
                  </div>
                )}

              {record?.merge_product?.product &&
                record?.merge_product?.product.map((product_name) => (
                  <p className="record-card-title">
                    {product_name?.item_name?.length > 50
                      ? `${product_name?.item_name.slice(0, 49)}...`
                      : product_name?.item_name}
                  </p>
                ))}
            </div>
          ) : (
            <div className="d-flex flex-column  flex-sm-row gap-2 ">
              {record?.boosted_product?.boost_product?.product_media &&
                record?.boosted_product?.boost_product?.product_media.map(
                  (media, ind) => {
                    if (media.default_image === true) {
                      return (
                        <img
                          key={ind}
                          src={media.image}
                          alt="media"
                          height={100}
                          width={100}
                          loading="lazy"
                        />
                      );
                    }
                    return "";
                  }
                )}
              <p className="record-card-title">
                {record?.boosted_product?.boost_product?.item_name?.length > 50
                  ? `${record?.boosted_product?.boost_product?.item_name.slice(
                      0,
                      49
                    )}...`
                  : record?.boosted_product?.boost_product?.item_name}
              </p>
            </div>
          )}

          <p
            className="mt-2 mt-sm-0"
            style={{ color: "red", fontSize: "14px" }}
          >
            {formattedTimestamp}
          </p>
          <div className="d-flex justify-content-between m-2">
            <div>
              <p style={{ fontSize: "13px", color: "#777" }}>Data value</p>
              {record?.merge_product?.data_value ? (
                <p style={{ fontSize: "14px" }} className="fw-semibold">
                  {record?.merge_product?.data_value
                    ? record?.merge_product?.data_value
                    : "-"}
                </p>
              ) : (
                <p style={{ fontSize: "14px" }} className="fw-semibold">
                  {record?.boosted_product?.data_value
                    ? record?.boosted_product?.data_value
                    : "-"}
                </p>
              )}
            </div>
            <div>
              <p style={{ fontSize: "13px", color: "#777" }}>
                Commission value
              </p>
              {record?.merge_product?.commission_value ? (
                <p style={{ fontSize: "14px" }} className="fw-semibold">
                  {record?.merge_product?.commission_value
                    ? record?.merge_product?.commission_value
                    : "-"}
                </p>
              ) : (
                <p style={{ fontSize: "14px" }} className="fw-semibold">
                  {record?.boosted_product?.commission_value
                    ? record?.boosted_product?.commission_value
                    : "-"}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-6 d-flex justify-content-end ">
          <div>
            <p
              className="rounded px-2"
              style={{
                color: "var(--primary-color-green)",
                border: "1px solid var(--primary-color-green)",
              }}
            >
              {record?.status}
            </p>
            {record?.status === "Pending" && (
              <button
                className="rounded px-2"
                style={{
                  marginTop: "10px",
                  color: "var(--primary-color-green)",
                  border: "1px solid var(--primary-color-green)",
                }}
                onClick={submitNow}
              >
                Submit Now
              </button>
            )}
          </div>
        </div>
      </div>
      {insufficiantBalance && (
        <InsufficiantBalance
          insufficiantBalance={insufficiantBalance}
          setInsufficiantBalance={setInsufficiantBalance}
        />
      )}
    </>
  );
};

export default RecordCard;
