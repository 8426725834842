import React, { useState } from "react";
import FeedbackForm from "./FeedbackForm";
import Specification from "./Specification";
import Description from "./Description";
import "./Detail.css";

const Ratingsection = ({ product }) => {
  const [activeTab, setActiveTab] = useState("specifications");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const tabItems = [
    // { id: "reviews", label: "Reviews" },
    { id: "specifications", label: "Specifications" },
    { id: "description", label: "Description" },
  ];

  const tabContents = {
    // reviews: <FeedbackForm />,
    specifications: <Specification product={product} />,
    description: <Description product={product} />,
  };

  return (
    <div className="">
      <div className="">
        <ul className="nav nav-tabs" style={{ marginBottom: "1rem" }}>
          {tabItems.map((tab) => (
            <li key={tab.id} className="nav-item">
              <button
                className={`detail-page-detail-nav  nav-link ${
                  activeTab === tab.id ? "active" : ""
                }`}
                onClick={() => handleTabClick(tab.id)}
                style={{
                  borderBottom:
                    activeTab === tab.id
                      ? "2px solid var(--primary-color-green)"
                      : "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderTop: "none",
                  color:
                    activeTab === tab.id
                      ? "var(--primary-color-green)"
                      : "black",
                }}
              >
                {tab.label}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="tab-content">
        {tabItems.map((tab) => (
          <div
            key={tab.id}
            className={`tab-pane fade ${
              activeTab === tab.id ? "show active" : ""
            }`}
          >
            {tabContents[tab.id]}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Ratingsection;
