import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../env";

export const GetProductWithMainCateg = createAsyncThunk(
  "GetProductWithMainCateg",
  async (data) => {
    const uservalue = localStorage.getItem("userLogin");
    const tokenVALUE = JSON.parse(uservalue);
    const config = {
      // headers: {
      //   Authorization: `Bearer ${tokenVALUE?.data?.token?.access}`,
      // },
      timeout: 5000,
      cancelToken: new axios.CancelToken((cancel) => {
        // Create a cancel token and function
        setTimeout(() => {
          cancel("Request timed out");
        }, 5000);
      }),
    };

    try {
      let url = `${BASE_URL}catalogue/get-products-by-main-category/${data.id}/`;
      const urlArr = [];

      for (let [key, value] of Object.entries(data)) {
        if (key === "subCategId" && value) {
          urlArr.push(`category_id=${value}`);
        } else if (key === "priceRange" && value) {
          urlArr.push(`price_range=${value[0]},${value[1]}`);
        } else if (key === "brandData" && value) {
          const branUrlArr = [];
          value.forEach((brand) => {
            branUrlArr.push(`${brand.id}`);
          });
          if (branUrlArr.length > 0) {
            urlArr.push(`brand_id=${branUrlArr.join(",")}`);
          }
        }
      }

      if (urlArr.length > 0) {
        const additionalUrl = urlArr.join("&");
        url = `${url}?${additionalUrl}`;
      } else {
        url = url;
      }

      const response = await axios.get(`${url}`, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
const GetProductWithMainCategSlice = createSlice({
  name: "GetProductWithMainCateg",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(GetProductWithMainCateg.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetProductWithMainCateg.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(GetProductWithMainCateg.rejected, (state, action) => {
      // console.log(action.error.message);
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export default GetProductWithMainCategSlice.reducer;
