import React from "react";
import productDummyImage from "../assets/image/product.jpeg";
import { ApiCall, initializeApiWithHeaders } from "./PostApiRequest";
import { useDispatch } from "react-redux";
import { GetWishlist } from "../store/Wishlist/GetWishlist";
import { FaHeart } from "react-icons/fa";
const WishlistCard = ({ wishPrd }) => {
  const dispatch = useDispatch();

  const handleWishClick = async (product) => {
    initializeApiWithHeaders();

    const response = await ApiCall(
      `wishlist/remove-wishlist-item/${product?.id}/`,
      "DELETE"
    );
    if (response?.meta?.status_code == 200) {
      dispatch(GetWishlist());
    }
  };
  return (
    <div className="my-3 d-flex align-items-center  justify-content-between ">
      <div>
        <div>
          <h5 className="wish-card-heading" style={{ maxWidth: "220px" }}>
            {wishPrd?.product?.item_name.length > 20
              ? `${wishPrd?.product?.item_name.slice(0, 20)}...`
              : wishPrd?.product?.item_name}
          </h5>
          <p
            className="wish-card-desc"
            style={{ color: "darkgray", fontSize: "15px", maxWidth: "200px" }}
          >
            {wishPrd?.product?.item_description.length > 50
              ? `${wishPrd?.product?.item_description.slice(0, 50)}...`
              : wishPrd?.product?.item_description}
          </p>
        </div>
        <div className="d-flex align-items-center  gap-2 mt-2 ">
          <p>
            <span className="fw-bold  wish-card-price">
              ${" "}
              {wishPrd?.product?.offer
                ? wishPrd?.product?.offer_price
                : wishPrd?.product?.selling_price}
            </span>
          </p>

          <button
            title="wishlist"
            className="border-0 ms-5 "
            style={{ background: "transparent " }}
            onClick={() => {
              handleWishClick(wishPrd);
            }}
          >
            <FaHeart
              className="wish-icon"
              style={{ color: "var(--primary-color-green)" }}
            />
          </button>
        </div>
      </div>

      {/* <img src={image} className="wishImge" /> */}

      <div className="rounded overflow-hidden" style={{ background: "#eee" }}>
        {wishPrd?.product?.product_media?.length > 0 &&
        wishPrd?.product?.product_media[0]?.image ? (
          <img
            src={wishPrd?.product?.product_media[0]?.image}
            className="cartImage"
          />
        ) : (
          <img src={productDummyImage} className="cartImage" />
        )}
      </div>
    </div>
  );
};

export default WishlistCard;
