import React, { useState } from "react";
import "./RadioButtonGroup.css";
const options = [
  { value: "Gray", label: "Gray", backgroundColor: "gray" },
  { value: "Black", label: "Black", backgroundColor: "black" },
  // { value: "Red", label: "Red", backgroundColor: "#df1616" },
];
const ColorVariations = ({ setProductColor }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const handleOptionChange = (event) => {
    setProductColor(event.target.value);
    setSelectedOption(event.target.value);
  };
  return (
    <div className=" mt-3">
      <p>
        <span className="fw-bold my-5"> Color: &nbsp;</span>
        {selectedOption}
      </p>
      {options.map((option) => (
        <label
          key={option.value}
          title={option.label}
          className={`radio-button ${
            selectedOption === option.value ? "selected" : ""
          }`}
          style={{
            border: "1px solid black",
            padding: "10px",
            backgroundColor: option.backgroundColor,
            height: "60px",
            width: "60px",
          }}
        >
          <input
            type="radio"
            value={option.value}
            checked={selectedOption === option.value}
            onChange={handleOptionChange}
          />
        </label>
      ))}
    </div>
  );
};

export default ColorVariations;
