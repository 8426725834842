import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../env";

export const logout = createAsyncThunk("logout", async () => {
  const uservalue = localStorage.getItem("userLogin");
  const tokenVALUE = JSON.parse(uservalue);

  var myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    `Bearer ${tokenVALUE?.data?.token?.access}`
  );

  var formData = new FormData();
  formData.append("refresh_token", `${tokenVALUE?.data?.token?.refresh}`);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData,
    redirect: "follow",
  };

  return fetch(`${BASE_URL}auth/logout/`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      localStorage.removeItem("userLogin");
      return result;
    })
    .catch((error) => {
      return error;
    });
});
const logoutSlice = createSlice({
  name: "logout",
  initialState: {
    isloading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(logout.pending, (state, action) => {
      state.isloading = true;
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      state.isloading = false;
      state.data = action.payload;
    });
    builder.addCase(logout.rejected, (state, action) => {
      state.isError = true;
    });
  },
});

export default logoutSlice.reducer;
