import React from "react";
import TopGreenNavbar from "./TopGreenNavbar";
import GetitonNavbar from "./GetitonNavbar";
import AllCategoriesNavbar from "./AllCategoriesNavbar";
import "./header.css";
import { useDispatch, useSelector } from "react-redux";
import {
  GetSearchProduct,
  setSearchProduct,
} from "../../../store/Product/SearchProductSlice";
function Headers({ searchTerm, setSearchTerm }) {
  const dispatch = useDispatch();
  const isNavbarVisible = useSelector((state) => state.nav.isNavbarVisible);
  const handleSearchChange = () => {};
  const handleSearch = (event) => {
    console.log("event", event);
    dispatch(GetSearchProduct(event.target.value));
    dispatch(setSearchProduct(event.target.value));
    setSearchTerm(event.target.value);
  };

  return (
    <div>
      <div>
        <TopGreenNavbar />
        <GetitonNavbar
          handleSearchChange={handleSearchChange}
          setSearchTerm={setSearchTerm}
          handleSearch={handleSearch}
          searchTerm={searchTerm}
        />
        <AllCategoriesNavbar />
      </div>
      {!isNavbarVisible && (
        <div
          className={`${
            !isNavbarVisible
              ? "showNav position-fixed top-0  w-100 bg-white"
              : "d-none"
          }`}
        >
          <GetitonNavbar
            handleSearchChange={handleSearchChange}
            handleSearch={handleSearch}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </div>
      )}
    </div>
  );
}

export default Headers;
