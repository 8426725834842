import React, { useEffect } from "react";
import { Accordion, Modal, useAccordionButton } from "react-bootstrap";
import { RxChevronDown, RxCross2 } from "react-icons/rx";
import { TfiViewGrid } from "react-icons/tfi";
import gitLogo from "../../../../assets/image/logo/logo2.jpeg";
import { CiSearch } from "react-icons/ci";
import { Link, useNavigate } from "react-router-dom";
import { AllCategory } from "../../../../store/Category/GetAllCategory";
import { useDispatch, useSelector } from "react-redux";

const MenuModal = ({ show, setShow, onHide }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allCategories = useSelector((state) => state?.AllCategory?.data?.data);
  useEffect(() => {
    dispatch(AllCategory());
  }, []);

  function CustomToggle({ eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      console.log("totally custom!")
    );

    return (
      <button
        type="button"
        style={{ backgroundColor: "transparent", border: "none" }}
        onClick={decoratedOnClick}
        className="d-flex align-items-center gap-3"
      >
        <TfiViewGrid style={{ fontSize: "1.15rem" }} />
        <p>Categories</p>
        <RxChevronDown />
      </button>
    );
  }

  return (
    <div>
      <Modal
        show={show}
        size=""
        className="d-lg-none"
        onHide={() => {
          onHide();
        }}
      >
        <Modal.Header>
          <Modal.Title className="d-flex align-items-center  justify-content-between w-100">
            <img src={gitLogo} width={150} />
            <div onClick={onHide}>
              <RxCross2 className="" style={{ fontSize: "25px" }} />
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form className="h-100  w-100">
              <div
                className="border  position-relative  d-flex align-items-center focus-border-primary justify-content-between  h-100   rounded "
                style={{ borderRadius: "5px" }}
              >
                <CiSearch
                  className="position-absolute start-0 "
                  style={{ translate: "5px 0" }}
                />
                <input
                  type="text"
                  className="border-0 w-100 h-100 ps-4   py-3"
                  style={{ outline: "none", borderRadius: "5px" }}
                />
              </div>
            </form>
          </div>
          {/*============ categories dropdown menu============ */}
          <div>
            <Accordion defaultActiveKey="" style={{ height: "100%" }}>
              <div>
                <div className="ps-2 pt-3">
                  <CustomToggle eventKey="0" />
                </div>
                <Accordion.Collapse eventKey="0" className="mt-2">
                  <div className="border">
                    {allCategories?.map((categ, index) => (
                      <div
                        key={categ.id}
                        className={`py-2 ps-3 d-flex  align-items-center  gap-2 ${
                          index !== allCategories?.length - 1 && "border-bottom"
                        }`}
                      >
                        {categ.top_menu === "True" ? (
                          <>
                            <img src={categ.category_icon} width={12} />
                            <p
                              onClick={() => {
                                navigate(
                                  `/${categ?.category_name
                                    ?.split("'")
                                    .join("")
                                    .replace(",", "")
                                    .replace("&", "")
                                    .split(" ")
                                    .filter((char) => char !== "")
                                    .join("-")
                                    .toLowerCase()}`,
                                  {
                                    state: {
                                      id: categ?.id,
                                      categoryName: categ?.category_name,
                                    },
                                  }
                                );
                                onHide();
                                setShow(false);
                              }}
                            >
                              {categ.category_name}
                            </p>
                          </>
                        ) : (
                          <>
                            {categ?.category_name !== "Gadgets and Gear" && (
                              <>
                                <img src={categ.category_icon} width={12} />
                                <p>{categ.category_name}</p>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </Accordion.Collapse>
              </div>
            </Accordion>
          </div>
          {/* ============ nav menu ================= */}
          <Link
            to={`/`}
            className="nav-link my-3 "
            style={{
              textDecoration: "none",
              cursor: "pointer",
              fontSize: "15px",
              fontWeight: "400",
              textTransform: "uppercase",
            }}
          >
            Home
          </Link>
          {allCategories?.map((navLink) => (
            <div key={navLink.id} className="position-relative  ">
              {navLink?.top_menu === "True" && (
                <p
                  onClick={() => {
                    navigate(
                      `/${navLink?.category_name
                        ?.split("'")
                        .join("")
                        .replace(",", "")
                        .replace("&", "")
                        .split(" ")
                        .filter((char) => char !== "")
                        .join("-")
                        .toLowerCase()}`,
                      {
                        state: {
                          id: navLink?.id,
                          categoryName: navLink?.category_name,
                        },
                      }
                    );
                    onHide();
                    setShow(false);
                  }}
                  className="nav-link my-3 "
                  onMouseEnter={() => {}}
                  onMouseLeave={() => {}}
                  style={{
                    textDecoration: "none",
                    cursor: "pointer",
                    fontSize: "15px",
                    fontWeight: "400",
                    textTransform: "uppercase",
                  }}
                >
                  {navLink?.top_menu && navLink.category_name}
                </p>
              )}
            </div>
          ))}
          <Link
            to={`/drive-data`}
            className="nav-link my-3 "
            style={{
              textDecoration: "none",
              cursor: "pointer",
              fontSize: "15px",
              fontWeight: "400",
              textTransform: "uppercase",
            }}
          >
            Get Started
          </Link>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MenuModal;
