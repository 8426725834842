import React from "react";
import SideCategoryNavHeader from "../../common/SideCategoryNavHeader";
import { setPriceRangeFirstLoading } from "../../store/uiSlices/PriceRangeFirstLoading";
import { useDispatch } from "react-redux";

const BrandFilter = ({ productData, checked, setChecked }) => {
  const dispatch = useDispatch();
  const brandData = productData ? productData[productData?.length - 1] : [];

  const handleBrandChange = (brand) => {
    dispatch(setPriceRangeFirstLoading(true));
    const filBrand = checked?.filter((br) => br.id === brand.id);

    if (filBrand.length === 0) {
      setChecked((prev) => {
        return [...prev, { id: brand.id, name: brand.brand_name }];
      });
    } else {
      const filterBrand = checked?.filter((br) => br.id !== brand.id);
      setChecked(filterBrand);
    }
  };
  return (
    <div className="border  rounded py-3 mb-4">
      <SideCategoryNavHeader category={"Brand"} />
      <div
        className="px-3 pt-4 overflow-y-scroll category-filter"
        style={{ maxHeight: "300px" }}
      >
        <form>
          {brandData?.length > 0 &&
            brandData?.map((brand) => (
              <label style={{ width: "200px" }}>
                <input
                  type="checkbox"
                  className="larger-checkbox"
                  name="brand"
                  value="samsung"
                  onChange={() => {
                    handleBrandChange(brand);
                  }}
                  style={{ cursor: "pointer" }}
                />{" "}
                &nbsp;&nbsp;{`${brand?.brand_name}`}
              </label>
            ))}
        </form>
      </div>
    </div>
  );
};

export default BrandFilter;
