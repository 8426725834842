import React, { useEffect, useState } from "react";
import "./Checkout.css";
import { useDispatch, useSelector } from "react-redux";
import { ApiCall, initializeApiWithHeaders } from "../../common/PostApiRequest";
import { BASE_URL } from "../../env";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { removeAllFromCart } from "../../store/uiSlices/AddToCartSlice";
import { productDetails } from "../../constants/categoriesCard";
import { setLoginVal } from "../../store/uiSlices/loginStateSlice";
const PurchaseScreen = ({ prevFucntion, prev, next }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [totalPrice, setTotalPrice] = useState(0);
  const [localStore, setlocalStore] = useState(false);
  const [deliveryPrice, setDeliveryPrice] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [orderItemsData, setOrderItemsData] = useState([]);
  const cartItems = useSelector((state) => state?.AddToCartSlice?.items);
  const profileData = useSelector(
    (state) => state?.ProfileRecordSlice?.data?.data
  );
  useEffect(() => {
    if (cartItems?.length > 0) {
      let total = 0;
      cartItems?.forEach((item) => {
        return (total += Number(item.itemTotalPrice));
      });

      const orderData = cartItems?.map((item) => {
        return {
          product: item.id,
          price: item?.offer ? item.offer_price : item.selling_price,
          quantity: item.count,
          prodcut_size: item?.prodcutSize,
          prodcut_color: item?.prodcutSize,
        };
      });
      setOrderItemsData(orderData);
      setTotalPrice(total.toFixed(2));

      setGrandTotal((total + deliveryPrice).toFixed(2));
    }
  }, [cartItems]);

  const handleSubmit = async () => {
    initializeApiWithHeaders();
    const deliverFormData = JSON.parse(
      localStorage.getItem("delivery_address")
    );
    const uservalue = localStorage.getItem("userLogin");
    const tokenVALUE = JSON.parse(uservalue);
    const header = new Headers();
    header.append("Authorization", `Bearer ${tokenVALUE?.data?.token?.access}`);
    header.append("Content-Type", "application/json");
    const data = {
      order_detail: {
        address1: deliverFormData?.address1,
        address2: deliverFormData?.address2,
        city: deliverFormData?.city,
        post_code: deliverFormData?.postcode,
        order_amount: totalPrice,
        total_paid: grandTotal,
      },
      order_items: orderItemsData,
    };
    try {
      const order = await ApiCall(
        `${BASE_URL}order/place-order/`,
        "POST",
        data
      );

      if (order?.meta?.status_code === 201) {
        toast.success(order?.meta?.message);
        setTimeout(() => {
          navigate("/");
        }, 500);
        setlocalStore(true);

        dispatch(ProfileRecord());
      } else {
        toast.error(order?.meta?.message);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          toast.error(error.response?.data?.meta?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });

          setTimeout(() => {
            navigate("/");
          }, 200);
          setlocalStore(true);

          dispatch(ProfileRecord());
        }

        if (
          error.response?.data?.meta?.message ===
          "Request failed with status code 401"
        ) {
          dispatch(setLoginVal(false));
          localStorage.removeItem("userLogin");
        }
      } else if (error.request) {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
      }
    }
  };

  useEffect(() => {
    if (localStore) {
      localStorage.removeItem("delivery_address");
      localStorage.removeItem("cartItems");
      dispatch(removeAllFromCart());
    }

    setlocalStore(false);
  }, [localStore === true]);

  console.log(profileData);
  return (
    <div
      style={{ maxWidth: "600px", marginLeft: "auto", marginRight: "auto" }}
      className="Purchase"
    >
      <h4 className="mb-3 checkout-from-heading">
        Review Your Order and Submit
      </h4>
      <div className="border-bottom pb-3">
        <h5 className="mb-2 checkout-from-subheading">Delivery Address</h5>
        <p className="" style={{ color: "darkgray", fontSize: "14px" }}>
          Address line 1
        </p>
        <p style={{ color: "darkgray", fontSize: "14px" }}>Address line 2</p>
        <p style={{ color: "darkgray", fontSize: "14px" }}>
          Melbourne 3000, VIC, Australia
        </p>
      </div>

      <div className=" mt-3  ">
        <h5 className="mb-2 checkout-from-subheading">Order Detail</h5>
        <div
          className="parchase-table overflow-y-scroll"
          style={{ height: "325px" }}
        >
          <table className="w-100 mt-3  overflow-x-scroll overflow-y-scroll ">
            <thead className="w-100">
              <tr className="w-100 border-bottom ">
                <td
                  className="table-header"
                  style={{ color: "darkgray", fontSize: "15px" }}
                >
                  ORDERED ITEMS
                </td>
                <td
                  className="text-center table-header"
                  style={{ color: "darkgray", fontSize: "15px" }}
                >
                  QTY
                </td>
                <td
                  className="text-center table-header"
                  style={{ color: "darkgray", fontSize: "15px" }}
                >
                  UNIT PRICE
                </td>
                <td
                  className="text-center table-header"
                  style={{ color: "darkgray", fontSize: "15px" }}
                >
                  AMOUNT
                </td>
              </tr>
            </thead>
            <tbody className="">
              {cartItems?.map((product, index) => (
                <tr className="" key={index}>
                  <td>
                    <div className="d-flex align-items-center  mt-2">
                      <div
                        className="rounded"
                        style={{
                          height: "40px",
                          maxWidth: "60px",
                          background: "#eee",
                        }}
                      >
                        {product?.product_media?.length > 0 && (
                          <img
                            src={product?.product_media[0]?.image}
                            className="h-100"
                          />
                        )}
                      </div>
                      <p className="table-row ms-1">
                        {product?.item_name.length > 20
                          ? `${product?.item_name.slice(0, 20)}...`
                          : product?.item_name}
                      </p>
                    </div>
                  </td>
                  <td className="text-center table-row">{product?.count}</td>
                  <td className="text-center fw-semibold table-row">
                    {product?.offer
                      ? product?.offer_price
                      : product.selling_price}
                    $
                  </td>
                  <td
                    className="text-center fw-semibold table-row"
                    style={{ color: "var(--primary-color-green)" }}
                  >
                    {product?.itemTotalPrice}$
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="d-flex justify-content-end border-bottom pb-3 border-top pt-2">
        <div style={{ maxWidth: "330px" }}>
          <div className="d-flex justify-content-between align-items-center gap-5 ">
            <p
              className="text-end checkout-from-text"
              style={{ width: "100px" }}
            >
              Subtotal
            </p>
            <p className="fw-semibold table-row">${totalPrice}</p>
          </div>
          <div className="d-flex justify-content-between align-items-center gap-5 ">
            <p
              className="text-end checkout-from-text"
              style={{ width: "100px" }}
            >
              Delivery Fees
            </p>
            <p className="fw-semibold table-row">${deliveryPrice}</p>
          </div>
          <div className="d-flex justify-content-between align-items-center gap-5 ">
            <h5
              className="text-end checkout-from-subheading"
              style={{ minWidth: "100px" }}
            >
              Grand Total
            </h5>
            <p
              className="fs-6 fw-semibold checkout-from-subheading"
              style={{ color: "var(--primary-color-green)" }}
            >
              ${grandTotal}
            </p>
          </div>
        </div>
      </div>

      {/* <div className="border-bottom pb-4 mt-4">
        <div style={{ maxWidth: "350px" }}>
          <h5 className="mb-2 checkout-from-subheading">
            Delivery service type:
          </h5>
          <p
            style={{ color: "darkgray", fontSize: "15px", lineHeight: "18px" }}
          >
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa nulla
            id, deserunt inventore et laboriosam porro autem quam similique.
          </p>
        </div>
      </div> */}

      <div className="w-100 d-flex justify-content-between mb-3 mt-3 ">
        <button
          type="button"
          className="border-0 px-3 py-2 rounded "
          style={{
            color: `${prev ? "var(--primary-color-green)" : "#000"}`,
          }}
          onClick={() => {
            if (
              Number(
                profileData?.ecommerce_account_wallet_summary
                  ?.current_balance_amount
              ) > grandTotal
            ) {
              prevFucntion();
              prevFucntion();
            } else {
              prevFucntion();
            }
          }}
        >
          Previous
        </button>
        <button
          type="button"
          className="border-0 px-3 py-2 rounded "
          style={{
            color: `${next ? "var(--primary-color-green)" : "#000"}`,
          }}
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default PurchaseScreen;
