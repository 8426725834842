import React, { useEffect, useRef } from "react";
import HelmetComp from "../common/HelmetComp";
import Layout from "../common/layout/Layout";
import PrivacyPolicyComp from "../components/PrivacyPolicyComp";
import { useInView } from "react-intersection-observer";
import { useDispatch } from "react-redux";
import { showNavabr } from "../store/uiSlices/NavSlice";
import BreadCrumbs from "../common/BreadCrumbs";
import watchImage from "../assets/image/slider/156c27224ff4389c0bbe6ca8e3daeb7c.png";
import chairImage from "../assets/image/slider/c1c7140f4146c8d083042e49465f0c62.png";
import headPhones from "../assets/image/slider/a306c6a84a161de39e219011a9315d20.png";
import HeroSlider from "../common/HeroSlider";
function PrivacyPolicy() {
  const images = [
    { img: watchImage },
    { img: chairImage },
    { img: headPhones },
  ];
  const dispatch = useDispatch();
  const privacyRef = useRef(null);

  useEffect(() => {
    const option = {
      root: null,
      threshold: 0,
    };

    const heroObsrver = (enteries) => {
      const entery = enteries[0];
      if (entery.isIntersecting) {
        dispatch(showNavabr(true));
      } else {
        dispatch(showNavabr(false));
      }
    };

    const observer = new IntersectionObserver(heroObsrver, option);
    if (privacyRef.current) {
      observer.observe(privacyRef.current);
    }
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [privacyRef]);

  return (
    <div>
      <Layout>
        {/* <BreadCrumbs category={"Privacy Policy"} link={"/privacy-policy"} /> */}
        <div ref={privacyRef}>
          <HelmetComp title={"Privacy Policy"} />
          <HeroSlider images={images} />
        </div>
        <PrivacyPolicyComp />
      </Layout>
    </div>
  );
}
export default PrivacyPolicy;
