import { useDispatch, useSelector } from "react-redux";
import BoostDataCard from "../../../common/BoostDataCard";
import Carousel from "react-bootstrap/Carousel";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import ConfirmationModal from "../../../common/ConfirmationModal";
import Deposite from "../../../common/Deposite";
import { useEffect, useState } from "react";

const BoostDataInnerPage = ({ data, title, subTitle, isLoading }) => {
  const [pausedSlide, setPausedSlide] = useState(false);
  const isLight = useSelector((state) => state.darkLight);
  console.log(pausedSlide);
  return (
    <div className="my-5">
      <div className="d-flex flex-column flex-md-row align-items-center gap-2">
        <h1
          style={{
            fontWeight: "400",
            lineHeight: "1.2",
            fontSize: "24px",
            color: "var(--primary-color-green)",
          }}
          className="fw-bold"
        >
          {title}
        </h1>
        <p
          className="text-center"
          style={{
            color: `${isLight.lightDark ? "text-light" : " rgba(0,0,0,0.5)"}`,
            fontSize: "15px",
            lineHeight: "1.5",
          }}
        >
          - {subTitle}
        </p>
      </div>
      <ConfirmationModal />
      <div className="row boost-data-slider">
        {data?.map((detail, index) => (
          <div key={index} className=" col-md-6 col-lg-4 ">
            <BoostDataCard data={detail} index={index} isLoading={isLoading} />
          </div>
        ))}
      </div>
      <div className="d-lg-none">
        <Carousel
          pause={`${pausedSlide ? pausedSlide : "hover"}`}
          interval={null}
          touch={true}
          nextIcon={
            <div
              className=" text-dark  rounded-circled-flex align-items-center justify-content-center"
              style={{ fontSize: "30px", marginRight: "-35px" }}
            >
              <BsChevronRight />
            </div>
          }
          prevIcon={
            <div
              className=" rounded-circle text-dark d-flex align-items-center justify-content-center"
              style={{ fontSize: "30px", marginLeft: "-35px" }}
            >
              <BsChevronLeft className="" />
            </div>
          }
        >
          {data?.map((detail, index) => (
            <Carousel.Item key={index}>
              <BoostDataCard
                data={detail}
                index={index}
                setPausedSlide={setPausedSlide}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
      <Deposite />
    </div>
  );
};

export default BoostDataInnerPage;
