import React from "react";
import { useSelector } from "react-redux";
import { TfiUpload } from "react-icons/tfi";
const ScrollToTop = () => {
  const isNavbarVisible = useSelector((state) => state.nav.isNavbarVisible);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };
  return (
    <div className=" p-1">
      <div>
        {!isNavbarVisible && (
          <TfiUpload
            onClick={scrollToTop}
            title="Scroll to Top"
            className="position-fixed rounded p-2 mx-4 scroll-to-top"
          />
        )}
      </div>
    </div>
  );
};

export default ScrollToTop;
