import React from "react";
const IlustrationPagedummyComp = ({
  pageTitle,
  illustration,
  heading,
  description,
  descHeading,
  descSubHeading,
  subDesc1p1,
  subDesc1p2,
  subDesc1p3,
  illustration2,
  heading2,
  description2,
  descHeading2,
  descSubHeading2,
  subDesc2p1,
  subDesc2p2,
  subDesc2p3,
  illustration3,
  heading3,
  description3,
  descHeading3,
  descSubHeading3,
  subDesc3p1,
  subDesc3p2,
  subDesc3p3,

  descHeading4,
  subDesc4p1,
  subDesc4p2,
  subDesc4p3,
  descSubHeading4,
  description4,

  descHeading5,
  subDesc5p1,
  subDesc5p2,
  subDesc5p3,
  descSubHeading5,
  description5,

  descHeading6,
  subDesc6p1,
  subDesc6p2,
  subDesc6p3,
  descSubHeading6,
  description6,

  descHeading7,
  subDesc7p1,
  subDesc7p2,
  subDesc7p3,
  descSubHeading7,
  description7,
}) => {
  return (
    <div className="my-5 container ">
      <h1 className="fs-1 mb-5 text-center ">{pageTitle}</h1>
      <div>
        <div className="row">
          <div className="col-md-6 ">
            <img
              className="border p-1 rounded img-fluid  "
              src={illustration}
              alt="Privacy Policy"
            />
          </div>
          <div className="col-md-6">
            {heading && <h3>{heading}</h3>}
            <p className="fw-bold">{descHeading}</p>
            <br />
            {descSubHeading && (
              <>
                <p className="fw-emibold">{descSubHeading}</p>
                <br />
              </>
            )}
            {subDesc1p1 && (
              <>
                <p style={{ color: "rgba(0,0,0,0.75)", fontSize: "14px" }}>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{subDesc1p1}
                </p>
                <br />
              </>
            )}
            {subDesc1p2 && (
              <>
                {" "}
                <p style={{ color: "rgba(0,0,0,0.75)", fontSize: "14px" }}>
                  &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;{subDesc1p2}
                </p>
                <br />
              </>
            )}
            {subDesc1p3 && (
              <>
                {" "}
                <p style={{ color: "rgba(0,0,0,0.75)", fontSize: "14px" }}>
                  &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;{subDesc1p3}
                </p>
                <br />
              </>
            )}
            <p style={{ color: "rgba(0,0,0,0.75)", fontSize: "14px" }}>
              {description}
            </p>
          </div>
        </div>

        {descHeading2 && (
          <div className="row mt-5">
            <div className="col-md-6 d-none d-md-block">
              {heading2 && <h3>{heading2}</h3>}
              <p className="fw-bold">{descHeading2}</p>
              <br />
              {descSubHeading2 && (
                <>
                  <p className="fw-emibold">{descSubHeading2}</p>
                  <br />
                </>
              )}
              {subDesc2p1 && (
                <>
                  <p style={{ color: "rgba(0,0,0,0.75)", fontSize: "14px" }}>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{subDesc2p1}
                  </p>
                  <br />
                </>
              )}
              {subDesc2p2 && (
                <>
                  {" "}
                  <p style={{ color: "rgba(0,0,0,0.75)", fontSize: "14px" }}>
                    &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;{subDesc2p2}
                  </p>
                  <br />
                </>
              )}
              {subDesc2p3 && (
                <>
                  {" "}
                  <p style={{ color: "rgba(0,0,0,0.75)", fontSize: "14px" }}>
                    &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;{subDesc2p3}
                  </p>
                  <br />
                </>
              )}
              <p style={{ color: "rgba(0,0,0,0.75)", fontSize: "14px" }}>
                {description2}
              </p>
            </div>
            <div className="col-md-6 ">
              <img
                className="border p-1 rounded img-fluid  "
                src={illustration2}
                alt="Privacy Policy"
              />
            </div>
            <div className="col-md-6  d-md-none">
              {heading2 && <h3>{heading2}</h3>}
              <p className="fw-bold">{descHeading2}</p>
              <br />
              {descSubHeading2 && (
                <>
                  <p className="fw-emibold">{descSubHeading2}</p>
                  <br />
                </>
              )}
              {subDesc2p1 && (
                <>
                  <p style={{ color: "rgba(0,0,0,0.75)", fontSize: "14px" }}>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{subDesc2p1}
                  </p>
                  <br />
                </>
              )}
              {subDesc2p2 && (
                <>
                  {" "}
                  <p style={{ color: "rgba(0,0,0,0.75)", fontSize: "14px" }}>
                    &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;{subDesc2p2}
                  </p>
                  <br />
                </>
              )}
              {subDesc2p3 && (
                <>
                  {" "}
                  <p style={{ color: "rgba(0,0,0,0.75)", fontSize: "14px" }}>
                    &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;{subDesc2p3}
                  </p>
                  <br />
                </>
              )}
              <p style={{ color: "rgba(0,0,0,0.75)", fontSize: "14px" }}>
                {description2}
              </p>
            </div>
          </div>
        )}

        {descHeading3 && (
          <div className="row mt-5">
            <div className="col-md-6 ">
              <img
                className="border p-1 rounded img-fluid  "
                src={illustration3}
                alt="Privacy Policy"
              />
            </div>
            <div className="col-md-6">
              {heading3 && <h3>{heading3}</h3>}
              <p className="fw-bold">{descHeading3}</p>
              <br />
              {descSubHeading3 && (
                <>
                  <p className="fw-emibold">{descSubHeading3}</p>
                  <br />
                </>
              )}
              {subDesc3p1 && (
                <>
                  <p style={{ color: "rgba(0,0,0,0.75)", fontSize: "14px" }}>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{subDesc3p1}
                  </p>
                  <br />
                </>
              )}
              {subDesc3p2 && (
                <>
                  {" "}
                  <p style={{ color: "rgba(0,0,0,0.75)", fontSize: "14px" }}>
                    &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;{subDesc3p2}
                  </p>
                  <br />
                </>
              )}
              {subDesc3p3 && (
                <>
                  {" "}
                  <p style={{ color: "rgba(0,0,0,0.75)", fontSize: "14px" }}>
                    &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;{subDesc3p3}
                  </p>
                  <br />
                </>
              )}
              <p style={{ color: "rgba(0,0,0,0.75)", fontSize: "14px" }}>
                {description3}
              </p>
            </div>
          </div>
        )}

        {descHeading4 && (
          <div className="row mt-5">
            <div className="col-md-6">
              <p className="fw-bold">{descHeading4}</p>
              <br />
              {descSubHeading4 && (
                <>
                  <p className="fw-emibold">{descSubHeading4}</p>
                  <br />
                </>
              )}
              {subDesc4p1 && (
                <>
                  <p style={{ color: "rgba(0,0,0,0.75)", fontSize: "14px" }}>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{subDesc4p1}
                  </p>
                  <br />
                </>
              )}
              {subDesc4p2 && (
                <>
                  {" "}
                  <p style={{ color: "rgba(0,0,0,0.75)", fontSize: "14px" }}>
                    &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;{subDesc4p2}
                  </p>
                  <br />
                </>
              )}
              {subDesc4p3 && (
                <>
                  {" "}
                  <p style={{ color: "rgba(0,0,0,0.75)", fontSize: "14px" }}>
                    &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;{subDesc4p3}
                  </p>
                  <br />
                </>
              )}
              {description4 && (
                <p style={{ color: "rgba(0,0,0,0.75)", fontSize: "14px" }}>
                  {description4}
                </p>
              )}
            </div>
            <div className="col-md-6 ">
              <img
                className="border p-1 rounded img-fluid  "
                src={illustration2}
                alt="Privacy Policy"
              />
            </div>
          </div>
        )}

        {descHeading5 && (
          <div className="row mt-5">
            <div className="col-md-6 ">
              <img
                className="border p-1 rounded img-fluid  "
                src={illustration2}
                alt="Privacy Policy"
              />
            </div>
            <div className="col-md-6">
              <p className="fw-bold">{descHeading5}</p>
              <br />
              {descSubHeading5 && (
                <>
                  <p className="fw-emibold">{descSubHeading5}</p>
                  <br />
                </>
              )}
              {subDesc5p1 && (
                <>
                  <p style={{ color: "rgba(0,0,0,0.75)", fontSize: "14px" }}>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{subDesc5p1}
                  </p>
                  <br />
                </>
              )}
              {subDesc5p2 && (
                <>
                  {" "}
                  <p style={{ color: "rgba(0,0,0,0.75)", fontSize: "14px" }}>
                    &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;{subDesc5p2}
                  </p>
                  <br />
                </>
              )}
              {subDesc5p3 && (
                <>
                  {" "}
                  <p style={{ color: "rgba(0,0,0,0.75)", fontSize: "14px" }}>
                    &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;{subDesc5p3}
                  </p>
                  <br />
                </>
              )}
              <p style={{ color: "rgba(0,0,0,0.75)", fontSize: "14px" }}>
                {description5}
              </p>
            </div>
          </div>
        )}

        {descHeading6 && (
          <div className="row mt-5">
            <div className="col-md-6">
              {heading2 && <h3>{heading2}</h3>}
              <p className="fw-bold">{descHeading2}</p>
              <br />
              {descSubHeading6 && (
                <>
                  <p className="fw-emibold">{descSubHeading6}</p>
                  <br />
                </>
              )}
              {subDesc6p1 && (
                <>
                  <p style={{ color: "rgba(0,0,0,0.75)", fontSize: "14px" }}>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{subDesc6p1}
                  </p>
                  <br />
                </>
              )}
              {subDesc6p2 && (
                <>
                  {" "}
                  <p style={{ color: "rgba(0,0,0,0.75)", fontSize: "14px" }}>
                    &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;{subDesc6p2}
                  </p>
                  <br />
                </>
              )}
              {subDesc6p3 && (
                <>
                  {" "}
                  <p style={{ color: "rgba(0,0,0,0.75)", fontSize: "14px" }}>
                    &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;{subDesc6p3}
                  </p>
                  <br />
                </>
              )}
              <p style={{ color: "rgba(0,0,0,0.75)", fontSize: "14px" }}>
                {description6}
              </p>
            </div>
            <div className="col-md-6 ">
              <img
                className="border p-1 rounded img-fluid  "
                src={illustration2}
                alt="Privacy Policy"
              />
            </div>
          </div>
        )}

        {descHeading7 && (
          <div className="row mt-5">
            <div className="col-md-6 ">
              <img
                className="border p-1 rounded img-fluid  "
                src={illustration2}
                alt="Privacy Policy"
              />
            </div>
            <div className="col-md-6">
              {heading2 && <h3>{heading2}</h3>}
              <p className="fw-bold">{descHeading2}</p>
              <br />
              {descSubHeading7 && (
                <>
                  <p className="fw-emibold">{descSubHeading7}</p>
                  <br />
                </>
              )}
              {subDesc7p1 && (
                <>
                  <p style={{ color: "rgba(0,0,0,0.75)", fontSize: "14px" }}>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{subDesc7p1}
                  </p>
                  <br />
                </>
              )}
              {subDesc7p2 && (
                <>
                  {" "}
                  <p style={{ color: "rgba(0,0,0,0.75)", fontSize: "14px" }}>
                    &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;{subDesc7p2}
                  </p>
                  <br />
                </>
              )}
              {subDesc7p3 && (
                <>
                  {" "}
                  <p style={{ color: "rgba(0,0,0,0.75)", fontSize: "14px" }}>
                    &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;{subDesc7p3}
                  </p>
                  <br />
                </>
              )}
              <p style={{ color: "rgba(0,0,0,0.75)", fontSize: "14px" }}>
                {description7}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default IlustrationPagedummyComp;
