import React from "react";
import Modal from "./Modal";

const OpeningHourModal = ({ openingHourModal, setOpeningHourModal, data }) => {
  return (
    <>
      <Modal
        heading={"Data Submission"}
        show={openingHourModal}
        setShow={setOpeningHourModal}
      >
        <div>
          <p className="">
            Opening hours:{" "}
            <span className="fw-bold">
              {data?.opening_time}- {data?.closing_time}
            </span>
          </p>
        </div>
        <div>
          <button
            className="text-white px-4 py-1 rounded border-0"
            style={{
              background: "var(--primary-color-green)",
            }}
            onClick={() => {
              setOpeningHourModal(false);
            }}
          >
            Confirm
          </button>
        </div>
      </Modal>
    </>
  );
};

export default OpeningHourModal;
