import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isNavbarVisible: true, // Initially, the navbar is visible
};
export const NavSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    showNavabr: (state, action) => {
      state.isNavbarVisible = action.payload;
    },
  },
});
export const { showNavabr } = NavSlice.actions;
export default NavSlice.reducer;
