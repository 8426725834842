import React from "react";
import { useSelector } from "react-redux";

const HeadingWithSearchBar = ({ heading }) => {
  const isLight = useSelector((state) => state.darkLight);
  return (
    <div className="row gap-sm-0 gap-3">
      <div className="col-sm-6 heading-line">
        <h3
          style={{ fontSize: "23px", fontWeight: "500" }}
          className="heading-line"
        >
          {heading}
        </h3>
        <div className="d-flex align-items-center  gap-2 heading-highlight-text">
          <div
            className=""
            style={{
              height: "3px",
              width: "50px",
              background: "var(--primary-color-green)",
            }}
          />
          <div
            className=""
            style={{
              height: "3px",
              width: "20px",
              background: "var(--primary-color-green)",
            }}
          />
        </div>
      </div>
      {/* <div className="col-sm-6  search-bar-whith-heading">
        <form className="position-relative ">
          <CiSearch
            className={`position-absolute  ${
              isLight.lightDark ? "text-white" : "text-dark"
            } `}
            style={{ translate: "5px 0", top: "11px", left: "5px" }}
          />
          <input
            type="text"
            className=" py-2"
            placeholder="Search..."
            style={{ paddingLeft: "30px" }}
          />
        </form>
      </div> */}
    </div>
  );
};

export default HeadingWithSearchBar;
