import Carousel from "react-bootstrap/Carousel";
import { BsChevronLeft, BsChevronRight, BsStar } from "react-icons/bs";
import { useRef } from "react";
import NoResultFound from "./NoResultFound";

const NewProducts = ({ heading, homeCategory }) => {
  const carouselRef = useRef(null);

  const chunkArray = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };

  const groupedProducts = homeCategory
    .map((category) => chunkArray(category?.new_products || [], 5))
    .flat();

  const handlePrev = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
    }
  };

  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  return (
    <div>
      <div className="d-flex align-content-center justify-content-between ">
        <div className="mb-3">
          <h3 style={{ fontSize: "19px", fontWeight: "500" }}>{heading}</h3>
          <div className="d-flex align-items-center gap-2">
            <div
              className=""
              style={{
                height: "3px",
                width: "50px",
                background: "var(--primary-color-green)",
              }}
            />
            <div
              className=""
              style={{
                height: "3px",
                width: "20px",
                background: "var(--primary-color-green)",
              }}
            />
          </div>
        </div>
        <div className="d-flex gap-2">
          <div className="" style={{ cursor: "pointer" }} onClick={handlePrev}>
            <BsChevronLeft />
          </div>
          <div style={{ cursor: "pointer" }} onClick={handleNext}>
            <BsChevronRight />
          </div>
        </div>
      </div>
      <Carousel
        indicators={false}
        controls={false}
        ref={carouselRef}
        interval={null}
        prevIcon={false}
        nextIcon={false}
      >
        {groupedProducts?.length > 0 ? (
          groupedProducts.map((products, groupIndex) => (
            <Carousel.Item key={groupIndex}>
              {products.map((item, index) => (
                <div key={index} className="d-flex gap-3">
                  <div
                    className=""
                    style={{
                      height: "76px",
                      minWidth: "76px",
                      border: "1px solid var(--border-color-gray)",
                    }}
                  >
                    {item?.product_media?.map((media) => {
                      if (media?.default_image === true) {
                        return (
                          <img
                            key={media?.image}
                            src={media?.image}
                            className="w-100 h-100 "
                            alt="product"
                          />
                        );
                      }
                      return null;
                    })}
                  </div>
                  <div className="">
                    <p>
                      {" "}
                      {item?.item_name?.length > 50
                        ? `${item?.item_name.slice(0, 49)}...`
                        : item?.item_name}
                    </p>
                    <div className="d-flex align-items-center justify-content-center ">
                      <BsStar style={{ fontSize: "15px", color: "#f5ae0f" }} />
                      <BsStar style={{ fontSize: "15px", color: "#f5ae0f" }} />
                      <BsStar style={{ fontSize: "15px", color: "#f5ae0f" }} />
                      <BsStar style={{ fontSize: "15px", color: "#f5ae0f" }} />
                      <BsStar style={{ fontSize: "15px", color: "#f5ae0f" }} />
                    </div>
                    <p>{item?.selling_price}</p>
                  </div>
                </div>
              ))}
            </Carousel.Item>
          ))
        ) : (
          <NoResultFound />
        )}
      </Carousel>
    </div>
  );
};

export default NewProducts;
