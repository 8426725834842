import React, { useEffect, useMemo, useRef, useState } from "react";
import { PiHeadphonesLight } from "react-icons/pi";
import { FiUser } from "react-icons/fi";
import { RxChevronDown, RxCross2 } from "react-icons/rx";
import SignUpForm from "./headerAuthForm/SignUpForm";
import LogInForm from "./headerAuthForm/LogInForm";
import "react-phone-input-2/lib/style.css";
import OTP from "./headerAuthForm/OTP";
import DarkLightBtn from "../../DarkLightBtn";
import { useDispatch, useSelector } from "react-redux";
import { loginModalHide } from "../../../store/uiSlices/LoginModalSlice";
import Modal from "../../Modal";
import { selectLoginVal } from "../../../store/uiSlices/loginStateSlice";
import { TrackOrder } from "../../../store/Category/TrackOrderSlice";
import { TrackOrderComponent } from "../TrackOrderComponent";
import AdminOTPModal from "./AdminOTPModal";

const TopGreenNavbar = () => {
  const loginVal = useSelector(selectLoginVal);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(
    (state) => state.loginModal.isAuthModalShown
  );
  const [hideModal, setHideModal] = useState(false);
  const [resetFrom, setresetFrom] = useState(false);
  const [hideTrackModal, sethideTrackModal] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(true);
  const [showSignupForm, setShowSignupForm] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [showLanguageDropdownl, setshowLanguageDropdownl] = useState(false);
  const [message, setMessage] = useState("");

  const [showTrakeMyOrderResponse, setShowTrakeMyOrderResponse] =
    useState(false);
  const [adminOTPModal, setAdminOTPModal] = useState(false);

  const onHideModal = () => {
    setHideModal((prev) => !prev);
    dispatch(loginModalHide(true));
  };

  const onHideTrackModal = () => {
    sethideTrackModal((prev) => !prev);
  };

  const handleShowLangDropdown = () => {
    setshowLanguageDropdownl((prev) => !prev);
  };

  const langRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (langRef.current && !langRef.current.contains(e.target)) {
        setshowLanguageDropdownl(false);
      }
    };
    document.addEventListener("click", handleOutsideClick, true);
    return () => {
      document.removeEventListener("click", handleOutsideClick, true);
    };
  }, [langRef]);
  const hideLoginForm = () => {
    setShowLoginForm((prev) => !prev);
    setShowSignupForm(false);
  };

  const hideSignupForm = () => {
    setShowSignupForm((prev) => !prev);
    setShowLoginForm(false);
  };

  // =============== track my order ====================///

  const [values, setValues] = useState({ order_tracking_id: "" });
  const [errors, setErrors] = useState({});
  const handleReset = () => {
    setValues({ order_tracking_id: "" });
    setErrors({});
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!values.order_tracking_id) {
      setErrors({ order_tracking_id: "Order No is required" });
      return;
    }
    setErrors({});
    let orderTrackingId = values.order_tracking_id;
    dispatch(TrackOrder(orderTrackingId))
      .then((result) => {
        if (result?.payload?.meta?.status_code === 200) {
          console.log("result", result?.payload?.meta?.message);
          setMessage(result?.payload?.meta?.message);
          handleReset();
          setShowTrakeMyOrderResponse(true);
          sethideTrackModal(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
    if (value) {
      setErrors({ ...errors, [name]: "" });
    }
  };

  return (
    <div className="" style={{ background: "#008000" }}>
      <div
        className="container    w-100  text-white"
        style={{ padding: "8px 0" }}
      >
        <div className="row align-items-center w-100">
          {/* ============ top nav left ============= */}
          <div className="col-6 h-100 gap-2 hide-top-nav-top">
            <span>
              <PiHeadphonesLight className="fs-5 me-2 " />
            </span>
            <span style={{ fontSize: "0.875rem" }}>
              Have a question? <span>CALL US +44 7988 720466</span>
            </span>
          </div>
          {/* ============top nav right track order, sign in , language ============= */}
          <div className=" col-lg-6 d-flex align-items-center justify-content-end gap-2  gap-sm-3 ">
            <div
              className="d-flex align-items-center gap-1  px-1 "
              style={{ cursor: "pointer" }}
              onClick={() => {
                onHideTrackModal();
                setHideModal(false);
              }}
            >
              <FiUser className="d-none d-sm-block " />
              <p className="nav-top-text">Track My Order</p>
            </div>
            {!loginVal && (
              <div
                className="d-flex align-items-center gap-1  px-1"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  onHideModal();
                  sethideTrackModal(false);
                  setresetFrom(false);
                }}
              >
                <FiUser className="d-none d-sm-block " />
                <p className="nav-top-text">Sign In</p>
              </div>
            )}
            {/* =========language =========== */}
            <div ref={langRef} className="position-relative">
              <div
                className="d-flex align-items-center gap-1"
                style={{ cursor: "pointer" }}
                onClick={handleShowLangDropdown}
              >
                <p className="nav-top-text " style={{ paddingTop: "2.5px" }}>
                  English/USD
                </p>
                <RxChevronDown />
              </div>

              {/* ========= language drop down ============== */}
              <div
                className={`language-dropdown position-absolute ${
                  showLanguageDropdownl ? "open" : ""
                }  d-flex flex-column px-3 justify-content-center`}
              >
                <select
                  className="cruncy-select bg-white w-100"
                  style={{ cursor: "pointer" }}
                >
                  <option value="">USD</option>
                </select>
                <p className="text-dark mt-1" style={{ cursor: "pointer" }}>
                  English
                </p>
                <p className="text-dark" style={{ cursor: "pointer" }}>
                  French
                </p>
                <p className="text-dark" style={{ cursor: "pointer" }}>
                  Arabic
                </p>
              </div>
            </div>

            <DarkLightBtn />
          </div>
        </div>
        {/* ============== track my order =============== */}

        <div
          className={`${
            hideTrackModal ? "open" : ""
          } signAndTrackMyOrderOverlay`}
        >
          <div
            className={`${
              hideTrackModal ? "open" : ""
            } track-my-order rounded pt-3  pb-4`}
          >
            <div
              onClick={() => {
                onHideTrackModal();
                handleReset();
              }}
              className="d-flex justify-content-end px-2 "
            >
              <RxCross2
                className="text-dark "
                style={{ fontSize: "25px", cursor: "pointer" }}
              />
            </div>

            {/* <form className="text-center mx-5" onSubmit={handleSubmit}>
              <h2 className="fs-4 fw lh-md text-dark my-2">Track My Order</h2>
              <div style={{ height: "70px" }}>
                <input
                  type="text"
                  className="my-2 w-100"
                  placeholder="Order No"
                  name="order_tracking_id"
                  value={values.order_tracking_id}
                  onChange={handleChange}
                />
                {errors.order_tracking_id && (
                  <p
                    className="text-start"
                    style={{
                      color: "red",
                      fontSize: "15px",
                      marginTop: "-5px",
                    }}
                  >
                    {errors.order_tracking_id}
                  </p>
                )}
              </div>
              <button
                className="btn trak-sub-btn my-2 text-white ps-5 pe-5 w-100"
                style={{
                  background: "#008000",
                  boxShadow: `rgba(0, 0, 0, 0.1) 0 5px 5px 2px`,
                }}
                type="submit"
              >
                Track My Order
              </button>
            </form> */}
            <TrackOrderComponent
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              errors={errors}
              values={values}
            />
          </div>
        </div>

        {showTrakeMyOrderResponse && (
          <Modal
            show={showTrakeMyOrderResponse}
            setShow={setShowTrakeMyOrderResponse}
          >
            <p style={{ fontSize: "20px" }}>{message}</p>
            <button
              className="btn trak-sub-btn my-2 text-white ps-3 pe-3   "
              style={{
                background: "#008000",
                boxShadow: `rgba(0, 0, 0, 0.1) 0 5px 5px 2px`,
              }}
              onClick={() => {
                setShowTrakeMyOrderResponse(false);
              }}
            >
              ok
            </button>
          </Modal>
        )}

        {/* ============== sing in pop up =============== */}
        <div
          className={`${
            isAuthenticated ? "open" : ""
          } signAndTrackMyOrderOverlay`}
        >
          <div
            className={`${
              isAuthenticated ? "open" : ""
            } sing-in-popup rounded `}
            style={{ padding: "2.5rem 3rem 0rem 3rem" }}
          >
            <div
              onClick={() => {
                dispatch(loginModalHide(false));
                setresetFrom(true);
              }}
              className="d-flex justify-content-end "
              style={{ marginTop: "-20px", marginRight: "-30px" }}
            >
              <RxCross2
                className="text-dark "
                style={{ fontSize: "25px", cursor: "pointer" }}
              />
            </div>

            <div className="position-relative d-flex gap-5 ">
              <div
                className={` bg-transparent fw-medium ${
                  showLoginForm ? "active-auth-form" : "text-dark"
                }`}
                onClick={hideLoginForm}
                style={{ cursor: "pointer" }}
              >
                Log in
              </div>
              <div
                className={` bg-transparent fw-medium ${
                  showSignupForm ? "active-auth-form" : "text-dark"
                }`}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  hideSignupForm();
                }}
              >
                Sign up
              </div>
            </div>
            {/* =========== login form ================= */}
            <LogInForm
              showLoginForm={showLoginForm}
              setAdminOTPModal={setAdminOTPModal}
              onClick={onHideModal}
              singUpClick={hideSignupForm}
              resetFrom={resetFrom}
            />
            {/* ========== sign up form =========== */}
            <SignUpForm
              showSignupForm={showSignupForm}
              onClick={onHideModal}
              loginClick={hideLoginForm}
              resetFrom={resetFrom}
              setShowOTP={setShowOTP}
            />
          </div>
        </div>

        {/* ========== otp popup ============ */}

        <div className={`${showOTP ? "open" : ""} signAndTrackMyOrderOverlay`}>
          <div
            className={`${showOTP ? "open" : ""} sing-in-popup rounded `}
            style={{ padding: "2.5rem 3rem 0rem 3rem" }}
          >
            <div
              onClick={() => {
                setShowOTP(false);
              }}
              className="d-flex justify-content-end "
              style={{ marginTop: "-20px", marginRight: "-30px" }}
            >
              <RxCross2
                className="text-dark "
                style={{ fontSize: "25px", cursor: "pointer" }}
              />
            </div>

            <div className="position-relative d-flex justify-content-center gap-5 ">
              <OTP
                loginClick={hideLoginForm}
                setShowOTP={setShowOTP}
                onClick={onHideModal}
              />
            </div>
          </div>
        </div>
      </div>

      <>
        {adminOTPModal && (
          <AdminOTPModal
            adminOTPModal={adminOTPModal}
            setAdminOTPModal={setAdminOTPModal}
          />
        )}
      </>
    </div>
  );
};

export default TopGreenNavbar;
