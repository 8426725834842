import React, { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import "./commonCompStyle/ConfirmModal.css";
import { useDispatch, useSelector } from "react-redux";
import { confirmationModalHide } from "../store/uiSlices/LoginModalSlice";

const ConfirmationModal = ({ heading, scroll }) => {
  const [showResendButton, setShowResendButton] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [showSendButton, setShowSendButton] = useState(true);
  const isConfirmModalShow = useSelector(
    (state) => state.loginModal.isComfirmModalShown
  );
  const dispatch = useDispatch();

  const startTimer = () => {
    setTimeLeft(120);
    setShowSendButton(false);
    setShowResendButton(false);
  };

  useEffect(() => {
    let timer;

    const decrementTime = () => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          setShowResendButton(true);
        }
        return prevTime > 0 ? prevTime - 1 : 0;
      });
    };

    if (timeLeft > 0) {
      timer = setInterval(decrementTime, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [timeLeft]);

  return (
    <div className=" text-center shadow">
      <div
        className={`${isConfirmModalShow ? "open" : ""} confirmModalOverlay`}
      >
        <div
          className={`${
            isConfirmModalShow ? "open" : ""
          } confirmModalCard rounded pt-3  pb-4 shadow`}
          style={{
            maxWidth: `${450}px `,
            overflowY: `${scroll && "scroll"}`,
          }}
        >
          <div className="d-flex justify-content-between px-2 ">
            <h4
              className="fs-5 fw-bolder ms-3 mb-3"
              style={{ color: "var(--primary-color-green)" }}
            >
              {heading}
            </h4>
            <RxCross2
              onClick={() => {
                dispatch(confirmationModalHide(false));
              }}
              className="me-2 text-danger"
              style={{
                fontSize: "25px",
                cursor: "pointer",
                color: "var(--primary-color-green)",
              }}
            />
          </div>

          <div className=" mx-5 text-dark">
            {" "}
            <p
              className="fw-bold"
              style={{ color: "var(--primary-color-green)" }}
            >
              Confirm OTP
            </p>
            <div className="">
              <input
                type="text"
                name="email_code"
                className="mt-2 w-100"
                placeholder="Email Code"
              />
              {showSendButton && (
                <div
                  className="text-white"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    bottom: "35px",
                    left: "305px",
                    fontWeight: "500",
                    width: "45px",
                    height: "35px",
                    background: "#008000",
                    boxShadow: "rgba(0, 0, 0, 0.1) 0 5px 5px 2px",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  onClick={startTimer}
                >
                  Send
                </div>
              )}

              {timeLeft > 0 && (
                <p className="text-dark">
                  OTP expires in:{" "}
                  <span className="fw-bold text-danger">
                    0{formatTime(timeLeft)}
                  </span>
                </p>
              )}

              {showResendButton && (
                <div
                  className="d-flex align-items-center justify-content-between gap-3 my-1"
                  style={{ fontSize: "14px" }}
                >
                  <p className="text-dark">Didn't receive the email code? </p>
                  <div
                    onClick={startTimer}
                    style={{
                      color: "var(--primary-color-green)",
                      cursor: "pointer",
                    }}
                  >
                    Resend
                  </div>
                </div>
              )}
            </div>
            <button
              className="btn trak-sub-btn my-2 text-white ps-5 pe-5 w-100 "
              style={{
                background: "#008000",
                boxShadow: `rgba(0, 0, 0, 0.1) 0 5px 5px 2px`,
              }}
              onClick={() => {
                dispatch(confirmationModalHide(false));
                dispatch(commonModalHide(false));
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
