import React from "react";

export const TrackOrderComponent = ({
  handleSubmit,
  handleChange,
  values,
  errors,
}) => {
  return (
    <form className="text-center mx-5" onSubmit={handleSubmit}>
      <h2 className="fs-4 fw lh-md text-dark my-2">Track My Order</h2>
      <div style={{ height: "70px" }}>
        <input
          type="text"
          className="my-2 w-100"
          placeholder="Order No"
          name="order_tracking_id"
          value={values.order_tracking_id}
          onChange={handleChange}
        />
        {errors.order_tracking_id && (
          <p
            className="text-start"
            style={{
              color: "red",
              fontSize: "15px",
              marginTop: "-5px",
            }}
          >
            {errors.order_tracking_id}
          </p>
        )}
      </div>
      <button
        className="btn trak-sub-btn my-2 text-white ps-5 pe-5 w-100"
        style={{
          background: "#008000",
          boxShadow: `rgba(0, 0, 0, 0.1) 0 5px 5px 2px`,
        }}
        type="submit"
      >
        Track My Order
      </button>
    </form>
  );
};
