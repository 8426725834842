import { createSlice } from "@reduxjs/toolkit";
const uservalue = localStorage.getItem("userLogin");
const tokenVALUE = JSON.parse(uservalue);
const loginStateSlice = createSlice({
  name: "login",
  initialState: tokenVALUE?.data?.token?.access ? true : false,
  reducers: {
    setLoginVal: (state, action) => {
      return action.payload;
    },
  },
});

export const { setLoginVal } = loginStateSlice.actions;
export const selectLoginVal = (state) => state.login;

export default loginStateSlice.reducer;
