import React from "react";
import { TbSend } from "react-icons/tb";

const SubscribeNewsFooter = () => {
  return (
    <div
      className=""
      style={{
        borderTop: "1px solid var(--border-color-gray)",
      }}
    >
      {" "}
      <div className="container py-4">
        <div className="row ">
          <div
            className=" col-lg-7 d-flex align-items-center justify-content-center justify-content-lg-start  gap-3 mb-3 mb-lg-0 subscribe-form"
            style={{ fontSize: "21px" }}
          >
            <TbSend style={{ fontSize: "30px" }} />
            <p className="">Subscribe for news and Offers</p>
          </div>
          <form className="col-lg-5 d-flex  justify-content-end gap-3">
            <input
              type="text"
              className="rounded-pill   w-100"
              placeholder="Enter your email"
              style={{
                border: "1px solid var(--border-color-gray)",
                boxShadow: "rgba(0,0,0,.1) 0 1px 5px 0 inset",
                paddingLeft: "20px",
                outline: "none",
                fontSize: "15px",
              }}
            />
            <button
              type="submit"
              className="btn  rounded-pill text-white subscribe-submit-btn"
              style={{
                padding: "10px 25px",
                background: "var(--primary-color-green)",
              }}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SubscribeNewsFooter;
