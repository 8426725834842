import React, { useEffect } from "react";
import BoostDataInnerPage from "./BoostDataInnerPage";
import fourthImage from "../../../assets/image/product/thumb/4set.png";
import fifthImage from "../../../assets/image/product/thumb/5set.png";
import sixthImage from "../../../assets/image/product/thumb/6set.png";
import { AllSetsRecord } from "../../../store/BoostDataSlice/GetAllSetSlice";
import { useDispatch, useSelector } from "react-redux";

const exprtData = [
  {
    id: 4,

    title: "FOURTH SET",
    star: [1, 2, 3, 4],
    list: [
      { li: "5% BONUS DEPOSIT" },
      { li: "DAILY COMMISSIONS PLUS CHECK-IN BONUS" },
      { li: "RECEIVE 500 USDT BASIC SALARY" },
      { li: "SUBMIT PRODUCTS IN PRICE RANGE OF 500" },
      { li: "ONLY ONE SET IS ENOUGH TO GET BASIC SALARY" },
      { li: "WORK ONLY 10 MIN DAILY" },
      { li: "FUNDS WITHDRAWABLE" },
    ],
  },
  {
    id: 5,
    title: "FIFTH SET",

    star: [1, 2, 3, 4, 5],
    list: [
      { li: "5% BONUS DEPOSIT" },
      { li: "DAILY COMMISSIONS PLUS CHECK-IN BONUS" },
      { li: "RECEIVE 500 USDT BASIC SALARY" },
      { li: "SUBMIT PRODUCTS IN PRICE RANGE OF 1000" },
      { li: "ONLY ONE SET IS ENOUGH TO GET BASIC SALARY" },
      { li: "WORK ONLY 10 MIN DAILY" },
      { li: "FUNDS WITHDRAWABLE" },
    ],
  },
  {
    id: 6,
    title: "SIXTH SET",

    star: [1, 2, 3, 4, 5, 6],
    list: [
      { li: "5% BONUS DEPOSIT" },
      { li: "DAILY COMMISSIONS PLUS CHECK-IN BONUS" },
      { li: "RECEIVE 500 USDT BASIC SALARY" },
      { li: "SUBMIT PRODUCTS IN PRICE RANGE OF 2000" },
      { li: "ONLY ONE SET IS ENOUGH TO GET BASIC SALARY" },
      { li: "WORK ONLY 10 MIN DAILY" },
      { li: "FUNDS WITHDRAWABLE" },
    ],
  },
];

const Expert = () => {
  const dispatch = useDispatch();
  const allSets = useSelector((state) => state?.GetAllSetSlice);
  useEffect(() => {
    dispatch(AllSetsRecord()).then((result) => {
      if (result?.error?.message === "Request failed with status code 401") {
        dispatch(setLoginVal(false));
        localStorage.removeItem("userLogin");
      }
    });
  }, []);

  if (allSets && allSets?.data) {
    const remainingData = allSets?.data.slice(3);
    const mergedData = remainingData.map((backendObj) => {
      const frontendObj = exprtData.find((f) => f.title === backendObj.name);
      if (frontendObj) {
        return {
          ...backendObj,
          star: frontendObj.star,
          list: frontendObj.list,
        };
      } else {
        return backendObj;
      }
    });

    return (
      <div className="container py-4">
        <BoostDataInnerPage
          data={mergedData}
          title={"EXPERT"}
          subTitle={"SUBMITTING ONE OF THE 3 SETS TO GET YOUR BASIC SALARY."}
        />
      </div>
    );
  }
};

export default Expert;
