import React from "react";
import ForgetPasswordComp from "../components/ForgetPasswordComp";
const ForgetPassowrd = () => {
  return (
    <div>
      <ForgetPasswordComp />
    </div>
  );
};

export default ForgetPassowrd;
