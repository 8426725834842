import React from "react";
import image from "../../assets/image/product/shoes/boot1black.jpg";

const RecentProductCard = ({ homeCategory }) => {
  const categoryProduct = homeCategory?.filter(
    (category) => category?.menu_position === 1
  );
  const categoryProduct2 = homeCategory?.filter(
    (category) => category?.menu_position === 2
  );
  console.log("categoryProduct", categoryProduct[0]?.products[0]?.item_name);
  return (
    <>
      <div className="my-3 d-flex mt-4 gap-2 ">
        <div>
          <div
            className=" rounded overflow-hidden bg-dark "
            style={{ maxHeight: "60px", width: "100px" }}
          >
            <img
              src={categoryProduct[0]?.products[0]?.product_media[0]?.image}
              className="w-100"
              height={60}
            />
          </div>
          <button
            className="border-0 rounded  px-3 py-1 mt-2 w-100"
            style={{
              background: "#eee",
              color: "var(--primary-color-green)",
            }}
          >
            Edit
          </button>
        </div>

        <div className="d-flex flex-column justify-content-between">
          <div>
            <h5 className=" fw-semibold mb-2" style={{ fontSize: "16px" }}>
              {categoryProduct[0]?.products[0]?.item_name?.length > 50
                ? `${categoryProduct[0]?.products[0]?.item_name.slice(
                    0,
                    29
                  )}...`
                : categoryProduct[0]?.products[0]?.item_name}
            </h5>
            <p
              style={{
                color: "darkgray",
                fontSize: "14px",
                lineHeight: "18px",
              }}
            >
              {categoryProduct[0]?.products[0]?.item_description?.length > 50
                ? `${categoryProduct[0]?.products[0]?.item_description.slice(
                    0,
                    49
                  )}...`
                : categoryProduct[0]?.products[0]?.item_description}
            </p>
          </div>

          <p>
            <span
              style={{
                color: "darkgray",
                fontSize: "14px",
                lineHeight: "18px",
              }}
            >
              Price:
            </span>
            <span className="fw-semibold">
              {categoryProduct[0]?.products[0]?.selling_price}$
            </span>
          </p>
        </div>
      </div>
      <div className="my-3 d-flex mt-4 gap-2 ">
        <div>
          <div
            className=" rounded overflow-hidden bg-dark "
            style={{ maxHeight: "60px", width: "100px" }}
          >
            <img
              src={categoryProduct[0]?.products[0]?.product_media[0]?.image}
              className="w-100"
              height={60}
            />
          </div>
          <button
            className="border-0 rounded  px-3 py-1 mt-2 w-100"
            style={{
              background: "#eee",
              color: "var(--primary-color-green)",
            }}
          >
            Edit
          </button>
        </div>

        <div className="d-flex flex-column justify-content-between">
          <div>
            <h5 className=" fw-semibold mb-2" style={{ fontSize: "16px" }}>
              {categoryProduct2[0]?.products[0]?.item_name?.length > 50
                ? `${categoryProduct2[0]?.products[0]?.item_name.slice(
                    0,
                    29
                  )}...`
                : categoryProduct2[0]?.products[0]?.item_name}
            </h5>
            <p
              style={{
                color: "darkgray",
                fontSize: "14px",
                lineHeight: "18px",
              }}
            >
              {categoryProduct2[0]?.products[0]?.item_description?.length > 50
                ? `${categoryProduct2[0]?.products[0]?.item_description.slice(
                    0,
                    49
                  )}...`
                : categoryProduct2[0]?.products[0]?.item_description}
            </p>
          </div>

          <p>
            <span
              style={{
                color: "darkgray",
                fontSize: "14px",
                lineHeight: "18px",
              }}
            >
              Price:
            </span>
            <span className="fw-semibold">
              {categoryProduct2[0]?.products[0]?.selling_price}$
            </span>
          </p>
        </div>
      </div>
    </>
  );
};

export default RecentProductCard;
