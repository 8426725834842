import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthModalShown: false,
  isCommonModalShown: false,
  isCongratulationModalShown: false,
  isBottomFullModalShown: false,
  isComfirmModalShown: false,
  isDepositeModalShown: false,
};

const LoginModalSlice = createSlice({
  name: "Login Modal",
  initialState,
  reducers: {
    loginModalHide: (state, action) => {
      state.isAuthModalShown = action.payload;
    },

    commonModalHide: (state, action) => {
      state.isCommonModalShown = action.payload;
    },
    cogratulationModalHide: (state, action) => {
      state.isCongratulationModalShown = action.payload;
    },

    bottomFullModalHide: (state, action) => {
      state.isBottomFullModalShown = action.payload;
    },

    confirmationModalHide: (state, action) => {
      state.isComfirmModalShown = action.payload;
    },
    depositeModalHide: (state, action) => {
      state.isDepositeModalShown = action.payload;
    },
  },
});

export const {
  loginModalHide,
  commonModalHide,
  cogratulationModalHide,
  bottomFullModalHide,
  confirmationModalHide,
  depositeModalHide,
} = LoginModalSlice.actions;
export default LoginModalSlice.reducer;
