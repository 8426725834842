import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  firstLoadig: false,
};

const priceRangeFirstLoadingSlice = createSlice({
  name: "priceRangeFirstLoading",
  initialState,
  reducers: {
    setPriceRangeFirstLoading: (state, action) => {
      state.firstLoadig = action.payload;
    },
  },
});

export const { setPriceRangeFirstLoading } =
  priceRangeFirstLoadingSlice.actions;
export default priceRangeFirstLoadingSlice.reducer;
