import React, { useEffect, useRef } from "react";
import illustration from "../assets/image/link_page/deliveryInfo1.jpg";
import IlustrationPagedummyComp from "../common/IlustrationPagedummyComp";
import HelmetComp from "../common/HelmetComp";
import Layout from "../common/layout/Layout";
import { useDispatch } from "react-redux";
import { showNavabr } from "../store/uiSlices/NavSlice";
import BreadCrumbs from "../common/BreadCrumbs";
import HeroSlider from "../common/HeroSlider";
import watchImage from "../assets/image/slider/156c27224ff4389c0bbe6ca8e3daeb7c.png";
import chairImage from "../assets/image/slider/c1c7140f4146c8d083042e49465f0c62.png";
import headPhones from "../assets/image/slider/a306c6a84a161de39e219011a9315d20.png";
const DeliveryInfo = () => {
  const images = [
    { img: watchImage },
    { img: chairImage },
    { img: headPhones },
  ];
  const dispatch = useDispatch();
  const deliveryRef = useRef(null);

  useEffect(() => {
    const option = {
      root: null,
      threshold: 0,
    };

    const heroObsrver = (enteries) => {
      const entery = enteries[0];
      if (entery.isIntersecting) {
        dispatch(showNavabr(true));
      } else {
        dispatch(showNavabr(false));
      }
    };

    const observer = new IntersectionObserver(heroObsrver, option);
    if (deliveryRef.current) {
      observer.observe(deliveryRef.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [deliveryRef]);
  return (
    <Layout>
      <HelmetComp title={"Delivery Info"} />
      <div ref={deliveryRef}>
        <HeroSlider images={images} />
        {/* <BreadCrumbs link={"/delivery-info"} category={"Delivery Infomation"} /> */}
      </div>
      <IlustrationPagedummyComp
        illustration={illustration}
        pageTitle={"Delivery Information"}
        descHeading={""}
        descSubHeading={""}
        subDesc1p1={""}
        subDesc1p2={""}
        subDesc1p3={""}
        description={
          "At Get-It-On, our goal is to provide you with an exceptional online shopping experience, including fast and reliable delivery. We believe in transparency and customer satisfaction, which is why we have developed this delivery policy to inform you about our delivery procedures and commitments."
        }
        illustration2={illustration}
        descHeading2={"Delivery times"}
        subDesc2p1={
          "Order Processing Time: Once you have placed your order, we will process it within 24 working hours. During this time we will check product availability, prepare your order and ensure it is in optimum condition before dispatch."
        }
        subDesc2p2={
          "Standard Delivery Times: Our standard delivery times vary depending on your location and the delivery method you have chosen. You will find an accurate estimate of delivery times when you place your order."
        }
        subDesc2p3={
          "Express Delivery Times: If you need to receive your items more quickly, we also offer express delivery options at an additional cost. Details are available at checkout."
        }
        descSubHeading2={""}
        description2={""}
        illustration3={illustration}
        descHeading3={"Delivery charges"}
        subDesc3p1={
          "Standard Delivery: Standard delivery charges depend on the weight and size of the item and your location. Specific details are available when you place your order."
        }
        subDesc3p2={
          "Express Delivery: Express delivery charges are calculated according to the speed of delivery and your location. Prices are displayed when you place your order."
        }
        subDesc3p3={""}
        descSubHeading3={""}
        description3={""}
        descHeading4={"Order tracking"}
        subDesc4p1={""}
        subDesc4p2={""}
        subDesc4p3={""}
        descSubHeading4={""}
        description4={
          "As soon as your order is dispatched, you will receive confirmation by e-mail with a tracking number. You can then track your parcel in real time and be informed of its delivery status."
        }
        descHeading5={"International delivery"}
        subDesc5p1={""}
        subDesc5p2={""}
        subDesc5p3={""}
        descSubHeading5={""}
        description5={
          "We offer international delivery for our customers all over the world. Delivery times and costs vary depending on your destination country. Please note that customs duties or import taxes may apply depending on the local laws of your country."
        }
        descHeading6={"Receiving delivery"}
        subDesc6p1={""}
        subDesc6p2={""}
        subDesc6p3={""}
        descSubHeading6={""}
        description6={
          "Please ensure that you are available to receive your order at the delivery address specified. If you are not available, the carrier may leave a calling card or arrange a new delivery attempt."
        }
        descHeading7={"Customer service"}
        subDesc7p1={""}
        subDesc7p2={""}
        subDesc7p3={""}
        descSubHeading7={""}
        description7={
          "Our customer service team is here to answer all your questions about delivery and any other concerns you may have. Please feel free to contact us directly via Whatsapp or Telegram. We are committed to providing a quality delivery service and making your online shopping experience a pleasant one. Thank you for trusting us with your delivery needs."
        }
      />
    </Layout>
  );
};

export default DeliveryInfo;
