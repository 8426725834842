import React, { useEffect, useRef, useState } from "react";
import { PiCopySimpleLight } from "react-icons/pi";
import user from "../../assets/image/defaultUser.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { BalanceAndProfit } from "../../store/BoostDataSlice/GetBalanceandProfitSlice";

const ProfileCard = () => {
  const dispatch = useDispatch();
  const prfileData = useSelector(
    (state) => state?.ProfileRecordSlice?.data?.data
  );
  const balance = useSelector((state) => state?.GetBalanceRecords?.data?.data);
  const copyRef = useRef(null);
  const [copy, setCopy] = useState(false);
  useEffect(() => {
    dispatch(BalanceAndProfit());
  }, []);

  const copyText = async () => {
    if (copyRef.current) {
      const text = copyRef.current.innerText;
      try {
        await navigator.clipboard.writeText(text);
        setCopy(true);
        setTimeout(() => {
          setCopy(false);
        }, 1000);
      } catch (error) {}
    }
  };

  return (
    <div className="position-relative d-flex flex-column  align-items-center profile-card ">
      <div
        className="position-absolute d-flex align-items-center gap-1"
        style={{ top: "-25px" }}
      >
        <div
          className="nav-profile-cont d-flex align-items-center justify-content-center rounded-circle "
          style={{
            background: "#eee",
            cursor: "pointer",
            minHeight: "50px",
            minWidth: "50px",
          }}
        >
          <div
            className="nav-profile-img rounded-circle overflow-hidden d-flex align-items-center justify-content-center "
            style={{
              minHeight: "45px",
              minWidth: "45px",
            }}
          >
            <img
              src={
                prfileData?.user_profile?.profile_picture
                  ? prfileData?.user_profile?.profile_picture
                  : user
              }
              className="h-100 w-100  object-fit-cover"
            />
          </div>
        </div>
      </div>
      <div
        className="w-100 rounded shadow pt-4 pb-3 text-center"
        style={{ background: "#fff" }}
      >
        {/* ======= simple bio ======== */}

        <div className="position-relative">
          <h6 className="mt-2 fs-5 ">
            {prfileData?.username !== "null" ? prfileData?.username : "-"}
          </h6>
          <div
            className="d-flex justify-content-center position-relative gap-2 "
            style={{
              color: "var(--primary-color-green)",
              marginTop: "-5px",
            }}
          >
            <div className="d-flex align-items-center ">
              <p style={{ fontSize: "13px" }}>Referral Code: </p>
              <p
                className="fw-semibold"
                ref={copyRef}
                style={{ cursor: "pointer", fontSize: "14px" }}
              >
                {prfileData?.referral_code}
              </p>
            </div>
            <div
              onClick={() => {
                copyText();
              }}
            >
              <PiCopySimpleLight
                className="fs-6"
                style={{ cursor: "pointer" }}
              />
            </div>
            {copy && (
              <div
                className="position-absolute px-2 py-1 shadow "
                style={{ top: "-2.5px", right: "5px" }}
              >
                Copied
              </div>
            )}
          </div>
        </div>

        {/* ====== credit progress bar ========== */}
        <div className="row position-relative  align-items-center my-3">
          <p className="col-sm-4 ms-2" style={{ fontSize: "13px" }}>
            Credit Score:{" "}
          </p>
          <div
            className="col-8  col-sm-6 position-relative profile-cradit-bar"
            style={{ height: "3px", background: "var(--primary-color-green)" }}
          >
            <div
              className="position-absolute text-light rounded px-2"
              style={{
                fontSize: "12px",
                top: "-7px",
                left: `${85}%`,
                background: "var(--primary-color-green)",
              }}
            >
              100
            </div>
          </div>
        </div>

        {/*========== */}

        <div className="d-flex align-items-center  justify-content-around ">
          <div className="px-2">
            <p className="fw-semibold" style={{ fontSize: "13px " }}>
              USDT{" "}
              <span>{balance?.total_profit ? balance?.total_profit : 0}</span>
            </p>
            <p
              style={{ fontSize: "11px", color: "var(--primary-color-green)" }}
            >
              Today's Profit
            </p>
          </div>
          <div className="px-2">
            <p className="fw-semibold" style={{ fontSize: "13px " }}>
              USDT{" "}
              <span>{balance?.total_balance ? balance?.total_balance : 0}</span>
            </p>
            <p
              style={{ fontSize: "11px", color: "var(--primary-color-green)" }}
            >
              Total Balance
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
