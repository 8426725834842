import React, { useEffect, useRef, useState } from "react";
import Layout from "../common/layout/Layout";
import { BsThreeDots } from "react-icons/bs";
import PickUpCard from "./CheckoutComponent/PickUpCard";
import RecentProductCard from "./CheckoutComponent/RecentProductCard";
import DeleviryForm from "./CheckoutComponent/DeleviryForm";
import PaymentFrom from "./CheckoutComponent/PaymentFrom";
import PurchaseScreen from "./CheckoutComponent/PurchaseScreen";
import { BsBusFrontFill } from "react-icons/bs";
import { FaCreditCard } from "react-icons/fa";
import { PiHandshakeFill } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { showNavabr } from "../store/uiSlices/NavSlice";
import { HomeCategory } from "../store/Category/HomeCategorySlice";

const CheckoutInfo = [
  {
    id: 1,
    title: "Delivery Address",
    description: "Setup Your Address",
    icon: BsBusFrontFill,
  },
  {
    id: 2,
    title: "Payment",
    description: "Payment Option",
    icon: FaCreditCard,
  },
  {
    id: 3,
    title: "Purchase",
    description: "Review and Submit",
    icon: PiHandshakeFill,
  },
];

const CheckoutScreen = () => {
  const dispatch = useDispatch();
  const [currentId, setCurrentId] = useState(1);
  const [prev, setPrev] = useState(false);
  const [next, setNext] = useState(true);
  const homeCategory = useSelector((state) =>
    state?.HomeCategory?.data?.data ? state?.HomeCategory?.data?.data : []
  );
  console.log("homeCategory", homeCategory);
  const prevFucn = () => {
    setCurrentId((prev) => {
      if (prev === 1) {
        return 1;
      } else if (prev > 1) {
        setNext(false);
        setPrev(true);
        return (prev -= 1);
      }
    });
  };
  const nextFucn = (inc) => {
    setCurrentId((prev) => {
      if (prev === 3) {
        return 3;
      } else if (prev < 3) {
        setNext(true);
        setPrev(false);
        return (prev += 1);
      }
    });
  };
  useEffect(() => {
    dispatch(HomeCategory());
  }, []);

  useEffect(() => {
    if (currentId === 1) {
      setNext(true);
      setPrev(false);
    }
  }, [currentId === 1]);

  const checkoutRef = useRef(null);

  useEffect(() => {
    const option = {
      root: null,
      threshold: 0,
    };

    const heroObsrver = (enteries) => {
      const entery = enteries[0];
      if (entery.isIntersecting) {
        dispatch(showNavabr(true));
      } else {
        dispatch(showNavabr(false));
      }
    };

    const observer = new IntersectionObserver(heroObsrver, option);
    if (checkoutRef.current) {
      observer.observe(checkoutRef.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [checkoutRef]);

  return (
    <Layout>
      <div style={{ background: "#eee" }}>
        {" "}
        <div className="container  py-5 ">
          <div ref={checkoutRef} className="row">
            <div className="col-3 d-none   d-xl-flex flex-column gap-4 ">
              <div className="rounded px-4 py-3 bg-white ">
                <div className="d-flex align-items-center  justify-content-between ">
                  <div>
                    <h3 className="fs-5 fw-semibold ">Book to Pickup</h3>
                    <p style={{ color: "darkgray", fontSize: "14px" }}>
                      24 Books to Return
                    </p>
                  </div>
                  <BsThreeDots
                    style={{
                      color: "darkgray",
                      fontSize: "17px",
                      cursor: "pointer",
                    }}
                  />
                </div>
                <PickUpCard homeCategory={homeCategory} key={1} />
              </div>
              <div className="rounded px-4 py-3 bg-white ">
                <div className="d-flex align-items-center  justify-content-between ">
                  <div>
                    <h3 className="fs-5 fw-semibold ">Recent Products</h3>
                    <p style={{ color: "darkgray", fontSize: "14px" }}>
                      New Arrivals
                    </p>
                  </div>
                  <BsThreeDots
                    style={{
                      color: "darkgray",
                      fontSize: "17px",
                      cursor: "pointer",
                    }}
                  />
                </div>
                <RecentProductCard homeCategory={homeCategory} key={1} />
              </div>
            </div>
            <div className="col-xl-9  bg-white px-4 py-3 rounded d-flex flex-column justify-content-between">
              {/* =========== checkout  nav bar =========== */}
              <div className=" row justify-content-between ">
                {CheckoutInfo.map((nav, index) => (
                  <>
                    <div
                      key={index}
                      className=" col-4 d-none d-lg-flex align-items-center gap-3 py-3 rounded-top chackout-top-nav"
                      style={{
                        maxWidth: "30%",
                        background: "#eee",
                      }}
                    >
                      <div
                        className="rounded p-2 d-flex align-items-center  justify-content-center "
                        style={{
                          height: "40px",
                          width: "40px",
                          background: `${
                            nav.id === currentId
                              ? "var(--primary-color-green)"
                              : "#00800024"
                          }`,
                          color: `${
                            nav.id === currentId
                              ? "#ffffff"
                              : "var(--primary-color-green)"
                          }`,
                          cursor: "pointer",
                        }}
                      >
                        <p>{nav.id}</p>
                      </div>
                      <div>
                        <h6
                          className="fw-semibold"
                          style={{
                            color: `${
                              nav.id === currentId
                                ? "var(--primary-color-green)"
                                : "#000"
                            }`,
                          }}
                        >
                          {nav.title}
                        </h6>
                        <p>{nav.description}</p>
                      </div>
                    </div>

                    {/* ==================== mob view ============== */}
                    <div
                      key={nav.id}
                      className="border col-4  d-flex d-lg-none  align-items-center  gap-3 py-3 rounded-top chackout-top-nav"
                      style={{
                        maxWidth: "30%",
                        background: `${nav.id === currentId ? "#fff" : "#eee"}`,
                      }}
                    >
                      <div
                        className="coun-checkout rounded p-2 d-flex align-items-center  justify-content-center "
                        style={{
                          height: "40px",
                          width: "40px",
                          background: `${
                            nav.id === currentId
                              ? "var(--primary-color-green)"
                              : "#00800024"
                          }`,
                          color: `${
                            nav.id === currentId
                              ? "#ffffff"
                              : "var(--primary-color-green)"
                          }`,
                          cursor: "pointer",
                        }}
                      >
                        <p>{nav.id}</p>
                      </div>

                      <div>
                        <nav.icon
                          className="fs-3"
                          style={{
                            color: "var(--primary-color-green)",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ))}
              </div>
              <div className="my-5 flex-fill checkout-from h-100 ">
                {currentId === 1 && (
                  <DeleviryForm
                    prevFucntion={prevFucn}
                    nextFucntion={nextFucn}
                    currentId={currentId}
                    prev={prev}
                    next={next}
                  />
                )}
                {currentId === 2 && (
                  <PaymentFrom
                    prevFucntion={prevFucn}
                    nextFucntion={nextFucn}
                    currentId={currentId}
                    prev={prev}
                    next={next}
                  />
                )}
                {currentId === 3 && (
                  <PurchaseScreen
                    prevFucntion={prevFucn}
                    nextFucntion={nextFucn}
                    currentId={currentId}
                    prev={prev}
                    next={next}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CheckoutScreen;
