import React, { useState } from "react";
import "./Detail.css";
import { BiMinus, BiPlus } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { itemCounter } from "../../store/uiSlices/AddToCartSlice";

const IncDecQuantity = ({ product }) => {
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);

  const handleIncrease = (id) => {
    dispatch(itemCounter({ id: id, change: "increment" }));
  };

  const handleDecrease = (item) => {
    if (item.count > 1) {
      dispatch(itemCounter({ id: item.id, change: "decrement" }));
    }
  };
  return (
    <div className="d-flex align-items-center justify-content-center border rounded align-items-center">
      <button
        className="count-btn btn"
        onClick={() => {
          handleDecrease(product);
        }}
      >
        {" "}
        <BiMinus className="count-btn" />
      </button>
      <span className="mx-2">{quantity}</span>
      <button
        className="count-btn btn"
        onClick={() => {
          handleIncrease(product?.id);
        }}
      >
        <BiPlus className="count-btn" />
      </button>
    </div>
  );
};

export default IncDecQuantity;
