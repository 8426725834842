import React, { useEffect } from "react";
import VipCard from "../../../common/VipCard";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "react-bootstrap/Carousel";
import vipImage from "../../../assets/image/product/thumb/VIP.png";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { VipLevelRecord } from "../../../store/BoostDataSlice/GetAllVIPLevelRecordSlice";
const vipCardData = [
  {
    id: 1,
    title: "VIP1",
    img: vipImage,
    star: [1],
  },
  {
    id: 2,
    title: "VIP2",
    img: vipImage,
    star: [1, 2],
  },
  {
    id: 3,
    title: "VIP3",
    img: vipImage,
    star: [1, 2, 3],
  },
  {
    id: 4,
    title: "VIP4",
    img: vipImage,
    star: [1, 2, 3, 4],
  },
];

const VipLevel = () => {
  const dispatch = useDispatch();
  const isLight = useSelector((state) => state.darkLight);
  const vipLevel = useSelector((state) => state?.vipLevelRecord);

  useEffect(() => {
    dispatch(VipLevelRecord()).then((result) => {
      if (result?.error?.message === "Request failed with status code 401") {
        dispatch(setLoginVal(false));
        localStorage.removeItem("userLogin");
      }
    });
  }, []);

  if (vipLevel && vipLevel?.data && vipLevel?.data && vipLevel?.data?.data) {
    const mergedData = vipLevel.data.data.map((backendObj) => {
      const frontendObj = vipCardData.find(
        (f) => f.title === backendObj.subscription_type_name
      );
      if (frontendObj) {
        const vipTask = backendObj?.vip_task;
        const maxTask = backendObj?.max_task;
        const subscription_type_name = backendObj?.subscription_type_name;
        const vip_amount = backendObj?.vip_amount;
        const integerValue = parseInt(vip_amount, 10);

        frontendObj.listTitle = `${subscription_type_name} Deposit by our event: ${integerValue} USDT`;
        frontendObj.list = [
          `Profit of 0.5% per task-${vipTask} task per set`,
          "Better Profit and permission",
          `Up to ${maxTask} task per day can be submitted by ${subscription_type_name} User`,
        ];

        return {
          ...backendObj,
          img: frontendObj.img,
          star: frontendObj.star,
          listTitle: frontendObj.listTitle,
          list: frontendObj.list,
        };
      } else {
        return backendObj;
      }
    });

    return (
      <div className="container my-5  ">
        <div className="d-flex flex-column  ">
          <div className=" ">
            <div className="d-flex flex-column flex-md-row align-items-center gap-2">
              <h1
                style={{
                  fontWeight: "400",
                  lineHeight: "1.2",
                  fontSize: "21px",
                  color: "var(--primary-color-green)",
                }}
                className="fw-bold"
              >
                VIP LEVEL
              </h1>
              <p
                style={{
                  color: `${
                    isLight.lightDark ? "rgba(0,0,0,0.5)" : "text-light"
                  }`,
                  fontSize: "15pxnull",
                  lineHeight: "1.5",
                }}
                className="text-sm-left text-center "
              >
                UPGRADE YOUR ACCOUNT TO GET MORE DAILY COMMISSIONS
              </p>
            </div>
            <div className="row boost-vip-slider">
              {mergedData !== null &&
                mergedData !== undefined &&
                mergedData.map((vip, index) => (
                  <div
                    key={vip.index}
                    className="col-sm-6 mb-4 mb-lg-0  col-lg-3"
                  >
                    <VipCard data={vip} index={index} mergedData={mergedData} />
                  </div>
                ))}
            </div>
            {/* ======== small screen slider ============ */}

            <div className="d-lg-none">
              <Carousel
                pause={"hover"}
                interval={null}
                nextIcon={
                  <div
                    className=" text-dark  rounded-circled-flex align-items-center justify-content-start "
                    style={{
                      fontSize: "30px",
                      marginRight: "-35px",
                    }}
                  >
                    {/* <BsArrowRight className="" /> */}
                    <BsChevronRight />
                  </div>
                }
                prevIcon={
                  <div
                    className=" rounded-circle text-dark d-flex align-items-center justify-content-start"
                    style={{ fontSize: "30px", marginLeft: "-35px" }}
                  >
                    {/* <BsArrowLeft className="" /> */}
                    <BsChevronLeft className="" />
                  </div>
                }
              >
                {mergedData.map((vip, index) => (
                  <Carousel.Item>
                    <VipCard data={vip} index={index} mergedData={mergedData} />
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default VipLevel;
