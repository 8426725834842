import React, { useEffect, useRef, useState } from "react";
import Modal from "../../../common/Modal";
import { useFormik } from "formik";
import * as yup from "yup";
import { ApiCall } from "../../PostApiRequest";
import { toast } from "react-toastify";
import AdminOTPSlice, {
  AdminOTP,
} from "../../../store/authenticationSlices/AdminOTPSlice";
import { useDispatch } from "react-redux";
import { setLoginVal } from "../../../store/uiSlices/loginStateSlice";
import { ProfileRecord } from "../../../store/profileSlice/ProfileRecordSlice";

const AdminOTPModal = ({ adminOTPModal, setAdminOTPModal }) => {
  const dispatch = useDispatch();
  const [timer, setTimer] = useState(120);
  const inputRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];

  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);

      return () => clearInterval(countdown);
    }
  }, [timer]);

  const inputStyle = {
    width: "3.1rem",
    height: "3.5rem",
    marginRight: "0.5rem",
    fontSize: "1.0rem",
    textAlign: "center",
    borderRadius: "5px",
    border: "2px solid red",
  };

  const handleInputChange = (index, value) => {
    if (value.match(/^\d$/)) {
      formik.setFieldValue(`otp${index + 1}`, value);
      if (index < 5) {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const handleBackspace = (index) => {
    if (index === 6) {
      for (let i = 1; i <= 6; i++) {
        formik.setFieldValue(`otp${i}`, "");
      }
      inputRefs[0].current.focus();
    } else if (index > 0) {
      for (let i = index; i <= 6; i++) {
        formik.setFieldValue(`otp${i}`, "");
      }
      inputRefs[index - 1].current.focus();
    }
  };
  const handleError = (error) => {
    if (error.response) {
      if (error.response.status === 400) {
        toast.error(error.response?.data?.meta?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else if (error.request) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      console.log("Error:", error.message);
    }
  };
  const uservalue = localStorage.getItem("userLogin");
  const tokenVALUE = JSON.parse(uservalue);
  const handleSubmit = async (values) => {
    dispatch(setLoginVal(false));
    const formDataValues = `${values.otp1}${values.otp2}${values.otp3}${values.otp4}${values.otp5}${values.otp6}`;
    try {
      const returnData = await dispatch(AdminOTP(formDataValues));
      console.log("login response", returnData);

      if (returnData?.payload?.meta?.status_code === 200) {
        toast.success(returnData?.payload?.meta?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        dispatch(setLoginVal(true));
        dispatch(ProfileRecord());
        setAdminOTPModal(false);
      } else {
        toast.error(returnData?.payload?.meta?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const validationSchema = yup.object().shape({
    otp1: yup.string().matches(/^\d$/, "Invalid OTP").required("R"),
    otp2: yup.string().matches(/^\d$/, "Invalid OTP").required("R"),
    otp3: yup.string().matches(/^\d$/, "Invalid OTP").required("R"),
    otp4: yup.string().matches(/^\d$/, "Invalid OTP").required("R"),
    otp5: yup.string().matches(/^\d$/, "Invalid OTP").required("R"),
    otp6: yup.string().matches(/^\d$/, "Invalid OTP").required("R"),
  });

  const formik = useFormik({
    initialValues: {
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otp5: "",
      otp6: "",
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleResendOTP = async () => {
    const formData = new FormData();
    formData.append("otp_field", "email");
    formData.append("email", tokenVALUE?.data?.email);

    try {
      const response = await ApiCall("auth/resend_otp/", "POST", formData);

      if (response.meta.status_code === 200) {
        toast.success(response.meta.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimer(120);
      } else {
        toast.error(response.meta.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        // setIsLoading(false);
      }
    } catch (error) {
      handleError(error);
      // setIsLoading(false);
    }
  };

  return (
    <>
      <Modal heading={"OTP"} show={adminOTPModal} setShow={setAdminOTPModal}>
        <form onSubmit={formik.handleSubmit}>
          <div className="my-5 d-flex align-items-center gap-2">
            {Object.keys(formik.values).map((fieldName, index) => (
              <div key={fieldName}>
                <input
                  type="text"
                  value={formik.values[fieldName]}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  onBlur={() => formik.setFieldTouched(fieldName, true)}
                  onKeyDown={(e) => {
                    if (e.key === "Backspace") {
                      e.preventDefault();
                      handleBackspace(index);
                    }
                  }}
                  ref={inputRefs[index]}
                  maxLength="1"
                  name={fieldName}
                  style={{
                    ...inputStyle,
                    border:
                      formik.touched[fieldName] && formik.errors[fieldName]
                        ? "1px solid red"
                        : "1px solid #ccc",
                  }}
                />
                {/* {formik.touched[fieldName] && formik.errors[fieldName] && (
                  <div style={{ color: "red" }}>{formik.errors[fieldName]}</div>
                )} */}
              </div>
            ))}
          </div>

          <button
            type="submit"
            className="text-white px-4 py-1 rounded border-0"
            style={{
              background: "var(--primary-color-green)",
            }}
          >
            Confirm OTP
          </button>
        </form>
        <div className="my-2 d-flex align-items-center gap-2">
          {timer === 0 ? (
            <p>
              <a href="#resend" onClick={handleResendOTP}>
                Resend OTP
              </a>
            </p>
          ) : (
            <p>
              Resend OTP in{" "}
              <span
                style={{ color: "red", fontSize: "15px", fontWeight: "500" }}
              >
                0{Math.floor(timer / 60)}:
              </span>
              <span
                style={{ color: "red", fontSize: "15px", fontWeight: "500" }}
              >
                {timer % 60 < 10 ? `0${timer % 60}` : timer % 60} minutes
              </span>
            </p>
          )}
        </div>
      </Modal>
    </>
  );
};

export default AdminOTPModal;
