import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import HeadingWithSearchBar from "../../common/HeadingWithSearchBar";
import { useDispatch, useSelector } from "react-redux";
import productDummyImage from "../../assets/image/product.jpeg";
import NoResultFound from "../../common/NoResultFound";
import { BASE_URL } from "../../env";
import { setPriceRangeFirstLoading } from "../../store/uiSlices/PriceRangeFirstLoading";
import { useNavigate } from "react-router-dom";

const BookSlider = ({ homeCategory }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const categoryProduct = homeCategory?.filter(
    (category) => category?.menu_position === 3
  );
  return (
    <>
      {categoryProduct[0]?.category_name && (
        <div
          className="container my-4 py-2"
          style={{
            borderTop: "1px solid var(--border-color-gray)",
            borderBottom: "1px solid var(--border-color-gray)",
          }}
        >
          <div className="mt-4 mb-4">
            <HeadingWithSearchBar
              heading={`${categoryProduct[0]?.category_name}`}
            />
          </div>
          {categoryProduct[0]?.products?.length > 0 ? (
            <Swiper
              modules={[Navigation, Pagination, Autoplay]}
              spaceBetween={50}
              slidesPerView={6}
              loop={true}
              autoplay={{
                delay: 1500,
                // disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              breakpoints={{
                1250: {
                  slidesPerView: 6,
                },
                1028: {
                  slidesPerView: 5,
                },
                768: {
                  slidesPerView: 3,
                },
                600: {
                  slidesPerView: 2,
                },

                500: {
                  slidesPerView: 2,
                },
                400: {
                  slidesPerView: 1,
                },

                200: {
                  slidesPerView: 1,
                },
              }}
              navigation
              scrollbar={{ draggable: true }}
            >
              {categoryProduct[0]?.products?.map((book, index) => (
                <SwiperSlide key={index}>
                  {book?.product_media?.length > 0 &&
                    book?.product_media[0]?.image && (
                      <div
                        style={{
                          height: "200px",
                          width: "150px",
                          background: "#777",
                        }}
                      >
                        <img
                          // className="img-fluid"
                          // width={200}
                          // height={250}
                          src={
                            book?.product_media?.length > 0 &&
                            book?.product_media[0]?.image
                          }
                          className="w-100 h-100"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            dispatch(setPriceRangeFirstLoading(false));
                            navigate(
                              `${`/${
                                categoryProduct[0]?.category_name
                              }/${book?.item_name?.split("/").join(" ")}`}`,
                              {
                                state: {
                                  path: categoryProduct[0]?.category_name,
                                  id: categoryProduct[0]?.id,
                                  product: book,
                                },
                              }
                            );
                          }}
                        />
                      </div>
                    )}
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <NoResultFound />
          )}
        </div>
      )}
    </>
  );
};
export default BookSlider;
