import React from "react";
import image from "../../assets/image/books/book1.jpg";

const PickUpCard = ({ homeCategory }) => {
  const categoryProduct = homeCategory?.filter(
    (category) => category?.menu_position === 3
  );
  console.log(
    "categoryProduct",
    categoryProduct[0]?.products[0]?.product_media[0]
  );
  return (
    <>
      {categoryProduct[0]?.products?.slice(0, 3).map((book, index) => (
        <div key={index}>
          {book?.product_media?.length > 0 && book?.product_media[0]?.image && (
            <div className="my-3 d-flex mt-4 gap-2">
              <div
                className="rounded overflow-hidden"
                style={{ maxHeight: "120px", width: "100px" }}
              >
                <img
                  src={
                    book?.product_media?.length > 0 &&
                    book?.product_media[0]?.image
                  }
                  width={80}
                  height={100}
                />
              </div>
              <div>
                <h5 className="fw-semibold" style={{ fontSize: "16px" }}>
                  {book?.item_name?.length > 50
                    ? `${book?.item_name.slice(0, 29)}...`
                    : book?.item_name}
                </h5>
                <p
                  style={{
                    color: "darkgray",
                    fontSize: "14px",
                    lineHeight: "18px",
                  }}
                >
                  {book?.item_description?.length > 50
                    ? `${book?.item_description.slice(0, 49)}...`
                    : book?.item_description}
                </p>
                <button
                  className="border-0 rounded px-3 py-1 mt-2"
                  style={{
                    background: "#eee",
                    color: "var(--primary-color-green)",
                  }}
                >
                  Book Now
                </button>
              </div>
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default PickUpCard;
