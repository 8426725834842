import React from "react";
import "./commonCompStyle/HomeStyle.css";
import { useNavigate } from "react-router-dom";

const ViewAllBtnCard = ({ count, categoryId, categoryName }) => {
  const navigate = useNavigate();

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center  rounded px-3  h-100 text-center bg-white"
      style={{
        border: "1px solid var(--border-color-gray)",
        maxWidth: "190px",
      }}
    >
      <p style={{ fontSize: "18px" }}>{count} + Items Available</p>
      <button
        className="border-0  mt-2 px-3 py-1 text-light view-all"
        onClick={() => {
          navigate(
            `/${categoryName
              ?.split("'")
              .join("")
              .replace(",", "")
              .replace("&", "")
              .split(" ")
              .filter((char) => char !== "")
              .join("-")
              .toLowerCase()}`,
            {
              state: {
                id: categoryId,
                categoryName: categoryName,
              },
            }
          );
        }}
      >
        {"View All"}
      </button>
    </div>
  );
};

export default ViewAllBtnCard;
