import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../env";

export const AllCategory = createAsyncThunk("AllCategory", async () => {
  const uservalue = localStorage.getItem("userLogin");
  const tokenVALUE = JSON.parse(uservalue);
  const config = {
    // headers: {
    //   Authorization: `Bearer ${tokenVALUE?.data?.token?.access}`,
    // },
    timeout: 30000,
    cancelToken: new axios.CancelToken((cancel) => {
      // Create a cancel token and function
      setTimeout(() => {
        cancel("Request timed out");
      }, 30000);
    }),
  };
  try {
    const response = await axios.get(
      `${BASE_URL}catalogue/get-all-category/`,
      config
    );
    return response.data;
  } catch (error) {
    // throw error;
  }
});
const GetAllCategorySlice = createSlice({
  name: "AllCategory",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(AllCategory.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(AllCategory.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(AllCategory.rejected, (state, action) => {
      // console.log(action.error.message);
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export default GetAllCategorySlice.reducer;
