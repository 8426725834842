import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../env";
export const AdminOTP = createAsyncThunk(
  "AdminOTPSlice",
  async (formDataValues) => {
    const formData = new FormData();
    const uservalue = localStorage.getItem("userLogin");
    const tokenVALUE = JSON.parse(uservalue);
    formData.append("email_code", formDataValues);
    console.log("formData", formData);

    var requestOptions = {
      method: "POST",
      body: formData,
      redirect: "follow",
    };

    return fetch(`${BASE_URL}auth/verify_otp/`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const userLogin = JSON.parse(localStorage.getItem("userLogin")) || {};
        userLogin.data = userLogin.data || {};
        userLogin.data.token = {
          access: result?.data?.token?.access,
          refresh: result?.data?.token?.refresh,
        };
        localStorage.setItem("userLogin", JSON.stringify(userLogin));

        return result;
      })
      .catch((error) => {
        return error;
      });
  }
);
const AdminOTPSlice = createSlice({
  name: "AdminOTP",
  initialState: {
    isloading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(AdminOTP.pending, (state, action) => {
      state.isloading = true;
    });
    builder.addCase(AdminOTP.fulfilled, (state, action) => {
      state.isloading = false;
      state.data = action.payload;
    });
    builder.addCase(AdminOTP.rejected, (state, action) => {
      state.isError = true;
    });
  },
});

export default AdminOTPSlice.reducer;
