import React from "react";
import { BsStar } from "react-icons/bs";
import "./commonCompStyle/HomeStyle.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import productDummyImage from "../assets/image/product.jpeg";
import { setPriceRangeFirstLoading } from "../store/uiSlices/PriceRangeFirstLoading";
const ProductCard = ({
  relatedProduct,
  product,
  related,
  categoryId,
  categoryName,
  home,
}) => {
  const location = useLocation();
  const relatedProductPath = location.pathname.split("/")[1];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLight = useSelector((state) => state.darkLight);

  return (
    <div
      className="d-flex flex-column align-items-center rounded px-3 py-3 text-center bg-white"
      style={{
        border: "1px solid var(--border-color-gray)",
        maxWidth: "190px",
      }}
    >
      <div
        onClick={() => {
          dispatch(setPriceRangeFirstLoading(false));
          navigate(
            `${
              home
                ? `/${categoryName}/${product?.item_name
                    ?.split("/")
                    .join(" ")
                    .split(",")
                    .join("")
                    .split("%")
                    .join("percent")
                    .split("(")
                    .join("")
                    .split(")")
                    .join("")
                    .split(".")
                    .join("")}.`
                : related
                ? `/${relatedProductPath}/${product?.item_name
                    .split("/")
                    .join(" ")
                    .split(",")
                    .join("")
                    .split("%")
                    .join("percent")
                    .split("(")
                    .join("")
                    .split(")")
                    .join("")
                    .split(".")
                    .join("")}`
                : `${product?.item_name
                    .split("/")
                    .join(" ")
                    .split(",")
                    .join("")
                    .split("%")
                    .join("percent")
                    .split("(")
                    .join("")
                    .split(")")
                    .join("")
                    .split(".")
                    .join("")}`
            }`,
            {
              state: {
                path: categoryName,
                id: categoryId,
                product: product,
                relatedProduct: relatedProduct,
              },
            }
          );
        }}
        className="product-image-cont"
        style={{ cursor: "pointer" }}
      >
        {product?.product_media?.length > 0 &&
        product?.product_media[0]?.image ? (
          <img
            src={
              product?.product_media?.length > 0 &&
              product?.product_media[0]?.image
            }
            className="product-image"
          />
        ) : (
          <img
            src={productDummyImage}
            className="object-fit-cover w-100 h-100"
          />
        )}
      </div>
      <div
        onClick={() => {
          dispatch(setPriceRangeFirstLoading(false));
          navigate(
            `${
              home
                ? `/${categoryName}/${product?.item_name
                    .split("/")
                    .join(" ")
                    .split(",")
                    .join("")
                    .split("%")
                    .join("percent")
                    .split("(")
                    .join("")
                    .split(")")
                    .join("")
                    .split(".")
                    .join("")}`
                : related
                ? `/${relatedProductPath}/${product?.item_name
                    .split("/")
                    .join(" ")
                    .split(",")
                    .join("")
                    .split("%")
                    .join("percent")
                    .split("(")
                    .join("")
                    .split(")")
                    .join("")
                    .split("(")
                    .join("")
                    .split(")")
                    .join("")
                    .split(".")
                    .join("")}`
                : `${product?.item_name
                    .split("/")
                    .join(" ")
                    .split(",")
                    .join("")
                    .split("%")
                    .join("percent")
                    .split("(")
                    .join("")
                    .split(")")
                    .join("")
                    .split(".")
                    .join("")}`
            }`,
            {
              state: { product: product, relatedProduct: relatedProduct },
            }
          );
        }}
        className={`fw-bold product-title ${
          isLight.lightDark ? "text-dark" : "text-light"
        }`}
        style={{ cursor: "pointer" }}
      >
        {`${product?.item_name}`.length >= 20
          ? `${product?.item_name}`.slice(0, 19) + "..."
          : product?.item_name}
      </div>
      <div className="d-flex gap-1 py-2">
        <BsStar style={{ fontSize: "15px", color: "#f5ae0f" }} />
        <BsStar style={{ fontSize: "15px", color: "#f5ae0f" }} />
        <BsStar style={{ fontSize: "15px", color: "#f5ae0f" }} />
        <BsStar style={{ fontSize: "15px", color: "#f5ae0f" }} />
        <BsStar style={{ fontSize: "15px", color: "#f5ae0f" }} />
      </div>
      <div>
        <p style={{ color: "", fontWeight: "600" }}>
          ${product?.selling_price} / {product?.unit}
        </p>
      </div>
    </div>
  );
};

export default ProductCard;
