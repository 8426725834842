import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../env";

export const ProductSets = createAsyncThunk(
  "ProductSets",
  async (totalBalance, { rejectWithValue }) => {
    const uservalue = localStorage.getItem("userLogin");
    const tokenVALUE = JSON.parse(uservalue);
    const config = {
      headers: {
        Authorization: `Bearer ${tokenVALUE?.data?.token?.access}`,
      },
      timeout: 30000,
      cancelToken: new axios.CancelToken((cancel) => {
        // Create a cancel token and function
        setTimeout(() => {
          cancel("Request timed out");
        }, 30000);
      }),
    };

    try {
      const response = await axios.get(
        `${BASE_URL}boost-data/get-boost-product/?set_amount=${totalBalance}`,
        config
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

const ProductSetsSlice = createSlice({
  name: "ProductSets",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(ProductSets.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(ProductSets.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(ProductSets.rejected, (state, action) => {
      // console.log(action.error.message);
      state.isLoading = false;
      state.isError = true;
      state.error = action.error.message;
    });
  },
});

export default ProductSetsSlice.reducer;
