import React, { useState } from "react";

const SizeSelector = ({ setProductSize }) => {
  const sizes = ["LARGE", "MEDIUM", "SMALL", "XTRA LARGE"];
  const [selectedSize, setSelectedSize] = useState(sizes[0]);
  const handleSizeChange = (size) => {
    setSelectedSize(size);
    setProductSize(size);
  };
  return (
    <div className=" mt-3">
      <p>
        <span className="fw-bold my-5"> Product Size : &nbsp;</span>
        {selectedSize}
      </p>
      {sizes?.map((size) => (
        <button
          key={size}
          className={`btn mt-2 ${
            selectedSize === size ? "btn-secondary" : "btn-outline-secondary"
          } mx-2`}
          onClick={() => handleSizeChange(size)}
        >
          {size}
        </button>
      ))}
    </div>
  );
};

export default SizeSelector;
