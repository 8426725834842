import React from "react";
import ProductCard from "../ProductCard";

const RelatedProducts = ({
  product,
  currentProductId,
  categoryName,
  categoryId,
}) => {
  return (
    <div>
      <div className="row  ">
        {product
          ?.filter((prod) => prod.id !== currentProductId)
          ?.map((relatedProduct, index) => (
            <div key={index} className="col-6 col-sm-4 col-md-3  mb-3 ">
              {relatedProduct?.id && (
                <ProductCard
                  categoryName={categoryName}
                  categoryId={categoryId}
                  relatedProduct={product}
                  id={relatedProduct?.id}
                  product={relatedProduct}
                  related={true}
                />
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default RelatedProducts;
