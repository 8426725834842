import React from "react";
import ContactUsForm from "./ContactUsForm";
const ContactUsComp = () => {
  return (
    <div className="container">
      <div className="text-center">
        <h1 className="fs-1">Get In Touch</h1>
        {/* <p style={{ color: "rgba(0,0,0,.5)" }} className="mb-5">
          Your email address will not be published. Required fields are marked *
        </p> */}
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col text-center">
            <div className="position-relative">
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "45%",
                  transform: "translate(-50%, -50%)",
                  height: "2px",
                  background: "#007bff",
                  width: "20px",
                }}
              />
              <h5 className="fw-bolder mb-2">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Our Location
              </h5>
            </div>
            <h4 className="fw-bolder">Head Office Location</h4>
            <p>
              We sell our product all over the world. <br />
            </p>
            <p className="fs-4 fw-bolder">United Kingdom</p>
            <p>
              Our second location at Riverway Retail Park, Irvine, North
              Ayrshire, Scotland, KA12 8EJ, United Kingdom.
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsComp;
