import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
// import { Country, State, City } from "country-state-city";
import { useSelector } from "react-redux";

const DeleviryForm = ({
  prevFucntion,
  nextFucntion,
  prev,
  next,
  currentId,
}) => {
  const state = useSelector((state) => state);
  // const countryData = Country.getAllCountries();
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (state?.AddToCartSlice?.items?.length > 0) {
      let total = 0;
      state?.AddToCartSlice?.items?.forEach((item) => {
        return (total += Number(item.itemTotalPrice));
      });

      setTotal(total);
    }
  }, [state?.AddToCartSlice?.items]);
  const deliverFormData = JSON.parse(localStorage.getItem("delivery_address"));

  const initialValues = {
    address1: deliverFormData ? deliverFormData?.address1 : "",
    address2: deliverFormData ? deliverFormData?.address2 : "",
    postcode: deliverFormData ? Number(deliverFormData?.postcode) : "",
    city: deliverFormData ? deliverFormData?.city : "",
    state: deliverFormData ? deliverFormData?.state : "",
    country: deliverFormData ? deliverFormData?.country : "",
  };

  const numberRegex = "^[0-9]+$";

  const validationSchema = yup.object().shape({
    address1: yup.string().required("This field is required."),

    postcode: yup
      .string()
      .matches(numberRegex, "Postcode must be number.")
      .required("This field is required."),
    country: yup.string().required("This field is required."),
    city: yup.string().required("This field is required."),
    state: yup.string().required("This field is required."),
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleReset,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (
        state?.ProfileRecordSlice?.data?.data?.ecommerce_account_wallet_summary
          ?.current_balance_amount > total
      ) {
        nextFucntion();
        nextFucntion();
      } else {
        nextFucntion();
      }

      localStorage.setItem("delivery_address", JSON.stringify(values));
    },
  });

  // useEffect(() => {
  //   if (values.country !== "") {
  //     const currentCountry = countryData.filter(
  //       (country) => country.name === values.country
  //     );

  //     const stateOfCountry = State.getStatesOfCountry(
  //       currentCountry[0]?.isoCode
  //     );

  //     setStates(stateOfCountry);

  //     if (values.state !== "") {
  //       const curreentState = states.filter(
  //         (state, index) => state.name === values.state
  //       );
  //       const cities = City.getCitiesOfState(
  //         curreentState[0]?.countryCode,
  //         curreentState[0]?.isoCode
  //       );
  //       setCities(cities);
  //     }
  //   }
  // }, [values.country, values.state, values.city]);

  return (
    <div
      className=" h-100 d-flex flex-column justify-content-between"
      style={{ maxWidth: "600px", marginLeft: "auto", marginRight: "auto" }}
    >
      <h4 className="my-3 checkout-from-heading">Enter Your Address</h4>
      <form
        onSubmit={handleSubmit}
        className="flex-fill d-flex flex-column  justify-content-between  h-100 "
      >
        <div>
          {" "}
          <div className="d-flex flex-column form-input">
            <label htmlFor="address1 ">
              Address Line 1 <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              id="address1"
              name="address1"
              value={values.address1}
              placeholder="Address Line 1"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.address1 && touched.address1 && (
              <p style={{ color: "red", fontSize: "14px" }} className="mt-1">
                {errors.address1}
              </p>
            )}
          </div>
          <div className="d-flex flex-column form-input">
            <label htmlFor="address2">Address Line 2</label>
            <input
              type="text"
              id="address2"
              name="address2"
              value={values.address2}
              placeholder="Address Line 2"
              onChange={handleChange}
              onBlur={handleBlur}
            />{" "}
            {errors.address2 && touched.address2 && (
              <p style={{ color: "red", fontSize: "14px" }} className="mt-1">
                {errors.address2}
              </p>
            )}
          </div>
          <div className="d-flex flex-column flex-sm-row  w-100 gap-sm-3">
            <div className="d-flex flex-column w-100 form-input">
              <label htmlFor="australia">
                Country <span style={{ color: "red" }}>*</span>
              </label>
              {/* <select
                type="text"
                id="australia"
                className="bg-white overflow-y-scroll "
                placeholder="Select country"
                name="country"
                value={values.country}
                style={{ maxHeight: "100px" }}
                onChange={(e) => {
                  setFieldValue("country", e.target.value);
                  if (states.length > 0) {
                    setFieldValue("state", "no state");
                    setFieldValue("city", "no city");
                  } else {
                    setStates([]);
                    setCities([]);
                    setFieldValue("state", "");
                    setFieldValue("city", "");
                  }
                }}
                onBlur={handleBlur}
              >
                <option value="">Select Country</option>
                {countryData?.map((data, index) => (
                  <option key={index} value={data.name}>
                    {data.name}
                  </option>
                ))}
              </select> */}
              <input type="text"
                id="australia"
                className="bg-white overflow-y-scroll "
                placeholder="Enter country name"
                name="country"
                value={values.country}
                onChange={handleChange}
                onBlur={handleBlur}
                style={{ maxHeight: "100px" }} />
              {errors.country && touched.country && (
                <p style={{ color: "red", fontSize: "14px" }} className="mt-1">
                  {errors.country}
                </p>
              )}
            </div>
            <div className="d-flex flex-column w-100 form-input">
              <label htmlFor="state">
                State <span style={{ color: "red" }}>*</span>
              </label>
              {/* <select
                id="state"
                name="state"
                value={values.state}
                placeholder="Select State"
                onChange={(e) => {
                  if (states.length > 0) {
                    setFieldValue("state", e.target.value);
                  } else {
                    setFieldValue("city", "no city");
                  }
                }}
                onBlur={handleBlur}
                className="bg-white"
              >
                <option value="">Select State</option>
                {states?.map((state, index) => (
                  <option key={index} value={state.name}>
                    {state.name}
                  </option>
                ))}
              </select> */}

<input type="text"
               id="state"
               name="state"
               value={values.state}
               placeholder="Enter state name"
                onChange={handleChange}
                onBlur={handleBlur}
                style={{ maxHeight: "100px" }} />
              {errors.state && touched.state && (
                <p style={{ color: "red", fontSize: "14px" }} className="mt-1">
                  {errors.state}
                </p>
              )}
            </div>
          </div>
          <div className="d-flex flex-column flex-sm-row w-100 gap-sm-3">
            <div className="d-flex flex-column w-100 form-input">
              <label htmlFor="city">
                City <span style={{ color: "red" }}>*</span>
              </label>
              {/* <select
                type="text"
                id="city"
                className="bg-white overflow-y-scroll "
                placeholder="Select City"
                name="city"
                value={values.city}
                style={{ maxHeight: "100px" }}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                {" "}
                <option value="">Select City</option>
                {cities?.map((city, index) => (
                  <option key={index} value={city.name}>
                    {city.name}
                  </option>
                ))}
              </select> */}

<input  type="text"
                id="city"
                className="bg-white overflow-y-scroll "
                placeholder="Select City"
                name="city"
                value={values.city}
                style={{ maxHeight: "100px" }}
                onChange={handleChange}
                onBlur={handleBlur} />
              {errors.city && touched.city && (
                <p style={{ color: "red", fontSize: "14px" }} className="mt-1">
                  {errors.city}
                </p>
              )}
            </div>
            <div className="d-flex flex-column w-100 form-input">
              <label htmlFor="postcode">
                Postcode <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                id="postcode"
                name="postcode"
                value={values.postcode}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="1300"
              />
              {errors.postcode && touched.postcode && (
                <p style={{ color: "red", fontSize: "14px" }} className="mt-1">
                  {errors.postcode}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="w-100 d-flex justify-content-between mb-3">
          <button
            type="button"
            className="border-0 px-3 py-2 rounded "
            style={{
              color: `${prev ? "var(--primary-color-green)" : "#000"}`,
            }}
            onClick={prevFucntion}
          >
            Previous
          </button>
          <button
            type="submit"
            className="border-0 px-3 py-2 rounded "
            style={{
              color: `${next ? "var(--primary-color-green)" : "#000"}`,
            }}
          >
            {currentId === 3 ? "Submit" : "Next"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default DeleviryForm;
