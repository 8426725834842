import React, { useState } from "react";
import FaqAcordion from "../../../common/FaqAcordion";
import video from "../../../assets/vedio/placeholderVedio.mp4";
import Modal from "../../../common/Modal";

const randomQueData = [
  {
    id: 1,
    question: "How do I create an account?",
    answer:
      "To create an account, you need to contact your agent so that he can give you his referral code. If you do not have an agent, please contact us.",
  },
  {
    id: 2,
    question: "How can I contact you?",
    answer:
      " You can contact us directly using the whatsapp or Telegram icons on the right.",
  },
  {
    id: 3,
    question: "How do I reset my password?",
    answer:
      "Just click sign in bottom right corner then click forget password.",
  },
  {
    id: 4,
    question: "What are the fees associated with your service?",
    answer:
      "There are no hidden costs with our services. No bank is involved in our transactions because all deposits and withdrawals are made in Tether (USDT).",
  },
];

const FaqSec = () => {
  const [readMore, setReadMore] = useState(false);
  return (
    <div className="container my-4 py-4">
      <div className=" d-flex flex-column flex-lg-row  justify-content-between gap-md-5 ">
        <div className="w-100 faq-sec">
          <h1
            style={{
              fontWeight: "400",
              lineHeight: "1.2",
              fontSize: "21px",
              color: "var(--primary-color-green)",
            }}
            className="fw-bold text-center text-lg-start "
          >
            FAQ
          </h1>

          <FaqAcordion data={randomQueData} />
        </div>
        <div className="w-100 flex-full">
          <div className="">
            <h1
              style={{
                fontWeight: "400",
                lineHeight: "1.2",
                fontSize: "21px",
                color: "var(--primary-color-green)",
              }}
              className="fw-bold text-center text-lg-start mb-2"
            >
              HOW IT WORKS?
            </h1>
            <div className="d-flex  how-it-work-sec">
              <div className=" ">
                <p className="faq-text">
                  There are millions of merchants selling products on online
                  shops such as Amazon, Walmart, and eBay but not all of them
                  are profitable. 80% of them are losing money and our main job
                  is to help increase the reviews and ratings of their products
                  so that more buyers will see and buy the products.
                  <br /> <br />
                  The main task is assisting these merchants to apply the
                  company services
                </p>
                <>
                  {" "}
                  <button
                    className="text-white border-0 px-2 py-1 rounded mt-3"
                    style={{ background: "var(--primary-color-green)" }}
                    onClick={() => {
                      setReadMore((prev) => !prev);
                    }}
                  >
                    Read More ...
                  </button>
                  {readMore && (
                    <Modal scroll={true} show={readMore} setShow={setReadMore}>
                      <div className="category-filter">
                        <p className="text-center faq-text">
                          There are millions of merchants selling products on
                          online shops such as Amazon, Walmart, and eBay but not
                          all of them are profitable. 80% of them are losing
                          money and our main job is to help increase the reviews
                          and ratings of their products so that more buyers will
                          see and buy the products.
                          <br /> <br />
                          The main task is assisting these merchants to apply
                          the company services to drive their product data
                          value. Let them get more exposure to attract consumers
                          and investors for expanding their market.
                          <br /> To make your job, you have 2 categories;
                          <br />
                          <span className="text-left fw-bold">
                            - NEWBIES
                            <br />- EXPERT
                          </span>
                          <br />
                          <br />
                          <span className="fw-bold text-success">
                            NEWBIES :
                          </span>
                          <br />
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; You will
                          receive 50 USDT free when you register with an agent
                          referral code.
                          <br />
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; You need to
                          complete the 3 sets (first, second and third set) to
                          get a basic salary after 5 days.
                          <br /> Each set have 40 items to complete. When you
                          finish set, commissions and check-in bonus are
                          automatically added to your balance and you can
                          withdraw all when the 3 sets are complete and restart
                          the next day.
                          <br />
                          <br />
                          &nbsp;
                          <span className="text-success fw-bold ">
                            EXPERT :
                          </span>
                          <br />
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;For
                          this category, you should submit only one of the 3
                          sets to get basic salary. Salarie and the minimum
                          balance required are different for each set. When you
                          complete the set,you can withdraw all the funds plus
                          commissions and check-in bonuses.
                          <br />
                          <br />
                          Please note that when you are driving data, you are
                          not paying these items, if you want to buy items, you
                          should go to the homepage for the ecommerce part.
                          <br /> you cannot do sets in both categories at the
                          same time. You can either do the first category or the
                          second. However, you are allowed to leave the{" "}
                          <span className="fw-bold ">NEWBIES</span> category and
                          then work with the EXPERT category if you have not
                          worked for more than 3 days in the{" "}
                          <span className="fw-bold ">NEWBIES</span> category.
                          But you cannot work in the{" "}
                          <span className="fw-bold ">EXPERT</span> category and
                          then decide to work in the{" "}
                          <span className="fw-bold ">NEWBIES</span> category.
                          <br />
                          <br /> If you have any questions, contact your agent
                          or customer service by Whatsapp or Telegram.
                        </p>
                      </div>
                    </Modal>
                  )}
                </>
              </div>
              <div>
                <video width="" height="240" controls className="video-cont">
                  <source src={video} type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqSec;
