import React, { useEffect, useState } from "react";
import Modal from "../../common/Modal";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { useFormik } from "formik";
import * as yup from "yup";
import { ApiCall, initializeApiWithHeaders } from "../../common/PostApiRequest";
import { toast } from "react-toastify";
import "../categories/categories.css";
import { BASE_URL } from "../../env";

const PasswordProfile = ({ heading, show, setShow }) => {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCofirmPassword, setShowConfirmPassword] = useState(false);
  const initialValues = {
    old_password: "",
    password: "",
    confirm_password: "",
  };

  const userSchema = yup.object().shape({
    old_password: yup.string().required("Old Password is required"),
    password: yup
      .string()
      .required("Password is required")
      .min(6, "Password is too short"),
    confirm_password: yup
      .string()
      .required("Confirm Password is required")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });
  const {
    errors,
    touched,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: userSchema,
    onSubmit: async (values, { resetForm }) => {
      const token = JSON.parse(localStorage.getItem("userLogin")).data.token
        .access;
      let headers = new Headers();
      headers.append("Authorization", `Bearer ${token}`);
      let formData = new FormData();
      formData.append("old_password", values?.old_password);
      formData.append("password", values?.password);
      formData.append("confirm_password", values?.confirm_password);
      try {
        initializeApiWithHeaders();
        const response = await fetch(`${BASE_URL}auth/change-password/`, {
          method: "PUT",
          headers: headers,
          body: formData,
        });
        const res = await response.json();
        console.log(res);
        if (res.meta.status_code === 200) {
          toast.success(res?.meta?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setTimeout(() => {
            setShow(false);
          }, 1000);
        } else {
          toast.error(res?.meta?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        handleError(error);
      }
    },
  });

  const handleError = (error) => {
    if (error.response) {
      if (error.response.status === 400) {
        toast.error(error.response?.data?.meta?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else if (error.request) {
      toast(error.message);
    } else {
      console.log("Error:", error.message);
    }
  };
  return (
    <div>
      <Modal
        heading={"Change Password"}
        icon={true}
        width={30}
        show={show}
        setShow={setShow}
      >
        <form onSubmit={handleSubmit} className="mb-3">
          <div className="d-flex flex-column gap-3">
            <div
              className="d-flex flex-column align-items-start"
              style={{ height: "90px" }}
            >
              <label>Old Password</label>
              <input
                type={`${showOldPassword ? "text" : "password"}`}
                name="old_password"
                value={values.old_password}
                onChange={handleChange}
                onBlur={handleBlur}
                className="w-100 py-2 bg-white"
                placeholder="Old Password"
              />
              <div
                className="position-absolute eye-icon"
                style={{ top: "85px", right: "60px", cursor: "pointer" }}
                onClick={() => setShowOldPassword(!showOldPassword)}
              >
                {showOldPassword ? (
                  <BsEyeSlash className="text-2xl" />
                ) : (
                  <BsEye className="text-2xl" />
                )}
              </div>
              {touched.old_password && errors.old_password && (
                <div className="text-danger">{errors.old_password}</div>
              )}
            </div>
            <div
              className="d-flex flex-column align-items-start"
              style={{ height: "90px" }}
            >
              <label>Password</label>
              <input
                type={`${showPassword ? "text" : "password"}`}
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                className="w-100 py-2 bg-white"
                placeholder="Password"
              />
              <div
                className="position-absolute eye-icon"
                style={{ top: "189px", right: "60px", cursor: "pointer" }}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <BsEyeSlash className="text-2xl" />
                ) : (
                  <BsEye className="text-2xl" />
                )}
              </div>
              {touched.password && errors.password && (
                <div className="text-danger">{errors.password}</div>
              )}
            </div>

            <div
              className="d-flex flex-column align-items-start"
              style={{ height: "90px" }}
            >
              <label>Confirm Password</label>
              <input
                type={`${showCofirmPassword ? "text" : "password"}`}
                name="confirm_password"
                value={values.confirm_password}
                onChange={handleChange}
                onBlur={handleBlur}
                className="w-100 py-2 bg-white"
                placeholder="Confirm Password"
              />

              <div
                className="position-absolute eye-icon"
                style={{ top: "295px", right: "60px", cursor: "pointer" }}
                onClick={() => setShowConfirmPassword(!showCofirmPassword)}
              >
                {showCofirmPassword ? (
                  <BsEyeSlash className="text-2xl" />
                ) : (
                  <BsEye className="text-2xl" />
                )}
              </div>
              {touched.confirm_password && errors.confirm_password && (
                <div className="text-danger">{errors.confirm_password}</div>
              )}
            </div>
            <button
              type="submit"
              className="text-white border-0 px-5 py-1 rounded mt-3"
              style={{ background: "var(--primary-color-green)" }}
            >
              Update
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default PasswordProfile;
