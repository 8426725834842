import { createSlice } from "@reduxjs/toolkit";

const addTaskSlice = createSlice({
  name: "addTask",
  initialState: false,
  reducers: {
    setAddTask: (state, action) => {
      return action.payload;
    },
  },
});

export const { setAddTask } = addTaskSlice.actions;
export const selectAddTask = (state) => state.addTask;

export default addTaskSlice.reducer;
