import { useEffect, useRef, useState } from "react";
import Modal from "./Modal";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { GetNetworkType } from "../store/BoostDataSlice/GetNetworkType";
import { GetCryptoType } from "../store/BoostDataSlice/GetCryptoType";
import { BASE_URL } from "../env";
import { ProfileRecord } from "../store/profileSlice/ProfileRecordSlice";

const AddWithdrawalAddress = ({
  heading,
  title,
  icon,
  setWithdraw,
  show,
  setShow,
  setShowMoney,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetNetworkType());
    dispatch(GetCryptoType());
  }, []);
  const depositAddress = useSelector(
    (state) => state?.GetDepositeAddress?.data?.data
  );
  const cryptoType = useSelector((state) => state?.cryptoType?.data?.data);
  const networkType = useSelector((state) => state?.networkType?.data?.data);
  const [showQrImage, setShowQrImage] = useState(false);
  const [crypto, setCrypto] = useState(null);
  const [network, setNetwork] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [withdrawalFile, setWithdrawalFile] = useState(null);
  const copyRef = useRef(null);
  const [copy, setCopy] = useState(false);
  const copyText = async () => {
    if (copyRef.current) {
      const text = copyRef.current.innerText;
      try {
        await navigator.clipboard.writeText(text);
        setCopy(true);
        setTimeout(() => {
          setCopy(false);
        }, 1000);
      } catch (error) {
        console.log("Context not coped properly");
      }
    }
  };

  useEffect(() => {
    setCrypto(null);
    setNetwork(null);
  }, []);

  const handleFileChange = (event) => {
    const fileInput = event.target;
    const file = fileInput.files[0];
    console.log("file", file);
    setWithdrawalFile(file);
    fileInput.value = "";
  };
  const initialValues = {
    crypto_type: "",
    network_type: "",
    withdrawalAddress: "",
  };

  const userSchema = yup.object().shape({
    crypto_type: yup.string("").required("This field is required."),
    network_type: yup.string("").required("This field is required."),
    withdrawalAddress: yup.string("").required("This field is required."),
  });
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: userSchema,
    onSubmit: async (values) => {
      console.log("values", values);
      try {
        // initializeApiWithHeaders(false);
        const uservalue = localStorage.getItem("userLogin");
        const tokenVALUE = JSON.parse(uservalue);
        const formData = new FormData();
        let headers = new Headers();
        headers.append(
          "Authorization",
          `Bearer ${tokenVALUE?.data?.token?.access}`
        );

        formData.append("crypto", values?.crypto_type);
        formData.append("network", values?.network_type);
        formData.append("address", values?.withdrawalAddress);
        if (withdrawalFile) {
          formData.append("qr_image", withdrawalFile);
        }

        const response = await fetch(
          `${BASE_URL}lookups/add-withdrawal-address/`,
          {
            headers,
            method: "POST",
            body: formData,
          }
        );
        const res = await response.json();

        if (res.meta.status_code === 201) {
          toast.success(res.meta.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          handleReset();
          dispatch(ProfileRecord());
          setTimeout(() => {
            setShowMoney(true);
            setShow(false);
          }, 1000);
          handleReset();
        } else {
          toast.error(res.meta.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {}
    },
  });

  return (
    <Modal show={show} setShow={setShow}>
      <form onSubmit={handleSubmit} className="d-flex flex-column gap-4">
        <div style={{ height: "70px" }}>
          <p>
            Please select Crypto? <span className="text-danger">*</span>
          </p>
          <div className="d-flex flex-column  text-start mt-2">
            <select
              className="w-100 bg-white"
              // value={"USDT"}
              name="crypto_type"
              value={values.crypto_type}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="">Select...</option>
              {cryptoType?.length > 0
                ? cryptoType?.map((crypto) => (
                    <option key={crypto?.id} value={crypto?.id}>
                      {crypto?.crypto_type_code}
                    </option>
                  ))
                : []}
            </select>
            {errors.crypto_type && touched.crypto_type && (
              <p className="text-danger">{errors.crypto_type}</p>
            )}
          </div>
        </div>

        <div>
          <div style={{ height: "70px" }}>
            <p>
              Please select Network? <span className="text-danger">*</span>
            </p>
            <div className="d-flex flex-column text-start mt-2">
              <select
                className="w-100 bg-white"
                name="network_type"
                value={values.network}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="">Select...</option>
                {networkType?.length > 0
                  ? networkType?.map((network) => (
                      <option key={network?.id} value={network?.id}>
                        {network?.network_type_code}
                      </option>
                    ))
                  : []}
              </select>
              {errors.network_type && touched.network_type && (
                <p className="text-danger">{errors.network_type}</p>
              )}
            </div>
          </div>

          <div className="my-5">
            <p>
              Please select Network? <span className="text-danger">*</span>
            </p>
            <label
              className="text-start w-100  border rounded py-1 custom-file-upload"
              style={{ cursor: "pointer" }}
            >
              <input
                type="file"
                className="d-none"
                name="withdrawalImg"
                onChange={(e) => {
                  handleFileChange(e);
                }}
                onBlur={handleBlur}
              />
              Upload Qr Image
            </label>
            {/* {errors.withdrawalImg && touched.withdrawalImg && (
              <p className="text-danger text-start">{errors.withdrawalImg}</p>
            )} */}
          </div>

          <div className="d-flex flex-column " style={{ height: "70px" }}>
            <label>
              Withdrawal Address <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              placeholder="Enter Withdrawal Address"
              name="withdrawalAddress"
              values={values.withdrawalAddress}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.withdrawalAddress && touched.withdrawalAddress && (
              <p className="text-danger text-start">
                {errors.withdrawalAddress}
              </p>
            )}
          </div>
        </div>
        <button
          type={"submit"}
          className="border-0 rounded px-4 py-1 text-white"
          style={{ background: "var(--primary-color-green)" }}
        >
          Submit
        </button>
        {/* {showQrImage && (
          <>
            <>
              <div className="d-flex justify-content-center ">
                {depositAddress?.qr_image && (
                  <img src={depositAddress?.qr_image} width={200} />
                )}
              </div>

              <div className="d-flex gap-2 align-items-center ">
                <p>Deposit Address</p>
                <BsChevronRight />
              </div>
              <div className=" position-relative  d-flex justify-content-between  align-items-center">
                <p ref={copyRef}>
                  {depositAddress?.address ? depositAddress?.address : ""}
                </p>
                <div
                  onClick={() => {
                    copyText();
                  }}
                >
                  <PiCopySimpleLight
                    className="fs-4 me-3 "
                    style={{ cursor: "pointer" }}
                  />
                </div>
                {copy && (
                  <div
                    className="px-2 position-absolute   bg-white shadow bg-light rounded "
                    style={{
                      right: "-45px",
                      color: "var(--primary-color-green)",
                    }}
                  >
                    Copied
                  </div>
                )}
              </div>
            </>
            <div>
              <button
                className="border-0 rounded px-4 py-1 text-white"
                style={{ background: "var(--primary-color-green)" }}
                onClick={() => {
                  setShowConfirm(true);
                  setNetwork(null);
                  setCrypto(null);
                  setShowQrImage(false);
                //   dispatch(depositeModalHide(false));
                }}
              >
                Confirm Deposit
              </button>
            </div>
          </>
        )} */}
      </form>
    </Modal>
  );
};

export default AddWithdrawalAddress;
