import React from "react";
import noDataImage from "../assets/image/nodata.png";

const NoResultFound = () => {
  return (
    <div className="container h-100">
      <div className="d-flex flex-column  align-items-center  justify-content-center ">
        <div
          className="rounded-circle d-flex align-items-center justify-content-center"
          style={{ height: "200px", width: "200px", background: "#f7f7f7" }}
        >
          <img src={noDataImage} />
        </div>
        <div className="mt-3">
          <h3 style={{ fontWeight: "500" }}>No result found</h3>
        </div>
      </div>
    </div>
  );
};

export default NoResultFound;
