import React, { useEffect, useRef } from "react";
import ContactUsComp from "../components/ContactUsComp";
import HelmetComp from "../common/HelmetComp";
import Layout from "../common/layout/Layout";
import { useDispatch } from "react-redux";
import { showNavabr } from "../store/uiSlices/NavSlice";
import BreadCrumbs from "../common/BreadCrumbs";
import watchImage from "../assets/image/slider/156c27224ff4389c0bbe6ca8e3daeb7c.png";
import chairImage from "../assets/image/slider/c1c7140f4146c8d083042e49465f0c62.png";
import headPhones from "../assets/image/slider/a306c6a84a161de39e219011a9315d20.png";
import HeroSlider from "../common/HeroSlider";
const ContactUs = () => {
  const dispatch = useDispatch();
  const contactRef = useRef(null);
  const images = [
    { img: watchImage },
    { img: chairImage },
    { img: headPhones },
  ];

  useEffect(() => {
    const option = {
      root: null,
      threshold: 0,
    };

    const heroObsrver = (enteries) => {
      const entery = enteries[0];
      if (entery.isIntersecting) {
        dispatch(showNavabr(true));
      } else {
        dispatch(showNavabr(false));
      }
    };

    const observer = new IntersectionObserver(heroObsrver, option);
    if (contactRef.current) {
      observer.observe(contactRef.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [contactRef]);
  return (
    <Layout>
      <HelmetComp title={"Contact Us"} />
      <div ref={contactRef}>
        <HeroSlider images={images} />
        {/* <BreadCrumbs category={"Contact Us"} link={"/contact-us"} /> */}
      </div>
      <ContactUsComp />
    </Layout>
  );
};
export default ContactUs;
