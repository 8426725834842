import React, { useEffect, useRef } from "react";
import "./categories.css";
import { LuChevronRight } from "react-icons/lu";
import RightSideCards from "../../common/RightSideCards";
import SideCategoryNavHeader from "../../common/SideCategoryNavHeader";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumbs from "../../common/BreadCrumbs";
import HelmetComp from "../../common/HelmetComp";
import PriceRange from "./PriceRange";
import { showNavabr } from "../../store/uiSlices/NavSlice";
import { setSubCategoryId } from "../../store/uiSlices/SubCategoryId";
import BrandFilter from "./BrandFilter";
import SearchProductCategory from "../homeComponent/SearchProductCategory";
function CategoryComponent({
  isLoading,
  setPriceRange,
  priceRange,
  categoryName,
  categoryId,
  minValue,
  maxValue,
  checked,
  setChecked,
  allProductdata,
}) {
  const route = "/women-category";
  const allCategories = useSelector((state) => state?.AllCategory?.data?.data);
  const isShowMainCategories = useSelector(
    (state) => state?.isMainCategoryShow?.showMainCategories
  );
  const productByMainCategory = useSelector(
    (state) => state?.ProductWithMainCateg?.data?.data
  );
  const productBySubCategory = useSelector(
    (state) => state?.GetProductByCategory?.data?.data
  );
  const dispatch = useDispatch();
  const womenRef = useRef(null);
  useEffect(() => {
    const option = {
      root: null,
      threshold: 0,
    };
    const heroObsrver = (enteries) => {
      const entery = enteries[0];
      if (entery.isIntersecting) {
        dispatch(showNavabr(true));
      } else {
        dispatch(showNavabr(false));
      }
    };
    const observer = new IntersectionObserver(heroObsrver, option);
    if (womenRef.current) {
      observer.observe(womenRef.current);
    }
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);
  const searchProduct = useSelector(
    (state) => state?.searchProduct?.data?.data
  );
  const stateSearchProduct = useSelector(
    (state) => state?.searchProduct?.searchProduct
  );

  return (
    <>
      <HelmetComp title={categoryName} />
      <div ref={womenRef}>
        {/* <BreadCrumbs
          link={route}
          category={categoryName}
          prodname=""
          breadcrumb={""}
        /> */}
      </div>
      <div className="mt-5 py-4 container ">
        <div className="row">
          <div className="col-lg-3 d-none d-lg-block leftSidebar mb-3 pr-xl-4">
            <div className="d-none d-lg-block">
              {allCategories?.filter(
                (category) => category.category_name === `${categoryName}`
              )[0]?.sub_category?.length > 0 && (
                <div className="border rounded py-3 mb-4">
                  <div className="">
                    <SideCategoryNavHeader category={categoryName} />
                  </div>
                  <div
                    className="px-3 mt-3 overflow-y-scroll category-filter"
                    style={{ maxHeight: "300px" }}
                  >
                    <>
                      {allCategories
                        ?.filter(
                          (category) =>
                            category.category_name === `${categoryName}`
                        )[0]
                        ?.sub_category?.map((category, index) => (
                          <p
                            key={index}
                            onClick={() => {
                              dispatch(setSubCategoryId(category?.id));
                              dispatch(isShowMainCategories(true));
                            }}
                          >
                            <LuChevronRight />
                            &nbsp;&nbsp;
                            <span className="text-green">
                              {category.category_name}
                            </span>
                          </p>
                        ))}
                    </>

                    <p
                      onClick={() => {
                        dispatch(setSubCategoryId(null));
                        dispatch(isShowMainCategories(true));
                      }}
                    >
                      <LuChevronRight />
                      &nbsp;&nbsp;
                      <span className="text-green">Remove Filter</span>
                    </p>
                  </div>
                </div>
              )}
              <div>
                <PriceRange
                  categoryId={categoryId}
                  setPriceRange={setPriceRange}
                  priceRange={priceRange}
                  minValue={minValue}
                  maxValue={maxValue}
                />
              </div>

              <BrandFilter
                productData={allProductdata}
                checked={checked}
                setChecked={setChecked}
              />
            </div>
          </div>

          <div className=" col-lg-9 mainContent category-content">
            {searchProduct?.length > 0 && stateSearchProduct !== "" ? (
              <>
                <SearchProductCategory
                  searchProduct={searchProduct}
                  stateSearchProduct={stateSearchProduct}
                />
              </>
            ) : (
              <>
                <RightSideCards
                  productData={
                    isLoading
                      ? []
                      : isShowMainCategories
                      ? productByMainCategory
                      : productBySubCategory
                  }
                  categoryName={categoryName}
                  categoryId={categoryId}
                  urlCateg={"women-category"}
                  quantity={
                    isShowMainCategories
                      ? productByMainCategory?.length >= 2
                        ? productByMainCategory?.length - 2
                        : 0
                      : productBySubCategory?.length >= 2
                      ? productBySubCategory?.length - 2
                      : 0
                  }
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CategoryComponent;
