import React from "react";
import Layout from "../common/layout/Layout";
import { BsChevronRight } from "react-icons/bs";
import Modal from "../common/Modal";
import image from "../assets/image/product/smarWatches/545eea34fd5b86640e90d1161a76a1f3.png";
const notificationData = [
  {
    id: 1,
    title: "loremfasd jsadhfjhdsfbla blah blah blah blah blah ",
    dateString: "2023-04-32 23:47:53",
    img: image,
    messageType: "Registration Message",
  },

  {
    id: 2,
    title: "loremfasd jsadhfjhdsfbla blah blah blah blah blah ",
    dateString: "2023-04-32 23:47:53",
    img: image,
    messageType: "Informational message",
  },
  {
    id: 3,
    title: "loremfasd jsadhfjhdsfbla blah blah blah blah blah ",
    dateString: "2023-04-32 23:47:53",
    img: image,
    messageType: "Blance message",
  },
];

const Notification = () => {
  return (
    <Layout>
      <div style={{ background: "#f8f8f8" }}>
        <div className="container my-4 py-4">
          <h2 className="fw-semibold" style={{ fontSize: "24px" }}>
            Notification
          </h2>
          {notificationData.map((notify) => (
            <div
              key={notify.id}
              className="d-flex justify-content-between align-items-center bg-white p-3 my-4 rounded"
            >
              <div className="d-flex   flex-column  gap-3">
                <p>{notify.title}</p>
                <p style={{ fontSize: "10px", color: "f7f7f7" }}>
                  {notify.dateString}
                </p>
              </div>
              <div>
                <Modal icon={true} width={30}>
                  <div>
                    <img src={image} width={250} />
                    <h5 className="fw-bolder mt-3 mb-1">
                      {notify.messageType}
                    </h5>
                    <div className="d-flex   flex-column  gap-3">
                      <p>{notify.title}</p>
                      <p style={{ fontSize: "10px", color: "f7f7f7" }}>
                        {notify.dateString}
                      </p>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Notification;
