import React, { useEffect, useState } from "react";
import HeadingWithSearchBar from "../../common/HeadingWithSearchBar";
import ProductCard from "../../common/ProductCard";
import ViewAllBtnCard from "../../common/ViewAllBtnCard";
import { useSelector } from "react-redux";
import NoResultFound from "../../common/NoResultFound";
import { BASE_URL } from "../../env";

const CategorySix = ({ homeCategory }) => {
  const [data, setData] = useState([]);

  const categoryProduct = homeCategory?.filter(
    (category) => category?.menu_position === 6
  );

  return (
    <>
      {categoryProduct[0] && (
        <div className="container py-4  ">
          <div className="d-flex flex-column  gap-4">
            <HeadingWithSearchBar
              heading={`${categoryProduct[0]?.category_name}`}
            />
            {categoryProduct[0]?.products?.length > 1 ? (
              <div className="row ">
                {categoryProduct[0]?.products?.map(
                  (product, index) =>
                    index <= 10 && (
                      <div
                        key={product?.id}
                        className="col-6 col-sm-4 col-md-3 col-lg-2 mb-3 "
                      >
                        <ProductCard
                          categoryId={categoryProduct[0]?.id}
                          home={true}
                          categoryName={categoryProduct[0]?.category_name}
                          product={product}
                          id={product?.id}
                        />
                      </div>
                    )
                )}

                {categoryProduct[0]?.products?.length > 11 && (
                  <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-3 ">
                    <ViewAllBtnCard
                      categoryId={categoryProduct[0]?.id}
                      categoryName={categoryProduct[0]?.category_name}
                      count={categoryProduct[0]?.products?.length - 10}
                    />
                  </div>
                )}
              </div>
            ) : (
              <NoResultFound />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CategorySix;
