import { createSlice } from "@reduxjs/toolkit";
const light = JSON.parse(localStorage.getItem("light"));
const initialState = {
  lightDark: true,
};
const darkLightSlice = createSlice({
  name: "darkLightSlice",
  initialState,
  reducers: {
    darkLightAction: (state, action) => {
      state.lightDark = action.payload;
    },
  },
});

export const darkLightAction = darkLightSlice.actions;
export default darkLightSlice.reducer;
