import React, { useEffect, useState } from "react";
import Modal from "../../common/Modal";
import { BiUser } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { ProfileRecord } from "../../store/profileSlice/ProfileRecordSlice";
import { useFormik } from "formik";
import * as yup from "yup";
import { initializeApiWithHeaders } from "../../common/PostApiRequest";
import { toast } from "react-toastify";
import "../categories/categories.css";
import { BASE_URL } from "../../env";

const EditProfilePopUp = ({ heading, show, setShow }) => {
  const dispatch = useDispatch();
  const prfileData = useSelector(
    (state) => state?.ProfileRecordSlice?.data?.data
  );
  const [showCamera, setShowCamera] = useState(false);
  const [profileImg, setProfileImg] = useState(null);
  const [selectedImage, setSelectedImage] = useState(
    prfileData?.user_profile?.profile_picture
  );

  useEffect(() => {
    dispatch(ProfileRecord());
  }, []);
  function handleFileInputChange(event) {
    event.preventDefault();
    const file = event.target.files[0];

    setProfileImg(file);
    setSelectedImage(URL.createObjectURL(file));
  }

  const initialValues = {
    language: prfileData?.user_profile?.language
      ? prfileData?.user_profile?.language
      : "",
    gender: prfileData?.user_profile?.gender
      ? prfileData?.user_profile?.gender
      : "",
    username: prfileData?.username ? prfileData?.username : "",
    email: prfileData?.email ? prfileData?.email : "",
  };

  const userSchema = yup.object().shape({
    language: yup.string().required("Language is required"),
    gender: yup.string().required("Gender is required"),
    username: yup.string().required("Username is required"),
    email: yup.string().required("Email is required"),
  });

  const {
    errors,
    touched,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: userSchema,
    onSubmit: async (values, { resetForm }) => {
      const token = JSON.parse(localStorage.getItem("userLogin")).data.token
        .access;
      let headers = new Headers();
      headers.append("Authorization", `Bearer ${token}`);
      let formData = new FormData();
      formData.append("language", values.language);
      formData.append("gender", values.gender);
      formData.append("username", values.username);
      formData.append("email", values.email);
      if (profileImg) {
        formData.append("profile_picture", profileImg);
      }

      try {
        initializeApiWithHeaders();
        const response = await fetch(
          `${BASE_URL}auth/update-user-profile/${prfileData?.user_profile?.id}/`,
          {
            method: "PUT",
            headers: headers,
            body: formData,
          }
        );
        const res = await response.json();

        if (res.meta.status_code === 200) {
          toast.success(res?.meta?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          dispatch(ProfileRecord());
          setTimeout(() => {
            setShow(false);
            // navigate("/schedule");
          }, 1000);
        } else {
          toast.error(res?.meta?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        handleError(error);
      }
    },
  });

  const handleError = (error) => {
    if (error.response) {
      if (error.response.status === 400) {
        toast.error(error.response?.data?.meta?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else if (error.request) {
      toast(error.message);
    } else {
    }
  };
  return (
    <div>
      <Modal
        heading={heading}
        icon={true}
        width={30}
        show={show}
        setShow={setShow}
      >
        <form onSubmit={handleSubmit} className="mb-3">
          <div className="d-flex flex-column  gap-3">
            <div className="d-flex align-items-center  justify-content-between ">
              <p>Profile Image</p>
              <div
                className="position-relative rounded-circle overflow-hidden  d-flex  align-items-center  justify-content-center "
                style={{
                  width: "50px",
                  height: "50px",
                  background: "gray",
                  cursor: "pointer",
                }}
                onMouseEnter={() => {
                  setShowCamera(true);
                }}
                onMouseLeave={() => {
                  setShowCamera(false);
                }}
              >
                <div
                  className="position-absolute w-100 h-100"
                  style={{ zIndex: "1", cursor: "pointer" }}
                >
                  {selectedImage ? (
                    <img
                      className="h-100 w-100  object-fit-cover"
                      src={selectedImage}
                      alt=""
                    />
                  ) : (
                    <BiUser style={{ height: "90%", width: "90%" }} />
                  )}
                </div>

                <label
                  className={`${
                    showCamera ? "select-img-filed" : "bg-transparent "
                  } position-absolute w-100 h-100`}
                  style={{
                    zIndex: "10",
                    backgroundColor: "#ffffff78",
                    cursor: "pointer",
                  }}
                >
                  <input
                    type="file"
                    className="w-100 h-100 opacity-0"
                    style={{ cursor: "pointer" }}
                    onChange={(e) => {
                      handleFileInputChange(e);
                    }}
                  />
                </label>
              </div>
            </div>
            <div className="d-flex flex-column align-items-start">
              <label>Username</label>
              <input
                type="text"
                name="username" // Add name attribute to your select
                value={values.username}
                onChange={handleChange}
                onBlur={handleBlur}
                className="w-100 py-2 bg-white"
                placeholder="Username"
              />

              {touched.username && errors.username && (
                <div className="text-danger">{errors.username}</div>
              )}
            </div>
            <div className="d-flex flex-column align-items-start">
              <label>Email</label>
              <input
                type="email"
                name="email" // Add name attribute to your select
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                className="w-100 py-2 bg-white"
                placeholder="Email"
              />

              {touched.email && errors.email && (
                <div className="text-danger">{errors.email}</div>
              )}
            </div>

            <div className="d-flex flex-column align-items-start">
              <label>Gender</label>
              <select
                name="gender" // Add name attribute to your select
                value={values.gender}
                onChange={handleChange}
                onBlur={handleBlur}
                className="w-100 py-2 bg-white"
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Others">Others</option>
              </select>
              {touched.gender && errors.gender && (
                <div className="text-danger">{errors.gender}</div>
              )}
            </div>

            <div className="d-flex flex-column align-items-start">
              <label>Language</label>
              <select
                name="language"
                value={values.language}
                onChange={handleChange}
                onBlur={handleBlur}
                className="w-100 py-2 bg-white"
              >
                <option value="English">English</option>
                <option value="Francias">Francias</option>
                <option value="Arabic">Arabic</option>
              </select>
              {touched.language && errors.language && (
                <div className="text-danger">{errors.language}</div>
              )}
            </div>

            <button
              type="submit"
              className=" text-white border-0 px-5 py-1 rounded mt-3"
              style={{ background: "var(--primary-color-green)" }}
            >
              Update
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default EditProfilePopUp;
