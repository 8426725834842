import HomeListSlider from "../../common/HomeListSlider";
import MostPopularSlider from "../../common/MostPopularSlider";
import NewProducts from "../../common/NewProducts";

function ListProductSlider({ homeCategory }) {
  return (
    <div style={{ backgroundColor: "#f4f4f4", padding: "30px" }}>
      <div className="container my-5">
        <div className="row justify-content-between gap-5 gap-md-0 ">
          <div className="col-lg-4 col-md-6 ">
            <HomeListSlider
              heading={"Best Sale Product"}
              homeCategory={homeCategory}
            />
          </div>
          <div className="col-lg-4 col-md-6">
            <MostPopularSlider
              heading={"Most Popular Product"}
              homeCategory={homeCategory}
            />
          </div>
          <div className="col-lg-4 col-md-6">
            <NewProducts heading={"New Product"} homeCategory={homeCategory} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListProductSlider;
