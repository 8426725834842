import React from "react";
import { useDispatch, useSelector } from "react-redux";
import productDummyImage from "../assets/image/product.jpeg";
import { useNavigate } from "react-router-dom";
import { setPriceRangeFirstLoading } from "../store/uiSlices/PriceRangeFirstLoading";
import { FaCartPlus } from "react-icons/fa";
import { addToCart } from "../store/uiSlices/AddToCartSlice";
const LargeOneItemCard = ({ products, categoryName, categoryId }) => {
  const isLight = useSelector((state) => state.darkLight);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const AddToCartBtn = ({ products }) => {
    const dispatch = useDispatch();
    const handleAddtoCart = () => {
      dispatch(
        addToCart({
          ...products,
          count: 1,
          productColor: "",
          productSize: "",
          itemTotalPrice: products.offer
            ? products?.offer_price
            : products.selling_price,
        })
      );
    };
    return (
      <div
        onClick={handleAddtoCart}
        className="d-flex align-items-center  justify-content-center  gap-3 add-to-cart-btn"
      >
        <FaCartPlus /> <p>Add to cart</p>
      </div>
    );
  };

  return (
    <div
      className="large-card p-5 text-center h-100 card-hover bg-white w-100"
      style={{ border: "1px solid var(--border-color-gray)" }}
    >
      <div className="w-100">
        <p className="pb-2">{products?.category?.category_name}</p>
        <h3
          className=" fs-27 mb-0"
          style={{ cursor: "pointer" }}
          onClick={() => {
            dispatch(setPriceRangeFirstLoading(false));
            navigate(
              `${`/${categoryName}/${products?.item_name
                .split("/")
                .join(" ")}`}`,
              {
                state: {
                  path: categoryName,
                  id: categoryId,
                  product: products,
                },
              }
            );
          }}
        >
          {products?.item_name.length > 60
            ? `${products?.item_name.slice(0, 60)}...`
            : products?.item_name}
        </h3>
      </div>
      <div className=" w-100">
        <div className="large-card-img d-flex justify-content-center  align-items-center ">
          {products?.product_media?.length > 0 ? (
            <img
              src={products?.product_media?.[0]?.image}
              style={{ cursor: "pointer" }}
              // className=" women-fashion-large-card-image"
              className=""
              height={422}
              loading="lazy"
              onClick={() => {
                dispatch(setPriceRangeFirstLoading(false));
                navigate(
                  `${`/${categoryName}/${products?.item_name
                    .split("/")
                    .join(" ")}`}`,
                  {
                    state: {
                      path: categoryName,
                      id: categoryId,
                      product: products,
                    },
                  }
                );
              }}
            />
          ) : (
            <img
              src={productDummyImage}
              loading="lazy"
              // className="women-fashion-large-card-image"
              className="w-100"
            />
          )}
        </div>
      </div>
      <div>
        <p
          className="fs-17 mb-2"
          style={{
            color: `${isLight.lightDark ? "rgba(0,0,0,0.5)" : "white"}`,
          }}
        >
          {products?.item_description?.length > 200
            ? `${products?.item_description?.slice(0, 200)}...`
            : products?.item_description}
        </p>
        <div className="d-flex justify-content-center w-100">
          {" "}
          <AddToCartBtn products={products} />
        </div>
      </div>
    </div>
  );
};

export default LargeOneItemCard;
