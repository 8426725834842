import React, { useEffect, useState } from "react";
import HeadingWithSearchBar from "../../common/HeadingWithSearchBar";
import ProductCard from "../../common/ProductCard";
import ViewAllBtnCard from "../../common/ViewAllBtnCard";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../env";
import NoResultFound from "../../common/NoResultFound";

const CategoryFour = ({ homeCategory }) => {
  const categoryProduct = homeCategory?.filter(
    (category) => category?.menu_position === 4
  );

  return (
    <>
      {categoryProduct[0]?.category_name && (
        <div style={{ backgroundColor: "#f4f4f4" }}>
          <div className="container py-4  ">
            <div className="d-flex flex-column  gap-4">
              <HeadingWithSearchBar
                heading={`${categoryProduct[0].category_name}`}
              />
              <div className="row ">
                {categoryProduct[0]?.products?.length > 0 ? (
                  categoryProduct[0]?.products?.map(
                    (product, index) =>
                      index <= 10 && (
                        <div
                          key={index}
                          className="col-6 col-sm-4 col-md-3 col-lg-2 mb-3"
                        >
                          <ProductCard
                            categoryId={categoryProduct[0].id}
                            home={true}
                            categoryName={categoryProduct[0].category_name}
                            product={product}
                            id={product.id}
                          />
                        </div>
                      )
                  )
                ) : (
                  <NoResultFound />
                )}
                {categoryProduct[0]?.products?.length > 11 && (
                  <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-3">
                    <ViewAllBtnCard
                      count={categoryProduct[0]?.products?.length - 10}
                      categoryId={categoryProduct[0]?.id}
                      categoryName={categoryProduct[0].category_name}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CategoryFour;
