import React, { useEffect, useRef } from "react";
import HelmetComp from "../common/HelmetComp";
import DetailPageComp from "../components/categories/DetailPageComp";
import Layout from "../common/layout/Layout";
import { useDispatch } from "react-redux";
import { showNavabr } from "../store/uiSlices/NavSlice";
import { useLocation, useParams } from "react-router-dom";
function DetailPage() {
  const location = useLocation();
  const dispatch = useDispatch();
  const detailRef = useRef(null);
  useEffect(() => {
    const option = {
      root: null,
      threshold: 0,
    };
    const heroObsrver = (enteries) => {
      const entery = enteries[0];
      if (entery.isIntersecting) {
        dispatch(showNavabr(true));
      } else {
        dispatch(showNavabr(false));
      }
    };
    const observer = new IntersectionObserver(heroObsrver, option);
    if (detailRef.current) {
      observer.observe(detailRef.current);
    }
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);
  return (
    <div>
      <Layout>
        <HelmetComp title={"Product Details"} />
        <div ref={detailRef}>
          <DetailPageComp
            categoryName={location?.state?.path}
            categoryId={location?.state?.id}
            product={location?.state?.product}
            relatedProduct={location?.state?.relatedProduct}
          />
        </div>
      </Layout>
    </div>
  );
}

export default DetailPage;
