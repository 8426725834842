import React from "react";

import { Link } from "react-router-dom";
import { paymentOptions } from "../../../constant/FooterData";

const EndFooter = () => {
  return (
    <div className="container">
      <div
        className="d-flex align-items-center  justify-content-end flex-column flex-sm-row "
        style={{ padding: "1rem 0" }}
      >
        <div className="d-flex align-items-center gap-3">
          <p>Pay With:</p>
          <div className="d-flex align-items-center gap-3">
            {paymentOptions.map((option, index) => (
              <Link key={index}>
                <img src={option.src} width={30} height={30} />
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EndFooter;
