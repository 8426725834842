import React from "react";
import Layout from "../common/layout/Layout";
import { ApiCall } from "../common/PostApiRequest";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ForgetPasswordComp = () => {
  const handleError = (error) => {
    if (error.response) {
      if (error.response.status === 400) {
        toast.error(error.response?.data?.meta?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else if (error.request) {
      toast(error.message);
    } else {
      console.log("Error:", error.message);
    }
  };
  const initialValues = { email: "" };
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
    isSubmitting,
  } = useFormik({
    initialValues,
    validate: (values) => {
      const errors = {};
      if (!values.email) {
        errors.email = "This field is required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      }

      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const response = await ApiCall("auth/forget-password/", "POST", values);
        if (response.meta.status_code === 200) {
          toast.success(response.meta.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          // handleReset();
          setTimeout(() => {
            setSubmitting(false);
          }, 1000);
        } else {
          toast.error(response.meta.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        handleError(error);
      }
    },
  });
  return (
    <Layout>
      <div className="container py-4 d-flex justify-content-center">
        <form
          onSubmit={handleSubmit}
          className="d-flex flex-column gap-4 mt-3"
          style={{ maxWidth: "400px", width: "100%", marginBottom: "3rem" }}
        >
          <h4>Forgot Password</h4>
          <p style={{ fontSize: "15px" }}>
            Please enter your email address to receive password reset link.
          </p>
          <div className="" style={{ height: "40px" }}>
            <input
              type="text"
              className=" w-100"
              name="email"
              placeholder="Email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
            {touched.email && errors.email && (
              <p className="error text-danger" style={{ fontSize: "13px" }}>
                {errors.email}
              </p>
            )}
          </div>
          <button
            // to={"/reset-password"}
            type="submit"
            className="w-100 text-center border-0  text-white btn-hover"
            style={{
              background: "var(--primary-color-green)",
              borderRadius: "5px",
              padding: "12.5px 0",
            }}
          >
            Send Password reset link
          </button>
        </form>
      </div>
    </Layout>
  );
};

export default ForgetPasswordComp;
