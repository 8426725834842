import "./boostDataStyle.css";
import BoostDataInnerPage from "./BoostDataInnerPage";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BoostDataNavLayout from "../../../common/BoostDataNavLayout";
import { AllSetsRecord } from "../../../store/BoostDataSlice/GetAllSetSlice";
import {
  selectLoginVal,
  setLoginVal,
} from "../../../store/uiSlices/loginStateSlice";

const newbiesData = [
  {
    id: 1,
    star: [1],
    title: "FIRST SET",
    list: [
      { li: "RECEIVE FREE 50 USDT" },
      { li: "DAILY COMMISSIONS" },
      { li: "SUBMIT PRODUCTS IN PRICE RANGE OF 50" },
      { li: "WORK ONLY 10 MIN DAILY" },
      { li: "POSSIBILITY TO GO FOR 2ND SET" },
    ],
  },
  {
    id: 2,
    star: [1, 2],
    title: "SECOND SET",
    list: [
      { li: "USE SAME AMOUNT FROM 1 SET" },
      { li: "DAILY COMMISSIONS PLUS CHECK-IN BONUS" },
      { li: "SUBMIT PRODUCTS IN PRICE RANGE OF 60" },
      { li: "WORK ONLY 10 MIN DAILY" },
      { li: "FUNDS WITHDRAWABLE" },
      { li: "POSSIBILITY TO GO FOR 3RD SET" },
      { li: "RECEIVE ONLY DAILY WAGES BUT NOT SALARY" },
    ],
  },
  {
    id: 3,
    star: [1, 2, 3],
    title: "THIRD SET",
    list: [
      { li: "USE SAME AMOUNT FROM 2 SET" },
      { li: "DAILY COMMISSIONS PLUS CHECK-IN BONUS" },
      { li: "RECEIVE 500 USDT BASIC SALARY" },
      { li: "SUBMIT PRODUCTS IN PRICE RANGE OF 100" },
      { li: "WORK ONLY 10 MIN DAILY" },
      { li: "FUNDS WITHDRAWABLE" },
    ],
  },
];

const Newbies = () => {
  const dispatch = useDispatch();
  const allState = useSelector((state) => state?.GetAllSetSlice);
  const loginVal = useSelector(selectLoginVal);
  console.log("login value", loginVal);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    dispatch(AllSetsRecord()).then((result) => {
      if (result?.error?.message === "Request failed with status code 401") {
        dispatch(setLoginVal(false));
        localStorage.removeItem("userLogin");
      }
    });
  }, []);
  useEffect(() => {
    setIsLoading(true);
    if (allState?.data !== null && allState?.data !== undefined) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [allState?.data]);
  if (allState && allState?.data && allState?.data) {
    const remainingData = allState?.data?.slice(0, 3);
    const mergedData = remainingData.map((backendObj) => {
      const frontendObj = newbiesData.find((f) => f.title === backendObj.name);
      if (frontendObj) {
        return {
          ...backendObj,
          star: frontendObj.star,
          list: frontendObj.list,
        };
      } else {
        return backendObj;
      }
    });

    return (
      <div>
        <div className="container my-5">
          <BoostDataNavLayout />
          <BoostDataInnerPage
            data={mergedData}
            title={"NEWBIES"}
            isLoading={isLoading}
            subTitle={"SUBMIT THE 3 SETS TO GET YOUR BASIC SALARY."}
          />
        </div>
      </div>
    );
  }
};

export default Newbies;
