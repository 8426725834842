import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  subCategoryId: null,
};

const subCategoryIdSlice = createSlice({
  name: "subCategoryid",
  initialState,
  reducers: {
    setSubCategoryId: (state, action) => {
      state.subCategoryId = action.payload;
    },
  },
});

export const { setSubCategoryId } = subCategoryIdSlice.actions;
export default subCategoryIdSlice.reducer;
