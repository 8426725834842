import { Navigate } from "react-router-dom";

const WithoutTokenProtectedRoute = ({ isLogin, children }) => {
  if (isLogin) {
    return <Navigate to="/" replace />;
  }
  return children;
};

export default WithoutTokenProtectedRoute;
