import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../env";

export const AllSetsRecord = createAsyncThunk("AllSetsRecord", async () => {
  const uservalue = localStorage.getItem("userLogin");
  const tokenVALUE = JSON.parse(uservalue);
  const config = {
    timeout: 30000,
    cancelToken: new axios.CancelToken((cancel) => {
      // Create a cancel token and function
      setTimeout(() => {
        cancel("Request timed out");
      }, 30000);
    }),
  };

  if (tokenVALUE?.data?.token?.access) {
    config.headers = {
      Authorization: `Bearer ${tokenVALUE?.data?.token?.access}`,
    };
  }

  try {
    const response = await axios.get(
      `${BASE_URL}boost-data/get-all-set/`,
      config
    );
    console.log("response data", response?.data?.data);
    const updatedArray = await response?.data?.data.map((obj, index, array) => {
      if (obj?.user_level === "EXPERT") {
        if (index < 3) {
          return { ...obj, status: true };
        } else if (index >= array.length - 3) {
          return { ...obj, status: false };
        }
      } else {
        if (
          index > 0 &&
          array[index - 1]?.submitted_task !== array[index - 1]?.assigned_tasks
        ) {
          return { ...obj, status: true };
        }
      }

      return { ...obj, status: false };
    });
    console.log("updatedArray", updatedArray);
    return updatedArray;
  } catch (error) {
    throw error;
  }
});

const AllSetsRecordSlice = createSlice({
  name: "AllSetsRecord",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(AllSetsRecord.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(AllSetsRecord.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(AllSetsRecord.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export default AllSetsRecordSlice.reducer;
