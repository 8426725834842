import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../common/Modal";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  ApiCall,
  initializeApiWithHeaders,
} from "../../../common/PostApiRequest";
import { toast } from "react-toastify";
import { ProfileRecord } from "../../../store/profileSlice/ProfileRecordSlice";
import { BalanceAndProfit } from "../../../store/BoostDataSlice/GetBalanceandProfitSlice";
import { useEffect, useState } from "react";

const InternalTransferOTP = ({
  setInternalTransferOTPModal,
  internalTransferOTPModal,
  setShow,
  data,
  nextFucntion,
  id,
}) => {
  const [timeLeft, setTimeLeft] = useState(0);
  const dispatch = useDispatch();
  const isAuthModalShow = useSelector(
    (state) => state.loginModal.isAuthModalShown
  );
  const isCommonModalShow = useSelector(
    (state) => state.loginModal.isCommonModalShown
  );
  const [showSendButton, setShowSendButton] = useState(true);
  const [showResendButton, setShowResendButton] = useState(false);
  useEffect(() => {
    setTimeLeft(0);
    setShowSendButton(true);
    setShowResendButton(false);
    return () => {
      // Reset the state when the component is unmounted
      setTimeLeft(0);
      setShowSendButton(true);
      setShowResendButton(false);
    };
  }, [isAuthModalShow, isCommonModalShow]);
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };
  const startTimer = () => {
    setTimeLeft(120);
    setShowSendButton(false);
    setShowResendButton(false);
  };

  useEffect(() => {
    let timer;
    const decrementTime = () => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          setShowResendButton(true);
        }
        return prevTime > 0 ? prevTime - 1 : 0;
      });
    };

    if (timeLeft > 0) {
      timer = setInterval(decrementTime, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [timeLeft]);

  const initialValues = {
    email_code: "",
  };

  const numberRegex = "^[0-9]+$";
  const userSchema = yup.object().shape({
    email_code: yup
      .string()
      .matches(numberRegex, "Email code is a number field.")
      .required("This field is required."),
  });
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    handleReset,
  } = useFormik({
    initialValues,
    validationSchema: userSchema,
    onSubmit: async (values) => {
      console.log("data?.widthDraw", data?.widthDraw);
      try {
        const response = await ApiCall("auth/verify_otp/", "POST", values);
        if (response.meta.status_code === 200) {
          toast.success(response.meta.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          if (data?.widthDraw) {
            try {
              initializeApiWithHeaders();
              const formData = new FormData();
              formData.append("transaction_type", "WITHDRAWAL");

              formData.append("withdrawal_address", id);

              formData.append(
                "transaction_amount",
                `${data?.widthdraw_amount}`
              );
              const response = await ApiCall(
                "transaction/add-transaction-data/",
                "POST",
                formData
              );
              if (response.meta.status_code === 201) {
                toast.success(response.meta.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              } else {
                toast.error(response.meta.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }

              console.log(response);
            } catch (error) {
              console.log(error);
            }
          }

          if (data.intTransfer) {
            initializeApiWithHeaders();
            try {
              const transfer = await ApiCall(
                "transaction/internal-transfer/",
                "POST",
                {
                  transaction_type: "INTERNAL",
                  transaction_amount: data.balance,
                  transfer: data.transfer,
                  to: data.to,
                }
              );

              if (transfer?.meta?.status_code === 201) {
                toast.success(transfer?.meta?.message);
                const response = dispatch(ProfileRecord())
                  .then((result) => {
                    if (
                      result?.payload?.response?.data?.code ===
                      "token_not_valid"
                    ) {
                      localStorage.removeItem("userLogin");
                    }
                  })
                  .catch((error) => {
                    console.error("Error:", error);
                  });

                dispatch(BalanceAndProfit());
                if (nextFucntion) {
                  nextFucntion();
                }
              } else {
                toast.error(transfer?.meta?.message);
              }
            } catch (error) {
              if (error.response) {
                if (error.response.status === 400) {
                  console.log(
                    "error.response.status",
                    error.response?.data?.meta?.message
                  );
                  toast.error(error.response?.data?.meta?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              } else if (error.request) {
                console.log("error.request", error.request);
                toast.error(error.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              } else {
                // console.log("Error:", error.message);
              }
            }
          }
          setShow(false);
          setInternalTransferOTPModal(false);
        } else {
          toast.error(response.meta.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        toast.error(error.response.data.meta.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        handleError(error);
      }
    },
  });

  const handleResendOtp = async () => {
    startTimer();
    const uservalue = localStorage.getItem("userLogin");
    const tokenVALUE = JSON.parse(uservalue);
    console.log("email", tokenVALUE?.data?.email);
    const formData = new FormData();
    formData.append("otp_field", "email");
    formData.append("email", tokenVALUE?.data?.email);
    try {
      const response = await ApiCall("auth/resend_otp/", "POST", formData);
      if (response.meta.status_code === 200) {
        toast.success(response.meta.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(response.meta.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.log(error);
      handleError(error);
    }
  };

  return (
    <Modal
      show={internalTransferOTPModal}
      setShow={setInternalTransferOTPModal}
    >
      <form onSubmit={handleSubmit}>
        <p className="fw-bold" style={{ color: "var(--primary-color-green)" }}>
          Confirm OTP
        </p>
        <div className="">
          <input
            type="text"
            name="email_code"
            className="mt-2 w-100"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email_code}
            placeholder="Email Code"
          />
          {showSendButton && (
            <div
              className="text-white"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                bottom: "35px",
                left: "305px",
                fontWeight: "500",
                width: "45px",
                height: "35px",
                background: "#008000",
                boxShadow: "rgba(0, 0, 0, 0.1) 0 5px 5px 2px",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={handleResendOtp}
            >
              Send
            </div>
          )}

          {timeLeft > 0 && (
            <p className="text-dark">
              OTP expires in:{" "}
              <span className="fw-bold text-danger">
                0{formatTime(timeLeft)}
              </span>
            </p>
          )}

          {showResendButton && (
            <div
              className="d-flex align-items-center justify-content-between gap-3 my-1"
              style={{ fontSize: "14px" }}
            >
              <p className="text-dark">Didn't receive the email code? </p>
              <div
                type="button"
                onClick={handleResendOtp}
                style={{
                  color: "var(--primary-color-green)",
                  cursor: "pointer",
                }}
              >
                Resend
              </div>
            </div>
          )}
        </div>
        <button
          type="submit"
          className="btn trak-sub-btn my-2 text-white ps-5 pe-5 w-100 "
          style={{
            background: "#008000",
            boxShadow: `rgba(0, 0, 0, 0.1) 0 5px 5px 2px`,
          }}
        >
          Confirm
        </button>
      </form>
    </Modal>
  );
};

export default InternalTransferOTP;
