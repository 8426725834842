import React from "react";

const NotifyBadge = ({ data, setCartpage, setWishlistpage }) => {
  return (
    <div
      className="d-flex gap-3 w-100"
      style={{ cursor: "pointer" }}
      onClick={() => {
        if (data.smallTitle === "My Cart") {
          setCartpage((prev) => !prev);
        } else if (data.smallTitle === "Favorite") {
          setWishlistpage((prev) => !prev);
        }
      }}
    >
      <div
        className=" position-relative d-flex align-items-center   justify-content-center rounded-circle notify-badge"
        style={{ background: "#f7f7f7" }}
      >
        <data.icon
          className="text-secondry fs-5 fs-lg-4 notify-badge-icon"
          style={{ color: "#6b6b6b" }}
        />
        <div
          className="bg-red  d-flex align-items-center  justify-content-center position-absolute top-0 start-100 translate-middle   rounded-circle text-white notify-badge-notification"
          style={{
            background: "#fe696a",
          }}
        >
          <p className="notify-count">
            {data?.dataCount ? data?.dataCount : 0}
          </p>
        </div>
      </div>
      <div className="hide-notify-text">
        <p
          className="top-bar-small-text"
          style={{ color: "#7d879c", fontWeight: "400" }}
        >
          {data.smallTitle}
        </p>
        <h4 style={{ color: "#4b566b" }} className="top-bar-text">
          {data.title}
        </h4>
      </div>
    </div>
  );
};

export default NotifyBadge;
